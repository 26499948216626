import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { Trans, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { documentUploaderSelector } from 'app/reducers/app-config';

type MappedProps = ReturnType<typeof mapStateToProps>;

interface Props extends WithTranslation, MappedProps {}

const ReverseConfirmationComponent = (props: Props) => {
  const { confirmationImage, documentUploader } = props;
  const hasDocumentUploader = !!documentUploader;

  const documentUploaderMessage = <React.Fragment>
    <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
      <Trans i18nKey='reverseMortgageConfirmation.title'>
        Please upload the following documents:
      </Trans>
    </Typography>
    <Typography variant='body2' paragraph={true} component='div'>
      <Trans i18nKey='reverseMortgageConfirmation.paragraph1'>
        <ul>
          <li>Copy of Driver's License for Borrower & Co-Borrower (if applicable)</li>
          <li>If you skip this step, proof of ID will be required before ordering your appraisal</li>
        </ul>
      </Trans>
    </Typography>
  </React.Fragment>;

  const thankYouMessage = <React.Fragment>
    <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
      <Trans i18nKey='reverseMortgageConfirmation.thankYou.title'>
        Thank you!
      </Trans>
    </Typography>
    <Typography variant='body2' paragraph={true}>
      <Trans i18nKey='reverseMortgageConfirmation.thankYou.paragraph1'>
        Thank you for your application! We will be in touch with you shortly.
      </Trans>
    </Typography>
  </React.Fragment>;

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          { hasDocumentUploader ? documentUploaderMessage : thankYouMessage }
          <Grid container justifyContent='center'>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  documentUploader  : documentUploaderSelector(state),
  confirmationImage : state.config.confirmationImage,
});

export const ReverseConfirmation = connect(mapStateToProps, null)(ReverseConfirmationComponent);
