import React from 'react';
import { FieldNames } from 'app/models/fields/names';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { purchaseQuestionsByName } from 'app/models/questions/purchase';
import { PurchaseAppDisclosure } from 'app/components/FormAgreements/PurchaseAppDisclosure';

export const getPurchaseSectionById = (id: number): Section => {
  return PURCHASE_SECTIONS.find((section) => section.id === id) as Section;
};

const PURCHASE_SECTIONS: Section[] = [
  {
    id             : 101,
    hideBackButton : true,
    questions      : purchaseQuestionsByName([FieldNames.propertyType]),
    agreement      : <PurchaseAppDisclosure />,
  },
  {
    id       : 102,
    questions: purchaseQuestionsByName([
      FieldNames.propertyUsage,
      FieldNames.buyingProcess,
      FieldNames.propertyZip,
    ]),
  },
  {
    id       : 103,
    questions: purchaseQuestionsByName([
      FieldNames.creditScore,
      FieldNames.escrowUsage,
    ]),
  },
  {
    id       : 104,
    questions: purchaseQuestionsByName([FieldNames.loanAmount]),
  },
  {
    id       : 105,
    questions: purchaseQuestionsByName([
      FieldNames.firstName,
      FieldNames.lastName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.employCompanyName,
    ]),
    title        : 'Get your personalized loan programs and rates',
    submitAction : FormActions.handleShortFormSubmit,
    submitText   : 'Submit',
  },
];
