import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as BackendAdapter from 'i18next-multiload-backend-adapter';
import * as XHR from 'i18next-xhr-backend';
import { postMissingKey, getResourceBundle } from 'app/api/translations';
import { Endpoints } from 'app/api/helpers';
import { FormContext, getFormContextFromPath } from 'app/i18n/helpers';
import { formats } from 'app/i18n/format';
import { routes } from 'app/routes/route-list';
import { matchPath } from 'react-router';
import { getSlug, hasSlug } from 'app/util/headers';

const backendOption: XHR.I18NextXhrBackend.BackendOptions = {
  loadPath         : `${Endpoints.TRANSLATIONS}/${getSlug()}?language={{lng}}&namespace={{ns}}`,
  addPath          : `${Endpoints.TRANSLATIONS}/${getSlug()}?language={{lng}}&namespace={{ns}}`,
  crossDomain      : true,
  /* This parse function is needed for it to work */
  parse            : (data) => data,
  allowMultiLoading: true,
  ajax             : (url, options, callback, data) => {
    if (data) {
      postMissingKey(url, options, callback, data);
    } else {
      const isSlugInCookie = hasSlug();
      if (isSlugInCookie) {
        getResourceBundle(url, options, callback);
      }
    }
  },
};

const backend = {
  backend: XHR,
  backendOption,
};

const detection: object = {
  // Order and from where user language should be detected
  order: ['querystring', 'localStorage', 'cookie', 'navigator', 'path'],
};

i18n
  /* Detect user lanaguge, See: https://github.com/i18next/i18next-browser-languageDetector */
  .use(LanguageDetector)
  .use(BackendAdapter)
  .use(initReactI18next)
  .init({
    backend,
    detection,
    fallbackLng                : 'en',
    defaultNS                  : 'global',
    fallbackNS                 : ['global'],
    ns                         : ['global', 'common', 'validation', 'notifications', ...Object.keys(FormContext).map(key => FormContext[key])],
    load                       : 'languageOnly',
    debug                      : Boolean(process.env.REACT_APP_IS_STAGING_OR_DEV),
    appendNamespaceToMissingKey: Boolean(process.env.REACT_APP_IS_STAGING_OR_DEV),
    /* When on staging or developing locally, missing keys will be inserted into the `translations`.dev` table */
    // saveMissing                : Boolean(process.env.REACT_APP_IS_STAGING_OR_DEV),
    // saveMissingTo              : 'current',
    /* When language is set to 'cimode' the translations keys are returned instead of the values */
    appendNamespaceToCIMode    : Boolean(process.env.REACT_APP_IS_STAGING_OR_DEV),
    returnEmptyString          : false,
    returnObjects              : true,
    react                      : {
      bindI18nStore             : false,
      bindI18n                  : 'languageChanged languageChanging loaded',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'sup', 'ul', 'li'],   // Don't convert to <1></1> if simple react elements
    },
    interpolation               : {
      /* Formatters */
      format     : formats,
      escapeValue: false,
    },
  },
  ).then(() => {
    /* Update form context on init */
    updateFormContext(window.location.pathname);
  });

/**
 * Gets the form context from the pathname passed in
 */
export const updateFormContext = (pathname: string = '') => {
  const path      = pathname.replace('/v2', '');
  const routeList = Object.values(routes);
  let   result    = 'global';
  /* Iterate through our route list to find a match for where we are */
  for (const route of routeList) {
    const isMatch = matchPath(path, { path: route, exact: true });
    if (isMatch) {
      result = getFormContextFromPath(route);
      break;
    }
  }
  /* Set localization namespace */
  i18n.services.logger.log('Switching namespace: ', { result });
  i18n.setDefaultNamespace(result);
  return result;
};

export { i18n };
