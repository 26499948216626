import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { getSlug, getProduct } from 'app/util/headers';
import { LoanOfficerDetail } from 'app/api/loan-officer';

export const mapContactUsPayload = (payload) => {
  return {
    ...payload,
    contactUs: {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phone: payload.phone,
      message: payload.message,
    },
  };
};

export const postContactUsApplication = async (body, loanOfficer: LoanOfficerDetail) => {
  try {
    const slug = getSlug();
    const product = getProduct();
    body = { ...body, slug };
    const officerRequest = {
      slug,
      product,
      templateName  : 'contact-us-lo',
      toEmail       : loanOfficer.email,
      loanOfficerId : loanOfficer.id,
      templateData  : {
        contactUs: body.contactUs,
      },
    };
    const dataRequest = {
      description   : body.message,
      email         : body.email,
      first_name    : body.firstName,
      last_name     : body.lastName,
      loanOfficerId : loanOfficer.id,
      phone         : body.phone,
      slug,
    };
    const [officerResponse, dataResponse] = await Promise.all([
      client.post(Endpoints.CONTACT_US_EMAIL, officerRequest),
      client.post(Endpoints.CONTACT_US_DATA, dataRequest),
    ]);
    return { officerResponse, dataResponse };
  } catch(error) {
    throw error;
  }
};
