import { FieldNames } from 'app/models/fields/names';

// Field Labels
// ------------------
export const FieldLabels = {
  [FieldNames.homeValue]            : 'Home Value',
  [FieldNames.downDollar]           : 'Down Payment',
  [FieldNames.loanAmount]           : 'Loan Amount',
  [FieldNames.maxLoanAmount]        : 'Maximum Loan Amount',
  [FieldNames.prequalAmount]        : 'the Maximum Pre-Qualified Amount',
  [FieldNames.maxLumpSum]           : 'Maximum Lump Sum',
  [FieldNames.livingPropertyStreet] : 'Street Address',
  [FieldNames.livingPropertyStreet2]: 'Street Address 2',
};

export const ToggleLabels = {
  [FieldNames.borrowedDownAmount]           : 'What is the borrowed amount?',
  [FieldNames.hasAlimony]                   : 'Are you obligated to pay alimony, child support, or separate maintenance?',
  [FieldNames.hasAnotherMortgageInProgress] : 'Have you or will you be applying for a mortgage on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?',
  [FieldNames.hasBankrupt]                  : 'Have you declared bankruptcy in the last 7 years?',
  [FieldNames.hasBorrowedDown]              : 'Are you borrowing any money for this real estate transaction (e.g. money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?',
  [FieldNames.hasCommercialBankrupt]        : 'Has the applicant or any principals ever declared bankruptcy or had any judgements, repossessions, garnishments or other legal proceeding filed against them?',
  [FieldNames.hasCommercialCredit]          : 'Has the applicant or any principals ever obtained credit under another name?',
  [FieldNames.hasCommercialDebt]            : 'Is the applicant or any principals liable on debts not shown, including any contingent liabilities such as leases, endorsements, guarantees, etc.?',
  [FieldNames.hasCommercialLegalAction]     : 'Is the applicant or any principals currently a defendant in any suit or legal action?',
  [FieldNames.hasCommercialTaxDue]          : 'Are any tax obligations, including payroll or other real estate taxes, past due?',
  [FieldNames.hasCitizenship]               : 'Are you a US Citizen?',
  [FieldNames.hasConveyedTitle]             : 'Have you conveyed title to any property in lieu of foreclosure in the past 7 years?',
  [FieldNames.hasDelinquent]                : 'Are you currently delinquent or in default on a federal debt?',
  [FieldNames.hasFamilyRelationship]        : 'If this is a Purchase Transaction:  Do you have a family relationship or business affiliation with the seller of the property?',
  [FieldNames.hasForeclosed]                : 'Have you had property foreclosed upon in the last 7 years?',
  [FieldNames.hasGreenCard]                 : 'Are you a permanent resident alien?',
  [FieldNames.hasJudgements]                : 'Are there any outstanding judgments against you?',
  [FieldNames.hasLoanObligation]            : 'Have you or will you be applying for any new credit (e.g. installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?',
  [FieldNames.hasLawsuit]                   : 'Are you party to a lawsuit in which you potentially have any personal financial liability?',
  [FieldNames.hasNoteEndorsement]           : 'Are you a co-signer or guarantor on any debt or loan not disclosed on this application?',
  [FieldNames.hasOccupyPrimary]             : 'Do you intend to occupy the property as your primary residence?',
  [FieldNames.hasOtherLienOnProperty]       : 'Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g. the Property Assessed Clean Energy Program)?',
  [FieldNames.hasOwnerInterest]             : 'Have you had an ownership interest in another property in the last three years?',
  [FieldNames.hasShortSale]                 : 'Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?',
  [FieldNames.realtorShare]                 : 'Would you like to share your letter with your real estate agent?',
};

export const AutoPrequalLabels = {
  [FieldNames.hasBankrupt]   : 'Have you or your co-borrower declared bankruptcy in the last 1 year?',
  [FieldNames.hasForeclosed] : 'Have you or your co-borrower been foreclosed upon or given title or deed in lieu thereof in the last 2 years?',
  [FieldNames.hasCitizenship]: 'Are you a US Citizen or permanent resident?',
  [FieldNames.hasAlimony]    : 'Are you or your co-borrower obligated to pay alimony, child support, or separate maintenance?',
};
