import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'app/components/Button';
import { ContactLink } from 'app/components/ContactLink';
import { ImageSection } from 'app/components/ImageSection';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { FieldNames } from 'app/models/fields/names';
import { LoanPurpose } from 'app/api/rates/types';
import { FormName } from '../../models/options/enums';
import { getFormName } from '../../routes/helpers';

export const MinimumLoanAmountComponent = ({ ...props }: any) => {
  const {
    minimumLoanAmount,
    pathName,
    stopGateHelocImage,
    stopGateImage,
    t,
    values = {},
  } = props;
  const isHomeEquity = values[FieldNames.loanPurpose] === LoanPurpose.HELOC ||
    getFormName(pathName) === FormName.HOME_EQUITY_TURBO;
  const clientMinimumLoanAmount  = isHomeEquity ? values[FieldNames.minLoanAmount] : minimumLoanAmount;
  const stopGateMinimumLoanImage = isHomeEquity ? stopGateHelocImage : stopGateImage;
  return (
    <ImageSection image={stopGateMinimumLoanImage}>
      <Typography variant="h6" gutterBottom={true} classes={{ h6: 'contentTitle' }}>
        {t('stopGate.minimumLoanAmount.title', {
          minLoanAmount: clientMinimumLoanAmount,
          defaultValue : 'Oops! Our minimum loan amount is {{ minLoanAmount, currency.0 }}.',
        })}
      </Typography>
      <Typography variant="body2" gutterBottom={true}>
        {t('stopGate.minimumLoanAmount.paragraph1', {
          defaultValue:
            'We\'d like to help you with your home equity loan request. You can either go back and adjust your home value or speak with one of our helpful loan officers.',
        })}
      </Typography>
      <Button
        classes = {{ root: 'stackedButton', fullWidth: 'tabletSizeLarge' }}
        color   = "primary"
        fullWidth
        onClick = {props.getPreviousSection}
        size    = "large"
        variant = "contained"
      >
        {t('stopGate.minimumLoanAmount.button.back', { defaultValue: 'Back' })}
      </Button>
      <ContactLink
        buttonProps={{
          classes  : { root: 'stackedButton', fullWidth: 'tabletSizeLarge' },
          color    : 'primary',
          fullWidth: true,
          size     : 'large',
          variant  : 'contained',
        }}
      >
        {t('stopGate.minimumLoanAmount.button.contact', { defaultValue: 'Contact Loan Officer' })}
      </ContactLink>
    </ImageSection>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  minimumLoanAmount     : state.config.clientMinLoan,
  pathName              : state.router.location.pathname,
  stopGateHelocImage    : state.config.stopGateHelocImage,
  stopGateImage         : state.config.stopGateImage,
  ...ownProps,
});

export const MinimumLoanAmount = connect(
  mapStateToProps,
  null,
)(withTranslation()(MinimumLoanAmountComponent));
