import * as FormActions from 'app/actions/form/actions';
import * as DevToolActions from 'app/actions/dev-tool/actions';
import * as AppConfigActions from 'app/actions/app-config/actions';
import * as ExpressActions from 'app/actions/form/turbo/actions';
import * as OverdraftProtectionActions from 'app/actions/form/overdraft-protection/actions';
import { storage } from 'app/util/storage';
import { logger } from 'app/util/logger';

export type ReduxActions = typeof FormActions &
  typeof DevToolActions &
  typeof AppConfigActions &
  typeof ExpressActions &
  typeof OverdraftProtectionActions;
/**
 * Checks localStorage to see if the refer to loan officer bypass localstorage item is set from dev tools.
 */
export const referToLoBypassEnabled = () => typeof storage.local.getItem('referToLoBypass') === 'string';
export const showTransKeysEnabled = () => storage.local.getItem('i18nextLng') === 'cimode';
export const devToolLog = (msg: string) => logger.debug(`%c[DEVTOOL]: ${msg}`, 'color: blue');
