import { logger } from 'app/util/logger';

/**
 * This determins if a path or location is external or internal
 *
 * @param {string} location the location of the resource
 * @returns {boolean} true if the location is prefixed with http:// or https://
 */
const isExternalLocation = (location: string): boolean => {
  return location.startsWith('https://');
};

/**
 * This safely tries to retrieve an image from either an external or internal source.  If the source
 * is internal and does not exist in the bundle, it will fail gracefully and log the error.
 *
 * @param {string} imageLocation the external / internal location of the image
 * @returns {string} the external location of the image OR the internal reference inside the bundle prefixed with origin
 */
export function getImagePathSafely(imageLocation: string): string {
  if (imageLocation && imageLocation.length > 0) {
    if (isExternalLocation(imageLocation)) {
      return imageLocation; // externally hosted asset
    }
    try {
      const assetName = require(`../../assets/img/${imageLocation}`);
      return `${window.location.origin}${assetName}`; // asset from bundle
    } catch (error) {
      logger.error(`Image with location: ${imageLocation} is not in bundle!`);
    }
  }
  logger.error('Image location is undefined or empty!');
}
