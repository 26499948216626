import React from 'react';
import { FieldArray } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { requiredArray } from 'app/util/validators';
import { commercialVehicleInitialValue } from 'app/util/initial-values';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeLabel } from 'app/i18n/helpers';
import { Select } from '../FormFields/Select';
import {
  COMMERCIAL_NUMBER_OF_VEHICLES_OPTIONS,
  COMMERCIAL_VEHICLE_DESIRED_LOAN_TERM_OPTIONS,
  NEW_OR_USED_OPTIONS,
  YEAR_1970PLUS_TO_NOW,
} from '../../models/options/options';
import { TextInput } from '../FormFields/TextInput';
import { Currency } from '../FormFields/Currency';
import { CurrencyAllowZero } from '../FormFields/CurrencyAllowZero';

const renderCommercialVehicle = ({ ...props }) => {
  const { fields, name, t }    = props;
  const vehicleTypeField                = `${name}.${FieldNames.vehicleType}`;
  const vehicleYearField                = `${name}.${FieldNames.vehicleYear}`;
  const vehicleMakeField                = `${name}.${FieldNames.vehicleMake}`;
  const vehicleModelField               = `${name}.${FieldNames.vehicleModel}`;
  const vehicleQuantityField            = `${name}.${FieldNames.vehicleCount}`;
  const vehicleNewOrUsedField           = `${name}.${FieldNames.vehicleNewOrUsed}`;
  const vehicleTotalPurchasePriceField  = `${name}.${FieldNames.commercialVehicleTotalPurchasePrice}`;
  const vehicleTotalDownPaymentField    = `${name}.${FieldNames.commercialVehicleTotalDownPayment}`;
  const vehicleTotalTradeInValueField   = `${name}.${FieldNames.commercialVehicleTotalTradeInValue}`;
  const vehicleLoanTermDesiredField     = `${name}.${FieldNames.loanTermDesired}`;

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={ `${ field }-${ index }` } style={ { paddingBottom: 20 } }>
          <TextInput
            isOptional={ false }
            label={ localizeLabel(vehicleTypeField, t, 'Vehicle Type / Description') }
            name={ `${ field }.${ FieldNames.vehicleType }` }
          />
          <Select
            isOptional={ false }
            label={ localizeLabel(vehicleYearField, t, 'Year') }
            name={ `${ field }.${ FieldNames.vehicleYear }` }
            options = { YEAR_1970PLUS_TO_NOW }
          />
          <TextInput
            isOptional={ false }
            label={ localizeLabel(vehicleMakeField, t, 'Make') }
            name={ `${ field }.${ FieldNames.vehicleMake }` }
          />
          <TextInput
            isOptional={ false }
            label={ localizeLabel(vehicleModelField, t, 'Model') }
            name={ `${ field }.${ FieldNames.vehicleModel }` }
          />
          <Select
            label={ localizeLabel(vehicleQuantityField, t, 'Quantity') }
            name={ `${ field }.${ FieldNames.vehicleCount }` }
            options={ COMMERCIAL_NUMBER_OF_VEHICLES_OPTIONS }
          ></Select>
          <Select
            label={ localizeLabel(vehicleNewOrUsedField, t, 'New or Used') }
            name={ `${ field }.${ FieldNames.vehicleNewOrUsed }` }
            options={ NEW_OR_USED_OPTIONS }
          ></Select>
          <Currency
            label={ localizeLabel(vehicleTotalPurchasePriceField, t, 'Total Purchase Price') }
            name={ `${ field }.${ FieldNames.commercialVehicleTotalPurchasePrice }` }
          ></Currency>
          <CurrencyAllowZero
            label={ localizeLabel(vehicleTotalDownPaymentField, t, 'Total Down Payment') }
            name={ `${ field }.${ FieldNames.commercialVehicleTotalDownPayment }` }
          ></CurrencyAllowZero>
          <CurrencyAllowZero
            label={ localizeLabel(vehicleTotalTradeInValueField, t, 'Total Trade-In Value') }
            name={ `${ field }.${ FieldNames.commercialVehicleTotalTradeInValue }` }
          ></CurrencyAllowZero>
          <Select
            label={ localizeLabel(vehicleLoanTermDesiredField, t, 'Desired Loan Term ') }
            name={ `${ field }.${ FieldNames.loanTermDesired }` }
            options={ COMMERCIAL_VEHICLE_DESIRED_LOAN_TERM_OPTIONS }
          ></Select>
          { fields.length > 1 && (
            <Button
              disableRipple
              color='primary'
              classes={ {
                root: 'link',
                text: 'remove',
              } }
              onClick={ () => fields.remove(index) }
            >
              { t(`question.${ name }.button.remove`, { defaultValue: 'Remove' }) }
            </Button>
          ) }
        </div>
      );
    });

  return (
    <div>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(commercialVehicleInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Vehicle' })}
      </Button>
    </div>
  );
};

export const CommercialVehicle = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderCommercialVehicle}
        name      = {name}
        props     = {{ name, t, options }}
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
