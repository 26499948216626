import React from 'react';
import { Field } from 'redux-form';
import { dollarOrEmptyString } from 'app/util/formatters';
import { toNumber } from 'app/util/parsers';
import { VALIDATORS, required, noopValidate } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { toNonZeroNumber } from 'app/util/normalizers';

export const Currency = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  /* The validator property comes from the question object */
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;

  return (
    <Field
      component      = {renderInputBase}
      fieldPopover   = {props.fieldPopover}
      format         = {dollarOrEmptyString}
      inputGridProps = {props.inputGridProps}
      label          = {props.label}
      maxLength      = {15}
      name           = {name}
      normalize      = {toNonZeroNumber}
      parse          = {toNumber}
      popover        = {props.popover}
      type           = "tel"
      validate       = {[isRequired, conditionalValidator]}
    />
  );
};
