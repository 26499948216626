export enum Type {
  AJAX_GET_PROPERTY_INFO          = 'AJAX_GET_PROPERTY_INFO',
  AJAX_GET_PROPERTY_INFO_FAIL     = 'AJAX_GET_PROPERTY_INFO_FAIL',
  AJAX_GET_PROPERTY_INFO_SUCCESS  = 'AJAX_GET_PROPERTY_INFO_SUCCESS',
}

export interface PropertyInfoState {
  value? : number;
  high?  : number;
  low?   : number;
  taxes? : number;
}
