import { Question } from 'app/models/types';
import { FormOption } from 'app/models/options/options';
import { logger } from 'app/util/logger';

export const filterQuestions = (
  questions: Question[] = [],
  formValues: object = {},
  excludedQuestions: string[] = [],
) => {
  return questions.filter((question) => {
    const hasNoConditionals = typeof question.showIf === 'undefined';
    const isClientExcluded = excludedQuestions.indexOf(question.name) > -1;
    /* If client has requested this question not be displayed */
    if (isClientExcluded) {
      return false;
    }
    /* If no conditionals to pass, return true */
    if (hasNoConditionals) {
      return true;
    }
    const passesConditionals = question.showIf.some((condition) => {
      return condition(formValues);
    });
    if (!passesConditionals) {
      logger.debug(`${question.name} is hidden, conditionals did not pass`);
    }
    return passesConditionals;
  });
};

/**
 * This excludes options based on the option's value
 *
 * @param {FormOption<any[]} options the array of options
 * @param {string[]} excludedOptions values to exclude
 */
export const filterOptions = (options: FormOption<any>[], excludedOptions: string[]) => {
  if (options && excludedOptions) {
    return options.filter(option => !excludedOptions.includes(option.value));
  }
  return options;
};
