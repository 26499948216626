import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  COMMERCIAL_RENEW_INFO_SUMMARY,
} from './constants';
import { commercialRenewQuestionsByName } from '../questions/commercial-renew';
import { TransferToFormCondition } from '../fields/conditionals';

export const getCommercialRenewSectionById = (id: number): Section => {
  return COMMERCIAL_RENEW_SECTIONS.find((section) => section.id === id) as Section;
};
const COMMERCIAL_RENEW_SECTIONS: Section[] = [
  {
    id       : COMMERCIAL_RENEW_INFO_SUMMARY,
    questions: commercialRenewQuestionsByName([
      FieldNames.existingLoanNumber,
      FieldNames.loanRenewalDate,
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
    ]),
    hideBackButton: true,
    title : 'Please enter your Loan and Business Information',
    conditionalIf : {
      transferFormIf : [TransferToFormCondition.commercialRenewToFinancialStatement],
    },
  },
];
