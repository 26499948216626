import { FormatFunction } from 'i18next';
import moment from 'moment';
import { logger } from 'app/util/logger';
import { Language } from '@lenderful/domain';

type   CurrencyFormat = 'currency' | 'currency.0' | 'currency.1' | 'currency.2';
type   DecimalFormat  = 'decimal' | 'decimal.0' | 'decimal.1' | 'decimal.2' | 'decimal.3';
type   PercentFormat  = 'percent' | 'percent.0' | 'percent.1' | 'percent.2' | 'percent.3' | 'percent.4';
type   DateFormat     = 'date';
type   PhoneFormat    = 'phone';

/**
 * Names of the formatters available through our i18n formatting function
 */
export type I18NFormat =
  | CurrencyFormat
  | PercentFormat
  | DateFormat
  | PhoneFormat
  | DecimalFormat
  | '';

interface FormatOptions {
  lng   : Language;
  value?: number | string;
  args? : number | string;
}

interface StringOptions extends FormatOptions {
  value: string;
}

interface NumberOptions extends FormatOptions {
  value: number;
}

/**
 * Formatters used to initialize the i18next object.
 *
 * @example
 * "someKey": "The value passed into text would be uppercase {{text, uppercase}}"
 *
 * @see https://www.i18next.com/translation-function/formatting
 *
 * @param {*} [value='']
 * @param {*} [format='']
 * @param {Language} [lng='en']
 * @returns {string}
 */
export const formats: FormatFunction = (
  value : any        = '',
  format: I18NFormat = '',
  lng   : Language   = Language.ENGLISH_US,
): string => {
  /* Allow args on format types, ie ({{ value, percent.3 }} to use the percent format with 3 decimals) */
  const [formatType, args] = format.split('.');
  switch (formatType) {
    case 'currency':
      return toCurrency({ value, lng, args });
    case 'percent':
      return toPercent({ value, lng, args });
    case 'date':
      return toDate({ value, lng, args });
    case 'phone':
      return toPhone({ value, lng, args });
    case 'decimal':
      return toDecimal({ value, lng, args });
    default:
      return value && value.toString();
  }
};

/**
 * Converts a value to currency string
 *
 * @remarks
 * This will use 2 digits by default, unless args are passed in.
 *
 * @example
 * "default": "This value will show $10,000.99 {{ value, currency }}"
 * @example
 * "twoDecimal": "This value will show $10,000.99 {{ value, currency.2 }}"
 * @example
 * "noDecimal": "This value will show $10,001 {{ value, currency.0 }}"
 *
 * @param {NumberOptions} { lng, value = 0, args = 2 }
 * @returns {string} $10,000.00
 */
export const toCurrency = ({ lng, value = 0, args = 2 }: NumberOptions): string => {
  if (Number.isNaN(+value)) {
    value = 0;
  }
  const formatted = new Intl.NumberFormat(lng, {
    style                : 'currency',
    currency             : 'USD',
    minimumFractionDigits: +args,
    maximumFractionDigits: +args,
  }).format(value);

  return formatted;
};

/**
 * Converts a decimal to percent string.
 * 0.15 = 15%
 *
 * @remarks
 * This will return a rounded percent with zero decimals by default.
 *
 * @example
 * "default": "This value will show 10%, {{ value, percent }}"
 * @example
 * "noDecimal": "This value will show 10%, {{ value, percent.0 }}"
 * @example
 * "threeDecimal": "This value will show 9.974%, {{ value, percent.3 }}"
 *
 * @param {NumberOptions} { lng, value = 0, args = 0 }
 * @returns 10%
 */
export const toPercent = ({ lng, value = 0, args = 0 }: NumberOptions): string => {
  /* Handle percentages being represented as (n * 100) */
  if (value > 1) {
    value /= 100;
  }
  const formatted = new Intl.NumberFormat(lng, {
    style                : 'percent',
    minimumFractionDigits: +args,
    maximumFractionDigits: +args,
  }).format(value);

  return formatted;
};

/**
 * Converts a date to a MM/DD/YYYY string
 *
 * @param {StringOptions} { value = '' }
 * @returns {string} Date string in MM/DD/YYYY format
 */
export const toDate = ({ value = '' }: StringOptions): string => {
  const formatted = moment(value).format('MM/DD/YYYY');
  return moment(formatted).isValid() ? formatted : '';
};

/**
 * Converts a date to MM/DD/YYYY HH:mm:ss string
 * @param string Formatted date and time `MM/DD/YYYY HH:mm:ss`
 */
export const toDateTime = (value: string): string => {
  const formatted = moment(value).format('MM/DD/YYYY HH:mm:ss');
  return moment(formatted).isValid() ? formatted : '';
};

/**
 * Takes in an unformatted phone number and formats for displaying text.
 * Supports phone numbers with or without extensions. Extensions are indicated
 * by a comma.
 *
 * @param {StringOptions} { value = '' } Phone number as unformatted string
 * @returns {string} (ie: (248) 555-1212 ext 555)
 */
export const toPhone = ({ value = '' }: StringOptions): string => {
  if (!value) {
    logger.debug('No phone number passed to toPhone');
    return '';
  }
  const cleanedValue = value.replace(/\D/g, '').substring(0, 10);
  const areaCode     = cleanedValue.slice(0, 3);
  const firstThree   = cleanedValue.slice(3, 6);
  const lastFour     = cleanedValue.slice(6, 10);
  const extension    = () => {
    // Extension is separated by comma
    if (value.includes(',')) {
      const extStart = value.indexOf(',');
      return `ext ${value.slice(extStart + 1, value.length)}`;
    }
    return '';
  };

  return `(${areaCode}) ${firstThree}-${lastFour} ${extension()}`;
};

/**
 * Takes in a number value and returns a decimal.
 *
 * @example
 * // value = 0.1234567
 * "default": "Value will show 0.12, {{ value, decimal }}"
 * // value = 0.1612345
 * "oneDecimal": "Value will show 0.2, {{ value, decimal.1 }}"
 * // value = 0.1612345
 * "threeDecimal": "Value will show 0.164, {{ value, decimal.3 }}"
 *
 * @param {NumberOptions} { value = 0, args = 2 }
 * @returns {string}
 */
const toDecimal = ({ value = 0, args = 2 }: NumberOptions): string => {
  const factor         = Math.pow(10, (+args+1));
  const roundedDecimal = (Math.round(value * factor) / factor).toFixed(+args);
  return roundedDecimal;
};
