import React from 'react';
import {
  Button,
  colors,
  createStyles,
  Popover as MaterialPopover,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = (theme) => createStyles({
  titlePopover: {
    position    : "relative",
    paddingLeft : 10,
    display     : "inline-block",
    textAlign   : "right",
  },
  popoverBtn: {
    background    : colors.grey[600],
    color         : colors.common.white,
    borderRadius  : "50%",
    fontSize      : 14,
    lineHeight    : 1,
    minWidth      : 17,
    minHeight     : 16,
    padding       : 0,
    verticalAlign : "top",
    boxShadow     : "none",
    zIndex        : 2,
    fontWeight    : "bold",
  },
  popoverContent: {
    fontSize : 11,
    padding  : 5,
    maxWidth : 260,
  },
  fieldPopover: {
    position : "absolute",
    right    : 0,
  },
});

export class PopoverComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClick = (event) => {
    this.setState({
      open    : true,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, text, isSummaryPage, fieldPopover } = this.props;
    const popoverClass = isSummaryPage || fieldPopover ? classes.fieldPopover : classes.titlePopover;
    return (
      <div className={popoverClass}>
        <Button
          variant   = "contained"
          onClick   = {this.handleClick}
          className = {classes.popoverBtn}
          tabIndex  = {-1}
        >
          ?
        </Button>
        <MaterialPopover
          open       = {this.state.open}
          anchorEl   = {this.state.anchorEl}
          onClose    = {this.handleClose}
          anchorOrigin={{
            vertical  : 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical  : 'bottom',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.popoverContent}>{text}</Typography>
        </MaterialPopover>
      </div>
    );
  }
}

export const Popover = withStyles(styles)(PopoverComponent);
