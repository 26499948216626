import { FieldNames } from 'app/models/fields/names';

/**
 * Takes in the name of the field and returns the corresponding
 * autocomplete value.
 * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-autocomplete
 *
 * @param {string} name Input field name
 * @returns {string} Autocomplete value
 */
export const mapAutoCompleteName = (name: string): string => {
  const map = {
    [FieldNames.dateOfBirth]          : 'bday',
    [FieldNames.email]                : 'email',
    [FieldNames.employCity]           : 'address-level2',
    [FieldNames.employPrevCity]       : 'address-level2',
    [FieldNames.employPrevState]      : 'address-level1',
    [FieldNames.employPrevStreet]     : 'address-line1',
    [FieldNames.employPrevStreet2]    : 'address-line2',
    [FieldNames.employPrevZip]        : 'postal-code',
    [FieldNames.employState]          : 'address-level1',
    [FieldNames.employStreet]         : 'address-line1',
    [FieldNames.employStreet2]        : 'address-line2',
    [FieldNames.employZip]            : 'postal-code',
    [FieldNames.firstName]            : 'given-name',
    [FieldNames.lastName]             : 'family-name',
    [FieldNames.livingPropertyCity]   : 'address-level2',
    [FieldNames.livingPropertyState]  : 'address-level1',
    [FieldNames.livingPropertyStreet] : 'address-line1',
    [FieldNames.livingPropertyStreet2]: 'address-line2',
    [FieldNames.livingPropertyZip]    : 'postal-code',
    [FieldNames.middleName]           : 'additional-name',
    [FieldNames.phone]                : 'tel-national',
    [FieldNames.propertyCity]         : 'address-level2',
    [FieldNames.propertyState]        : 'address-level1',
    [FieldNames.propertyStreet]       : 'address-line1',
    [FieldNames.propertyStreet2]      : 'address-line2',
    [FieldNames.propertyZip]          : 'postal-code',
  };

  return map[name];
};
