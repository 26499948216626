import React from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
} from '@material-ui/core';
import { Popover } from 'app/components/Popover';

export const renderInputBase = ({ ...props }: any) => {
  const {
    input,
    label,
    customProps,
    isDate,
    fieldPopover,
    formControlClasses,
    popover,
    labelClasses,
    inputClasses,
    inputGridProps,
    rows,
    hideErrorMessage,
  } = props;
  const { touched, error } = props.meta;
  const name = props.name || input.name;

  return (
    <Grid container {...inputGridProps}>
      <Grid item classes={{ item: 'wrapper' }}>
        {popover && fieldPopover ? (
          <Popover
            fieldPopover = {fieldPopover}
            text         = {popover}
          />
        ) : null}
        <FormControl error={touched && !!error} fullWidth classes={formControlClasses}>
          {label && (
            <InputLabel color="primary" htmlFor={name} shrink={isDate} classes={labelClasses}>
              {label}
            </InputLabel>
          )}
          <Input
            id         = {name}
            classes    = {inputClasses}
            rows       = {rows}
            inputProps = {{
              autoComplete: props.autoComplete,
              autoFocus   : props.isModal ? true : props.autoFocus,
              maxLength   : props.maxLength,
              max         : props.max,
              min         : props.min,
              type        : props.type || 'text',
            }}
            {...customProps}
            {...input}
          />
          {touched && error && !hideErrorMessage && <FormHelperText>{error}</FormHelperText>}
          {/* @TODO: Add FormHelperText for warnings (will need styling) */}
        </FormControl>
      </Grid>
    </Grid>
  );
};
