import { Type } from 'app/actions/form/types';

interface ChecklistState {
  checklist: string;
}

const initialState: ChecklistState = {
  checklist: '',
};

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const checklistReducer = (state = initialState, action): ChecklistState => {
  switch (action.type) {
    case Type.AJAX_POST_CHECKLIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

// Helpers
// -------
