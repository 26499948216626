import React from 'react';
import { compose } from 'redux';
import { createStyles, Grid, Typography, withStyles, Theme, WithStyles } from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import { Button } from '../Button';
import { personalRecordAuditEventAndRedirect } from '../../actions/form/actions';
import { connect } from 'react-redux';

const styles = (theme: Theme) => createStyles({
  resultsList: {
    marginTop  : '10px',
    listStyle  : 'none',
    fontSize   : '1.15rem',
    lineHeight : '1.75rem',
    '& span'   : {
      paddingLeft: '5px',
    },
    [theme.breakpoints.up('sm')] : {
      paddingBottom: '30px',
    },
  },
});

type DispatchProps = typeof mapDispatchToProps;
interface Props extends WithStyles<typeof styles>, WithTranslation, DispatchProps {
  values: any;
}
class PersonalLoanCreditReportResultComponent extends React.Component<Props, any> {

  render() {
    const { classes, values, personalRecordAuditEventAndRedirect } = this.props;
    const loanAmount                                               = values[FieldNames.loanAmount];
    const loanTerm                                                 = values[FieldNames.loanTerm];
    const rate                                                     = values[FieldNames.rate];
    const monthlyPayment                                           = values[FieldNames.monthlyPayment];
    const email                                                    = values[FieldNames.email];
    const firstName                                                = values[FieldNames.firstName];
    const middleName                                               = values[FieldNames.middleName];
    const lastName                                                 = values[FieldNames.lastName];

    return (
      <Grid container={false}>
        <Grid item>
          <Typography variant='body2' paragraph={true}>
            <Trans i18nKey='PersonalLoan.creditReport.paragraph1'>
              Please review your loan terms which may have changed as a result of your credit score and/or other factors.
            </Trans>
          </Typography>
          <ul className={classes.resultsList}>
            <li>
              <strong>Loan Amount</strong>:
              <span>
                <Trans i18nKey='PersonalLoan.creditReport.displayLoanAmount' values={{ loanAmount }}>
                  {'{{ loanAmount, currency.0 }}'}
                </Trans>
              </span>
            </li>
            <li>
              <strong>Loan Term</strong>:
              <span>
                <Trans i18nKey='PersonalLoan.creditReport.displayLoanTerm' values={{ loanTerm }}>
                  {'{{ loanTerm }} year term'}
                </Trans>
              </span>
            </li>
            <li>
              <strong>Personal Loan Rate</strong>:
              <span>
                <Trans i18nKey='PersonalLoan.creditReport.displayRate' values={{ rate }}>
                  {'{{ rate, percent.3 }}'}
                </Trans>
              </span>
            </li>
            <li>
              <strong>Monthly Payment</strong>:
              <span>
                <Trans i18nKey='PersonalLoan.creditReport.displayMonthlyPayment' values={{ monthlyPayment }}>
                  {'{{ monthlyPayment, currency.2 }}'}
                </Trans>
              </span>
            </li>
          </ul>
          <Typography variant='body2' paragraph={true}>
            <Trans i18nKey='personalLoan.creditReport.finalStepMessage'>
              Your pre-qualification is based on the data you have provided including personal information, housing
              situation and income.  If the data you used for the pre-qualification changes at any time, please contact
              us immediately so we can address the modifications.  Please note that interest rates are subject to change
              without notice and may affect the loan amount for which you qualify. This pre-qualification is not a
              guarantee of final loan approval which is subject to a review of credit data, identity, income and other
              information.  Please acknowledge this by clicking on the "Continue" button below.  Thanks!
            </Trans>
          </Typography>
        </Grid>
        <Typography align='center' paragraph={true}>
          <Button
            classes  = {{ root: 'longButton' }}
            color    = 'primary'
            variant  = 'contained'
            rel      = 'noopener'
            onClick = {() => personalRecordAuditEventAndRedirect({ email, firstName, middleName, lastName })}>
            Continue
          </Button>
        </Typography>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  personalRecordAuditEventAndRedirect,
};

export const PersonalLoanCreditReportResult = compose(
  withStyles(styles),
  withTranslation(),
  connect(null,mapDispatchToProps),
)(PersonalLoanCreditReportResultComponent);
