import React from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { getImagePathSafely } from 'app/util/images';

export const HeroImage = ({ ...props }: any) => {
  const {
    contentContainerClass,
    heroContainerClass,
    heroGridProps,
    heroImageClass,
    heroTitleClass,
    image,
    pageTitle,
    removeFormContainer,
    showMobileImage,
    staticImage,
    titleClass,
  } = props;
  const imagePath = staticImage ? image : getImagePathSafely(image);
  const heroImage = {
    background     : `url('${imagePath}') no-repeat 50% black`,
    backgroundSize : 'cover',
    position       : 'relative',
    width          : '100%',
  } as React.CSSProperties;

  // If no custom properties are set, the default styling will be used.
  const isContentContainer = removeFormContainer ? contentContainerClass : 'formWrapper';
  const isHeroContainer    = removeFormContainer ? heroContainerClass : 'formWrapper';
  const isHeroImageClass   = heroImageClass ? heroImageClass : 'heroImage';

  /**
 * Wrapper for showing hero images on mobile.
 * Checks if the showMobileImage property is set to true.
 *
 * @param {*} { children }
 * @returns Content with a conditional wrapper
 */
  const HeroImageWrapper = ({ children }: any) => {
    return !showMobileImage ? <Hidden smDown>{children}</Hidden> : children;
  };

  return (
    <React.Fragment>
      <HeroImageWrapper>
        <Grid
          alignItems = "center"
          classes    = {{ container: `${isHeroImageClass}` }}
          container
          justifyContent = "center"
          style   = {heroImage}
          {...heroGridProps}
        >
          <Grid item classes={{ item: 'heroContent' }}>
            <Grid container justifyContent="center" classes={{ container: `${isHeroContainer}` }}>
              <Grid item>
                <Typography
                  variant = "h2"
                  classes = {{
                    root    : `${heroTitleClass}`,
                    h2: 'heroTitle',
                  }}
                >
                  {pageTitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </HeroImageWrapper>
      {!showMobileImage && (
        <Hidden mdUp>
          <Grid container classes={{ container: `${isContentContainer}` }}>
            <Grid item xs={12}>
              <Typography
                variant   = "h6"
                component = "h1"
                classes   = {{
                  root : `${titleClass}`,
                  h6: 'titleMargin',
                }}
              >
                {pageTitle}
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
      )}
      <Grid container classes={{ container: `${isContentContainer}` }}>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
