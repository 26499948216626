import React from 'react';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { IndentedWrapper } from 'app/components/IndentedWrapper';
import { useTranslation } from 'react-i18next';
import { localizeLabel } from 'app/i18n/helpers';

export const DeclarationForeclosureDate = ({ ...props }) => {
  const { label, name } = props;
  const { t }           = useTranslation();
  return (
    <IndentedWrapper>
      <SinglePastDate
        label = {localizeLabel(name, t, label)}
        name  = {name}
      />
    </IndentedWrapper>
  );
};
