import { Endpoints } from 'app/api/helpers';
import { client } from 'app/api/_client';
import { CreateInsuranceQuoteRequest, CreateInsuranceQuoteResponse } from 'app/api/insurance-quote/types';
import { getSlug } from 'app/util/headers';

/**
 * This makes an API call to the backend to create an insurance quote
 *
 * @param {CreateInsuranceQuoteRequest} request
 * @param {string} type
 * @returns {Promise<CreateInsuranceQuoteResponse>}
 */
export const createInsuranceQuote = async (request: CreateInsuranceQuoteRequest, type: string): Promise<CreateInsuranceQuoteResponse> => {
  const slug = getSlug();
  return client
    .post<CreateInsuranceQuoteResponse>(`${Endpoints.CREATE_INSURANCE_QUOTE}/${type}`, { ...request, slug })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
