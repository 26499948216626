import { Type } from 'app/actions/form/types';

interface PartialState {
  formValues : any;
  formVersion: string;
  hasChanges : boolean;
  hash       : string;
  isLoading  : boolean;
  isModalOpen: boolean;
  path       : string;
  sectionId  : number;
  uuid       : string;
  source     : string;
}

const initialState: PartialState = {
  formValues : null,
  formVersion: '',
  hasChanges : false,
  hash       : '',
  isLoading  : false,
  isModalOpen: false,
  path       : '',
  sectionId  : 0,
  uuid       : '',
  source     : '',
};

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const partialReducer = (state = initialState, action): PartialState => {
  switch (action.type) {
    case '@@redux-form/CHANGE':
      return { ...state, hasChanges: true };
    case Type.HANDLE_SAVE_PARTIAL:
      return { ...state, source: action.source };
    case Type.MODAL_PARTIAL_CLOSE:
      return { ...state, isModalOpen: false };

    case Type.AJAX_POST_PARTIAL_APP:
      return { ...state, isLoading: true };
    case Type.AJAX_POST_PARTIAL_APP_SUCCESS:
      return { ...state, uuid: action.payload.uuid, hasChanges: false, isLoading: false, isModalOpen: true };
    case Type.AJAX_POST_PARTIAL_APP_FAIL:
      return { ...state, isLoading: false };

    case Type.AJAX_PUT_PARTIAL_APP:
      return { ...state, isLoading: true };
    case Type.AJAX_PUT_PARTIAL_APP_SUCCESS:
      return { ...state, hasChanges: false, isLoading: false };
    case Type.AJAX_PUT_PARTIAL_APP_FAIL:
      return { ...state, isLoading: false };

    case Type.AJAX_GET_PARTIAL_APP:
      return { ...state, isLoading: true };
    case Type.AJAX_GET_PARTIAL_APP_SUCCESS:
      return { ...state, ...action.payload, hasChanges: false, isLoading: false };
    case Type.AJAX_GET_PARTIAL_APP_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

// Helpers
// -------
