import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { getImagePathSafely } from 'app/util/images';

export const ImageSection = ({ ...props }: any) => {
  const { children, image } = props;
  const imagePath = getImagePathSafely(image);
  const imageStyles = {
    background    : `url('${imagePath}') no-repeat 50% center / cover`,
    height        : '100%',
    display       : 'block',
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} classes={{ item: 'splitWrapper' }}>
          {children}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={6} classes={{ item: 'splitImage' }}>
            <div style={imageStyles}>&nbsp;</div>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};
