import React from 'react';
import { connect } from 'react-redux';
import { withStyles, createStyles, Grid, Typography, Button, WithStyles } from '@material-ui/core';
import { getNextSection } from 'app/actions/form/actions';
import { Modal } from 'app/components/Modals/Templates/Modal';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';
import {
  modalZipCodeValidationClose,
} from '../../actions/form/zip-code-validation/actions';
import { Alert } from '@material-ui/lab';

const styles = (theme) =>
  createStyles({
    infoWrapper: {
      marginBottom: '1rem',
      padding     : '0 2rem',
      [theme.breakpoints.down('xs')]: {
        padding: '0 1rem',
      },
    },
    buttonWrapper: {
      marginTop: '2rem',
      display  : 'flex',
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem',
      },
    },
    bottomMargin: {
      marginBottom: '1rem',
    },
    fullWidth: {
      width: '100%',
    },
  });

type DispatchProps = typeof mapDispatchToProps;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps & WithStyles<typeof styles> & WithTranslation;

class ModalZipCodeValidationComponent extends React.Component<Props, any> {

  render() {
    const { classes, isZipModalOpen, modalZipCodeValidationClose, t, zipCode } = this.props;
    return isZipModalOpen ? (
      <Modal openModal={isZipModalOpen} closeModal={modalZipCodeValidationClose}>
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.infoWrapper}>
              <Typography variant="body2" className={classes.bottomMargin}>
                <strong>
                  {t('modal.zipCodeValidation.title', { defaultValue: 'Invalid zip code' })}
                </strong>
              </Typography>
              <Typography variant="body2">
                <Grid container>
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <Trans i18nKey='validation:error.isValidZipCode' values={{ zipCode }}>
                        {`The zip code you entered is invalid: {{zipCode}}. Please enter a valid zip code.`}
                      </Trans>
                    </Alert>
                  </Grid>
                </Grid>
              </Typography>
              <div className={classes.buttonWrapper}>
                <Button
                  color     = "primary"
                  size      = "large"
                  variant   = "outlined"
                  onClick   = {() => this.props.modalZipCodeValidationClose()}
                  className = { classes.fullWidth }
                >
                  Close and Re-enter
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state: any) => {
  const { zipCodeValidation } = state;
  return ({
    isZipModalOpen  : zipCodeValidation.isZipModalOpen,
    zipCode         : zipCodeValidation.zipCode,
  });
};

const mapDispatchToProps = {
  modalZipCodeValidationClose,
  getNextSection,
};

export const ModalZipCodeValidation =  withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModalZipCodeValidationComponent)));
