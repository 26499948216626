import React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { RootState } from 'app/store/types';
import { Button } from 'app/components/Button';
import { getFormValues } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { getFormName } from 'app/routes/helpers';
import { documentUploaderSelector } from 'app/reducers/app-config';

type MappedProps = ReturnType<typeof mapStateToProps>;
type DocumentUploadButtonProps = MappedProps;

const DocumentUploadButtonComponent = (props: DocumentUploadButtonProps) => {
  const { documentUploader, formName, values } = props;

  if (!documentUploader) {
    return null;
  }

  const url = new URL(documentUploader.url);
  const urlSearchParams = url.searchParams;
  if (formName.startsWith('sb')) {
    urlSearchParams.append('name', values[FieldNames.commercialBusinessName]);
    urlSearchParams.append('phone', values[FieldNames.commercialPhone]);
  } else {
    urlSearchParams.append('name', `${values[FieldNames.firstName]} ${values[FieldNames.lastName]}`);
    urlSearchParams.append('phone', values[FieldNames.phone]);
    urlSearchParams.append('email', values[FieldNames.email]);
  }
  url.search = urlSearchParams.toString();

  return (
    <Button
      classes  = {{ root: 'longButton' }}
      color    = 'primary'
      variant  = 'contained'
      rel      = 'noopener'
      target   = '_blank'
      href     = {url.href}
    >
      <Trans i18nKey='upload.documents.button'>
        Upload Documents
      </Trans>
    </Button>
  );
};

const mapStateToProps = (state: RootState) => ({
  documentUploader     : documentUploaderSelector(state),
  formName             : getFormName(state.router.location.pathname),
  values               : getFormValues(getFormName(state.router.location.pathname))(state),
});

export const DocumentUploadButton = connect(mapStateToProps)(DocumentUploadButtonComponent);
