import { Selector, createSelector } from 'reselect';
import { Type } from 'app/actions/form/types';
import { RateInfo, ProductFamily, ProductType } from 'app/models/types';
import { LoanPurpose } from 'app/api/rates/types';
import { routes } from 'app/routes/route-list';
import { FieldNames } from 'app/models/fields/names';
import { parse, stringify } from 'query-string';

type RateState = RateInfo[];

const initialState: RateState = [];

export const ratesReducer = (state = initialState, action): RateState => {
  switch (action.type) {
    case Type.AJAX_FETCH_RATES_SUCCESS:
      return action.payload;

    case Type.AJAX_FETCH_PERSONAL_LOAN_RATES_SUCCESS:
    case Type.AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_SUCCESS:
    case Type.AJAX_FETCH_AUTO_LOAN_RATES_SUCCESS:
    case Type.AJAX_FETCH_CREDIT_BOOSTER_RATES_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

/**
 * HELOC and Home Equity Rates returned from a selector
 */
export interface SelectorEquityRateInfo {
  apr                        : number;
  loanType                   : string;
  pmiMonthlyPayment          : number;
  principalAndInterestPayment: number;
  productFamily              : ProductFamily;
  productType                : ProductType;
  productName                : string;
  term                       : number;
  rate                       : number;
  loanAmount?                : number;
}

export interface CreditBoosterProgram {
  title: string;
  value: string;
}

export const ratesSelector = (state): RateInfo[] => state.rates;

/**
 * Pulls the auto prequal rate out of the rates array or returns an empty object if it doesn't exit
 */
export const prequalRateSelector = createSelector([ratesSelector], ([rate]) => rate || {});

/**
 * Returns the program details for the CreditBooster Loan
 */
export const creditBoosterProgramSelector: Selector<any, CreditBoosterProgram[]> = createSelector(
  [ratesSelector],
  (rates = []) => {
    return rates.map(rate => ({ title: rate.productName, value: rate.productName }));
  },
);

/**
 * Adds computed rate values to the RateInfo response from the API
 *
 * @param {RateInfo} rate Partial RateInfo
 * @param {boolean} isPrequal Flag for showing if rates are prequal
 * @returns {RateInfo} Complete RateInfo
 */
export const mapComputedRateValues = (rate: RateInfo, isPrequal: boolean, loanPurpose: LoanPurpose): RateInfo => {
  /* Home equity rates already contain loanType */
  if (!rate.loanType) {
    rate.loanType = rate.productName;
    rate.applyUrl = getApplyUrl(rate, loanPurpose);
  }
  /* Subtract PMI from all non prequal rates */
  if (!isPrequal) {
    rate.principalAndInterestPayment = monthlyPaymentWithoutPmi(rate);
  }
  return rate;
};

/**
 * Takes in a rate and returns the princpialAndInterestAmount
 * minus the private mortgage insurance amount.
 *
 * @param {RateInfo} rate
 * @returns {number} princpialAndInterest payment without PMI
 */
export const monthlyPaymentWithoutPmi = (rate: RateInfo): number => {
  const pmi = rate.pmiMonthlyPayment || 0;
  return rate.principalAndInterestPayment - pmi;
};

type ApplyUrlRateInfo = Pick<RateInfo, 'rate' | 'term' | 'quoteFees' | 'loanType' | 'productName'>;

/**
 * Computes applyUrl based on rate info for use in the "Apply" buttons
 *
 * @param {RateInfo} rateInfo Rate info missing applyUrl property
 * @returns {string} URL for taking users to long form
 */
const getApplyUrl = (rateInfo: ApplyUrlRateInfo, loanPurpose: LoanPurpose): string => {
  const path = loanPurpose === LoanPurpose.PURCHASE ? routes.applicationPurchase : routes.applicationRefinance;

  // destructure to limit what fields we use for query string
  const { rate, term, quoteFees, loanType, productName }: ApplyUrlRateInfo = rateInfo;
  const queryParams = stringify({ rate, term, quoteFees, loanType, productName });

  return `/v2${path}?${queryParams}#101`;
};

/**
 * Parses URL to look for specific query parameters on the "Apply" URL generated in PDF
 *
 * @returns form values with populated rate info data based on the query string params
 */
export const getApplyRatesFromQueryString = (): object => {
  const { search } = window.location;
  if (search) {
    const parsedRateInfo: ApplyUrlRateInfo = parse(search);
    if (parsedRateInfo.rate) {
      return {
        [FieldNames.selectedRate]: {
          loanType    : parsedRateInfo.loanType,
          productName : parsedRateInfo.productName,
          quoteFees   : parsedRateInfo.quoteFees,
          rate        : parsedRateInfo.rate,
          term        : parsedRateInfo.term,
        },
        [FieldNames.fee]      : parsedRateInfo.quoteFees,
        [FieldNames.loanTerm] : parsedRateInfo.term,
        [FieldNames.loanType] : parsedRateInfo.loanType,
        [FieldNames.rate]     : parsedRateInfo.rate,
      };
    }
  }
  return {};
};
