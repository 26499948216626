import React from 'react';
import {
  Grid,
  Typography,
  withStyles,
  colors,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeLabel, localizeDisplayValue } from 'app/i18n/helpers';
import { I18NFormat } from 'app/i18n/format';
import { FieldNames, FieldName } from 'app/models/fields/names';
import { ProductFamily, ProductType } from '@lenderful/domain';

const styles = (theme) =>
  createStyles({
    fieldsContainer: {
      margin: '1.75rem 0 0',
      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },
    },
    lastColumn: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '25px',
      },
    },
    fieldWrapper: {
      marginBottom: '1.3rem',
      [theme.breakpoints.down('xs')]: {
        borderBottom: `1px solid ${colors.grey[300]}`,
        marginBottom: 0,
        padding     : '1rem 0',
      },
    },
  });

interface PrequalField {
  name       : FieldNames;
  label      : string;
  i18nFormat?: I18NFormat;
}

const prequalFields: PrequalField[] = [
  { name: FieldNames.prequalAmount, label: 'Pre-Qualified Amount', i18nFormat: 'currency.0' },
  { name: FieldNames.prequalLetterAmount, label: 'Letter Amount', i18nFormat: 'currency.0' },
  { name: FieldNames.loanAmount, label: 'Loan Amount', i18nFormat: 'currency.0' },
  { name: FieldNames.downDollar, label: 'Down Payment', i18nFormat: 'currency.0' },
  { name: FieldNames.loanType, label: 'Loan Term/Type' },
  { name: FieldNames.rate, label: 'Rate', i18nFormat: 'percent.3' },
  { name: FieldNames.monthlyPayment, label: 'Monthly Payment', i18nFormat: 'currency' },
  { name: FieldNames.prequalExpiration, label: 'Pre-Qualification Expiration', i18nFormat: 'date' },
];

/* Used to iterate over the prequal summary fields (split in two) */
const prequalSummaryFieldIterable = [
  prequalFields.slice(0, prequalFields.length / 2),
  prequalFields.slice(prequalFields.length / 2, prequalFields.length),
];

interface PrequalSummaryFieldProps extends WithStyles<typeof styles> {
  paragraphText?: string;
  editFieldName?: string;
  editFieldComponent?: JSX.Element;
  isEdit?: boolean;
  renderedButtons?: ({ isMobile }) => JSX.Element;
  values            : {
    [key in FieldName]?: any;
  };
}

export const PrequalSummaryFields = withStyles(styles)((props: PrequalSummaryFieldProps) => {
  const { values = {}, classes } = props;
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={classes.fieldsContainer}>
          {prequalSummaryFieldIterable.map((fields, i) => (
            <Grid item xs={12} sm={6} key={i} className={i > 0 ? classes.lastColumn : ''}>
              {fields.map(({ i18nFormat, label, name }, j) => (
                <div className={classes.fieldWrapper} key={label + j}>
                  <Typography variant="body1" classes={{ body1: 'readOnlyLabel' }}>
                    {localizeLabel(name, t, label)}
                  </Typography>
                  <Typography variant="body2" classes={{ body2: 'readOnlyValue' }}>
                    { props.values[FieldNames.rate].productType === ProductType.ARM && name === FieldNames.loanType ?
                      (localizeDisplayValue(name, t, `${ProductFamily.CONVENTIONAL} ${ProductType.ARM}`, i18nFormat)) :
                      (localizeDisplayValue(name, t, values[name], i18nFormat))
                    }
                  </Typography>
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});
