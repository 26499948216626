import { Type } from 'app/actions/form/types';
import { RootState } from '../store/types';

interface ReportState {
  avmReportId? : number;
}

const initialState: ReportState = {
  avmReportId : undefined,
};

export const reportReducer = (state = initialState, action): ReportState => {
  switch (action.type) {
    case Type.HANDLE_AVM_RESPONSE:
      return { ...state, avmReportId: action.avmReportId };
    default:
      return state;
  }
};

export const selectAVMReportId = (state: RootState): number | undefined => {
  return state.report.avmReportId;
};
