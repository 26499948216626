export enum FormName {
  AUTOLOAN                   = 'autoloan',
  AUTOPREQUAL                = 'autoprequal',
  CLOSING_COSTS              = 'closingCosts',
  COMMERCIAL                 = 'commercial',
  COMMERCIALBRIDGELOAN       = 'commercialBridgeLoan',
  COMMERCIALEQUIPMENT        = 'commercialEquipment',
  COMMERCIALPURCHASE         = 'commercialPurchase',
  COMMERCIALREFINANCE        = 'commercialRefinance',
  COMMERCIALRENEW            = 'commercialRenew',
  COMMERCIALVEHICLE          = 'commercialVehicle',
  CONTACT                    = 'contact',
  CONSTRUCTION               = 'construction',
  CREDITBOOSTER              = 'creditBooster',
  CREDITCARD                 = 'creditCard',
  FINANCIALSTATEMENT         = 'financialStatement',
  HOMEEQUITY                 = 'homeEquity',
  HOME_EQUITY_RATES          = 'homeEquityRates',
  HOME_EQUITY_TURBO          = 'homeEquityExpress',
  HOMEEQUITYLONGFORM         = 'homeEquityLongForm',
  INSURANCE_QUOTE            = 'insuranceQuote',
  LAND                       = 'land',
  LONGFORM                   = 'longForm',
  OTHER_VEHICLE              = 'otherVehicle',
  OVERDRAFTPROTECTION        = 'overdraftProtection',
  PERSONALLOAN               = 'personalLoan',
  REFINANCE_TURBO            = 'refinanceTurbo',
  REVERSE                    = 'reverse',
  SBLINEOFCREDIT             = 'sbLineOfCredit',
  SBTERM                     = 'sbTerm',
  SHORT_FORM_PURCHASE        = 'shortFormPurchase',
  SHORT_FORM_REFINANCE       = 'shortFormRefinance',
}
export enum FormType {
  PURCHASE  = 'purchase',
  REFINANCE = 'refinance',
  PREQUAL   = 'prequal'
}
export enum PropertyType {
  SINGLE                          = 'SFH',
  CONDO                           = 'CON',
  MODULAR                         = 'MOD',
  MULTI                           = '24U',
  MANUFACTURED_HOME_ON_FOUNDATION = 'MAN',
}
export enum PropertyUsage {
  PRIMARY    = 'PRI',
  SECONDARY  = '2ND',
  INVESTMENT = 'INV',
  UNKNOWN    = 'UNK'
}
export enum BuyingProcess {
  SIGNED   = 'Signed Purchase Agreement',
  PENDING  = 'Offer Pending',
  SHOPPING = 'Shopping',
  OTHER    = 'Other'
}
export enum PurchaseTimeline {
  SHORT  = '0-3 Months',
  MEDIUM = '3-6 Months',
  LONG   = '6+ Months'
}
export enum CreditScore {
  HIGH   = '740',
  MEDIUM = '739',
  LOW    = '679'
}
export enum EscrowUsage {
  YES = 'Yes',
  NO  = 'No'
}
export enum AccountType {
  CHECKING      = 'CH',
  SAVINGS       = 'SA',
  RETIREMENT    = 'RE',
  INVESTMENT    = 'IN',
  SALE_PROCEEDS = 'SP',
  GIFT          = 'GI',
  EARNEST_MONEY = 'EM',
  OTHER         = 'OT'
}
export enum LivingSituation {
  RENT   = 'R',
  OWN    = 'O',
  NORENT = 'X'
}
export enum YesOrNo {
  YES = 'Yes',
  NO  = 'No'
}
export enum BankruptType {
  SEVEN    = 'SEVEN',
  ELEVEN   = 'ELEVEN',
  TWELVE   = 'TWELVE',
  THIRTEEN = 'THIRTEEN'
}
export enum BankruptAge {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN
}
export enum TitleHeld {
  SOLE   = 'S',
  SPOUSE = 'JS',
  OTHER  = 'JO'
}
export enum Purpose {
  PURCHASE = 'PURCHASE',
  PREQUAL  = 'PREQUAL'
}
export enum BorrowerType {
  PRIMARY    = 'PRIMARY',
  COBORROWER = 'CO-BORROWER',
  RELATIVE   = 'RELATIVE',
}
export enum LandingLoanType {
  PURCHASE  = 'PURCHASE',
  REFINANCE = 'REFINANCE'
}
export enum MaritalStatus {
  MARRIED   = 'Married',
  UNMARRIED = 'Unmarried',
  SEPARATED = 'Separated'
}
export enum LiabilityUsage {
  PRIMARY_HOME    = 'PRIMARY_HOME',
  OTHER_HOME      = 'OTHER_HOME',
  NOT_MY_HOME     = 'NOT_MY_HOME',
  COLLATERAL_HOME = 'COLLATERAL_HOME',
}
export enum PartialSource {
  SAVE     = 'SAVE',
  REFERRED = 'REFERRED_TO_LO'
}
export enum DayOfWeek {
  ANY       = 'Any Day',
  MONDAY    = 'Monday',
  TUESDAY   = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY  = 'Thursday',
  FRIDAY    = 'Friday'
}
export enum TimeOfDay {
  ANY    = 'Any Time',
  NINE   = '9am',
  TEN    = '10am',
  ELEVEN = '11am',
  TWELVE = '12pm',
  ONE    = '1pm',
  TWO    = '2pm',
  THREE  = '3pm',
  FOUR   = '4pm'
}

export enum DeclarationType {
  BANKRUPT                       = '92',
  FORECLOSED                     = '93',
  OBLIGATED_ALIMONY_CHILDSUPPORT = '97',
  BORROWED_DOWN_PAYMENT          = '98',
}

export enum NameSuffix {
  JR  = 'Jr',
  SR  = 'Sr',
  II  = 'II',
  III = 'III',
  IV  = 'IV',
  V   = 'V',
}

export enum CitizenshipType {
  US_CITIZEN        = '01',
  PERM_RESIDENT     = '03',
  NON_PERM_RESIDENT = '05',
}

export enum MilitaryExperienceType {
  NONE                         = 'NO',
  ACTIVE_DUTY                  = 'AC',
  RETIRED_DISCHARGED_SEPARATED = 'RT',
  RESERVE_OR_NATIONAL_GUARD    = 'RS',
  SURVIVING_SPOUSE             = 'SP',
}

export enum EmploymentOwnershipShare {
  LESS_THAN_25_PERCENT             = 'LT',
  GREATER_THAN_OR_EQUAL_25_PERCENT = 'GT',
}

export enum FieldRequirementType {
  REQUIRED = 'R',
  OPTIONAL = 'O',
  HIDDEN   = 'H',
}

export enum VehiclePurchasePlan {
  PURCHASE_FROM_DEALER_NEW  = 'N',
  PURCHASE_FROM_DEALER_USED = 'U',
  REFINANCE_CURRENT_VEHICLE = 'R',
  LEASE_BUYOUT              = 'L',
  PRIVATE_PARTY_PURCHASE    = 'P'
}

export enum DesiredLoanTerm {
  MONTHS_12 = 12,
  MONTHS_24 = 24,
  MONTHS_36 = 36,
  MONTHS_48 = 48,
  MONTHS_60 = 60,
  MONTHS_72 = 72,
  MONTHS_84 = 84
}

export const CONSUMER_FORMS = [
  FormName.AUTOLOAN,
  FormName.PERSONALLOAN,
  FormName.OTHER_VEHICLE,
  FormName.CREDITCARD];

export enum ConstructionLoanType {
  NOTSURE  = 'N',
  ONE      = 'ONECLOSING',
  TWO      = 'TWOCLOSING',
}

export enum CommercialPurchasePropertyType {
  HOTELS     = 'Hotels/Hospitality',
  INDUSTRIAL = 'Industrial',
  RETAIL     = 'Retail',
  OFFICE     = 'Office',
  MIXED_USE  = 'Mixed Use',
  OTHER      = 'Other'
}

export enum CommercialEntityType {
  ASSOCIATION         = 'ASSOCIATION',
  CORPORATION         = 'CORPORATION',
  GOVERNMENT_ENTITY   = 'GOVERNMENT_ENTITY',
  INDIVIDUAL          = 'INDIVIDUAL',
  LLC                 = 'LLC',
  NON_PROFIT          = 'NON_PROFIT',
  PARTNERSHIP         = 'PARTNERSHIP',
  PROPRIETORSHIP      = 'PROPRIETORSHIP',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  S_CORP              = 'S_CORP',
  TRUST               = 'Trust',
}

export enum CommercialBusinessType {
  CONSTRUCTION   = 'Construction',
  MANUFACTURING  = 'Manufacturing',
  RETAIL         = 'Retail',
  SERVICE        = 'Service',
  WHOLESALE      = 'Wholesale',
  OTHER          = 'Other'
}

export enum CommercialTrends {
  INCREASING     = 'Increasing',
  DECREASING     = 'Decreasing',
  STABLE         = 'Stable'
}

export enum CommercialLoanHolder {
  BUSINESS        = 'Business',
  INDIVIDUAL      = 'Individual',
}

export enum CommercialLoanPurpose {
  EQUIPMENT           = 'Equipment',
  BRIDGE_LOAN         = 'Bridge Loan',
  TERM_LOAN           = 'Term Loan',
  INVENTORY_FINANCING = 'Inventory Financing',
  LINE_OF_CREDIT      = 'Line of Credit',
  OTHER               = 'Other',
}

export enum NumberOfStructures {
  ZERO      = '0',
  ONE       = '1',
  TWO       = '2',
  THREE     = '3',
  FOUR      = '4',
  FIVE_PLUS = '5+',
}

export enum CreditBoosterAccountTypes {
  SAVINGS = 'Savings',
  DEPOSIT  = 'Certificate of Deposit'
}

export enum EquipmentOrInventory {
  EQUIPMENT = 'Equipment',
  INVENTORY  = 'Inventory'
}

export enum CommercialLoanTiming {
  WITHIN_1_WEEK      = 'Within 1 Week',
  WITHIN_1_MONTH     = 'Within 1 Month',
  ONE_THROUGH_THREE  = '1-3 Months',
  THREE_PLUS         = '3+ Months',
}

export enum NumberOfVehicles {
  ONE       = '1',
  TWO       = '2',
  THREE     = '3',
  FOUR      = '4',
  FIVE_PLUS = '5+',
}

export enum NewOrUsed {
  NEW       = 'New',
  USED      = 'Used',
}

export enum BridgeOrCredit {
  BRIDGE  = 'Bridge Loan',
  CREDIT  = 'Line of Credit'
}

export enum NumberOfStories {
  ONE   = '1',
  TWO   = '2',
  THREE = '3',
  FOUR  = '4',
}

export enum NumberOfUnits {
  ONE   = '1',
  TWO   = '2',
  THREE = '3',
  FOUR  = '4',
}

export enum ConstructionHomeType {
  FRAME          = 'FRAME',
  MASONRY        = 'MASONRY',
  MASONRY_VENEER = 'MASONRY_VENEER',
  FIRE_RESISTANT = 'FIRE_RESISTANT',
  LOG            = 'LOG',
}

export enum ConstructionRoofingType {
  COMPOSITION_SHINGLE   = 'COMPOSITION_SHINGLE',
  ARCHITECTURAL_SHINGLE = 'ARCHITECTURAL_SHINGLE',
  COMPOSITION_OVER_WOOD = 'COMPOSITION_OVER_WOOD',
  METAL                 = 'METAL',
  SLATE                 = 'SLATE',
  PLEXIGLASS            = 'PLEXIGLASS',
  WOOD                  = 'WOOD',
  TILE                  = 'TILE',
  TAR_AND_GRAVEL        = 'TAR_AND_GRAVEL',
  ROLL                  = 'ROLL',
  RUBBER                = 'RUBBER',
  PLASTIC               = 'PLASTIC',
  FIBERGLASS            = 'FIBERGLASS',
  FOAM                  = 'FOAM',
  ALL_OTHER             = 'ALL_OTHER',
}

export enum DynamicFormFieldArrayType {
  ADDRESS             = 'Address',
  CURRENCY            = 'Currency',
  CURRENCY_ALLOW_ZERO = 'CurrencyAllowZero',
  DATE_FUTURE         = 'DateFuture',
  DATE_PAST           = 'DatePast',
  DATE_SINGLE         = 'DateSingle',
  EMAIL               = 'Email',
  INTEGER_ALLOW_ZERO  = 'IntegerAllowZero',
  NAME                = 'Name',
  PERCENT             = 'Percent',
  PHONE               = 'Phone',
  RADIO_BUTTON        = 'RadioField',
  SELECT              = 'Select',
  STATE               = 'State',
  TEXT_INPUT          = 'TextInput',
  YEAR                = 'Year',
  Y_N_SWITCH_TOGGLE   = 'YesNoSwitchToggle',
  ZIP_CODE            = 'ZipCode',
}

export enum CommercialBusinessOwnershipType {
  MINORITY = 'Minority',
  NA = 'N/A',
  WOMEN = 'Women',
  LGBTQI = 'LGBTQI+',
}

export enum CommercialCollateralDescriptionType {
  ABA                  = 'All Business Assets',
  COMMERCIAL_EQUIPMENT = 'Commercial Equipment',
  COMMERCIAL_VEHICLE   = 'Commercial Vehicle',
}

export enum CurrentLoanType {
  THIRTY  = '30 Year Fixed',
  TWENTY  = '20 Year Fixed',
  FIFTEEN = '15 Year Fixed',
  TEN     = '10 Year Fixed',
  BALLOON = 'Balloon Loan',
  ARM     = 'ARM',
  OTHER   = 'Other',
}
