/*
 * REDUX-FORM PARSERS
 *
 * These are functions that are passed into the "parse"
 * param on an <Input /> component. These are used to parse
 * the value input by the user to the type we want stored in
 * the redux store.
 *
 * See: https://goo.gl/6Ldgvn
 *
 * @param {any} value The value as input by the user
 * @param {string} name The name of the input field
 *
 * @returns {any} The value to be stored in the redux store
 */

/**
 * Parses the user input and returns a number to be stored in the redux store.
 *
 * @param {(string | number)} value
 * @returns {number}
 */
export const toNumber = (value: string | number): number => {
  /* Return 0 if value is undefined or an empty string */
  if (typeof value === 'undefined' || value === '') {
    return 0;
  }
  /* Return 0 if value is NaN */
  if (typeof value === 'number' && Number.isNaN(value)) {
    return 0;
  }
  return typeof value === 'number'
    ? value
    : parseFloat(value.replace(/[^0-9.]/g, ''));
};

/**
 *  Parses the user input to return one rounded down decimal place
 *
 * @param {*} value
 * @returns {*}
 */
export const toDecimalNumber = (value: any): any => {
  const roundedDown = Math.floor(value * 10) / 10;
  if (value.charAt(value.length - 1) === '.') {
    return value;
  } else {
    return roundedDown;
  }
};
