import React from 'react';
import {
  CircularProgress,
  createStyles,
  Fade,
  withStyles,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { Button } from 'app/components/Button';
import { useTranslation } from 'react-i18next';

const styles = (theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      position  : 'absolute',
      top       : '50%',
      left      : '50%',
      marginTop : -12,
      marginLeft: -12,
    },
  });

const DelayingAppearance = ({ ...props }) => {
  const { classes, children, buttonProps, loading, success } = props;
  const { t }              = useTranslation();
  const submitBaseText     = children ? children : t('global:button.submit', { defaultValue: 'Submit' });
  const pendingText        = props.pendingText || t('global:button.pending', { defaultValue: 'Submitting' });
  const successText        = props.successText;
  const CompletedWithCheck = () => (
    <React.Fragment>
      {successText}
      <Check classes={{ root: 'buttonCheck' }} />
    </React.Fragment>
  );

  const renderButtonText = () => {
    if (loading) {
      return pendingText;
    } else if (successText && success) {
      return <CompletedWithCheck />;
    } else {
      return submitBaseText;
    }
  };

  return (
    <div className={classes.wrapper}>
      <Button {...buttonProps}>
        {renderButtonText()}
      </Button>
      {loading ? (
        <Fade
          in            = {true}
          style         = {{ transitionDelay: '500ms' }}
          unmountOnExit = {true}
        >
          <CircularProgress size={24} className={classes.buttonProgress} />
        </Fade>
      ) : null}
    </div>
  );
};

export const ButtonDelay: any = withStyles(styles)(DelayingAppearance);
