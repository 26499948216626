/*
 * REDUX-FORM NORMALIZERS
 *
 * These are functions that are passed into the "normalize"
 * param on an <Input /> component
 *
 * See: https://goo.gl/fZEFfe
 *
 * @param {any} value The value entered by the user
 * @param {any} previousValue The previous value for the field
 * @param {any[]} allValues All values in the entire form
 * @param {any[]} previousAllValues Previous values in the entire form
 *
 * @returns {any} New value to be set to the field
 */

/**
 * Normalizes the provided string or number to a string
 * only containing numbers
 *
 * @param {(string | number)} value A string or number
 * @return {string} A string of only numbers
 */
export const toNumberString = (value: string | number) =>
  typeof value === 'string'
    ? value.replace(/[^0-9]/g, '')
    : value.toString().replace(/[^0-9]/g, '');

/**
 * Normalizes the provided number to display an empty string
 * if the value is zero. This is used to ensure the materialize
 * input field label is full size when there is no value passed in
 *
 * @param {number} value A number input by user
 * @returns {string | number}
 */
export const toNonZeroNumber = (value: number): string | number => {
  if (value === 0) {
    return '';
  } else {
    return value;
  }
};

/**
 * Normalizes any NaN values to zero
 *
 * @param {*} value
 * @returns {*}
 */
export const toNumberOrZero = (value: any): any => {
  if (typeof value !== 'undefined') {
    if (Number.isNaN(value)) {
      return 0;
    }
  }
  return value;
};

/**
 * Normalizes a phone number, takes in user input
 * and returns phone number in (123) 456-7890 format
 *
 * @param {string} value Phone input value
 * @returns {string} Normalized phone number
 */
export const normalizePhone = (value: string): string => {
  if (!value) {
    return value;
  }
  const numbersOnly = toNumberString(value);
  // (123)
  if (numbersOnly.length <= 3) {
    return `${numbersOnly}`;
  }
  // (123) 456
  if (numbersOnly.length <= 7) {
    return `(${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(3)}`;
  }
  // (123) 456 7890
  return `(${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(
    3,
    6,
  )}-${numbersOnly.slice(6, 10)}`;
};

/**
 * Normalizes a social security number, takes in user input with dashes
 * and returns social security number without dashes. (ex: 123456789)
 *
 * @param {string} value SSN input value
 * @returns {string} Normalized social security number
 */
export const toNormalizedSsn = (value: string): string => {
  if (!value) {
    return '';
  }
  return toNumberString(value);
};

/**
 *
 * @param value Normalizes a name to only allow whistlisted characters:
 * upper and lowercase letters, space, apostrophe, and hyphen
 *
 */
export const toNormalizedName = (value: string): string => {
  if (!value) {
    return '';
  }
  return value.replace(new RegExp('[^A-za-z\\-\\s\']', 'g'), '');
};

/**
 * Normalizer for returning a value less than the field provided
 *
 * @param {string} otherField The name of the field to compare with
 * @returns The lesser value
 */
export const lessThan = (otherField: string) => {
  return (value: number, previousValue: number, allValues: any) => {
    return value < allValues[otherField] ? value : previousValue;
  };
};

/**
 * Normalizer for returning a value less than the field provided
 *
 * @param {string} otherField The name of the field to compare with
 * @returns The lesser value
 */
export const greaterThan = (otherField: string) => {
  return (value: number, previousValue: number, allValues: any) => {
    return value > allValues[otherField] ? value : previousValue;
  };
};

/**
 * Normalizer to return a value not greater than 100
 *
 * @param {number} value Original value
 * @param {number} previousValue Previous value
 * @returns {number} Value not greater than 100
 */
export const oneHundredMax = (value: number, previousValue: number): number => {
  return value <= 100 ? value : previousValue;
};

/**
 * Normalizes a date by only allowing the user
 * to type in a 4 digit year
 *
 * @param {string} value Date input value
 * @returns {string} Normalized date
 */
export const normalizeDate = (value: string): string => {
  if (!value) {
    return value;
  }
  // Splits up numbers in array
  const dateArray = value.split('-');
  // Only show up to 4 numbers for the year
  const year  = dateArray[0].length <= 4 ? dateArray[0] : dateArray[0].substr(0, 4);
  const month = dateArray[1];
  const day   = dateArray[2];
  return `${year}-${month}-${day}`;
};

/**
 * Object containing all normalizers, this is used
 * when you want to dynamically inject normalizers
 *
 * @TODO: Example usage
 */
export const NORMALIZERS = {
  toNumberString,
  normalizeDate,
  normalizePhone,
  lessThan,
  greaterThan,
  oneHundredMax,
};

