import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, withStyles, createStyles, colors } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { HeroImage } from 'app/components/Pages/Templates/HeroImage';
import { AssistedForm } from 'app/components/AssistedForm';
import { routes } from 'app/routes/route-list';
import { getImagePathSafely } from 'app/util/images';

export const BranchPage = ({ ...props }: any) => {
  const {
    buttons             = [],
    heroImageSrc        = '',
    heroImageTitle      = '',
    paragraph           = '',
    showAssistedForm    = false,
    showContactLO       = false,
    showFullScreenImage = false,
  } = props;
  const buttonLayout        = `numberOfButtons-${buttons.length}`;
  const fullScreenImagePath = getImagePathSafely(heroImageSrc);

  const styles = (theme) =>
    createStyles({
      backgroundImage: {
        [theme.breakpoints.down('sm')]: {
          background: `url('${fullScreenImagePath}') no-repeat 50% center / cover`,
          maxWidth  : '100%',
          width     : '100%',
          height    : '100%',
          padding   : '0 16px',
          position  : 'fixed',
          top       : 0,
          left      : 0,
          '& .branchSubheading': {
            color     : colors.common.white,
            textShadow: '0 2px 5px rgba(0,0,0,0.5)',
            marginTop : '10px',
            lineHeight: 1.2,
            padding   : 0,
          },
        },
        [theme.breakpoints.between('sm', 'sm')]: {
          '& .branchSubheading': {
            fontSize: 30,
          },
          '& .appButton': {
            fontSize    : 25,
            fontWeight  : 500,
            minWidth    : '375px',
            minHeight   : '75px',
            padding     : '0 16px',
            marginTop   : '20px',
          },
        },
      },
    });

  const mobileBackground = (mobileProps) => {
    return (
      <Grid
        container
        direction = 'column'
        justifyContent   = 'center'
        className = {mobileProps.classes.backgroundImage}
      >
        <Grid item>{mobileProps.children}</Grid>
      </Grid>
    );
  };

  const MobileWrapper: any = withStyles(styles)(mobileBackground);

  const FullScreenImageWrapper = ({ children }: any) =>
    showFullScreenImage ? <MobileWrapper>{children}</MobileWrapper> : children;

  return (
    <FullScreenImageWrapper>
      <HeroImage
        heroImageClass  = 'branchHero'
        heroTitleClass  = 'branchTitle'
        image           = {heroImageSrc}
        pageTitle       = {heroImageTitle}
        removeFormContainer
        showMobileImage = {!showFullScreenImage}
        titleClass      = 'branchTitle heroTitle'
        heroGridProps   = {{
          alignItems: 'flex-start',
          direction : 'column',
        }}
      >
        <Grid
          container
          alignContent = 'flex-start'
          classes      = {{ container: 'branchContainer branchContent' }}
        >
          <Grid item xs={12}>
            <Typography variant='subtitle1' classes={{ subtitle1: 'branchSubheading' }}>
              {paragraph}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent = 'space-between'
              classes = {{ container: `branchButtonContainer ${buttonLayout}` }}
            >
              {buttons.map((button, index) => {
                return (
                  <Grid item key={index} classes={{ item: 'gridButton' }}>
                    <Button
                      classes   = {{ root: 'tabletButton appButton' }}
                      color     = 'primary'
                      component = {Link}
                      to        = {button.url}
                      variant   = 'contained'
                      onClick   = {button.handleClick}
                    >
                      {button.label}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {showAssistedForm && (<AssistedForm {...props} />)}
        {showContactLO && (
          <Grid container classes={{ container: 'lightBackground banner' }}>
            <Grid item xs={12}>
              <Button
                classes   = {{ root: 'tabletButton' }}
                color     = 'secondary'
                component = {Link}
                to        = {routes.contact}
                variant   = 'contained'
              >
                Contact Loan Officer
              </Button>
            </Grid>
          </Grid>
        )}
      </HeroImage>
    </FullScreenImageWrapper>
  );
};
