import React from 'react';
import { createStyles, Grid, withStyles, withTheme, WithStyles, WithTheme, Theme } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { Trans } from 'react-i18next';

const styles = (theme: Theme) => {
  return createStyles({
    buttonContainer: {
      marginTop: '15px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '25px',
        marginBottom: '10px',
      },
    },
  });
};

interface Props extends WithStyles<typeof styles, true>, WithTheme {
  isLoanAmountEditable     : boolean;
  initialLoanAmount        : number;
  initialCreditScore       : string;
  initialLoanTermDesired   : number;
  loanAmount               : number;
  minLoanAmount            : number;
  maxLoanAmount            : number;
  handleChangeLoanAmount   : () => void;
  handleRecalculate        : () => void;
  handleCancel             : () => void;
  purchasePrice            : number;
  tradeInValue             : number;
  downPayment              : number;
  creditScore              : string;
  loanTermDesired          : number;
}

class AutoLoanAdditionalButtonsComponent extends React.Component<Props, any> {

  renderChangeLoanAmountButton = (): JSX.Element => {
    const { handleChangeLoanAmount } = this.props;
    return (
      <Grid item>
        <Button
          classes = {{ sizeSmall: 'mobileSmall' }}
          onClick = { handleChangeLoanAmount  }
          size    = 'small'
          color   = 'secondary'
          variant = 'contained'
        >
          <Trans i18nKey='AutoLoan.summary.changeLoanAmountButton'>
            Change Loan Amount
          </Trans>
        </Button>
      </Grid>
    );
  };

  renderRecalculateButton = (): JSX.Element => {
    const { initialLoanAmount, minLoanAmount, maxLoanAmount, handleRecalculate, purchasePrice, tradeInValue, downPayment, initialCreditScore, initialLoanTermDesired, creditScore, loanTermDesired } = this.props;
    const loanAmount = purchasePrice - (tradeInValue + downPayment);

    const isDisabled =
      ( initialLoanAmount === loanAmount &&
        initialLoanTermDesired === loanTermDesired &&
        initialCreditScore === creditScore) ||
      loanAmount > maxLoanAmount ||
      loanAmount < minLoanAmount ||
      ((tradeInValue + downPayment) / purchasePrice < .1);

    return (
      <Grid item>
        <Button
          classes  = {{ sizeSmall: 'mobileSmall' }}
          onClick  = { handleRecalculate }
          disabled = { isDisabled }
          size     = 'small'
          color    = 'primary'
          variant  = 'contained'
        >
          <Trans i18nKey='AutoLoan.summary.recalculateButton'>
            Recalculate
          </Trans>
        </Button>
      </Grid>
    );
  };

  renderCancelButton = (): JSX.Element => {
    const { handleCancel } = this.props;
    return (
      <Grid item>
        <Button
          classes  = {{ sizeSmall: 'mobileSmall' }}
          onClick  = { handleCancel }
          size     = 'small'
          color    = 'secondary'
          variant  = 'contained'
        >
          <Trans i18nKey='AutoLoan.summary.cancelButton'>
            Cancel
          </Trans>
        </Button>
      </Grid>
    );
  };

  render() {
    const { classes, isLoanAmountEditable } = this.props;
    return (
      <Grid container justifyContent='space-evenly' className={classes.buttonContainer}>
        { !isLoanAmountEditable && this.renderChangeLoanAmountButton() }
        { isLoanAmountEditable && this.renderRecalculateButton() }
        { isLoanAmountEditable && this.renderCancelButton() }
      </Grid>
    );
  }
}

export const AutoLoanAdditionalButtons = withTheme(withStyles(styles)(AutoLoanAdditionalButtonsComponent));
