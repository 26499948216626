import React from 'react';
import { FieldNames } from 'app/models/fields/names';
import { Section } from 'app/models/types';
import { INSURANCE_QUOTE_QUESTIONS } from 'app/models/questions/insurance-quote';
import { handleConsentOrReturnToPreviousPage, handleCreateInsuranceQuote } from 'app/actions/form/insurance-quote/actions';
import { AcrisureAgreement } from 'app/components/FormAgreements/AcrisureAgreement';
import { INSURANCE_QUOTE_CONSENT, INSURANCE_QUOTE_ADDITIONAL_QUESTIONS } from 'app/models/sections/constants';
import { FormParagraphs } from 'app/models/paragraphs';

export const getInsuranceQuoteSectionById = (id: number): Section => {
  const section = INSURANCE_QUOTE_SECTIONS.find((section) => section.id === id) as Section;
  return section;
};

const INSURANCE_QUOTE_SECTIONS: Section[] = [
  {
    id              : INSURANCE_QUOTE_CONSENT,
    hideBackButton  : true,
    hideProgressBar : true,
    title           : 'Would you be interested in receiving a home insurance quote?',
    paragraph       : FormParagraphs.acrisureVerbiage,
    questions: INSURANCE_QUOTE_QUESTIONS.getByName([
      FieldNames.canReceiveInsuranceQuote,
    ]),
    agreement: <AcrisureAgreement />,
    submitAction: handleConsentOrReturnToPreviousPage,
  },
  {
    id              : INSURANCE_QUOTE_ADDITIONAL_QUESTIONS,
    hideBackButton  : true,
    hideProgressBar : true,
    questions       : INSURANCE_QUOTE_QUESTIONS.getByName([
      FieldNames.livingArea,
      FieldNames.homeStories,
      FieldNames.constructionHomeType,
      FieldNames.constructionRoofingType,
      FieldNames.buildingUnits,
    ]),
    title: 'Please provide a bit more information for the most accurate quote:',
    submitText: 'Submit',
    submitAction: handleCreateInsuranceQuote,
  },
];
