import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';

export interface AddressValidationRequest {
  city    : string;
  slug    : string;
  street  : string;
  street2?: string;
  state   : string;
  zip     : string;
}

type ValidationErrorCode =
  'INVALID_ZIP'     |
  'INVALID_CITY'    |
  'INVALID_ADDRESS' |
  'INVALID_STATE'   |
  'USPS_ERROR';

export interface AddressValidationResponse {
  isValid : boolean;
  error? : {
    code        : ValidationErrorCode,
    description : string;
  };
  city     : string;
  state    : string;
  street   : string;
  street2? : string;
  zip      : string;
}

export const validateAddress = (request: AddressValidationRequest): Promise<AddressValidationResponse> => {
  return client
    .post<AddressValidationResponse>(Endpoints.VALIDATE_ADDRESS, request)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};
