import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';

export const postEmailRates = (body) => {
  return client
    .post(Endpoints.EMAIL, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
