import { Question } from 'app/models/types';

/**
 * This class is used as a convenient container of questions with helper
 * methods to obtain a specific question by id or name.
 */
export class Questions {

  constructor (private questions: Question[]) {}

  /**
   * Takes in a question id and returns the corresponding question
   *
   * @param {number} id Question id
   * @returns {Question} Question with matching id
   */
  public getById = (id: number): Question => {
    return this.questions.find(question => question.id === id);
  };

  /**
   * Takes in an array of field names and returns an array of questions
   *
   * @param {string[]} [names=[]] Field names
   * @returns {Question[]} Questions matching field names
   */
  public getByName = (names: string[] = []): Question[] => {
    return names.map(name => this.questions.find(question => question.name === name));
  };
}
