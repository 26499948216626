import React from 'react';
import { Field } from 'redux-form';
import { toNumberString } from 'app/util/normalizers';
import { isNumber, required, minLength } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

export const ZipCode = (props) => {
  const { name } = props;
  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      component    = {renderInputBase}
      label        = {props.label}
      maxLength    = {5}
      name         = {name}
      normalize    = {toNumberString}
      type         = "tel"
      validate     = {[required, isNumber, minLength(5)]}
    />
  );
};
