import React from 'react';
import { colors, createStyles, Grid, Typography, withStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => createStyles({
  scoreContainer: {
    marginBottom: '70px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '50px',
    },
  },
  largeScore: {
    fontWeight: 500,
    fontSize: 60,
  },
  tickerWrapper: {
    position: 'relative',
    width: '100%',
    marginTop: '-20px',
    marginLeft: '5px',
  },
  innerCircle: {
    paddingTop: '50%',
    display: 'block',
    height: 0,
  },
  creditGaugeWrapper: {
    position: 'relative',
    width: '275px',
  },
  creditGauge: {
    position: 'absolute',
    width: '100%',
  },
  circle: {
    width: '251px',
    position: 'relative',
    margin: '0 auto',
    height: '127px',
  },
});

export const CreditGraph = withStyles(styles)(({ ...props }: any) => {
  const { classes, creditScore } = props;
  const minScore            = 300;
  const maxScore            = 850;
  const scoreColors         = {
    text    : colors.grey[900],
    default : darken(`${colors.green.A400}`, 0.15),
  };

  // Since the graph does not start at 0, get the range of numbers that the graph actually displays
  const graphRange = maxScore - minScore;
  // Subtract the number the graph starts at from the credit score value
  const scoreWithinRange = creditScore - minScore;
  // Get the percentage of the graph that the credit score represents
  const graphPercentage = scoreWithinRange / graphRange;
  // Get the degrees to rotate by multiplying the percentage by 180 degrees
  const graphScore = graphPercentage * 180;

  const tickerStyle = {
    width        : '28px',
    height       : '28px',
    borderRadius : '50%',
    background   : `${colors.common.white}`,
    border       : `7px solid ${scoreColors.default}`,
  };

  const tickerPosition = {
    transform: `rotateZ(${graphScore}deg)`,
  };

  return (
    <Grid container justifyContent='center' className={classes.scoreContainer}>
      <Typography component='span' className={classes.creditGaugeWrapper}>
        <svg
          className = {classes.creditGauge}
          width     = '261px'
          height    = '147px'
          viewBox   = '0 0 261 147'
          version   = '1.1'
        >
          <path
            fill = {scoreColors.default}
            d    = 'M18.0390265,126.914469 L5,126.893901 C5,56.8688388 61.139214,0 130.5,0 C199.755853,0 256,56.7627402 256,126.893901 L242.960451,126.893901 C241.36379,64.2646924 191.624758,14 130.5,14 C69.3685512,14 19.6253207,64.2756977 18.0390268,126.914459 Z'
          />
          <text fill={scoreColors.text}>
            <tspan x='0' y='146'>
              {minScore}
            </tspan>
          </text>
          <text fill={scoreColors.text}>
            <tspan x='238' y='146'>
              {maxScore}
            </tspan>
          </text>
          <text className={classes.largeScore} fill={scoreColors.text}>
            <tspan x='79' y='124'>
              {creditScore}
            </tspan>
          </text>
        </svg>
        <div className={classes.circle}>
          <span className={classes.innerCircle}>&nbsp;</span>
          <div style={tickerPosition} className={classes.tickerWrapper}>
            <div style={tickerStyle}>&nbsp;</div>
          </div>
        </div>
      </Typography>
    </Grid>
  );
});
