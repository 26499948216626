import { AddressValidationState, Type } from 'app/actions/form/address-validation/types';

const initialState: AddressValidationState = {
  isModalOpen           : false,
  suggestedAddress      : null,
  enteredAddress        : null,
  propertyFieldNameType : null,
};

export const addressValidationReducer = (state = initialState, action): AddressValidationState => {
  switch (action.type) {
    case Type.AJAX_VALIDATE_ADDRESS:
      return { ...state, propertyFieldNameType: action.payload.propertyFieldNameType };
    case Type.AJAX_VALIDATE_ADDRESS_SUCCESS:
      return { ...state, ...action.payload, isModalOpen: true };
    case Type.AJAX_VALIDATE_ADDRESS_FAIL:
      return { ...state, ...initialState };
    case Type.MODAL_ADDRESS_VALIDATION_CLOSE:
      return { ...state, isModalOpen: false };

    default:
      return state;
  }
};
