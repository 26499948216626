import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';

export interface ZipCodeValidationResponse {
  isValid: boolean;
  error? : {
    code       : 'INVALID ZIP CODE',
    description: string;
  };
  zipCode          : string;
}

export const validateZipCode = (zipCode: string): Promise<ZipCodeValidationResponse>  => {
  return client
    .get<ZipCodeValidationResponse>(`${Endpoints.VALIDATE_ZIP_CODE}?zipCode=${zipCode}`)
    .then((response) => {
      return { isValid: response.data ? true :false, zipCode } ;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};
