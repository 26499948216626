import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { useTranslation } from 'react-i18next';
import { DownloadChecklist } from 'app/components/DownloadChecklist';
import { RootState } from 'app/store/types';
import { isSchedulerEnabled } from 'app/reducers/app-config';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { getNextSection } from 'app/actions/form/actions';
import { connect } from 'react-redux';
import { logger } from 'app/util/logger';

// eslint-disable-next-line no-use-before-define
type ConnectProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type OwnProps     = {};
type Props        = ConnectProps & OwnProps;

const mapStateToProps = (state: RootState) => ({
  schedulerEnabled: isSchedulerEnabled(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getNextSection,
}, dispatch);

const LastStepComponent = (props: Props) => {
  const { getNextSection, schedulerEnabled } = props;
  const { t }         = useTranslation();
  const titleText     = t('lastStep.title', { defaultValue: 'Last step!' });
  const paragraphText = t('lastStep.paragraph1', {
    defaultValue:
      'Now that you\'ve provided all of your information, schedule an appointment with a loan officer to review and discuss the details of your mortgage inquiry. Quick and easy!',
  });
  React.useEffect(() => {
    if (!schedulerEnabled) {
      logger.debug('Client has scheduler disabled, skipping LastStep section');
      getNextSection();
    }
  }, [getNextSection, schedulerEnabled]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" classes={{ root: 'clearWrapper' }}>
          {titleText}
        </Typography>
        <Typography variant="body2" paragraph={true}>
          {paragraphText}
        </Typography>
        <DownloadChecklist />
      </Grid>
      <Grid item xs={12} classes={{ item: 'textCenter' }}>
        <Button
          color = "primary"
          disableRipple
          classes = {{
            root: 'link',
            text: 'skip',
          }}
          onClick = {() => getNextSection()}
        >
          {t('lastStep.button.skip', {
            defaultValue: 'I do not want to schedule an appointment',
          })}
        </Button>
      </Grid>
    </Grid>
  );
};

export const LastStep = compose<React.ComponentType<OwnProps>>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LastStepComponent);
