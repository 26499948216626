import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

export const AcknowledgeAutoInsuranceQuote = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant   = 'body2'
          component = 'span'
          classes   = {{ body2: 'small' }}
        >
          <Trans i18nKey='formAgreement.autoInsuranceQuote.paragraph1'>
            By clicking submit, I have read and agree to the
            <Typography
              variant   = 'body2'
              component = 'span'
              classes   = {{ body2: 'small formTerms' }}
            >
              <Link
                underline='always'
                href='https://www.acrisure.com/terms-of-use'
                rel='noopener'
                target='_blank'
                style={{ marginLeft: 5, marginRight: 5, fontSize: '1em', whiteSpace: 'nowrap' }}
              >
                Terms of Use
              </Link>
              and
              <Link
                underline='always'
                href='https://www.acrisure.com/privacy'
                rel='noopener'
                target='_blank'
                style={{ marginLeft: 5, fontSize: '1em', whiteSpace: 'nowrap' }}
              >
                Privacy Policy
              </Link>.
            </Typography>
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};
