import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { creditBoosterQuestionsByName } from '../questions/credit-booster';
import {
  CREDIT_BOOSTER_APP_ACCOUNT_INFO,
  CREDIT_BOOSTER_APP_CONFIRMATION,
  CREDIT_BOOSTER_APP_CURRENT_HOUSING_SITUATION,
  CREDIT_BOOSTER_APP_GENERAL_INFO,
  CREDIT_BOOSTER_APP_INCOME_AND_EMPLOYMENT,
  CREDIT_BOOSTER_APP_LOAN_AMOUNT,
  CREDIT_BOOSTER_APP_LOAN_SELECTION,
  CREDIT_BOOSTER_APP_PERSONAL_INFORMATION,
} from './constants';
import { handleCreditBoosterFetchRatesOrLoanLimits, handleCreditBoosterFormSubmit } from 'app/actions/form/credit-booster/actions';

export const getCreditBoosterSectionById = (id: number): Section => {
  return CREDIT_BOOSTER_SECTIONS.find((section) => section.id === id) as Section;
};

const CREDIT_BOOSTER_SECTIONS: Section[] = [
  {
    id       : CREDIT_BOOSTER_APP_GENERAL_INFO,
    hideBackButton: true,
    title    : 'Credit Booster Loan',
    subtitle : 'This loan is aimed at building or enhancing your personal credit score while building your savings!  ' +
      'We guarantee your payments by securing the proceeds of your loan for the duration of the term in a Savings or CD account.',
    questions: creditBoosterQuestionsByName(['CreditBoosterGeneralInfo']),
  },
  {
    id        : CREDIT_BOOSTER_APP_PERSONAL_INFORMATION,
    questions : creditBoosterQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
  },
  {
    id        : CREDIT_BOOSTER_APP_CURRENT_HOUSING_SITUATION,
    title     : 'Provide your homeowner information',
    questions : creditBoosterQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
  },
  {
    id         : CREDIT_BOOSTER_APP_INCOME_AND_EMPLOYMENT,
    title      : 'Tell us about your income and employment',
    questions  : creditBoosterQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
    ]),
  },
  {
    id           : CREDIT_BOOSTER_APP_ACCOUNT_INFO,
    title        : 'And details about your account',
    subtitle     : 'How will you secure this Credit Booster Loan?',
    questions    : creditBoosterQuestionsByName([FieldNames.accountSummary]),
    submitAction : handleCreditBoosterFetchRatesOrLoanLimits,
  },
  {
    id           : CREDIT_BOOSTER_APP_LOAN_SELECTION,
    title        : 'Please select from these loan options',
    questions    : creditBoosterQuestionsByName([FieldNames.creditBoosterLoanOption]),
    submitAction : handleCreditBoosterFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : CREDIT_BOOSTER_APP_LOAN_AMOUNT,
    title        : 'What loan amount would you like?',
    questions    : creditBoosterQuestionsByName([FieldNames.loanAmount]),
    submitAction : handleCreditBoosterFormSubmit,
    submitText   : 'Submit',
  },
  {
    id                : CREDIT_BOOSTER_APP_CONFIRMATION,
    questions         : creditBoosterQuestionsByName(['FinalPage']),
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
];
