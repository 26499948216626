import { getAuditMetadata, makeActionCreator } from 'app/util/actions';
import { Type } from 'app/actions/form/insurance-quote/types';
import { logger } from 'app/util/logger';
import { createInsuranceQuote } from 'app/api/insurance-quote';
import { mapInsuranceQuote } from 'app/api/insurance-quote/mappings';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { getNextSection, getPreviousSection } from 'app/actions/form/actions';
import { AuditUserEventRequest, AuditUserEventType, postAuditUserEvent } from 'app/api/audit-event';
import { FieldNames } from 'app/models/fields/names';
import { isYes } from 'app/models/fields/conditionals';
import { getSubmittedData } from 'app/reducers/submitted-data';
import { FormName } from 'app/models/options/enums';
import { InsuranceType } from '@lenderful/domain';

export const ajaxPostInsuranceQuote        = makeActionCreator(Type.AJAX_POST_INSURANGE_QUOTE, 'payload');
export const ajaxPostInsuranceQuoteSuccess = makeActionCreator(Type.AJAX_POST_INSURANGE_QUOTE_SUCCESS);
export const ajaxPostInsuranceQuoteFail    = makeActionCreator(Type.AJAX_POST_INSURANGE_QUOTE_FAIL);

/**
 * This handler delegates to the API method to create an insurance quote.  The URL of
 * the quote is returned and opened in a new window.
 */
export const handleCreateInsuranceQuote = (formData) => {
  return (dispatch, getState) => {
    const state         = getState();
    const loanOfficer   = getLoanOfficer(state);
    const submittedData = getSubmittedData(state);
    const request       = mapInsuranceQuote(formData, loanOfficer.id, submittedData[FormName.LONGFORM].loanAppId);

    return createInsuranceQuote(request, InsuranceType.MORTGAGE)
      .then((response) => {
        dispatch(ajaxPostInsuranceQuoteSuccess());
        window.open(response.quoteUrl, '_blank', 'noopener,noreferrer');
        dispatch(getPreviousSection());
        dispatch(getPreviousSection());
      })
      .catch((error) => {
        logger.error('handleCreateInsuranceQuote', { error });
        dispatch(ajaxPostInsuranceQuoteFail());
      });
  };
};

/**
 * This handler delegates to the API method to create an auto insurance quote.  The base64
 * for the PDF is returned and automatically downloaded using the download lib.
 * 
 * @param formData 
 * @returns 
 */
export const handleAutoCreateInsuranceQuote = (formData) => {
  return (dispatch) => {
    const request = mapInsuranceQuote(formData);
    return createInsuranceQuote(request, InsuranceType.AUTO)
      .then((response) => {
        dispatch(ajaxPostInsuranceQuoteSuccess());
        return response;
      })
      .catch((error) => {
        logger.error('handleCreateAutoInsuranceQuote', { error });
        dispatch(ajaxPostInsuranceQuoteFail());
      });
  };
};

/**
 * This handler determines whether the user has consented to the agreement
 * for creating an insurance quote.  If yes, it will record the consent.  If no,
 * it will take the user back one page.
 *
 * @param formData
 * @returns
 */
export const handleConsentOrReturnToPreviousPage = (formData) => {
  return async (dispatch) => {
    if (isYes(formData[FieldNames.canReceiveInsuranceQuote])) {
      const request: AuditUserEventRequest = {
        auditEvent: {
          email      : formData.email,
          firstName  : formData.firstName,
          middleName : formData.middleName,
          lastName   : formData.lastName,
          eventMeta  : JSON.stringify(getAuditMetadata('Next')),
          eventType  : AuditUserEventType.USER_ACKNOWLEDGES_ACRISURE,
        },
      };
      await postAuditUserEvent(request);
      return dispatch(getNextSection());
    }
    return dispatch(getPreviousSection());
  };
};
