import React from 'react';
import {
  colors,
  createStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
  InputLabel,
  Theme,
} from '@material-ui/core';
import { Field } from 'redux-form';
import { required } from 'app/util/validators';
import { RootState } from 'app/store/types';
import { connect } from 'react-redux';
import { creditBoosterProgramSelector } from 'app/reducers/rates';

const styles = (theme: Theme) => createStyles({
  selected: {
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.secondary.main,
      '& span': {
        color: '#ffffff',
      },
    },
  },
  radioLabel: {
    marginTop: 5,
    marginRight: 0,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 2,
      paddingBottom: 2,
      borderBottom: `1px solid ${colors.grey[300]}`,
    },
  },
  radioWrapper: {
    marginTop: 15,
    '& label': {
      marginRight: 0,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginLeft: 0,
        '& span:last-child': {
          marginLeft: 10,
        },
      },
    },
    '& label.selected span': {
      [theme.breakpoints.down('sm')]: {
        '& span': {
          color: '#ffffff',
        },
      },
    },
  },
});

const renderRadioGroup = (props) => {
  const { classes } = props;
  return (
    <FormControl fullWidth>
      <RadioGroup {...props.input}>
        {props.options.map((option, index) => {
          return (
            <React.Fragment key={index}>
              <FormControlLabel
                control   = {<Radio color='primary' />}
                label     = {option.title}
                value     = {option.value}
                className = {props.input.value === option.value ? `${classes.radioLabel} ${classes.selected}` : classes.radioLabel}
              />
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

type CreditBoosterLoanOptionsProps = any;

const CreditBoosterLoanOptionsComponent = withStyles(styles)(({ ...props }: CreditBoosterLoanOptionsProps) => {
  const { classes, creditBoosterPrograms } = props;
  return (
    <div className={classes.radioWrapper}>
      { props.label ? <InputLabel shrink>{ props.label }</InputLabel> : null }
      <Field
        classes   = {props.classes}
        component = {renderRadioGroup}
        name      = {props.name}
        onChange  = {props.onChange}
        options   = {creditBoosterPrograms}
        validate  = {[required]}
      />
    </div>
  );
});

const mapStateToProps = (state: RootState) => ({
  creditBoosterPrograms: creditBoosterProgramSelector(state),
});

export const CreditBoosterLoanOptions = connect(mapStateToProps)(CreditBoosterLoanOptionsComponent);
