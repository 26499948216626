import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { isSchedulerEnabled } from 'app/reducers/app-config';
import { withRouter, RouteComponentProps } from 'react-router';
import { routes } from 'app/routes/route-list';
import { compose } from 'redux';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { logger } from 'app/util/logger';

// eslint-disable-next-line no-use-before-define
type ConnectProps = ReturnType<typeof mapStateToProps>;
type HocProps     = WithTranslation & RouteComponentProps;
type OwnProps     = {};

type Props = ConnectProps & HocProps & OwnProps;

const mapStateToProps = (state: RootState) => ({
  config          : state.config,
  loanOfficer     : getLoanOfficer(state),
  schedulerEnabled: isSchedulerEnabled(state),
});

const ScheduleComponent = (props: Props) => {
  const { history, schedulerEnabled, t } = props;

  React.useEffect(() => {
    if (!schedulerEnabled) {
      logger.debug('Client has scheduler disabled, redirecting to Contact page');
      history.push(routes.contact);
    }
  });

  return (
    <Grid container classes={{ container: 'formWrapper schedule' }}>
      <Grid item>
        <Typography
          variant   = "h6"
          component = "h1"
          classes   = {{ root: 'clearWrapper scheduleTitle' }}
        >
          {t('page.scheduleAppointment.title', {
            defaultValue: 'Schedule an Appointment With Us',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default compose<React.ComponentType<OwnProps>>(
  withRouter(
    connect(
      mapStateToProps,
      null,
    )(withTranslation()(ScheduleComponent)),
  ),
);
