import { ReferralCode } from '@lenderful/domain';
import { Type } from 'app/actions/form/types';

interface ReferToLoState {
  isReferToLo: boolean;
  code       : ReferralCode | '';
  isLoading  : boolean;
}

const initialState: ReferToLoState = {
  isReferToLo: false,
  code       : '',
  isLoading  : false,
};

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const referToLoReducer = (state = initialState, action): ReferToLoState => {
  switch (action.type) {
    case Type.REFER_TO_LOAN_OFFICER:
      return { ...state, isReferToLo: true, code: action.code };
    case Type.AJAX_POST_PARTIAL_APP:
      return { ...state, isLoading: true };
    case Type.AJAX_POST_PARTIAL_APP_SUCCESS:
    case Type.AJAX_POST_PARTIAL_APP_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

// Helpers
// -------
