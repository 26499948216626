import { FieldNames } from 'app/models/fields/names';
import { ReoProperty } from 'app/api/loan-application/types';

/**
 * This method safely ensures a value is a number, otherwise it returns zero.
 *
 * @param {unknown} value possible numeric value
 * @returns value if it is a number, otherwise zero
 */
const getSafeNumber = (value: unknown): number => isNaN(value as number) ? 0 : value as number;

/**
 * Safely sums up all the incomes using the `incomeMonthly` attribute.
 *
 * @param {unknown} incomes array of incomes (retirement or additional)
 * @returns sum if incomes are an array with correct attrbute, otherwise zero
 */
const sumUpMonthlyIncome = (incomes: unknown): number => {
  if (Array.isArray(incomes)) {
    return incomes
      .map((income) => getSafeNumber(income.incomeMonthly))
      .reduce((accumulator: number, value: number) => accumulator + value, 0);
  }
  return 0;
};

/**
 * Sums up and returns all income for the borrower
 *
 * @param {*} values Form Values
 * @returns {number} total income for borrower
 */
// TODO: Should borrower and co-borrower Bonus also be included in total Income?  This affects checks agains
//       front-end and back-end ratios
export const getBorrowerTotalIncome = (values): number => {
  const totalEmploymentIncomeFields = [
    values[FieldNames.employBase],
    values[FieldNames.employOvertime],
    values[FieldNames.employCommission],
  ];

  const totalAdditionalIncome: number = sumUpMonthlyIncome(values[FieldNames.additionalIncome]);
  const totalRetirementIncome: number = sumUpMonthlyIncome(values[FieldNames.retirementIncome]);
  const totalEmploymentIncome = totalEmploymentIncomeFields
    .reduce((accumulator: number, value: number) => accumulator + getSafeNumber(value), 0);

  return totalAdditionalIncome + totalRetirementIncome + totalEmploymentIncome;
};

/**
 * Sums up and returns all income for the co-borrower
 *
 * @param {*} values Form Values
 * @returns {number} total income for co-borrower
 */
export const getCoBorrowerTotalIncome = (values): number => {
  const totalEmploymentIncomeFields = [
    values[FieldNames.coBorrowerEmployBase],
    values[FieldNames.coBorrowerEmployOvertime],
    values[FieldNames.coBorrowerEmployCommission],
  ];

  const totalAdditionalIncome: number = sumUpMonthlyIncome(values[FieldNames.coBorrowerAdditionalIncome]);
  const totalRetirementIncome: number = sumUpMonthlyIncome(values[FieldNames.coBorrowerRetirementIncome]);
  const totalEmploymentIncome = totalEmploymentIncomeFields
    .reduce((accumulator: number, value: number) => accumulator + getSafeNumber(value), 0);

  return totalAdditionalIncome + totalRetirementIncome + totalEmploymentIncome;
};

/**
 * Sums up and returns all inputted monthly liabilities (not from credit report) for the borrower
 *
 * @param {*} values Form Values
 * @returns {number} total liabilities for borrower
 */
export const getMonthlyLiabilities = (values): number => {

  const monthlyLoanPayment = getSafeNumber(values[FieldNames.monthlyPayment]);
  const monthlyRent = getSafeNumber(values[FieldNames.livingMonthlyRentAmount]);
  const monthlyTaxes = getSafeNumber(values[FieldNames.livingYearTaxes]) / 12;
  const monthlyInsurance = getSafeNumber(values[FieldNames.livingYearInsure]) / 12;
  const monthlyHoa = getSafeNumber(values[FieldNames.livingYearAssociate]) / 12;

  const monthlyTotals = monthlyLoanPayment + monthlyRent + monthlyTaxes + monthlyInsurance + monthlyHoa;
  return monthlyTotals;
};

export const getREOMonthlyExpenseAmount = (property: ReoProperty): number => {
  const annualInsuranceAmount       = getSafeNumber(property[FieldNames.propertyAnnualInsuranceAmount]);
  const annualTaxAmount             = getSafeNumber(property[FieldNames.propertyAnnaulTaxAmount]);
  const annualAssociationFeesAmount = getSafeNumber(property[FieldNames.propertyAnnualAssociationAmount]);

  return Math.round((annualInsuranceAmount + annualTaxAmount + annualAssociationFeesAmount) / 12);
};

export const getREOMonthlyRentAmount = (property: ReoProperty): number => {
  const annualRentAmount = getSafeNumber(property[FieldNames.propertyAnnualRentAmount]);
  return Math.round(annualRentAmount / 12);
};
