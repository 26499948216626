import Cookies from 'js-cookie';

/** 
 * This enumeration represents the values that determine whether a user
 * is navigating to one of our custom built loan officer pages (prequalexpress.com) or
 * directly to our client's website (mortgage.bank.com). 
 */
export enum PRODUCT {
  /**
   * This represents a user that has navigated to the companys website
   * (i.e mortgage.bank.com)
   */
  DIGITAL_MORTGAGE = 'DM',
  /**
   * This represents a user that has navigated to a loan officer page
   * (i.e jdoe.prequalexpress.com)
   */
  AUTO_PREQUAL ='APQ',
}

export const hasSlug = (): boolean => Cookies.get('slug') !== undefined;

export const getSlug = (): string => {
  return hasSlug() ? Cookies.get('slug') : process.env.REACT_APP_SLUG;
};

export const getProduct = (): PRODUCT => {
  return Cookies.get('product') !== undefined ? Cookies.get('product') : PRODUCT[PRODUCT.DIGITAL_MORTGAGE];
};

export const getEmployeeId = (): string => {
  return Cookies.get('employeeId');
};

/**
 * This returns true if the user has navigated to one of our custom built loan officer sites
 * (i.e prequalexpress.com)
 * 
 * @returns {boolean}
 */
export const isOnLoanOfficerSite = (): boolean => {
  return getProduct() === PRODUCT.AUTO_PREQUAL;
};