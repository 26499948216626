import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';

type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = MappedProps

const UploadDocumentsComponent = (props: Props) => {
  const { t } = useTranslation();
  const { confirmationImage } = props;
  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('turbo.uploadDocuments.title', { defaultValue: 'Please upload the following documents:' })}
          </Typography>
          <Typography variant='body2' paragraph={true}>
            {t('turbo.uploadDocuments.paragraph1', {
              defaultValue:
                <ul>
                  <li>Copy of Drivers License</li>
                  <li>Current Mortgage Statement</li>
                  <li>Home Insurance Declaration Page</li>
                  <li>HOA Invoice (if applicable)</li>
                </ul>,
            })}
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationImage   : state.config.confirmationImage,
});

export const UploadDocumentsStep = connect(mapStateToProps)(UploadDocumentsComponent);
