import React from 'react';
import {
  createStyles,
  Grid,
  withStyles,
  withTheme,
  WithStyles,
  WithTheme,
  Theme, Typography,
} from '@material-ui/core';
import { TileBox } from 'app/components/TileBox';
import { LoanBarChart } from 'app/components/Charts/LoanBarChart';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RateInfo } from 'app/models/types';
import { GenericLoanAmount } from 'app/components/Generic/GenericLoanAmount';
import { AutoLoanTerms } from 'app/components/AutoLoan/AutoLoanTerms';
import { PaymentDesktopWrapper } from 'app/components/Generic/PaymentDesktopWrapper';
import { PaymentMobileWrapper } from 'app/components/Generic/PaymentMobileWrapper';
import { AutoLoanAdditionalButtons } from 'app/components/AutoLoan/AutoLoanAdditionalButtons';
import { AutoLoanAmountEditable } from 'app/components/AutoLoan/AutoLoanAmountEditable';

const styles = (theme: Theme) => {
  return createStyles({
    row: {
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    topBox: {
      minHeight: '240px',
      [theme.breakpoints.down('sm')]: {
        minHeight: 0,
      },
    },
    maxWidth: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '420px',
        width   : '100%',
        margin  : '0 auto',
      },
    },
  });
};

interface Props extends WithStyles<typeof styles, true>, WithTheme, WithTranslation {
  rates                        : RateInfo[];
  loanAmount                   : number;
  minLoanAmount                : number;
  maxLoanAmount                : number;
  setSelectedRate              : (payload) => (dispatch, getState) => void;
  handleAutoLoanRateSelect     : () => (dispatch, getState) => void;
  isFromDealer                 : boolean,
  isLoanAmountEditable         : boolean;
  initialLoanAmount            : number;
  initialLoanTermDesired       : number;
  initialCreditScore           : string;
  handleChangeLoanAmount       : () => void;
  handleRecalculate            : () => void;
  handleCancel                 : () => void;
  purchasePrice                : number;
  tradeInValue                 : number;
  downPayment                  : number;
  creditScore                  : string;
  loanTermDesired              : number;
  values                       : any;
}

const boxColor = 'primary';

class AutoLoanRateOptionsComponent extends React.Component<Props, any> {

  filterAutoLoanRates = (rates, loanTermDesiredInMonths) => {
    const targetTermInYears = parseInt(loanTermDesiredInMonths) / 12;
    const sortedRates = rates.sort((rateA, rateB) => rateA.term < rateB.term);

    let foundIndex = sortedRates.findIndex(({ term }) => term === targetTermInYears);
    if (foundIndex === -1 && targetTermInYears > sortedRates[rates.length-1].term) {
      foundIndex = sortedRates.length - 1; // highest term
    } else if (foundIndex === -1 && targetTermInYears < sortedRates[0].term) {
      foundIndex = 0; // lowest term
    }

    // return at least one above or one below desired rate
    return sortedRates.filter((rate, index) => index-1 <= foundIndex && index+1 >= foundIndex);
  };

  getRatesSection = (): JSX.Element => {
    const { classes, t, loanAmount, rates, setSelectedRate, handleAutoLoanRateSelect, loanTermDesired } = this.props;
    const filteredRates = this.filterAutoLoanRates(rates, loanTermDesired);
    return (

      <TileBox
        color        = { boxColor }
        tileBoxProps = {{ className: classes.topBox }}
        title        = {
          t('AutoLoan.summary.title', { defaultValue: 'Auto Loan' })
        }
      >
        <Grid container>
          <GenericLoanAmount loanAmount={ loanAmount } />
          {filteredRates.length > 0 ? (
            <AutoLoanTerms
              rates                        = { filteredRates }
              color                        = { boxColor }
              setSelectedRate              = { setSelectedRate }
              handleAutoLoanRateSelect     = { handleAutoLoanRateSelect }
            />) :
            <Grid item xs={12} className={classes.row}>
              <Grid container alignItems='center'>
                <Grid item xs={12}>
                  <Typography align='center' component='h3'>
                    No rates available, please select a new loan term.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          }

        </Grid>
      </TileBox>
    );
  };

  getPaymentSection = (): JSX.Element => {
    const { t } = this.props;
    const title = t('AutoLoan.summary.termOptionsTitle', { defaultValue: 'Monthly Payment & Term Options' });
    const paymentContent = this.getPaymentContent();
    return (
      <React.Fragment>
        <PaymentDesktopWrapper color={boxColor} title={title}>
          { paymentContent }
        </PaymentDesktopWrapper>
        <PaymentMobileWrapper color={boxColor} title={title}>
          { paymentContent }
        </PaymentMobileWrapper>
      </React.Fragment>
    );
  };

  getPaymentContent = (): JSX.Element => {
    const { t, classes, rates, loanTermDesired, theme } = this.props;
    /* Localize the XAxis labels, (ie: 15-yr) */
    const ratesWithLocalizatedLabels = this.filterAutoLoanRates(rates, loanTermDesired).map((rate) => {
      const { term } = rate;
      return {
        ...rate,
        label: t('autoLoanBarChart.xAxisLabel', { defaultValue: '{{ term }}-Yr', term }),
      };
    });
    return (
      <Grid container className={classes.row}>
        <Grid item xs={12}>
          <Grid className={classes.maxWidth}>
            <LoanBarChart
              rates={ratesWithLocalizatedLabels}
              fillColor={theme.palette.primary.main}
              dataKey={'principalAndInterestPayment'}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  getEditableLoanAmount = (): JSX.Element => {
    const { minLoanAmount, maxLoanAmount, isFromDealer } = this.props;
    return (
      <AutoLoanAmountEditable
        isFromDealer  = {isFromDealer}
        minLoanAmount = {minLoanAmount}
        maxLoanAmount = {maxLoanAmount}
        values        = {this.props.values}
      />
    );
  };

  render() {
    const {
      isLoanAmountEditable,
      loanAmount,
      minLoanAmount,
      maxLoanAmount,
      initialLoanAmount,
      handleChangeLoanAmount,
      handleRecalculate,
      handleCancel,
      purchasePrice,
      tradeInValue,
      downPayment,
      initialCreditScore,
      initialLoanTermDesired,
      creditScore,
      loanTermDesired,
    } = this.props;
    return (
      <Grid container justifyContent='space-between'>
        <Grid item xs={12}>
          { !isLoanAmountEditable && this.getRatesSection() }
          { !isLoanAmountEditable && this.getPaymentSection() }
          { isLoanAmountEditable && this.getEditableLoanAmount() }
        </Grid>
        <Grid item xs={12}>
          <h3>Feel free to change your loan terms to discover new auto loan options. Once you've identified the right loan, click 'Apply' and complete your application.</h3>
          <AutoLoanAdditionalButtons
            isLoanAmountEditable     = { isLoanAmountEditable }
            handleChangeLoanAmount   = { handleChangeLoanAmount }
            handleRecalculate        = { handleRecalculate }
            handleCancel             = { handleCancel }
            initialLoanAmount        = { initialLoanAmount }
            initialCreditScore       = { initialCreditScore}
            initialLoanTermDesired   = { initialLoanTermDesired }
            loanAmount               = { loanAmount }
            minLoanAmount            = { minLoanAmount }
            maxLoanAmount            = { maxLoanAmount }
            purchasePrice            = { purchasePrice }
            tradeInValue             = { tradeInValue }
            downPayment              = { downPayment }
            creditScore              = { creditScore }
            loanTermDesired          = { loanTermDesired }
          />
        </Grid>
        <Typography variant="body2" classes={{ root: 'disclaimer' }}>
          <Trans i18nKey="autoLoanRateOptions.disclaimerParagraph1">
            <sup>*</sup>Disclaimer: Rate offers are examples only and are not a commitment to lend. Information provided is based on consumer input. Actual rate/APR and monthly payment amount will vary based upon credit history, rates in effect at the time of loan consummation and other factors. Additional information is required to provide a rate/APR based on an applicant’s specific circumstances. Programs available only to qualified borrowers. Subject to credit approval, underwriting approval and lender terms and conditions. Programs and rates subject to change without notice. Some restrictions may apply.
          </Trans>
        </Typography>
      </Grid>
    );
  }
}

export const AutoLoanRateOptions = withTheme(withStyles(styles)(withTranslation()(AutoLoanRateOptionsComponent)));
