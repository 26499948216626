import React from 'react';
import {
  Grid,
  Typography,
  withStyles,
  createStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  WithStyles,
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { TFunction } from 'i18next';

const styles = (theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      width: '100%',
    },
    listWrapper: {
      maxWidth: '270px',
      marginTop: '.5rem',
    },
  });

/**
 * Renders a row containing an explanation of how the max loan amount was calculated
 *
 * @param {*} { propertyUsageLabel, percent, idx }
 * @param {TFunction} t
 * @returns (ie: Primary Home = 90% LTV)
 */
const renderLtvCalculationRow = (propertyUsage: string, maxLtvPercent: number, t: TFunction) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography variant="body2" classes={{ body2: 'small' }}>
          {t(`question.propertyUsage.options.${propertyUsage}`)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" classes={{ body2: 'small' }}>
          {t(`maximumLoanExplanation.ltvPercent`, {
            defaultValue: '= {{ maxLtvPercent, percent.0 }} LTV ',
            maxLtvPercent,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface Props extends WithStyles<typeof styles> {
  maxLtvPercent    : number;
  helocPropertyUse : string;
}

export const MaximumLoanExplanation = withStyles(styles)(({ classes, maxLtvPercent, helocPropertyUse }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Accordion elevation={0}>
        <AccordionSummary
          classes={{
            root: 'removePadding',
            content: 'rateDisplayContent',
          }}
        >
          {t('maximumLoanExplanation.title', {
            defaultValue: 'How was my loan amount determined?',
          })}
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: 'removePadding',
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography>
                    <Trans i18nKey="maximumLoanExplanation.paragraph1">
                      The loan amount above is the <strong>maximum loan amount</strong> for your property type and loan-to-value (LTV) ratio, which was calculated as follows:
                    </Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container={false} className={classes.listWrapper}>
                { renderLtvCalculationRow(helocPropertyUse, maxLtvPercent, t) }
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});
