import { isLocalhost } from 'app/api/helpers';
import { datadogRum } from '@datadog/browser-rum';

declare const DD_LOGS: any; // DD_LOGS is added via webpack in index.html

/**
 * Datadog Browser Logging
 * @see https://docs.datadoghq.com/logs/log_collection/javascript
 */
export const logger = {
  init: (): void => {
    if (isLocalhost) {
      console.debug('[DD]: Running app locally, not reporting errors to datadog');
    } else {
      const ddLogs = window['DD_LOGS'];
      if (typeof ddLogs !== 'undefined') {
        ddLogs.init({
          clientToken: 'pubb7fc38cace66dcb536d528b0fd2a64c4',
          forwardErrorsToLogs: true,
        });

        // Default logger used
        ddLogs.logger.setLevel('info');
        ddLogs.addLoggerGlobalContext('service', 'browser');
        ddLogs.addLoggerGlobalContext('environment', process.env.REACT_APP_IS_STAGING_OR_DEV === 'true' ? 'staging' : 'production');
        ddLogs.addLoggerGlobalContext('stage', process.env.REACT_APP_IS_STAGING_OR_DEV === 'true' ? 'dev' : 'prod');
        ddLogs.addLoggerGlobalContext('hash', process.env.REACT_APP_GIT_COMMIT_HASH);
        ddLogs.addLoggerGlobalContext('version', process.env.REACT_APP_VERSION);
        ddLogs.addLoggerGlobalContext('host', window.location.host);
      }

      datadogRum.init({
        applicationId: 'ae14db41-e2ea-45f4-83a7-ebfcc63ea3ae',
        clientToken: 'pub0d6a6004089b78652a35f614915f6c15',
        site: 'datadoghq.com',
        service: 'lenderful',
        env: process.env.REACT_APP_IS_STAGING_OR_DEV === 'true' ? 'staging' : 'production',
        version: process.env.REACT_APP_VERSION,
        sampleRate: 100,
        replaySampleRate: 100,
        trackFrustrations: true,
        trackUserInteractions: true,
      });

      datadogRum.startSessionReplayRecording();
    }
  },
  debug: (message: string, context: any = {}): void => {
    if (!isLocalhost && window['DD_LOGS']) {
      DD_LOGS.logger.debug(message, context);
    }
    console.debug(message, context);
  },
  info: (message: string, context: any = {}): void => {
    if (!isLocalhost && window['DD_LOGS']) {
      DD_LOGS.logger.info(message, context);
    }
    console.info(message, context);
  },
  warn: (message: string, context: any = {}): void => {
    if (!isLocalhost && window['DD_LOGS']) {
      DD_LOGS.logger.warn(message, context);
    }
    console.warn(message, context);
  },
  error: (message: string, context: any = {}): void => {
    if (!isLocalhost && window['DD_LOGS']) {
      DD_LOGS.logger.error(message, context);
    }
    console.error(message, context);
  },
};
