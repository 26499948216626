import { logger } from 'app/util/logger';

/**
 * Takes an object and a string containing the path in dot notation
 * and returns the nested value.
 *
 * @param {object} sourceObj An object with nested values
 * @param {string} path Path to the nested value. ie: 'coBorrower.firstName'
 * @returns {*} Nested value
 */

export const nestedValueByString = (sourceObj: object, path: string): any => {
  try {
    return path.split('.').reduce((obj, prop) => {
      
      // handles properties with array syntax, ie. commercialOwnerList[0].ethnicity
      const propertyEndingWithArrayBrackets = /^(.*)\[([0-9]+)\]$/gm;
      const isPropEndWithArray = propertyEndingWithArrayBrackets.exec(prop);
      if (isPropEndWithArray?.length > 0) {
        const propertyPart = isPropEndWithArray[1];
        const indexOfArrayPart = isPropEndWithArray[2];
        return obj ? obj[propertyPart][indexOfArrayPart] : undefined;
      }
      return obj ? obj[prop] : undefined;
    }, sourceObj);
  } catch (error) {
    logger.debug('Error in nestedValueByString: ', error);
    return undefined;
  }
};
