import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components/Button';
import { goToInsuranceQuoteForm } from 'app/actions/form/actions';
import { Grid } from '@material-ui/core';
import { getFormName } from "../../routes/helpers";
import { productsSelector } from 'app/reducers/app-config';
import { Product } from '@lenderful/domain';

type MappedStateType = ReturnType<typeof mapStateToProps>;
type InsuranceQuoteActions = ReturnType<typeof mapDispatchToProps>;
type Props = InsuranceQuoteActions & MappedStateType;

const GoToClosingCostButtonComponent = (props: Props) => {
  const { formName, goToInsuranceQuoteForm, hasInsuranceQuoteProduct } = props;
  const { t } = useTranslation();

  if (!hasInsuranceQuoteProduct) {
    return null;
  }

  return (
    <Grid container justifyContent='center'>
      <Grid item>
        <Button
          classes  = {{ root: 'longButton' }}
          color    = 'primary'
          variant  = 'contained'
          onClick  = {() => goToInsuranceQuoteForm(formName)}
        >
          {t('insuranceQuote.button', { defaultValue: 'Get Home Insurance Quote' })}
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  formName                 : getFormName(state.router.location.pathname),
  hasInsuranceQuoteProduct : productsSelector(state).some(product => product.name === Product.INSURANCE_QUOTE),
});

const mapDispatchToProps = (dispatch) => ({
  goToInsuranceQuoteForm : (formName) => dispatch(goToInsuranceQuoteForm(formName)),
});

export const GoToInsuranceQuoteButton = compose<React.ComponentType<any>>(
  connect(mapStateToProps, mapDispatchToProps),
)(GoToClosingCostButtonComponent);
