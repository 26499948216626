import React from 'react';
import { Field } from 'redux-form';
import { normalizePhone } from 'app/util/normalizers';
import { required, noopValidate, isValidPhone } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

export const Phone = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete       = {mapAutoCompleteName(name)}
      component          = {renderInputBase}
      customProps        = {props.customProps}
      formControlClasses = {props.formControlClasses}
      inputClasses       = {props.inputClasses}
      inputGridProps     = {props.inputGridProps}
      label              = {props.label}
      name               = {name}
      normalize          = {normalizePhone}
      type               = "tel"
      validate           = {[isRequired, isValidPhone]}
    />
  );
};
