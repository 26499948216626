import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { ButtonDelay } from 'app/components/ButtonDelay';
import download from 'downloadjs';
import * as FormActions from 'app/actions/form/actions';
import { ClosingCostType, postLoanEstimate } from 'app/api/loan-estimate';
import { EscrowUsage, FormName } from 'app/models/options/enums';
import { getFormValues } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { isPrimaryHome } from 'app/models/fields/conditionals';

type Props = ReturnType<typeof mapStateToProps>;

const ClosingCostEstimationComponent = (props: Props) => {
  const { values, confirmationImage } = props;
  const { t } = useTranslation();

  const loanAmount             = values[FieldNames.loanAmount];
  const downDollar             = values[FieldNames.downDollar];
  const propertyAppraisedValue = values[FieldNames.homeValue];

  const [isDownloading, setIsDownloading] = React.useState(false);

  // when loan amount is not in form, we calculate it
  // TODO: see if we can move this to a selector and reuse in other places
  const getLoanAmount = (): number => {
    return loanAmount ? loanAmount : propertyAppraisedValue - downDollar;
  };

  const getTotalNumberOfProperties = (): number => {
    // if we have REO liabilities use that
    if (values[FieldNames.realEstateLiabilities]) {
      return values[FieldNames.realEstateLiabilities].length;
    }
    // otherwise we look at the property usage + additional properties
    const numberOfAdditionalProperties = values[FieldNames.addlProperties] ? values[FieldNames.addlProperties].length : 0;
    return (isPrimaryHome(values) ? 1 : 2) + numberOfAdditionalProperties;
  };

  const handleClick = () => {
    const request = {
      annualInsurance         : values[FieldNames.yearInsure],
      annualTaxes             : values[FieldNames.yearTaxes],
      closingCostType         : ClosingCostType.STANDARD,
      coBorrowerFirstName     : values[FieldNames.coBorrowerFirstName],
      coBorrowerMiddleName    : values[FieldNames.coBorrowerMiddleName],
      coBorrowerLastName      : values[FieldNames.coBorrowerLastName],
      coBorrowerSuffixName    : values[FieldNames.coBorrowerSuffixName],
      downDollar              : values[FieldNames.downDollar],
      firstName               : values[FieldNames.firstName],
      hasEscrow               : values[FieldNames.escrowUsage] === EscrowUsage.YES,
      lastName                : values[FieldNames.lastName],
      loanAmount              : getLoanAmount(),
      loanPurpose             : values[FieldNames.loanPurpose],
      loanTerm                : values[FieldNames.loanTerm],
      loanType                : values[FieldNames.loanType],
      middleName              : values[FieldNames.middleName],
      monthlyPayment          : values[FieldNames.monthlyPayment],
      pointFee                : values[FieldNames.fee],
      productType             : values[FieldNames.productType],
      propertyAppraisedValue  : values[FieldNames.homeValue],
      propertyStreet          : values[FieldNames.propertyStreet],
      propertyCity            : values[FieldNames.propertyCity],
      propertyState           : values[FieldNames.propertyState],
      propertyType            : values[FieldNames.propertyType],
      propertyUsage           : values[FieldNames.propertyUsage],
      propertyZip             : values[FieldNames.propertyZip],
      rate                    : values[FieldNames.rate],
      realEstateLiabilities   : values[FieldNames.realEstateLiabilities],
      suffixName              : values[FieldNames.suffixName],
      totalNumberOfProperties : getTotalNumberOfProperties(),
    };
    setIsDownloading(true);
    postLoanEstimate(request)
      .then(data => {
        const { settlementPdf, summaryPdf } = data;
        setIsDownloading(false);
        download(summaryPdf, 'closing-cost-estimate.pdf', 'application/pdf');
        if (settlementPdf) {
          download(settlementPdf, 'settlement-statement.pdf', 'application/pdf');
        }
      });
  };

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('closingCost.confirmation.title', { defaultValue: 'Thank you!' })}
          </Typography>
          <Typography variant='body2' paragraph={true}>
            {t('closingCost.confirmation.paragraph1', {
              defaultValue:
                'We have generated a quote for your closing costs.  Please click the button below to download your document.',
            })}
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <ButtonDelay
                loading     = {isDownloading}
                buttonProps = {{
                  classes  : { root: 'longButton' },
                  color    : 'primary',
                  disabled : isDownloading,
                  type     : 'submit',
                  variant  : 'contained',
                  onClick  : handleClick,
                }}
                pendingText={t('closingCost.downloading.button', { defaultValue: 'Downloading' })}
              >
                {t('closingCost.downloadPdf.button', { defaultValue: 'Download PDF' })}
              </ButtonDelay>
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  values            : getFormValues(FormName.CLOSING_COSTS)(state),
  confirmationImage : state.config.confirmationImage,
});

const mapDispatchToProps = FormActions;

export const ClosingCostEstimation = connect(mapStateToProps, mapDispatchToProps)(ClosingCostEstimationComponent);
