import { getAuditMetadata, makeActionCreator, timeout } from 'app/util/actions';
import { Type } from 'app/actions/form/verification/types';
import { getNextSection, goToSection } from 'app/actions/form/actions';
import { FieldNames } from 'app/models/fields/names';
import { logger } from 'app/util/logger';
import {
  addCustomers,
  createAdditionalReports,
  getCustomerAccounts,
  getCustomerReports,
} from 'app/api/verification/financial';
import { Borrower, GetCustomerAccountsResponse, GetCustomerReportsResponse } from 'app/api/verification/types';
import { coBorrowerHasDifferentAddress, hasCoBorrower, isPrimaryHome } from 'app/models/fields/conditionals';
import moment from 'moment';
import { borrowerSsnRef, coBorrowerSsnRef } from 'app/components/FormFields/Ssn/CollectSsnBase';
import { toNormalizedSsn } from 'app/util/normalizers';
import { RootState } from 'app/store/types';
import { getVerificationBorrowerCustomerId, getVerificationCustomerIds } from 'app/reducers/verification';
import {
  HOME_EQUITY_TURBO_BORROWER_DECLARATION,
  REFINANCE_TURBO_BORROWER_DECLARATION,
} from 'app/models/sections/constants';
import { getFormName } from "../../../routes/helpers";
import { FormName } from "../../../models/options/enums";
import { AuditUserEventRequest, AuditUserEventType, postAuditUserEvent } from '../../../api/audit-event';

/* Verification Actions */
export const ajaxVerificationAddCustomers                   = makeActionCreator(Type.AJAX_VERIFICATION_ADD_CUSTOMERS);
export const ajaxVerificationAddCustomersSuccess            = makeActionCreator(Type.AJAX_VERIFICATION_ADD_CUSTOMERS_SUCCESS, 'payload');
export const ajaxVerificationAddCustomersFail               = makeActionCreator(Type.AJAX_VERIFICATION_ADD_CUSTOMERS_FAIL, 'payload');
export const ajaxVerificationDeleteAllCustomers             = makeActionCreator(Type.AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS);
export const ajaxVerificationDeleteAllCustomersSuccess      = makeActionCreator(Type.AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS_SUCCESS);
export const ajaxVerificationDeleteAllCustomersFail         = makeActionCreator(Type.AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS_FAIL);
export const ajaxVerificationGetCustomerAccounts            = makeActionCreator(Type.AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS);
export const ajaxVerificationGetCustomerAccountsSuccess     = makeActionCreator(Type.AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS_SUCCESS, 'payload');
export const ajaxVerificationGetCustomerAccountsFail        = makeActionCreator(Type.AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS_FAIL, 'payload');
export const ajaxVerificationCreateAdditionalReports        = makeActionCreator(Type.AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS);
export const ajaxVerificationCreateAdditionalReportsSuccess = makeActionCreator(Type.AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_SUCCESS, 'payload');
export const ajaxVerificationCreateAdditionalReportsFail    = makeActionCreator(Type.AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_FAIL, 'payload');
export const ajaxVerificationGetCustomerReports             = makeActionCreator(Type.AJAX_VERIFICATION_GET_CUSTOMER_REPORTS);
export const ajaxVerificationGetCustomerReportsSuccess      = makeActionCreator(Type.AJAX_VERIFICATION_GET_CUSTOMER_REPORTS_SUCCESS, 'payload');
export const ajaxVerificationGetCustomerReportsFail         = makeActionCreator(Type.AJAX_VERIFICATION_GET_CUSTOMER_REPORTS_FAIL, 'payload');

export const verificationConnectComplete           = makeActionCreator(Type.VERIFICATION_CONNECT_COMPLETE);
export const verificationRefreshAccountComplete    = makeActionCreator(Type.VERIFICATION_REFRESH_ACCOUNT_COMPLETE);
export const verificationReportsGenerationComplete = makeActionCreator(Type.VERIFICATION_REPORT_GENERATION_COMPLETE);

/**
 * This calls the endpoint to obtain the URL to go through the financial verification process.
 *
 * @param formData
 * @returns
 */
export function ajaxGetFinancialVerificationUrl(formData) {

  const borrower: Borrower = {
    firstName : formData[FieldNames.firstName],
    lastName  : formData[FieldNames.lastName],
    birthday  : {
      year       : moment(formData[FieldNames.dateOfBirth]).year(),
      month      : moment(formData[FieldNames.dateOfBirth]).month() + 1,
      dayOfMonth : moment(formData[FieldNames.dateOfBirth]).date(),
    },
    ssn     : borrowerSsnRef ? toNormalizedSsn(borrowerSsnRef.value) : undefined,
    address : isPrimaryHome(formData) ? formData[FieldNames.propertyStreet] : formData[FieldNames.livingPropertyStreet],
    city    : isPrimaryHome(formData) ? formData[FieldNames.propertyCity]   : formData[FieldNames.livingPropertyCity],
    state   : isPrimaryHome(formData) ? formData[FieldNames.propertyState]  : formData[FieldNames.livingPropertyState],
    zip     : isPrimaryHome(formData) ? formData[FieldNames.propertyZip]    : formData[FieldNames.livingPropertyZip],
    email   : formData[FieldNames.email],
    phone   : formData[FieldNames.phone],
  };
  let coBorrower: Borrower;
  if (hasCoBorrower(formData)) {
    coBorrower = {
      firstName : formData[FieldNames.coBorrowerFirstName],
      lastName  : formData[FieldNames.coBorrowerLastName],
      birthday  : {
        year       : moment(formData[FieldNames.coBorrowerDOB]).year(),
        month      : moment(formData[FieldNames.coBorrowerDOB]).month() + 1,
        dayOfMonth : moment(formData[FieldNames.coBorrowerDOB]).date(),
      },
      ssn     : coBorrowerSsnRef ? toNormalizedSsn(coBorrowerSsnRef.value) : undefined,
      address : coBorrowerHasDifferentAddress(formData) ? formData[FieldNames.coBorrowerLivingPropertyStreet] : formData[FieldNames.livingPropertyStreet],
      city    : coBorrowerHasDifferentAddress(formData) ? formData[FieldNames.coBorrowerLivingPropertyCity] : formData[FieldNames.livingPropertyCity],
      state   : coBorrowerHasDifferentAddress(formData) ? formData[FieldNames.coBorrowerLivingPropertyState] : formData[FieldNames.livingPropertyState],
      zip     : coBorrowerHasDifferentAddress(formData) ? formData[FieldNames.coBorrowerLivingPropertyZip] : formData[FieldNames.livingPropertyZip],
      email   : formData[FieldNames.coBorrowerEmail],
      phone   : formData[FieldNames.coBorrowerPhone],
    };
  }

  const auditRequest: AuditUserEventRequest = {
    auditEvent: {
      email      : borrower.email,
      firstName  : borrower.firstName,
      lastName   : borrower.lastName,
      eventMeta  : JSON.stringify(getAuditMetadata('Next Button')),
      eventType  : AuditUserEventType.USER_ACCEPTS_FULL_TRI_MERGE,
    },
  };
  postAuditUserEvent(auditRequest);
  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    dispatch(ajaxVerificationAddCustomers());
    return addCustomers({ borrower, coBorrower })
      .then((response) => {
        const { connectUrl, borrowerCustomerId } = response;
        dispatch(ajaxVerificationAddCustomersSuccess(response));

        // existing customer with recent reports
        if (!connectUrl && borrowerCustomerId) {
          if(formName === FormName.HOME_EQUITY_TURBO) {
            return dispatch(goToSection(HOME_EQUITY_TURBO_BORROWER_DECLARATION));
          } else if(formName === FormName.REFINANCE_TURBO) {
            return dispatch(goToSection(REFINANCE_TURBO_BORROWER_DECLARATION));
          }
        }
        return dispatch(getNextSection());
      })
      .catch((error) => {
        logger.error('Error: failed to get financial verification url', { error });
        dispatch(ajaxVerificationAddCustomersFail(error));
      });
  };
}

/**
 * This action uses the poller to see if the customer accounts refresh
 * is complete.  When complete, we can go on to the next step.
 */
export function ajaxGetCustomerAccounts() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const customerIds = getVerificationCustomerIds(state);

    // poll to get customer accounts
    let responses = await Promise.all(
      customerIds.map(async customerId => pollCustomerAccounts(dispatch, customerId)),
    );
    while(!responses || !responses.every(response => response.isAccountRefreshComplete)) {
      await timeout(10000);
      responses = await Promise.all(
        customerIds.map(async customerId => pollCustomerAccounts(dispatch, customerId)),
      );
    }
    dispatch(verificationRefreshAccountComplete());
  };
}

/**
 * This action calls the backend to kick off the process of creating a
 * Verification of Assets Report for the borrower.
 */
export function ajaxCreateAdditionalReports() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const borrowerCustomerId = getVerificationBorrowerCustomerId(state);

    dispatch(ajaxVerificationCreateAdditionalReports());
    return createAdditionalReports({ customerId: borrowerCustomerId })
      .then((response) => {
        dispatch(ajaxVerificationCreateAdditionalReportsSuccess(response));
      })
      .catch((error) => {
        logger.error('Error: failed to create additional reports', { error });
        dispatch(ajaxVerificationCreateAdditionalReportsFail(error));
      });
  };
}

/**
 * This action uses the poller to see if all the customer reports are
 * complete and successfully created.
 */
export function ajaxGetCustomerReports() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const customerIds = getVerificationCustomerIds(state);

    // poll to get customer accounts
    let responses = await Promise.all(
      customerIds.map(async customerId => pollCustomerReports(dispatch, customerId)),
    );
    while(!responses || !responses.every(response => response.isAllReportsSuccessful)) {
      await timeout(10000);
      responses = await Promise.all(
        customerIds.map(async customerId => pollCustomerReports(dispatch, customerId)),
      );
    }
    dispatch(verificationReportsGenerationComplete());
  };
}

/**
 * This polls the customer accounts to see if they are done refreshing.
 *
 * @param dispatch
 * @param {string} customerId unique ID that represents the customer in Finicity
 * @returns {Promise<GetCustomerAccountsResponse>}
 */
const pollCustomerAccounts = async (dispatch, customerId: string): Promise<GetCustomerAccountsResponse> => {
  ajaxVerificationGetCustomerAccounts();
  try {
    logger.debug('Polling for customer accounts...');
    const response = await getCustomerAccounts({ customerId });
    dispatch(ajaxVerificationGetCustomerAccountsSuccess(response));
    return response;
  } catch (error) {
    logger.error('Customer account error when polling', error);
    dispatch(ajaxVerificationGetCustomerAccountsFail(error));
  }
};

/**
 * This polls the customer reports to see if they are complete and successful.
 *
 * @param dispatch
 * @param {string} customerId unique ID that represents the customer in Finicity
 * @returns {Promise<GetCustomerReportsResponse>}
 */
const pollCustomerReports = async (dispatch, customerId: string): Promise<GetCustomerReportsResponse> => {
  ajaxVerificationGetCustomerReports();
  try {
    logger.debug('Polling for customer reports...');
    const response = await getCustomerReports({ customerId });
    dispatch(ajaxVerificationGetCustomerReportsSuccess(response));
    return response;
  } catch (error) {
    logger.error('Customer reports error when polling', error);
    dispatch(ajaxVerificationGetCustomerReportsFail(error));
  }
};
