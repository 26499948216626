import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { getSlug } from 'app/util/headers';

type GetPropertyInfoRequest = {
  city          : string;
  state         : string;
  streetAddress : string;
  zipCode       : string;
}

type GetPropertyInfoResponse = {
  avmReportId? : number;
  county?      : string;
  taxes        : number;
  valuation?   : {
    value : number;
    high  : number;
    low   : number;
  }
}

export const getPropertyInfo = (request: GetPropertyInfoRequest): Promise<GetPropertyInfoResponse> => {
  const params = { ...request, slug: getSlug() };
  return client
    .get(Endpoints.PROPERTY_VALUATION, { params })
    .then((response) => {
      return response.data as GetPropertyInfoResponse;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};
