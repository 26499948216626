import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { Question } from 'app/models/types';
import { Select } from 'app/components/FormFields/Select';
import { PROPERTY_USAGE_OPTIONS } from 'app/models/options/options';
import { SHARED } from 'app/models/questions/shared';
import { State } from 'app/components/FormFields/State';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { CreditScoresRadio } from 'app/components/FormFields/CreditScoresRadio';

/**
 * Takes in a field name and returns the corresponding home equity
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const homeEquityRatesQuestionByName = (name: string): Question => {
  return HOME_EQUITY_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding home equity
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const homeEquityRatesQuestionById = (id: number): Question => {
  return HOME_EQUITY_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const homeEquityRatesQuestionsByName = (names: string[] = []): Question[] => {
  const questions: any[] = [];
  for (const name of names) {
    questions.push(homeEquityRatesQuestionByName(name));
  }
  return questions;
};

export const HOME_EQUITY_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : Currency,
    label     : 'Home Value',
    name      : FieldNames.homeValue,
  },
  {
    id        : 1002,
    component : Currency,
    label     : 'Current Mortgage Balance',
    name      : FieldNames.mortgageBalance,
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyUsage],
    component   : Select,
    options     : PROPERTY_USAGE_OPTIONS,
    fieldPopover: true,
  },
  {
    id        : 1004,
    component : Currency,
    label     : 'Requested Borrow Amount',
    name      : FieldNames.loanAmount,
  },
  {
    id       : 1005,
    component: State,
    name     : FieldNames.propertyState,
    label    : 'State',
  },
  {
    id       : 1006,
    component: ZipCode,
    name     : FieldNames.propertyZip,
    label    : 'Zip Code',
  },
  {
    id: 1007,
    ...SHARED[FieldNames.creditScore],
    component    : CreditScoresRadio,
    title        : 'What is your estimated credit score?',
    fieldPopover : false,
    popover      : null,
  },
];
