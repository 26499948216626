import React from 'react';
import { createStyles, withStyles, Hidden, WithStyles, PropTypes } from '@material-ui/core';
import { TileBox } from 'app/components/TileBox';

const styles = () => {
  return createStyles({
    bottomBox: {
      minHeight: '220px',
    },
  });
};

interface Props extends WithStyles<typeof styles> {
  children : React.ReactChild;
  color    : PropTypes.Color;
  title    : string;
}

class PaymentDesktopWrapperComponent extends React.Component<Props, any> {

  render() {
    const { classes, color, children, title } = this.props;
    return (
      <Hidden smDown>
        <TileBox
          color        = { color }
          title        = { title }
          tileBoxProps = {{
            alignItems : 'center',
            className  : classes.bottomBox,
          }}
        >
          { children }
        </TileBox>
      </Hidden>
    );
  }
}

export const PaymentDesktopWrapper = withStyles(styles)(PaymentDesktopWrapperComponent);
