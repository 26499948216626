import React from 'react';
import { colors, createStyles, Grid, withStyles } from '@material-ui/core';

const styles = (theme) => createStyles({
  root: {
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${colors.grey[300]}`,
      paddingBottom: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '16px',
    },
  },
});

export const IndentedWrapper = withStyles(styles)(({ ...props }: any) => {
  const { children, classes } = props;
  return (
    <div className={classes.root}>
      <Grid container classes={{ container: 'indent' }}>
        {children}
      </Grid>
    </div>
  );
});
