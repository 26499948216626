import { EscrowUsage, PropertyUsage, PropertyType } from 'app/models/options/enums';
import { LiabilityAccount } from 'app/api/credit-report';
import { getLoanAmount, Endpoints } from 'app/api/helpers';
import { client } from 'app/api/_client';
import { FieldNames } from 'app/models/fields/names';
import { getSlug } from 'app/util/headers';
import {
  Income,
  Liability,
  RatesRequest,
  RatesPropertyUsage,
  PrequalRatesRequest,
  ConsumerLoanRatesRequest,
  ConsumerLoanRequestType,
  LoanPurpose,
  LoanProgramRatesResponse,
  ConsumerLoanLimitsRequest,
  ConsumerLoanLimitsResponse,
  ConsumerLoanDebtToIncomeRequest,
  ConsumerLoanDebtToIncomeResponse,
  ReverseMortgageCalcsRequest,
  ReverseMortgageCalcsResponse,
  RatesPropertyType,
} from './types';
import { logger } from 'app/util/logger';
import { LiabilityStatus } from '@lenderful/domain';
import { mapCoBorrowerIncome, mapIncome } from 'app/api/loan-application/mappings';
import { isYes } from 'app/models/fields/conditionals';

/**
 * Maps an income array from a formArray and returns uniform key names
 * for the backend
 *
 * @param {any[]} [incomes=[]]
 * @returns {Income[]}
 */
export const mapIncomeArr = (incomes: any[] = []): Income[] => {
  return incomes.map((income) => ({
    type: income[FieldNames.incomeSource],
    amount: income[FieldNames.incomeMonthly],
  }));
};

/**
 * Maps an income or a liability amount to an object expected by the backend
 *
 * @param {number} [income=0]
 * @returns {(Income | Liability)}
 */
export const incomeOrLiabilityToObj = (amount: number = 0): Income | Liability => {
  return {
    type: '',
    amount,
  };
};

/**
 * This method maps real estate liabilities, but only includes the ones that
 * the borrower intends on continuing to own.
 *
 * @param {LiabilityAccount[]} realEstates
 * @returns
 */
export const mapLiabilities = (realEstates: LiabilityAccount[] = []) => {
  const realEstateLiabilities = realEstates
    .filter((liability) => liability && liability[FieldNames.liabilityStatus] === LiabilityStatus.OWN)
    .map((liability)    => incomeOrLiabilityToObj(liability[FieldNames.liabilityAmount]));

  return realEstateLiabilities;
};

export const mapRatesPropertyUsage = (propertyUsage: PropertyUsage): RatesPropertyUsage => {
  return propertyUsage === PropertyUsage.PRIMARY    ? 'PRIMARY_RESIDENCE' :
    propertyUsage === PropertyUsage.SECONDARY  ? 'SECONDARY_OR_VACATION' :
      propertyUsage === PropertyUsage.INVESTMENT ? 'INVESTMENT_OR_RENTAL' :
        'PRIMARY_RESIDENCE';
};

export const mapRatesPropertyType = (propertyType: PropertyType): RatesPropertyType => {
  return propertyType === PropertyType.SINGLE  ? 'SINGLE_FAMILY' :
    propertyType === PropertyType.CONDO   ? 'CONDO' :
      propertyType === PropertyType.MODULAR ? 'MODULAR' :
        propertyType === PropertyType.MULTI   ? 'MULTI_DWELLING' :
          propertyType === PropertyType.MANUFACTURED_HOME_ON_FOUNDATION ? 'MANUFACTURED_HOME_ON_FOUNDATION' :
            'SINGLE_FAMILY';
};

export const mapRatesRequest = (formData, config, isPrequal = false): RatesRequest | PrequalRatesRequest => {

  const loanAmount = isPrequal
    /* Prequal loan amount can be recalculated, so it should be based on the original asking amount (homeValue) */
    ? formData[FieldNames.homeValue] - formData[FieldNames.downDollar]
    : getLoanAmount(formData);

  const borrowerIncome = mapIncome(formData)
    .map(income => ({ amount: income.monthly_amount, type: income.income_type }));

  let coBorrowerIncome: { amount: number, type: string }[] = [];
  if (isYes(formData[FieldNames.coBorrowerIncomeVerifiable])) {
    coBorrowerIncome = mapCoBorrowerIncome(formData)
      .map(income => ({ amount: income.monthly_amount, type: income.income_type }));
  }

  return {
    creditReportId        : formData[FieldNames.creditReportId],
    escrowsWaived         : (formData[FieldNames.escrowUsage] === EscrowUsage.NO),
    fico                  : !isPrequal ? formData[FieldNames.creditScore] : undefined,
    loanPurpose           : LoanPurpose[formData[FieldNames.loanPurpose]],
    loanAmount,
    propertyAppraisedValue: formData[FieldNames.homeValue],
    propertyType          : mapRatesPropertyType(formData[FieldNames.propertyType]),
    propertyUsage         : mapRatesPropertyUsage(formData[FieldNames.propertyUsage]),
    propertyZip           : formData[FieldNames.propertyZip],
    annualTaxes           : formData[FieldNames.yearTaxes],
    annualInsurance       : formData[FieldNames.yearInsure],
    annualHOA             : formData[FieldNames.yearAssociate],
    monthlyIncome         : [...borrowerIncome, ...coBorrowerIncome],
    monthlyLiabilities    : mapLiabilities(formData[FieldNames.realEstateLiabilities]),
  };
};

export const fetchRates = (params) => {
  /* isPrequal is passed down through the form submit handlers in the form actions */
  const ratesEndpoint = params.isPrequal ? Endpoints.PREQUALRATES : Endpoints.RATES;
  params.slug = getSlug();
  let requestPromise;
  if (params.isPrequal) {
    requestPromise = client.post(ratesEndpoint, params);
  } else {
    requestPromise = client.get(ratesEndpoint, { params });
  }
  return requestPromise
    .then((response) => {
      if (response.data && response.data.errorMessage) {
        logger.error('Error in rates API call: ', response);
        return []; // Return an empty array so the user can keep moving through the form
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchConsumerLoanRates = async (request: ConsumerLoanRatesRequest): Promise<LoanProgramRatesResponse> => {
  const params = { ...request, slug: getSlug() };
  let endpoint;
  if (request.type === ConsumerLoanRequestType.PERSONAL) {
    endpoint = Endpoints.PERSONAL_LOAN_RATES;
  } else if (request.type === ConsumerLoanRequestType.AUTO) {
    endpoint = Endpoints.AUTO_LOAN_RATES;
  } else if (request.type === ConsumerLoanRequestType.CREDIT_BOOSTER) {
    endpoint = Endpoints.CREDIT_BOOSTER_RATES;
  } else if (request.type === ConsumerLoanRequestType.OVERDRAFT_PROTECTION) {
    endpoint = Endpoints.OVERDRAFT_PROTECTION_RATES;
  }
  return client.get(endpoint, { params })
    .then((response) => {
      if (response.data?.errorMessage) {
        logger.error('Error in fetching consumer loan rates API call: ', { request, response });
        return []; // Return empty array to keep user moving forward
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchConsumerLoanLimits = async (request: ConsumerLoanLimitsRequest): Promise<ConsumerLoanLimitsResponse> => {
  const params = { ...request, slug: getSlug() };
  let endpoint: string;
  if (request.type === ConsumerLoanRequestType.PERSONAL) {
    endpoint = Endpoints.PERSONAL_LOAN_LIMITS;
  } else if (request.type === ConsumerLoanRequestType.OVERDRAFT_PROTECTION) {
    endpoint = Endpoints.OVERDRAFT_PROTECTION_LOAN_LIMITS;
  } else if (request.type === ConsumerLoanRequestType.AUTO) {
    endpoint = Endpoints.AUTO_LOAN_LIMITS;
  } else if (request.type === ConsumerLoanRequestType.CREDIT_BOOSTER) {
    endpoint = Endpoints.CREDIT_BOOSTER_LOAN_LIMITS;
  }
  return client.get(endpoint, { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchConsumerLoanDebtToIncome = async (request: ConsumerLoanDebtToIncomeRequest): Promise<ConsumerLoanDebtToIncomeResponse> => {
  const params = { ...request, slug: getSlug() };
  const endpointMap = new Map<ConsumerLoanRequestType, string>([
    [ConsumerLoanRequestType.OVERDRAFT_PROTECTION, Endpoints.OVERDRAFT_PROTECTION_DTI],
    [ConsumerLoanRequestType.PERSONAL, Endpoints.PERSONAL_LOAN_DTI],
    [ConsumerLoanRequestType.AUTO, Endpoints.AUTO_LOAN_LIMITS_DTI],
  ]);
  const endpoint = endpointMap.get(request.type);
  return client.get(endpoint, { params })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const fetchReverseMortgageCalcs = async (request: ReverseMortgageCalcsRequest): Promise<ReverseMortgageCalcsResponse> => {
  const params = { ...request, slug: getSlug() };
  return client.get(Endpoints.REVERSE_MORTGAGE, { params })
    .then((response => response.data))
    .catch((error) => {
      throw error;
    });
};
