import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';

type MappedProps = ReturnType<typeof mapStateToProps>;

interface Props extends MappedProps {
  confirmationImage   : string;
}

const CreditCardConfirmationComponent = (props: Props) => {
  const { confirmationImage } = props;
  const { t } = useTranslation();
  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('creditCardConfirmation.title', { defaultValue: 'Thank you for applying for your Visa credit card!' })}
          </Typography>
          <Typography variant='body2' paragraph={true}>
            {
              t('creditCardConfirmation.paragraph1', {
                defaultValue: 'A bank officer will be in touch with you in the next business day or two.',
              })
            }
          </Typography>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationImage   : state.config.confirmationImage,
});

export const CreditCardConfirmation = connect(mapStateToProps)(CreditCardConfirmationComponent);
