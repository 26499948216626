import { Type } from 'app/actions/app-config/types';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { createSelector } from 'reselect';
import { RootState } from 'app/store/types';
import { getFormName } from 'app/routes/helpers';
import { LandingType } from '@lenderful/domain';
import { getFormValues } from 'redux-form';
import { FieldNames } from '../models/fields/names';

type LoanOfficerState = LoanOfficerDetail[];

const initialState: LoanOfficerState = [{
  email        : '',
  firstName    : '',
  id           : 0,
  lastName     : '',
  middleName   : '',
  nmls         : '',
  phone        : '',
  title        : '',
  website      : '',
  username     : '',
  forms        : [],
  landingTypes : [],
  states       : [],
}];

export const loanOfficerReducer = (state = initialState, action): LoanOfficerState => {
  switch (action.type) {
    case Type.AJAX_GET_LOAN_OFFICER_DETAIL_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

export const getLoanOfficer = (state: RootState): LoanOfficerDetail => {
  const pathName           = state.router.location.pathname;
  const formName           = getFormName(pathName);
  const defaultLoanOfficer = state.loanOfficers[0];
  const currentFormData = getFormValues(formName)(state);

  if (formName) {
    let loanOfficer = state.loanOfficers.find(loanOfficer => loanOfficer.forms.includes(formName.toUpperCase()));
    if(!loanOfficer && currentFormData?.[FieldNames.livingPropertyState]) {
      loanOfficer = state.loanOfficers.find(loanOfficer => loanOfficer.states.includes(currentFormData[FieldNames.livingPropertyState]));
    }
    return loanOfficer ? loanOfficer : defaultLoanOfficer;
  }
  return defaultLoanOfficer;
};

export const getLoanOfficerName = createSelector(
  [getLoanOfficer],
  (loanOfficer) => {
    if (loanOfficer.firstName && loanOfficer.lastName) {
      return `${loanOfficer.firstName} ${loanOfficer.lastName}`;
    } else {
      return '';
    }
  },
);

export const getLoanOfficerFirstName = createSelector(
  [getLoanOfficer],
  (loanOfficer) => {
    return loanOfficer.firstName ? loanOfficer.firstName : '';
  },
);

export const getLoanOfficerPhone = createSelector(
  [getLoanOfficer],
  (loanOfficer) => {
    return loanOfficer.phone + (loanOfficer.phoneExt ? `,${loanOfficer.phoneExt}` : '');
  },
);

export const isApplicationTypeEnabled = (landingType: LandingType) => createSelector(
  [getLoanOfficer],
  (loanOfficer) => {
    return loanOfficer.landingTypes.includes(landingType);
  },
);

