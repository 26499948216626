import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { useTranslation } from 'react-i18next';

export const MaximumLoanDisplay = ({ ...props }: any) => {
  const maxLoanAmount = props.values[FieldNames.maxLoanAmount];
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t('maximumLoanDisplay.title', { defaultValue: 'Your maximum loan amount:' })}
        </Typography>
        <Typography
          color   = "primary"
          variant = "h2"
          classes = {{
            root: 'largeRateDisplay formDisplay',
          }}
        >
          {t('maximumLoanDisplay.maxLoanAmount', {
            defaultValue: '{{ maxLoanAmount, currency.0 }}',
            maxLoanAmount,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
