import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { getLoanOfficer } from 'app/reducers/loan-officer';

interface Props extends WithTranslation {
  loanOfficer: LoanOfficerDetail;
}

export const LoanOfficerLandingComponent: any = ({ loanOfficer, t }: Props) => {
  const { firstName = '', middleName = '', lastName = '' } = loanOfficer;
  return (
    <Grid container={false}>
      <Grid item>
        <Grid container classes={{ container: 'titleContainer' }}>
          <Grid item>
            <Typography variant="h6" classes={{ h6: 'landingTitle' }}>
              {t('loanOfficerLanding.title', {
                firstName,
                middleName,
                lastName,
                defaultValue:
                  'Hello, I\'m {{ firstName }} {{ middleName }} {{ lastName }}',
              })}
            </Typography>
            <Typography variant="body2">
              {t('loanOfficerLanding.subtitle', {
                defaultValue: 'NMLS # {{ nmls }}',
                nmls: loanOfficer.nmls,
              })}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body2" paragraph={true}>
          <Trans i18nKey="loanOfficerLanding.paragraph1">
            I'll be your loan officer and can answer any questions you might have. By clicking the 'Next' button, you will be authorizing a soft credit pull for your pre-qualification. This does <strong>NOT</strong> impact your credit score, but is used to verify your current score and liabilities.
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => ({
  loanOfficer: getLoanOfficer(state),
});

export const LoanOfficerLanding = connect(
  mapStateToProps,
  null,
)(withTranslation()(LoanOfficerLandingComponent));
