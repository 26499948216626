import React from 'react';
import { Field } from 'redux-form';
import {
  createStyles,
  FormGroup,
  FormLabel,
  withStyles,
} from '@material-ui/core';
import { renderCheckboxBase } from 'app/components/FormFields/_renderCheckboxBase';

const styles = (theme) =>
  createStyles({
    root: {
      margin: '10px 0 16px 16px',
    },
  });

export const CheckboxGroup = withStyles(styles)(
  ({ classes, name, options, label = '' }: any) => {
    return (
      <FormGroup className={classes.root}>
        {label ? (
          <FormLabel classes={{ root: 'legend' }}>
            {label}
          </FormLabel>
        ) : null}
        <Field
          component={renderCheckboxBase}
          label={label}
          name={name}
          options={options}
        />
      </FormGroup>
    );
  },
);
