import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import * as React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import {
  commercialCollateralInitialValue,
} from '../../../util/initial-values';
import { FormOption } from 'app/models/options/options';

const commercialCollateralInputs = (options: FormOption<any>[]): DynamicComponentInput[] => ([
  {
    fieldName  : FieldNames.commercialCollateralDescription,
    isOptional : false,
    label      : 'Collateral Description',
    options,
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.commercialCollateralValue,
    isOptional : false,
    label      : 'Collateral Value',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
]);

const commercialCollateralProps = (options: FormOption<any>[]): DynamicFormFieldArray => ({
  addButtonText : 'Collateral',
  initialValue  : commercialCollateralInitialValue,
  inputs        : commercialCollateralInputs(options),
});

export const CommercialCollateralComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: commercialCollateralProps(options), name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
