import React from 'react';
import { connect } from 'react-redux';
import { ImageSection } from 'app/components/ImageSection';
import { Button } from 'app/components/Button';
import { Typography, Paper, Grid, withStyles, createStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getImagePathSafely } from 'app/util/images';

export const ReferralLandingComponent = ({ ...props }: any) => {
  const { clientName, referralLandingImage, route, t } = props;
  const imagePath = getImagePathSafely(referralLandingImage);

  const styles = (theme) =>
    createStyles({
      backgroundImage: {
        [theme.breakpoints.down('sm')]: {
          background: `url('${imagePath}') no-repeat 50% center / cover`,
          height    : '440px',
          position  : 'relative',
        },
      },
    });

  const mobileBackground = (mobileProps) => {
    return (
      <div className={mobileProps.classes.backgroundImage}>
        {mobileProps.children}
      </div>
    );
  };

  const MobileWrapper: any = withStyles(styles)(mobileBackground);

  return (
    <MobileWrapper>
      <Grid
        container
        classes={{ container: 'canvas withoutForm mobileSplash' }}
      >
        <Grid item xs={12}>
          <Grid container classes={{ container: 'shadeCover mobileOnly' }}>
            <Grid item xs={12}>
              <Paper
                elevation = {2}
                classes   = {{
                  root      : 'formWrapper mobileTransparent',
                  elevation2: 'stopGate',
                }}
              >
                <ImageSection image={referralLandingImage}>
                  <Typography
                    variant = "h6"
                    classes = {{
                      root : 'mobileWhite mobileTextShadow',
                      h6: 'splashTitle titleMargin section',
                    }}
                  >
                    {t('referralLanding.title', { defaultValue: 'Get Pre-Qualified Now!' })}
                  </Typography>
                  <Typography
                    variant      = "body2"
                    gutterBottom = {true}
                    classes      = {{
                      root : 'mobileWhite mobileTextShadow',
                      body2: 'splashBody',
                    }}
                  >
                    {t('referralLanding.paragraph1', {
                      clientName,
                      defaultValue: '{{ clientName }}\'s free, online mortgage tool will help you estimate how much house you can afford and will deliver a pre-qualification letter directly to your Inbox in minutes.',
                    })}
                  </Typography>
                  <Button
                    fullWidth
                    variant   = "contained"
                    component = {Link}
                    to        = {route}
                    classes   = {{
                      root     : 'stackedButton',
                      fullWidth: 'tabletSizeLarge',
                      contained: 'tertiary',
                    }}
                    size = "large"
                  >
                    {t('referralLanding.button.start', { defaultValue: 'Get Started' })}
                  </Button>
                </ImageSection>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobileWrapper>
  );
};

const mapStateToProps = (state, ownProps) => ({
  referralLandingImage: state.config.stopGateReferralImage,
  clientName          : state.config.clientDisplayName,
  ...ownProps,
});

export const ReferralLanding = connect(
  mapStateToProps,
  null,
)(withTranslation()(ReferralLandingComponent));
