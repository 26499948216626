import React from 'react';
import { connect } from 'react-redux';
import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import { ModalWithButton } from 'app/components/Modals/Templates/ModalWithButton';
import { withTranslation, WithTranslation } from 'react-i18next';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'inline',
    },
  });

interface Props extends WithStyles<typeof styles>, WithTranslation {
  clientName: string;
}

const TermsModalComponent: any = withStyles(styles)(({ classes, clientName, t }: Props) => {
  return (
    <ModalWithButton
      disableRipple
      buttonText   = {t('modal.terms.button.open', { defaultValue: 'Terms of Use' })}
      classes      = {{ root: 'link termsLink' }}
      modalClasses = {classes.root}
    >
      <Typography variant="body2" paragraph={true}>
        {t('modal.terms.paragraph1', {
          defaultValue:
              'By providing your email address, you agree that we may use it to send you further information related to our mortgage products.',
        })}
      </Typography>
      <Typography variant="body2" paragraph={true}>
        {t('modal.terms.paragraph2', {
          clientName,
          defaultValue:
              'By providing your phone number, you agree that {{ clientName }} may use automated calling technology to contact you by phone call, text or pre-recorded voice message at the telephone number provided, even if you previously registered on a Do-Not-Call registry. This consent is not required to obtain our or this lender\'s goods or services.',
        })}
      </Typography>
    </ModalWithButton>
  );
});

const mapStateToProps = (state: any, ownProps) => {
  return ({
    clientName: state.config.clientDisplayName,
    ...ownProps,
  });
};

export const ModalTerms = connect(
  mapStateToProps,
  null,
)(withTranslation()(TermsModalComponent));
