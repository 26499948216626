import { Field } from 'redux-form';
import {
  required,
  isGreaterThan,
  isGreaterThanMinLoan,
  VALIDATORS,
  noopValidate,
  isDownPaymentTooLow,
} from 'app/util/validators';
import { createStyles, withStyles, InputAdornment, Typography, Theme } from '@material-ui/core';
import { dollarOrEmptyString, emptyStringIfZero } from 'app/util/formatters';
import { toNumber, toDecimalNumber } from 'app/util/parsers';
import { lessThan, oneHundredMax } from 'app/util/normalizers';
import { FieldNames } from 'app/models/fields/names';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { useTranslation } from 'react-i18next';
import { localizeLabel } from 'app/i18n/helpers';

/**
 * Returns a dollar amount from the given percentage of purchase price
 *
 * @param {number} percent Percentage
 * @param {number} purchasePrice Purchase price
 * @returns {number} Dollar amount of the percentage of purchase price
 */
const percentToDollar = (percent: number, purchasePrice: number): number => {
  const dollarAmount = purchasePrice * (percent / 100);
  if (typeof dollarAmount === 'number') {
    return Math.round(dollarAmount);
  } else {
    return 0;
  }
};

/**
 * Returns the percentage of the purchase price as a whole integer
 *
 * @param {number} dollarAmount Dollar amount
 * @param {number} purchasePrice Purchase price in dollars
 * @returns {number} Percentage as integer
 */
const dollarToPercent = (dollarAmount: number, purchasePrice: number): number => {
  const percentAmount = (dollarAmount / purchasePrice) * 100;
  if (typeof percentAmount === 'number') {
    return (Math.floor(percentAmount * 10) / 10);
  } else {
    return 0;
  }
};

/**
 * Returns an update percentage amount based on the down payment and purchase price
 *
 * @param {number} downPayment Down payment in dollar amount
 * @param {number} purchasePrice Purchase price in dollar amount
 * @returns {number} Percentage of down payment to purchase price
 */
const updatePercent = (downPayment: number, purchasePrice: number): number => {
  if (!downPayment || !purchasePrice) {
    return 0;
  }
  const percent = (downPayment / purchasePrice) * 100;
  return (Math.floor(percent * 10) / 10);
};

/**
 * Handles purchase price change by ensuring the down payment dollar and
 * percentages are updated accordingly.
 *
 * @param {any} changeFn Change function provided by Redux Form
 * @returns Purchase price value
 */
const handlePurchasePriceChange = (changeFn: any) => {
  return (value, previousValue, allValues) => {
    const purchasePrice = value;
    const downPaymentDollar = allValues[FieldNames.downDollar];
    if (!purchasePrice) {
      /* If no purchase price, set down payment to zero */
      changeFn(FieldNames.downDollar, 0);
      changeFn(FieldNames.downPercent, 0);
    } else if (downPaymentDollar > purchasePrice) {
      /* If down payment is greater than purchase price, set dp to purchase price */
      changeFn(FieldNames.downDollar, purchasePrice);
      changeFn(FieldNames.downPercent, 100);
    } else {
      /* Update percent */
      changeFn(FieldNames.downPercent, updatePercent(downPaymentDollar, purchasePrice));
    }
    return value;
  };
};

/**
 * Normalizes the percentage input as well as handles updates to the down payment
 * dollar amount
 *
 * @param {any} blurFn Change function provided by Redux Form
 * @returns Percentage not greater than 100
 */
const handlePercentChange = (blurFn: any) => {
  return (value, previousValue, allValues) => {
    const purchasePrice = allValues[FieldNames.homeValue];
    if (!purchasePrice) {
      return 0;
    }
    /* If the percent hasn't changed, don't recalculate */
    if ((Math.floor(value * 10) / 10) === Math.floor(previousValue * 10) / 10) {
      return value;
    }
    /* Ensure value is equal to or less than 100 */
    value = oneHundredMax(value, previousValue);
    blurFn(FieldNames.downDollar, percentToDollar(value, purchasePrice));
    return value;
  };
};

/**
 * Normalizes the dollar input as well as handles updates to the down payment
 * percentage amount
 *
 * @param {any} changeFn Change function provided by Redux Form
 * @returns Dollar amount less than purchase price
 */
const handleDollarChange = (changeFn: any) => {
  return (value, previousValue, allValues) => {
    const purchasePrice = allValues[FieldNames.homeValue];
    const downPaymentDollar = allValues[FieldNames.downDollar];
    if (!downPaymentDollar || !purchasePrice) {
      return 0;
    }
    /* Ensure value is less than purchase price (min) */
    value = lessThan(FieldNames.homeValue)(value, previousValue, allValues);
    changeFn(FieldNames.downPercent, dollarToPercent(value, purchasePrice));
    return value;
  };
};

const styles = (theme: Theme) => createStyles({
  splitInput: {
    maxWidth: 350,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  inputField: {
    display: "inline-block",
    '&:first-child': {
      width: "75%",
      marginRight: "5%",
    },
    '&:last-child': {
      width: "20%",
    },
  },
});

export const PurchaseDownPayment = withStyles(styles)(({ ...props }: any) => {
  const { blur, classes, change, hideTitle, hideHomeValue } = props;
  const { t } = useTranslation();
  const type = props.validator && props.validator.type;
  const conditionalValidator = VALIDATORS[type] ? VALIDATORS[type] : noopValidate;

  const renderHomeValueInput = () => {
    return (
      <div>
        {!hideTitle && (
          <Typography variant="h6">
            {t(`question.${FieldNames.homeValue}.title`, {
              defaultValue: 'What is the estimated purchase price?',
            })}
          </Typography>
        )}
        <div className={classes.question}>
          <Field
            component    = {renderInputBase}
            format       = {dollarOrEmptyString}
            label        = {localizeLabel(FieldNames.homeValue, t, 'Home Value')}
            maxLength    = {15}
            name         = {FieldNames.homeValue}
            normalize    = {handlePurchasePriceChange(change)}
            parse        = {toNumber}
            type         = "tel"
            validate     = {[required, isGreaterThan(10000)]}
            fieldPopover = {props.fieldPopover}
            popover      = {props.popover}
          />
        </div>
      </div>
    );
  };

  const renderDownPaymentInput = () => {
    return (
      <div className={classes.splitQuestion}>
        {!hideTitle && (
          <Typography variant="h6">
            {t(`question.${FieldNames.downDollar}.title`, {
              defaultValue: 'What is your estimated down payment?',
            })}
          </Typography>
        )}
        <div className={classes.splitInput}>
          <div className={classes.inputField}>
            <Field
              component          = {renderInputBase}
              format             = {dollarOrEmptyString}
              label              = {localizeLabel(FieldNames.downDollar, t, 'Down Payment')}
              maxLength          = {15}
              name               = {FieldNames.downDollar}
              normalize          = {handleDollarChange(change)}
              parse              = {toNumber}
              validate           = {[required, isGreaterThanMinLoan, isDownPaymentTooLow, conditionalValidator]}
              type               = "tel"
              popover            = {props.downPaymentPopover}
              fieldPopover       = {props.downPaymentPopover}
              formControlClasses = {props.downPaymentFormControlClasses}
            />
          </div>
          <div className={classes.inputField} >
            <Field
              component          = {renderInputBase}
              format             = {emptyStringIfZero}
              inputClasses       = {{ input: 'percentField' }}
              name               = {FieldNames.downPercent}
              normalize          = {handlePercentChange(blur)}
              parse              = {toDecimalNumber}
              type               = "tel"
              formControlClasses = {props.downPaymentFormControlClasses}
              customProps  = {{
                endAdornment:
                  <InputAdornment position="end" classes={{ positionEnd: 'percent' }}>%</InputAdornment>,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {hideHomeValue ? null : renderHomeValueInput()}
      {renderDownPaymentInput()}
    </div>
  );
});
