import { Type } from 'app/actions/form/types';
import { HomeEquityProgram, HomeEquityRatesResponse } from 'app/api/home-equity/types';
import { FieldNames } from 'app/models/fields/names';
import { getFormName } from 'app/routes/helpers';
import { RootState } from 'app/store/types';
import { getFormValues } from 'redux-form';

const initialState: HomeEquityRatesResponse = {
  fixedRatePrograms: [],
};

export const homeEquityRatesReducer = (state = initialState, action): HomeEquityRatesResponse => {
  switch (action.type) {
    case Type.AJAX_FETCH_HOME_EQUITY_RATES_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export const homeEquityFixedRatesSelector = (state: RootState): HomeEquityProgram[] => {
  const formName = getFormName(state.router.location.pathname);
  const formValues = getFormValues(formName)(state);

  if (formValues[FieldNames.maxMonthlyPayment]) {
    return state.homeEquityRates.fixedRatePrograms
      .filter(program => program.principalAndInterestPayment <= formValues[FieldNames.maxMonthlyPayment]);
  }
  return state.homeEquityRates.fixedRatePrograms;
};

export const homeEquityLineOfCreditSelector = (state: RootState): HomeEquityRatesResponse['lineOfCredit'] => {
  return state.homeEquityRates.lineOfCredit;
};