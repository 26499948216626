import { Type } from 'app/actions/form/types';
import { FormName } from 'app/models/options/enums';
import { RootState } from 'app/store/types';

type SubmittedDataState = {
  [K in FormName]: any;
}

const initialState: SubmittedDataState = {
  [FormName.AUTOPREQUAL]                : null,
  [FormName.CONSTRUCTION]               : null,
  [FormName.CONTACT]                    : null,
  [FormName.CREDITBOOSTER]              : null,
  [FormName.CREDITCARD]                 : null,
  [FormName.FINANCIALSTATEMENT]         : null,
  [FormName.HOMEEQUITY]                 : null,
  [FormName.HOME_EQUITY_RATES]          : null,
  [FormName.HOMEEQUITYLONGFORM]         : null,
  [FormName.LAND]                       : null,
  [FormName.LONGFORM]                   : null,
  [FormName.SHORT_FORM_PURCHASE]        : null,
  [FormName.SHORT_FORM_REFINANCE]       : null,
  [FormName.OVERDRAFTPROTECTION]        : null,
  [FormName.PERSONALLOAN]               : null,
  [FormName.COMMERCIAL]                 : null,
  [FormName.COMMERCIALBRIDGELOAN]       : null,
  [FormName.COMMERCIALEQUIPMENT]        : null,
  [FormName.COMMERCIALPURCHASE]         : null,
  [FormName.COMMERCIALREFINANCE]        : null,
  [FormName.COMMERCIALRENEW]            : null,
  [FormName.COMMERCIALVEHICLE]          : null,
  [FormName.CLOSING_COSTS]              : null,
  [FormName.AUTOLOAN]                   : null,
  [FormName.HOME_EQUITY_TURBO]          : null,
  [FormName.OTHER_VEHICLE]              : null,
  [FormName.REFINANCE_TURBO]            : null,
  [FormName.REVERSE]                    : null,
  [FormName.INSURANCE_QUOTE]            : null,
  [FormName.SBLINEOFCREDIT]             : null,
  [FormName.SBTERM]                     : null,
};

export const submittedDataReducer = (state = initialState, action): SubmittedDataState => {
  switch (action.type) {
    case Type.SET_SUBMITTED_DATA:
      return {
        ...state,
        [action.formName]: action.payload,
      };

    default:
      return state;
  }
};

export const getSubmittedData = (state: RootState): SubmittedDataState => {
  return state.submittedData;
};

