import { FieldNames } from 'app/models/fields/names';
import {
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  CURRENT_LOAN_TYPE_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  LIVING_SITUATION_OPTIONS,
  REFINANCE_REASON_OPTIONS,
  REFINANCE_TURBO_REO_LIABILITY_USAGE,
  YES_OR_NO_OPTIONS,
} from 'app/models/options/options';
import { Currency } from 'app/components/FormFields/Currency';
import { Select } from 'app/components/FormFields/Select';
import { RadioField } from 'app/components/FormFields/RadioField';
import { SHARED } from 'app/models/questions/shared';
import { Questions } from './Questions';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { ZipCode } from '../../components/FormFields/ZipCode';
import { AdjustHomeValue } from '../../components/Turbo/AdjustHomeValue';
import { TextInput } from '../../components/FormFields/TextInput';
import {
  coBorrowerHasDifferentAddress, hasCoBorrower,
  hasShowNumberOfDependents,
  isCoBorrowerEmploymentOther, isCoBorrowerRetired,
  isCoEmployee,
  isCoLessThanTwoYrsAtJob,
  isCoSelfEmployed,
  isCollectOtherHomeLiabilties,
  isEmployee,
  isEmploymentOther,
  isInvestmentHome,
  isLessThanTwoYrsAtJob,
  isLessThanTwoYrsAtLivingAddress,
  isOwningOtherHomes,
  isPrimaryHome,
  isRetired,
  isSecondaryHome,
  isSelfEmployed,
  isTakingCashOut,
} from '../fields/conditionals';
import { IntegerAllowZero } from '../../components/FormFields/IntegerAllowZero';
import { SinglePastDate } from '../../components/FormFields/SinglePastDate';
import { Name } from '../../components/FormFields/Name';
import { LoanOfficerLanding } from '../../components/LoanOfficerLanding';
import { ReoLiabilities } from '../../components/Turbo/ReoLiabilities';
import { FinicityConnect } from '../../components/Turbo/FinicityConnect';
import { IncomeSources } from '../../components/FormFieldArrays/IncomeSources';
import { RetirementIncomeSources } from '../../components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from '../../components/FormFieldArrays/OtherIncomeSources';
import { SingleDate } from '../../components/FormFields/SingleDate';
import { CollectRequiredSsn } from '../../components/FormFields/Ssn/CollectRequiredSsn';
import { CurrentLoanType } from 'app/models/options/enums';
import { IntegerAllowBlank } from 'app/components/FormFields/IntegerAllowBlank';
import {
  REFINANCE_TURBO_COLLECT_SSN_QUESTION,
  REFINANCE_TURBO_COLLECT_SSN_QUESTION_FINAL,
} from '../sections/constants';
import { CollectConditionallyRequiredSsn } from '../../components/FormFields/Ssn/CollectConditionallyRequiredSsn';
import { TakeCashOut } from 'app/components/Turbo/TakeCashOut';

export const REFINANCE_TURBO_QUESTIONS: Questions = new Questions([
  {
    id        : 1001,
    component : RadioField,
    name      : FieldNames.loanPurpose,
    options   : REFINANCE_REASON_OPTIONS,
    title     : 'What is the purpose of your loan refinance?',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.propertyUsage],
    hideLabel: true,
    title    : 'What is this property used for?',
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyType],
    hideLabel : true,
    title     : 'What type of property are you refinancing?',
  },
  {
    id: 1004,
    ...SHARED[FieldNames.creditScore],
    title: 'What is your estimated credit score?',
  },
  {
    id       : 1005,
    component: Currency,
    label    : 'Home Value',
    name     : FieldNames.homeValue,
    title    : 'What is the estimated home value?',
    validator: {
      type : 'isGreaterThanField',
      param: FieldNames.loanAmount,
    },
  },
  {
    id       : 1006,
    component: Currency,
    label    : 'Loan Amount',
    name     : FieldNames.loanAmount,
    title    : 'What is your current mortgage balance?',
    validator: {
      type : 'loanAmountIsValid',
      param: FieldNames.homeValue,
    },
  },
  {
    id       : 1007,
    component: Address,
    title    : 'What is the address of the home?',
    label    : 'Street Address',
    name     : FieldNames.propertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id        : 1008,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.propertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id       : 1009,
    component: Address,
    label    : 'City',
    name     : FieldNames.propertyCity,
  },
  {
    id       : 1010,
    component: State,
    label    : 'State',
    name     : FieldNames.propertyState,
  },
  {
    id       : 1011,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.propertyZip,
  },
  {
    id        : 1012,
    component : AdjustHomeValue,
    name      : FieldNames.avmHomeValue,
  },
  {
    id        : 1013,
    component : TextInput,
    name      : FieldNames.currentInterestRate,
    label     : 'Interest Rate',
    validator : {
      type  : 'isPercentage',
      param : FieldNames.expectedOwnerOccupancy,
    },
    endAdornment: '%',
  },
  {
    id        : 1014,
    component : Select,
    name      : FieldNames.currentLoanType,
    options   : CURRENT_LOAN_TYPE_OPTIONS,
    label     : 'Loan Type',
  },
  {
    id        : 1015,
    component : Currency,
    name      : FieldNames.currentMonthlyPayment,
    label     : 'Monthly Payment (Principal & Interest only)',
  },
  {
    id: 1016,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1017,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1018,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1019,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1020,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1021,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1022,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id: 1023,
    ...SHARED[FieldNames.escrowUsage],
    name : FieldNames.livingEscrowUsage,
    title: 'Do you have an escrow account?',
  },
  {
    id: 1024,
    ...SHARED[FieldNames.escrowUsage],
    name : FieldNames.escrowUsage,
    title: 'Will you have an escrow account with your new loan?',
  },
  {
    id        : 1025,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.livingYearTaxes,
    showIf    : [isSecondaryHome, isInvestmentHome],
  },
  {
    id        : 1026,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.livingYearInsure,
    showIf    : [isSecondaryHome, isInvestmentHome],
  },
  {
    id         : 1027,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Homeowners Association Fees',
    name       : FieldNames.livingYearAssociate,
    showIf     : [isSecondaryHome, isInvestmentHome],
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1028,
    component    : Select,
    fieldPopover : true,
    name         : FieldNames.livingPropertyType,
    showIf       : [isSecondaryHome, isInvestmentHome],
  },
  {
    id        : 1029,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    showIf    : [isSecondaryHome, isInvestmentHome],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id         : 1030,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    showIf     : [isSecondaryHome, isInvestmentHome],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1031,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
    showIf    : [isSecondaryHome, isInvestmentHome],
  },
  {
    id        : 1032,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
    showIf    : [isSecondaryHome, isInvestmentHome],
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id     : 1033,
    name   : FieldNames.livingPropertyZip,
    showIf : [isSecondaryHome, isInvestmentHome],
  },
  {
    id        : 1034,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.yearTaxes,
  },
  {
    id        : 1035,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.yearInsure,
  },
  {
    id         : 1036,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Homeowners Association Fees',
    name       : FieldNames.yearAssociate,
  },
  {
    id        : 1037,
    component : SinglePastDate,
    label     : 'Occupancy Start Date',
    name      : FieldNames.livingOccupancyStart,
  },
  {
    id        : 1038,
    title     : 'Previous Address',
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPrevPropertyStreet,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPrevPropertyStreet,
    },
  },
  {
    id         : 1039,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPrevPropertyStreet2,
    showIf     : [isLessThanTwoYrsAtLivingAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPrevPropertyStreet2,
    },
  },
  {
    id        : 1040,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPrevPropertyCity,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id        : 1041,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPrevPropertyState,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id     : 1042,
    name   : FieldNames.livingPrevPropertyZip,
    showIf : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id        : 1043,
    component : Select,
    label     : 'Housing Situation',
    name      : FieldNames.livingPrevRentOrOwn,
    options   : LIVING_SITUATION_OPTIONS,
    showIf    : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id       : 1144,
    component: SinglePastDate,
    label    : 'Occupancy Start Date',
    name     : FieldNames.livingPrevOccupancyStart,
    showIf   : [isLessThanTwoYrsAtLivingAddress],
  },
  {
    id: 1047,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id       : 1048,
    component: IntegerAllowZero,
    name     : FieldNames.numOfDependent,
    title    : 'Number of dependents',
    showIf   : [hasShowNumberOfDependents],
  },
  {
    id: 1049,
    ...SHARED[FieldNames.citizenshipType],
  },
  {
    id: 1050,
    ...SHARED[FieldNames.militaryExperience],
  },
  {
    id: 1051,
    component: RadioField,
    name     : FieldNames.coBorrowerYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will you have a co-borrower?',
  },
  {
    id: 1052,
    ...SHARED[FieldNames.firstName],
    label : 'Co-Borrower\'s First Name',
    name  : FieldNames.coBorrowerFirstName,
    showIf: [hasCoBorrower],
  },
  {
    id        : 1053,
    component : Name,
    label     : 'Co-Borrower\'s Middle Name',
    name      : FieldNames.coBorrowerMiddleName,
    isOptional: true,
    showIf    : [hasCoBorrower],
  },
  {
    id: 1054,
    ...SHARED[FieldNames.lastName],
    label : 'Co-Borrower\'s Last Name',
    name  : FieldNames.coBorrowerLastName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1055,
    ...SHARED[FieldNames.dateOfBirth],
    label : 'Co-Borrower\'s Date of Birth',
    name  : FieldNames.coBorrowerDOB,
    showIf: [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1056,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1057,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },
  {
    id       : 1058,
    component: RadioField,
    name     : FieldNames.coBorrowerOnTitle,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will they be on the title?',
    showIf   : [hasCoBorrower],
  },
  {
    id        : 1059,
    component : RadioField,
    name      : FieldNames.coBorrowerSameAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Same address as borrower?',
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1060,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.coBorrowerLivingPropertyStreet,
    title     : `Co-borrower's current address?`,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id         : 1061,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf     : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id        : 1062,
    component : Address,
    label     : 'City',
    name      : FieldNames.coBorrowerLivingPropertyCity,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1063,
    component : State,
    label     : 'State',
    name      : FieldNames.coBorrowerLivingPropertyState,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    id        : 1064,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.coBorrowerLivingPropertyZip,
    showIf    : [coBorrowerHasDifferentAddress],
  },
  {
    ...SHARED[FieldNames.maritalStatus],
    id     : 1065,
    name   : FieldNames.coBorrowerMaritalStatus,
    title  : `Co-borrower's marital status?`,
    showIf : [hasCoBorrower],
  },{
    id: 1066,
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
  },
  {
    id: 1067,
    ...SHARED[FieldNames.coBorrowerMilitaryExperience],
  },
  {
    id        : 1068,
    component : LoanOfficerLanding,
    name      : FieldNames.loanOfficer,
  },
  {
    id        : 1069,
    component : ReoLiabilities,
    name      : FieldNames.realEstateLiabilities,
    options   : REFINANCE_TURBO_REO_LIABILITY_USAGE,
  },
  {
    id        : 1070,
    name      : FieldNames.canVerifyOfIncomeEmployment,
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
  },{
    id        : 1071,
    component : FinicityConnect,
    name      : FieldNames.isFinancialVerificationComplete,
  },
  {
    id       : 1072,
    component: RadioField,
    title    : 'What is your income source?',
    name     : FieldNames.employmentType,
    options  : EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id       : 1073,
    component: TextInput,
    label    : 'Company Name',
    name     : FieldNames.employCompanyName,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1074,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id        : 1075,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employStreet2,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1076,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1077,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1078,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1079,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employStart,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1080,
    component: Currency,
    label    : 'Monthly Base Pay',
    name     : FieldNames.employBase,
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id          : 1081,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id          : 1082,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1083,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id       : 1084,
    component: TextInput,
    label    : 'Previous Employers Name',
    name     : FieldNames.employPrevName,
    title    : 'Previous employment information',
    showIf   : [isLessThanTwoYrsAtJob],
    popover  :
      'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id       : 1085,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employPrevStreet,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1086,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employPrevStreet2,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1087,
    component: Address,
    label    : 'City',
    name     : FieldNames.employPrevCity,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1088,
    component: State,
    label    : 'State',
    name     : FieldNames.employPrevState,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1089,
    component: ZipCode,
    label    : 'Zip',
    name     : FieldNames.employPrevZip,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1090,
    component: SinglePastDate,
    label    : 'Start Date',
    name     : FieldNames.employPrevStart,
    showIf   : [isLessThanTwoYrsAtJob],
  },
  {
    id       : 1091,
    component: SinglePastDate,
    label    : 'End Date',
    name     : FieldNames.employPrevEnd,
    showIf   : [isLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id        : 1092,
    component : IncomeSources,
    name      : FieldNames.additionalIncome,
    showIf    : [isEmployee, isSelfEmployed],
  },
  {
    id         : 1093,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.employTitle,
    showIf     : [isEmployee, isSelfEmployed],
  },
  {
    id: 1094,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id        : 1095,
    component : RetirementIncomeSources,
    title     : 'Tell us about your retirement income',
    name      : FieldNames.retirementIncome,
    showIf    : [isRetired],
  },
  {
    id        : 1096,
    component : OtherIncomeSources,
    title     : 'Tell us about your other income',
    disclaimer: 'You are not obligated to disclose this information.',
    name      : FieldNames.otherIncome,
    showIf    : [isEmploymentOther],
    isOptional: true,
  },
  {
    id       : 1097,
    component: RadioField,
    title    : 'What is your co-borrower\'s income source?',
    name     : FieldNames.coBorrowerEmploymentType,
    options  : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id: 1098,
    component: TextInput,
    label: 'Company Name',
    name: FieldNames.coBorrowerEmployCompanyName,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1099,
    component: Address,
    label: 'Street Address',
    name: FieldNames.coBorrowerEmployStreet,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1100,
    component: Address,
    isOptional: true,
    label: 'Street Address 2',
    name: FieldNames.coBorrowerEmployStreet2,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1101,
    component: Address,
    label: 'City',
    name: FieldNames.coBorrowerEmployCity,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1102,
    component: State,
    label: 'State',
    name: FieldNames.coBorrowerEmployState,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1103,
    component: ZipCode,
    label: 'Zip Code',
    name: FieldNames.coBorrowerEmployZip,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1104,
    component: SinglePastDate,
    label: 'Start Date',
    name: FieldNames.coBorrowerEmployStart,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1105,
    component: Currency,
    label: 'Monthly Base Pay',
    name: FieldNames.coBorrowerEmployBase,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1106,
    component: TextInput,
    label: 'Previous Employers Name',
    name: FieldNames.coBorrowerEmployPrevName,
    title: 'Previous employment information',
    showIf: [isCoLessThanTwoYrsAtJob],
    popover:
      'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id: 1107,
    component: Address,
    label: 'Street Address',
    name: FieldNames.coBorrowerEmployPrevStreet,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1108,
    component: Address,
    isOptional: true,
    label: 'Street Address 2',
    name: FieldNames.coBorrowerEmployPrevStreet2,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1109,
    component: Address,
    label: 'City',
    name: FieldNames.coBorrowerEmployPrevCity,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1110,
    component: State,
    label: 'State',
    name: FieldNames.coBorrowerEmployPrevState,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1111,
    component: ZipCode,
    label: 'Zip',
    name: FieldNames.coBorrowerEmployPrevZip,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1112,
    component: SinglePastDate,
    label: 'Start Date',
    name: FieldNames.coBorrowerEmployPrevStart,
    showIf: [isCoLessThanTwoYrsAtJob],
  },
  {
    id: 1113,
    component: SingleDate,
    label: 'End Date',
    name: FieldNames.coBorrowerEmployPrevEnd,
    showIf: [isCoLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.coBorrowerEmployPrevStart,
    },
  },
  {
    id: 1114,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: 'Monthly Overtime',
    name: FieldNames.coBorrowerEmployOvertime,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id: 1115,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: 'Monthly Commission',
    name: FieldNames.coBorrowerEmployCommission,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id: 1116,
    component: Currency,
    fieldPopover: true,
    isOptional: true,
    label: 'Monthly Bonus',
    name: FieldNames.coBorrowerEmployBonus,
    showIf: [isCoEmployee, isCoSelfEmployed],
    popover:
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id: 1117,
    component: IncomeSources,
    name: FieldNames.coBorrowerAdditionalIncome,
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id        : 1118,
    component : TextInput,
    label     : 'Job Title',
    isOptional: true,
    name      : FieldNames.coBorrowerEmployTitle,
    showIf    : [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: 1119,
    ...SHARED[FieldNames.coBorrowerEmployPrevTitle],
  },
  {
    id       : 1120,
    component: OtherIncomeSources,
    name     : FieldNames.coBorrowerOtherIncome,
    showIf   : [isCoBorrowerEmploymentOther],
  },
  {
    id       : 1121,
    component: RetirementIncomeSources,
    name     : FieldNames.coBorrowerRetirementIncome,
    showIf   : [isCoBorrowerRetired],
  },
  {
    id       : 1122,
    component: RadioField,
    title    : 'What is your co-borrower\'s income source?',
    name     : FieldNames.coBorrowerEmploymentType,
    options  : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id        : 1123,
    component : CollectRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
  {
    id: 1124,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id         : 1125,
    component  : IntegerAllowBlank,
    isOptional : true,
    name       : FieldNames.currentLoanTerm,
    label      : 'Years Remaining',
    showIf     : [
      (values) =>
        values[FieldNames.currentLoanType] === CurrentLoanType.THIRTY ||
        values[FieldNames.currentLoanType] === CurrentLoanType.TWENTY ||
        values[FieldNames.currentLoanType] === CurrentLoanType.FIFTEEN ||
        values[FieldNames.currentLoanType] === CurrentLoanType.TEN,
    ],
  },
  {
    id        : 1126,
    component : TakeCashOut,
    name      : FieldNames.cashOut,
    showIf    : [isTakingCashOut],
  },
  {
    id        : 1127,
    component : Select,
    name      : FieldNames.liabilityCombinedNumberOtherHomes,
    label     : 'How many other homes do you own?',
    options: [
      { title: '0', value: 0 },
      { title: '1', value: 1 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
      { title: '4', value: 4 },
      { title: '5', value: 5 },
    ],
    showIf: [(values) => isOwningOtherHomes(values) || isPrimaryHome(values)],
  },
  {
    id         : 1128,
    component  : Currency,
    name       : FieldNames.liabilityCombinedYearTaxes,
    title      : 'Please provide the values for taxes and insurance for all of your other homes combined',
    label      : 'Combined Annual Taxes',
    showIf     : [(values) => isOwningOtherHomes(values) || isCollectOtherHomeLiabilties(values)],
  },
  {
    id        : 1129,
    component : Currency,
    name      : FieldNames.liabilityCombinedYearInsure,
    label     : 'Combined Annual Insurance',
    showIf     : [(values) => isOwningOtherHomes(values) || isCollectOtherHomeLiabilties(values)],
  },
  {
    id         : 1130,
    component  : Currency,
    name       : FieldNames.liabilityCombinedYearAssociate,
    isOptional : true,
    label      : 'Combined Homeowners Association Fees',
    showIf     : [(values) => isOwningOtherHomes(values) || isCollectOtherHomeLiabilties(values)],
  },
  {
    id        : 1131,
    component : RadioField,
    name      : FieldNames.isOwningOtherHomes,
    title     : 'Do you own other homes in addition to your primary home and the home being refinanced?',
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : REFINANCE_TURBO_COLLECT_SSN_QUESTION,
    component : CollectRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
  {
    id        : REFINANCE_TURBO_COLLECT_SSN_QUESTION_FINAL,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
]);
