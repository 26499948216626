import React from 'react';
import { Grid } from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { SwitchToggle } from 'app/components/FormFields/SwitchToggle';

export const AssistedForm: any = ({ ...props }) => {
  return (
    <Grid container classes={{ container: 'mediumBackground banner' }}>
      <Grid item xs={12} classes={{ item: 'centerToggle' }}>
        <form>
          <SwitchToggle
            name             = {FieldNames.assistedYN}
            label            = "Were you assisted by anyone today?"
            switchLabelProps = {{
              classes: { root: 'isolatedToggle', label: 'largeToggle' },
            }}
          />
        </form>
      </Grid>
    </Grid>
  );
};
