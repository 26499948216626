import { TPVehicleBasic, TPVehicleDetailed } from 'app/api/valuation/auto';

export enum Type {
  AJAX_GET_AUTO_VALUATION_TYPEAHEAD          = 'AJAX_GET_AUTO_VALUATION_TYPEAHEAD',
  AJAX_GET_AUTO_VALUATION_TYPEAHEAD_SUCCESS  = 'AJAX_GET_AUTO_VALUATION_TYPEAHEAD_SUCCESS',
  AJAX_GET_AUTO_VALUATION_TYPEAHEAD_FAIL     = 'AJAX_GET_AUTO_VALUATION_TYPEAHEAD_FAIL',
  AJAX_GET_AUTO_VALUATION_VARIANTS           = 'AJAX_GET_AUTO_VALUATION_VARIANTS',
  AJAX_GET_AUTO_VALUATION_VARIANTS_SUCCESS   = 'AJAX_GET_AUTO_VALUATION_VARIANTS_SUCCESS',
  AJAX_GET_AUTO_VALUATION_VARIANTS_FAIL      = 'AJAX_GET_AUTO_VALUATION_VARIANTS_FAIL',
  AJAX_GET_AUTO_VALUATION_REPORT             = 'AJAX_GET_AUTO_VALUATION_REPORT',
  AJAX_GET_AUTO_VALUATION_REPORT_SUCCESS     = 'AJAX_GET_AUTO_VALUATION_REPORT_SUCCESS',
  AJAX_GET_AUTO_VALUATION_REPORT_FAIL        = 'AJAX_GET_AUTO_VALUATION_REPORT_FAIL',
}

export interface AutoValuationState {
  vehicles       : TPVehicleBasic[];
  variants       : TPVehicleDetailed[];
}
