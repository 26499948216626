import React from 'react';
import { Field } from 'redux-form';
import { dollarOrZeroDollarString } from 'app/util/formatters';
import { toNumber } from 'app/util/parsers';
import { VALIDATORS, requiredFocus, noopValidate } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { toNumberOrZero } from 'app/util/normalizers';

export const CurrencyAllowZero = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : requiredFocus;
  /* The validator property comes from the question object */
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;

  return (
    <Field
      component    = {renderInputBase}
      fieldPopover = {props.fieldPopover}
      format       = {dollarOrZeroDollarString}
      label        = {props.label}
      maxLength    = {15}
      name         = {name}
      parse        = {toNumber}
      popover      = {props.popover}
      normalize    = {toNumberOrZero}
      validate     = {[isRequired, conditionalValidator]}
    />
  );
};
