import { FormName } from 'app/models/options/enums';
import { routes } from 'app/routes/route-list';

/**
 * Takes in a url hash and returns the section id as an integer
 *
 * @param {string} hash Hash from the router location
 * @returns {number} Section ID
 */
export const sectionFromHash = (hash: string): number => {
  return parseFloat(hash.replace('#', ''));
};

const routeToFormNameMap = {
  [routes.application]             : FormName.LONGFORM,
  [routes.applicationConstruction] : FormName.CONSTRUCTION,
  [routes.applicationHomeEquity]   : FormName.HOMEEQUITYLONGFORM,
  [routes.applicationLand]         : FormName.LAND,
  [routes.applicationPurchase]     : FormName.LONGFORM,
  [routes.applicationRefinance]    : FormName.LONGFORM,
  [routes.autoLoan]                : FormName.AUTOLOAN,
  [routes.autoPrequal]             : FormName.AUTOPREQUAL,
  [routes.autoPrequalEdit]         : FormName.AUTOPREQUAL,
  [routes.autoPrequalRenew]        : FormName.AUTOPREQUAL,
  [routes.closingCostsPurchase]    : FormName.CLOSING_COSTS,
  [routes.closingCostsRefinance]   : FormName.CLOSING_COSTS,
  [routes.commercial]              : FormName.COMMERCIAL,
  [routes.commercialBridgeLoan]    : FormName.COMMERCIALBRIDGELOAN,
  [routes.commercialEquipment]     : FormName.COMMERCIALEQUIPMENT,
  [routes.commercialPurchase]      : FormName.COMMERCIALPURCHASE,
  [routes.commercialRefinance]     : FormName.COMMERCIALREFINANCE,
  [routes.commercialVehicle]       : FormName.COMMERCIALVEHICLE,
  [routes.contact]                 : FormName.CONTACT,
  [routes.creditBooster]           : FormName.CREDITBOOSTER,
  [routes.creditCard]              : FormName.CREDITCARD,
  [routes.financialStatement]      : FormName.FINANCIALSTATEMENT,
  [routes.homeEquity]              : FormName.HOMEEQUITY,
  [routes.homeEquityRates]         : FormName.HOME_EQUITY_RATES,
  [routes.homeEquityTurbo]         : FormName.HOME_EQUITY_TURBO,
  [routes.insuranceQuote]          : FormName.INSURANCE_QUOTE,
  [routes.otherVehicle]            : FormName.OTHER_VEHICLE,
  [routes.overdraftProtection]     : FormName.OVERDRAFTPROTECTION,
  [routes.personalLoan]            : FormName.PERSONALLOAN,
  [routes.purchase]                : FormName.SHORT_FORM_PURCHASE,
  [routes.refinance]               : FormName.SHORT_FORM_REFINANCE,
  [routes.refinanceTurbo]          : FormName.REFINANCE_TURBO,
  [routes.reverse]                 : FormName.REVERSE,
  [routes.sbLineOfCredit]          : FormName.SBLINEOFCREDIT,
  [routes.sbTerm]                  : FormName.SBTERM,
};

/**
 * Gets the form name from the path provided
 *
 * @param {string} pathname Name of the path
 * @returns {FormName} Name of the form in ReduxForm({})
 */
export const getFormName = (pathname: string): FormName => {
  return routeToFormNameMap[pathname];
};

/**
 * Used to determine if the pathname was previously a route that
 * contained a form.
 *
 * @param {string} pathname Name of the path
 * @returns {boolean} true if pathname can be found in routes containing forms, false otherwise
 */
export const containsFormName = (pathname: string): boolean => {
  const keys = Object.keys(routeToFormNameMap);
  return keys.some(key => pathname.includes(key));
};

// Only works if the FormName to route is a 1 to 1
export const getRouteByFormName = (form: FormName): string => {
  return Object.keys(routeToFormNameMap).find(key => routeToFormNameMap[key] === form);
};
