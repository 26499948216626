import React from 'react';
import { Field } from 'redux-form';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import { required, noopValidate, isValidDate, isDateInFuture } from 'app/util/validators';
import { renderDateBase } from 'app/components/FormFields/_renderDateBase';

export const SingleFutureDate = ({ ...props }) => {
  const { name, label, isOptional, inputClasses, labelClasses } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired = isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete    = {mapAutoCompleteName(name)}
      component       = {renderDateBase}
      inputClasses    = {inputClasses}
      label           = {label}
      labelClasses    = {labelClasses}
      name            = {name}
      validate        = {[isRequired, isValidDate, isDateInFuture]}
      datePickerProps = {{
        disablePast: true,
      }}
    />
  );
};
