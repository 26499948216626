import React from 'react';
import { connect } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { ImageSection } from 'app/components/ImageSection';
import { ContactLink } from 'app/components/ContactLink';
import { YesOrNo } from 'app/models/options/enums';
import { FieldNames } from 'app/models/fields/names';
import { contactPhoneSelector } from 'app/reducers/app-config';
import { withTranslation, Trans } from 'react-i18next';

export const NoPurchasePriceComponent = ({
  contactPhone,
  host,
  stopGateImage,
  t,
  ...props
}: any) => {
  return (
    <ImageSection image={stopGateImage}>
      <Typography variant='h6' classes={{ h6: 'contentTitle' }}>
        {t('stopGate.noPurchasePrice.title', {
          defaultValue : 'Let us help you get started',
        })}
      </Typography>
      <Typography variant='body2' paragraph={true}>
        {t('stopGate.noPurchasePrice.paragraph1', {
          defaultValue:
            'Since you don\'t have a home picked out and you\'re not sure how much you want to spend, it would be best to talk to one of our loan officers.',
        })}
      </Typography>
      <Typography variant='body2' paragraph={true}>
        <Trans i18nKey='stopGate.noPurchasePrice.paragraph2'>
          We can help you get moving towards a pre-qualification for a home. You
          can&nbsp;
          <Button
            classes = {{ root: 'link' }}
            color   = 'inherit'
            disableRipple
            href  = {`tel:${contactPhone}`}
          >
            call
          </Button>
          &nbsp;or&nbsp;
          <ContactLink
            buttonProps={{
              classes      : { root: 'link' },
              disableRipple: true,
            }}
          >
            contact us online
          </ContactLink>
          .
        </Trans>
      </Typography>
      <Grid container justifyContent='center'>
        <Grid item>
          <Button
            color   = 'primary'
            onClick = {() => props.change(FieldNames.prequalAmountYN, YesOrNo.YES)}
            size    = 'large'
            classes = {{ root: 'longButton' }}
            variant = 'contained'
            style   = {{ fontSize: 14 }}
          >
            {t('stopGate.noPurchasePrice.button.back', {
              defaultValue: 'Go back, I\'ll estimate my purchase price',
            })}
          </Button>
        </Grid>
      </Grid>
    </ImageSection>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  contactPhone : contactPhoneSelector(state),
  host         : state.config.host,
  stopGateImage: state.config.stopGateImage,
  ...ownProps,
});

export const NoPurchasePrice = connect(
  mapStateToProps,
  null,
)(withTranslation()(NoPurchasePriceComponent));
