import { getFormValues } from 'redux-form';
import { Type } from './types';
import { logger } from 'app/util/logger';
import { GeneralReferralCode } from '@lenderful/domain';
import { ajaxFetchConsumerLoanLimitsFail, ajaxFetchConsumerLoanLimitsSuccess, goToSection, referToLoanOfficer, setMaxLoanAmount, setMinLoanAmount } from 'app/actions/form/actions';
import { mapUniversalLoanRequest, postUniversalLoanApplication } from 'app/api/loan-application/universal/loan';
import { fetchConsumerLoanLimits, fetchConsumerLoanRates } from 'app/api/rates';
import { ConsumerLoanLimitsResponse, ConsumerLoanRequestType, LoanProgramRatesResponse } from 'app/api/rates/types';
import { CREDIT_BOOSTER_APP_LOAN_SELECTION, CREDIT_BOOSTER_APP_LOAN_AMOUNT, CREDIT_BOOSTER_APP_CONFIRMATION } from 'app/models/sections/constants';
import { getFormName } from 'app/routes/helpers';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { FieldNames } from 'app/models/fields/names';
import { Dispatch } from 'redux';
import { mapComputedRateValues } from 'app/reducers/rates';
import { makeActionCreator } from 'app/util/actions';
import { featureTogglesSelector } from 'app/reducers/app-config';

export const ajaxFetchCreditBoosterRatesFail     = makeActionCreator(Type.AJAX_FETCH_CREDIT_BOOSTER_RATES_FAIL, 'payload');
export const ajaxFetchCreditBoosterRatesSuccess  = makeActionCreator(Type.AJAX_FETCH_CREDIT_BOOSTER_RATES_SUCCESS, 'payload');

/**
 * This method handles the workflow for Credit Booster.  It will try to look up programs
 * if the client is configured to.  Otherwise, it will look up loan amount limits for the
 * loan amount validation on the following page.
 * 
 * @param payload 
 * @returns 
 */
export function handleCreditBoosterFetchRatesOrLoanLimits(payload) {
  return (dispatch, getState) => {

    const state = getState();
    const { hasCreditBoosterPrograms } = featureTogglesSelector(state);

    if (hasCreditBoosterPrograms) {
      return Promise.resolve(dispatch(ajaxFetchCreditBoosterRates(payload)))
        .then((programs: LoanProgramRatesResponse) => {
          if (!programs || programs.length === 0) {
            // handle 200 response with no rates
            logger.warn('handleCreditBoosterFetchRatesOrLoanLimits: No qualified rates returned', programs);
            return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
          }
          return dispatch(goToSection(CREDIT_BOOSTER_APP_LOAN_SELECTION));
        });
    }
    else {
      return Promise.resolve(dispatch(ajaxFetchCreditBoosterLoanAmountLimits(payload)))
        .then((response: ConsumerLoanLimitsResponse) => {
          logger.info('handleCreditBoosterFetchRatesOrLoanLimits', response);
          dispatch(setMaxLoanAmount(response.maxLoanAmount));
          dispatch(setMinLoanAmount(response.minLoanAmount));
          return dispatch(goToSection(CREDIT_BOOSTER_APP_LOAN_AMOUNT));
        });
    }
  };
}

/**
 * Handles the Credit Booster Form Submit
 */
export function handleCreditBoosterFormSubmit() {
  return async (dispatch) => {
    await dispatch(submitCreditBoosterApplication());
    dispatch(goToSection(CREDIT_BOOSTER_APP_CONFIRMATION));
  };
}

/**
 * Builds the Credit Booster request and submits the the Universal Loan Application end point
 */
export function submitCreditBoosterApplication() {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);

    const universalLoanRequest = mapUniversalLoanRequest(formData, loanOfficer, formName);
    return postUniversalLoanApplication(universalLoanRequest).then(
      (response) => {
        logger.debug('Post Credit Booster Response', response);
        return response;
      },
      (error) => {
        logger.error('Error: Post Credit Booster', error);
      },
    );
  };
}

export function ajaxFetchCreditBoosterLoanAmountLimits(formData) {
  return async (dispatch: Dispatch) => {
    // credit booster doesn't currently care about fico
    const payload = {
      fico : '850',
      type : ConsumerLoanRequestType.CREDIT_BOOSTER,
    };

    try {
      const response = await fetchConsumerLoanLimits(payload);
      logger.debug('fetchConsumerLoanLimits response: ', response);
      dispatch(ajaxFetchConsumerLoanLimitsSuccess(response));
      return response;
    } catch (error) {
      dispatch(ajaxFetchConsumerLoanLimitsFail(error));
      logger.error('Error when fetching consumer loan limits', error);
    }
  };
}

export function ajaxFetchCreditBoosterRates(formData) {
  return (dispatch: Dispatch) => {
    // credit booster doesn't currently care about fico or loan amount
    const request = {
      fico           : '850',
      loanAmount     : 3000,
      type           : ConsumerLoanRequestType.CREDIT_BOOSTER,
      zipCode        : formData[FieldNames.livingPropertyZip],
    };

    dispatch({ type: Type.AJAX_FETCH_CREDIT_BOOSTER_RATES, request });

    return fetchConsumerLoanRates(request).then(
      (response) => {
        logger.debug('fetchConsumerLoanRates response: ', response);
        response.forEach((program: any) => {
          mapComputedRateValues(program, false, formData[FieldNames.loanPurpose]);
        });
        dispatch(ajaxFetchCreditBoosterRatesSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxFetchCreditBoosterRatesFail(error));
        throw error;
      },
    );
  };
}