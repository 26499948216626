import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { TextInput } from 'app/components/FormFields/TextInput';
import { Name } from 'app/components/FormFields/Name';
import { SingleFutureDate } from '../../components/FormFields/SingleFutureDate';

/**
 * Takes in a field name and returns the corresponding renewal question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const commercialRenewQuestionByName = (name: string): Question => {
  return COMMERCIAL_RENEW_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const commercialRenewQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(commercialRenewQuestionByName(name));
  }
  return questions;
};

export const COMMERCIAL_RENEW_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : TextInput,
    label     : 'Loan Number',
    name      : FieldNames.existingLoanNumber,
  },
  {
    id        : 1002,
    component : SingleFutureDate,
    label     : 'Approximate Renewal Date',
    name      : FieldNames.loanRenewalDate,
  },
  {
    id         : 1003,
    component  : Name,
    label      : 'Business Name',
    name       : FieldNames.commercialBusinessName,
  },
  {
    id        : 1004,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.commercialStreet,
  },
  {
    id         : 1005,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.commercialStreet2,
  },
  {
    id        : 1006,
    component : Address,
    label     : 'City',
    name      : FieldNames.commercialCity,
  },
  {
    id        : 1007,
    component : State,
    label     : 'State',
    name      : FieldNames.commercialState,
  },
  {
    id        : 1008,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.commercialZip,
  },

];
