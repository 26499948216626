import React from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';

export const renderTextFieldBase = ({ ...props }: any) => {
  const {
    input,
    label,
    inputGridProps,
    rows,
    hideErrorMessage,
    multiline,
  } = props;
  const { touched, error } = props.meta;
  const name = props.name || input.name;
  const hasError = touched && error && !hideErrorMessage;

  return (
    <Grid container {...inputGridProps}>
      <Grid item classes={{ item: 'wrapper' }}>
        <TextField
          id         = {name}
          label      = {label}
          multiline  = {!!multiline}
          fullWidth  = {true}
          rows       = {rows}
          variant    = 'outlined'
          error      = {hasError}
          helperText = {hasError ? error : ''}
          {...input}
        />
      </Grid>
    </Grid>
  );
};
