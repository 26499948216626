import React from 'react';
import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { creditCardQuestionsByName, creditCardQuestionById } from '../questions/credit-card';
import {
  CREDIT_CARD_APP_COBORROWER_PERSONAL_INFORMATION,
  CREDIT_CARD_APP_CURRENT_HOUSING_SITUATION,
  CREDIT_CARD_APP_HOUSING_OWNER_INFORMATION,
  CREDIT_CARD_APP_HOUSING_FREE_INFORMATION,
  CREDIT_CARD_APP_HOUSING_RENTER_INFORMATION,
  CREDIT_CARD_APP_COBORROWER_HOUSING_INFORMATION,
  CREDIT_CARD_APP_BORROWER_INCOME_SOURCE,
  CREDIT_CARD_APP_BORROWER_INCOME_EMPLOYED,
  CREDIT_CARD_APP_BORROWER_INCOME_RETIRED,
  CREDIT_CARD_APP_BORROWER_INCOME_OTHER,
  CREDIT_CARD_APP_COBORROWER_INCOME_SOURCE,
  CREDIT_CARD_APP_COBORROWER_INCOME_EMPLOYED,
  CREDIT_CARD_APP_COBORROWER_INCOME_RETIRED,
  CREDIT_CARD_APP_COBORROWER_INCOME_OTHER,
  CREDIT_CARD_APP_FINAL,
  CREDIT_CARD_FINAL_PAGE_QUESTION,
  CREDIT_CARD_APP_SSN,
  CREDIT_CARD_APP_CC_TYPE_AND_PERSONAL_INFO,
  CREDIT_CARD_APP_RELATIVE_PERSONAL_INFORMATION,
  CREDIT_CARD_APP_RELATIVE_HOUSING_INFORMATION,
  CREDIT_CARD_APP_DOB,
  CREDIT_CARD_APP_LIMIT,
} from './constants';
import {
  hasCoBorrower,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoEmployee,
  isCoSelfEmployed,
  isEmployee,
  isEmploymentOther,
  isHomeowner,
  isRentFree,
  isRenting,
  isRetired,
  isSelfEmployed,
  coBorrowerHasDifferentAddress,
  relativeHasDifferentAddress,
  LoanOfficerCondition,
  isExcludingSsnQuestions,
  isCoBorrowerNotEmployed,
  isExcludingQuestion,
} from '../fields/conditionals';
import {
  ajaxValidateCoBorrowerResidence,
  ajaxValidateBorrowerResidence,
  ajaxValidateRelativeResidence,
} from 'app/actions/form/address-validation/actions';
import { AcknowledgeCreditCardDisclosure } from '../../components/FormAgreements/AcknowledgeCreditCardDisclosure';
import { submitCreditCardApplication, creditCardRecordAuditEventAndRedirect } from '../../actions/form/actions';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';

export const getCreditCardSectionById = (id: number): Section => {
  return CREDIT_CARD_SECTIONS.find((section) => section.id === id) as Section;
};

const CREDIT_CARD_SECTIONS: Section[] = [

  {
    id       : CREDIT_CARD_APP_DOB,
    title    : 'Please provide your Date of Birth',
    questions: creditCardQuestionsByName([
      FieldNames.dateOfBirth,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerUnderageDynamic],
    },
  },
  {
    id       : CREDIT_CARD_APP_CC_TYPE_AND_PERSONAL_INFO,
    title    : 'We offer two great Visa credit card options. Please select one of the options below to proceed.',
    questions: creditCardQuestionsByName([
      FieldNames.creditCardType,
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.mothersMaidenName,
      FieldNames.email,
      FieldNames.phone,
    ]),
    submitAction: creditCardRecordAuditEventAndRedirect,
    agreement: <AcknowledgeCreditCardDisclosure />,
  },
  {
    id        : CREDIT_CARD_APP_COBORROWER_PERSONAL_INFORMATION,
    questions : creditCardQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerDOB,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerUnderageDynamic],
    },
  },
  {
    id        : CREDIT_CARD_APP_RELATIVE_PERSONAL_INFORMATION,
    questions : creditCardQuestionsByName([
      FieldNames.relativeFirstName,
      FieldNames.relativeMiddleName,
      FieldNames.relativeLastName,
      FieldNames.relativeSuffixName,
      FieldNames.relativeEmail,
      FieldNames.relativePhone,
      FieldNames.relativeDOB,
    ]),
  },
  {
    id        : CREDIT_CARD_APP_CURRENT_HOUSING_SITUATION,
    title     : 'What is your current housing situation?',
    questions : creditCardQuestionsByName([FieldNames.livingRentOrOwn]),
  },
  {
    id        : CREDIT_CARD_APP_HOUSING_OWNER_INFORMATION,
    showIf    : [isHomeowner],
    title     : 'Provide your homeowner information',
    questions : creditCardQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : CREDIT_CARD_APP_HOUSING_RENTER_INFORMATION,
    showIf     : [isRenting],
    title      : 'Provide your renter information',
    questions  : creditCardQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : CREDIT_CARD_APP_HOUSING_FREE_INFORMATION,
    showIf     : [isRentFree],
    title      : 'Provide your housing information',
    questions  : creditCardQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id        : CREDIT_CARD_APP_COBORROWER_HOUSING_INFORMATION,
    showIf    : [hasCoBorrower],
    title     : 'Does the co-applicant have the same address as the applicant?',
    questions : creditCardQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
    ]),
    submitAction : (formValues) =>
      coBorrowerHasDifferentAddress(formValues) ? ajaxValidateCoBorrowerResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id        : CREDIT_CARD_APP_RELATIVE_HOUSING_INFORMATION,
    title     : 'Does the nearest relative have the same address as the applicant?',
    questions : creditCardQuestionsByName([
      FieldNames.relativeSameAddress,
      FieldNames.relativeLivingPropertyStreet,
      FieldNames.relativeLivingPropertyStreet2,
      FieldNames.relativeLivingPropertyCity,
      FieldNames.relativeLivingPropertyState,
      FieldNames.relativeLivingPropertyZip,
    ]),
    showIf: [(values) => !hasCoBorrower(values)],
    submitAction : (formValues) =>
      relativeHasDifferentAddress(formValues) ? ajaxValidateRelativeResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id        : CREDIT_CARD_APP_BORROWER_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : creditCardQuestionsByName([FieldNames.employmentType]),
  },
  {
    id         : CREDIT_CARD_APP_BORROWER_INCOME_EMPLOYED,
    showIf     : [isEmployee, isSelfEmployed],
    title      : 'Tell us about your income and employment',
    questions  : creditCardQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    submitIf     : [(values) => 
      !hasCoBorrower(values) && 
      isExcludingSsnQuestions(values) && 
      isExcludingQuestion(FieldNames.creditCardLimit)(values),
    ],
    submitAction : submitCreditCardApplication,
  },
  {
    id        : CREDIT_CARD_APP_BORROWER_INCOME_RETIRED,
    showIf    : [isRetired],
    questions : creditCardQuestionsByName([
      FieldNames.retirementIncome,
    ]),
    submitIf     : [(values) => 
      !hasCoBorrower(values) && 
      isExcludingSsnQuestions(values) && 
      isExcludingQuestion(FieldNames.creditCardLimit)(values),
    ],
    submitAction : submitCreditCardApplication,
  },
  {
    id        : CREDIT_CARD_APP_BORROWER_INCOME_OTHER,
    showIf    : [isEmploymentOther],
    questions : creditCardQuestionsByName([
      FieldNames.otherIncome,
    ]),
    submitIf     : [(values) => 
      !hasCoBorrower(values) && 
      isExcludingSsnQuestions(values) && 
      isExcludingQuestion(FieldNames.creditCardLimit)(values),
    ],
    submitAction : submitCreditCardApplication,
  },
  { 
    id           : CREDIT_CARD_APP_LIMIT,
    title        : 'What would like to request for your credit card limit?',
    questions    : creditCardQuestionsByName([FieldNames.creditCardLimit]),
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : submitCreditCardApplication,
  },
  {
    id        : CREDIT_CARD_APP_COBORROWER_INCOME_SOURCE,
    showIf    : [hasCoBorrower],
    title     : `What is your co-applicant's income source?`,
    questions : creditCardQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
    submitIf      : [(values) => isExcludingSsnQuestions(values) && isCoBorrowerNotEmployed(values)],
    submitAction  : submitCreditCardApplication,
  },
  {
    id        : CREDIT_CARD_APP_COBORROWER_INCOME_EMPLOYED,
    showIf    : [isCoEmployee, isCoSelfEmployed],
    title     : `Tell us about your co-applicant's income and employment`,
    questions : creditCardQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
    ]),
    submitIf      : [isExcludingSsnQuestions],
    submitAction  : submitCreditCardApplication,
  },
  {
    id        : CREDIT_CARD_APP_COBORROWER_INCOME_RETIRED,
    showIf    : [isCoBorrowerRetired],
    questions : creditCardQuestionsByName([
      FieldNames.coBorrowerRetirementIncome,
    ]),
    submitIf      : [isExcludingSsnQuestions],
    submitAction  : submitCreditCardApplication,
  },
  {
    id        : CREDIT_CARD_APP_COBORROWER_INCOME_OTHER,
    showIf    : [isCoBorrowerEmploymentOther],
    questions : creditCardQuestionsByName([
      FieldNames.coBorrowerOtherIncome,
    ]),
    submitIf      : [isExcludingSsnQuestions],
    submitAction  : submitCreditCardApplication,
  },
  {
    id            : CREDIT_CARD_APP_SSN,
    questions     : creditCardQuestionsByName([FieldNames.ssnQuestionRequirement]),
    showIf        : [(values) => !isExcludingSsnQuestions(values)],
    submitAction  : submitCreditCardApplication,
    agreement     : <AuthorizeSsnFullPull />,
  },
  {
    id                : CREDIT_CARD_APP_FINAL,
    questions         : [creditCardQuestionById(CREDIT_CARD_FINAL_PAGE_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
];
