import React from 'react';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import {
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  DESIRED_LOAN_TERM_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  LIVING_SITUATION_OPTIONS,
  YES_OR_NO_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  coBorrowerHasDifferentAddress,
  hasCoBorrower,
  isCoLessThanTwoYrsAtJob,
  isFromDealer,
  isLessThanTwoYrsAtJob,
} from '../fields/conditionals';
import { Currency } from '../../components/FormFields/Currency';
import { INCOME_DISCLAIMER, SHARED } from 'app/models/questions/shared';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { SinglePastDate } from '../../components/FormFields/SinglePastDate';
import { TextInput } from '../../components/FormFields/TextInput';
import { SingleDate } from '../../components/FormFields/SingleDate';
import { RetirementIncomeSources } from '../../components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from '../../components/FormFieldArrays/OtherIncomeSources';
import { SwitchToggle } from '../../components/FormFields/SwitchToggle';
import { OV_LOAN_FINAL_PAGE_QUESTION } from '../sections/constants';
import { OtherVehicleConfirmation } from 'app/components/OvLoan/OtherVehicleConfirmation';

/**
 * Takes in a field name and returns the corresponding other vehicle loan
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const ovLoanQuestionByName = (name: string): Question => {
  return OV_LOAN_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const ovLoanQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(ovLoanQuestionByName(name));
  }
  return questions;
};

export const ovLoanQuestionById = (id: number): Question => {
  return OV_LOAN_QUESTIONS.find((question) => question.id === id) as Question;
};

export const OV_LOAN_QUESTIONS: Question[] = [
  {
    id           : 1000,
    component    : TextInput,
    name         : FieldNames.vehicle,
    label        : 'Year, Make & Model',
  },
  {
    id           : 1001,
    component    : RadioField,
    name         : FieldNames.vehicleType,
    label        : 'Vehicle Type',
    options      : VEHICLE_TYPE_OPTIONS,
    title        : 'Tell us about the vehicle you plan to purchase.',
  },
  {
    id           : 1002,
    component    : Currency,
    label        : 'Purchase Price',
    name         : FieldNames.purchasePrice,
    title        : 'What is the purchase price?',
    popover      : "Enter the total price you expect to pay for your vehicle.",
  },
  {
    id           : 1003,
    component    : Currency,
    label        : 'Trade In Value',
    name         : FieldNames.tradeInValue,
    showIf       : [isFromDealer],
    title        : 'If you have a trade-in, what is the expected value?',
    isOptional   : true,
    popover      : "If you are working with a dealer and expect to trade-in your current vehicle, then enter the amount you expect to receive for it.",
  },
  {
    id           : 1004,
    component    : Currency,
    label        : 'Down Payment',
    name         : FieldNames.downDollar,
    title        : 'What is your down payment?',
    isOptional   : true,
    popover      : "Enter the amount of money you expect to put down for the vehicle purchase, separate from the trade-in value.",
    validator    : {
      type: 'isAutoLoanValid',
      param: FieldNames.downDollar,
    },
  },
  {
    id           : 1005,
    component    : Select,
    name         : FieldNames.loanTermDesired,
    options      : DESIRED_LOAN_TERM_OPTIONS,
    title        : 'What is your desired loan term/length (in months)?',
  },
  {
    id           : 1007,
    component    : ZipCode,
    name         : FieldNames.propertyZip,
    title        : 'In which zip code is your home located?',
  },
  {
    id: 1008,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1009,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1010,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1011,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1012,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1013,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1014,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id           : 1015,
    component    : RadioField,
    name         : FieldNames.coBorrowerYN,
    options      : YES_OR_NO_OPTIONS,
    title        : 'Will you have a co-borrower?',
  },
  {
    ...SHARED[FieldNames.firstName],
    id           : 1016,
    name         : FieldNames.coBorrowerFirstName,
    title        : 'Tell us about the co-borrower',
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.lastName],
    id           : 1017,
    name         : FieldNames.coBorrowerLastName,
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.middleName],
    id           : 1018,
    name         : FieldNames.coBorrowerMiddleName,
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.email],
    id           : 1019,
    name         : FieldNames.coBorrowerEmail,
    showIf       : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.dateOfBirth],
    id           : 1020,
    name         : FieldNames.coBorrowerDOB,
    showIf       : [hasCoBorrower],
  },
  {
    id            : 1021,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id           : 1022,
    component    : RadioField,
    name         : FieldNames.livingRentOrOwn,
    options      : LIVING_SITUATION_OPTIONS,
  },
  {
    id           : 1023,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id           : 1024,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id           : 1025,
    component    : Address,
    label        : 'City',
    name         : FieldNames.livingPropertyCity,
  },
  {
    id           : 1026,
    component    : State,
    label        : 'State',
    name         : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id           : 1027,
    name         : FieldNames.livingPropertyZip,
    hideTitle : true,
  },
  {
    id           : 1028,
    component    : SinglePastDate,
    label        : 'Occupancy Start Date',
    name         : FieldNames.livingOccupancyStart,
  },
  {
    id           : 1029,
    component    : RadioField,
    name         : FieldNames.coBorrowerSameAddress,
    options      : YES_OR_NO_OPTIONS,
  },
  {
    id           : 1030,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.coBorrowerLivingPropertyStreet,
    title        : `Co-borrower's current address?`,
    showIf       : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id           : 1031,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf       : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id           : 1032,
    component    : Address,
    label        : 'City',
    name         : FieldNames.coBorrowerLivingPropertyCity,
    showIf       : [coBorrowerHasDifferentAddress],
  },
  {
    id           : 1033,
    component    : State,
    label        : 'State',
    name         : FieldNames.coBorrowerLivingPropertyState,
    showIf       : [coBorrowerHasDifferentAddress],
  },
  {
    id           : 1034,
    component    : ZipCode,
    label        : 'Zip Code',
    name         : FieldNames.coBorrowerLivingPropertyZip,
    showIf       : [coBorrowerHasDifferentAddress],
  },
  {
    id           : 1035,
    component    : RadioField,
    name         : FieldNames.employmentType,
    options      : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer   : INCOME_DISCLAIMER,
  },
  {
    id           : 1036,
    component    : TextInput,
    label        : 'Company Name',
    name         : FieldNames.employCompanyName,
  },
  {
    id           : 1037,
    component    : TextInput,
    label        : 'Job Title',
    isOptional   : true,
    name         : FieldNames.employTitle,
  },
  {
    id           : 1038,
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.employStart,
  },
  {
    id           : 1039,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.employBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    id           : 1040,
    showIf       : [isLessThanTwoYrsAtJob],
    component    : TextInput,
    label        : 'Previous Employers Name',
    name         : FieldNames.employPrevName,
    title        : 'Previous employment information',
    popover      : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id           : 1041,
    showIf       : [isLessThanTwoYrsAtJob],
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.employPrevStart,
  },
  {
    id           : 1042,
    showIf       : [isLessThanTwoYrsAtJob],
    component    : SingleDate,
    label        : 'End Date',
    name         : FieldNames.employPrevEnd,
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id           : 1043,
    component    : RetirementIncomeSources,
    title        : 'Tell us about your income',
    name         : FieldNames.retirementIncome,
  },
  {
    id           : 1044,
    component    : OtherIncomeSources,
    title        : 'Tell us about your income',
    name         : FieldNames.otherIncome,
  },
  {
    id           : 1045,
    component    : RadioField,
    name         : FieldNames.incomeOnDeclineYN,
    options      : YES_OR_NO_OPTIONS,
    title        : 'Is your income expected to decline?',
  },
  {
    id           : 1046,
    component    : RadioField,
    name         : FieldNames.coBorrowerEmploymentType,
    options      : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id           : 1047,
    component    : TextInput,
    label        : 'Company Name',
    name         : FieldNames.coBorrowerEmployCompanyName,
  },
  {
    id           : 1048,
    component    : TextInput,
    label        : 'Job Title',
    isOptional   : true,
    name         : FieldNames.coBorrowerEmployTitle,
  },
  {
    id           : 1049,
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.coBorrowerEmployStart,
  },
  {
    id           : 1050,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.coBorrowerEmployBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    id           : 1051,
    showIf       : [isCoLessThanTwoYrsAtJob],
    component    : TextInput,
    label        : 'Previous Employers Name',
    name         : FieldNames.coBorrowerEmployPrevName,
    title        : 'Previous employment information',
    popover      : `Lenders will require previous employer information if you've been with this employer for less than 2 years.`,
  },
  {
    id           : 1052,
    showIf       : [isCoLessThanTwoYrsAtJob],
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.coBorrowerEmployPrevStart,
  },
  {
    id           : 1053,
    showIf       : [isCoLessThanTwoYrsAtJob],
    component    : SingleDate,
    label        : 'End Date',
    name         : FieldNames.coBorrowerEmployPrevEnd,
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.coBorrowerEmployPrevStart,
    },
  },
  {
    id           : 1054,
    title        : 'Tell us about your income',
    component    : RetirementIncomeSources,
    name         : FieldNames.coBorrowerRetirementIncome,
  },
  {
    id           : 1055,
    title        : 'Tell us about your income',
    component    : OtherIncomeSources,
    name         : FieldNames.coBorrowerOtherIncome,
  },
  {
    id           : 1056,
    component    : RadioField,
    name         : FieldNames.coBorrowerIncomeOnDeclineYN,
    options      : YES_OR_NO_OPTIONS,
    title        : 'Is your income expected to decline?',
  },
  {
    id           : 1057,
    component    : (props) => <SwitchToggle {...props} isThinStyle={true} /> ,
    name         : `${FieldNames.decBorrower}.${FieldNames.hasCitizenship}`,
    label        : 'Are you a US Citizen or permanent resident?',
  },
  {
    id           : 1058,
    showIf       : [hasCoBorrower],
    component    : (props) => <SwitchToggle {...props} isThinStyle={true} /> ,
    name         : `${FieldNames.decCoBorrower}.${FieldNames.hasCitizenship}`,
    label        : 'Is your co-borrower a US Citizen or permanent resident?',
  },
  {
    id          : OV_LOAN_FINAL_PAGE_QUESTION,
    component   : OtherVehicleConfirmation,
    name        : 'FinalPage',
  },
];
