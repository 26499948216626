import React from 'react';
import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { autoLoanQuestionsByName, autoLoanQuestionById } from '../questions/auto-loan';
import {
  AUTO_LOAN_APP_COBORROWER_PERSONAL_INFORMATION,
  AUTO_LOAN_GET_FICO_AND_ZIP,
  AUTO_LOAN_APP_CURRENT_HOUSING_SITUATION,
  AUTO_LOAN_APP_HOUSING_OWNER_INFORMATION,
  AUTO_LOAN_APP_HOUSING_FREE_INFORMATION,
  AUTO_LOAN_APP_HOUSING_RENTER_INFORMATION,
  AUTO_LOAN_APP_CHOOSE_VEHICLE,
  AUTO_LOAN_GET_CONTACT_INFORMATION,
  AUTO_LOAN_GET_LOAN_INFORMATION,
  AUTO_LOAN_GET_PURCHASE_PLAN,
  AUTO_LOAN_APP_COBORROWER_HOUSING_INFORMATION,
  AUTO_LOAN_APP_BORROWER_INCOME_SOURCE,
  AUTO_LOAN_APP_BORROWER_INCOME_EMPLOYED,
  AUTO_LOAN_APP_BORROWER_INCOME_RETIRED,
  AUTO_LOAN_APP_BORROWER_INCOME_OTHER,
  AUTO_LOAN_APP_COBORROWER_INCOME_SOURCE,
  AUTO_LOAN_APP_COBORROWER_INCOME_EMPLOYED,
  AUTO_LOAN_APP_COBORROWER_INCOME_RETIRED,
  AUTO_LOAN_APP_COBORROWER_INCOME_OTHER,
  AUTO_LOAN_APP_CITIZENSHIP_AND_AUTHORIZATION,
  AUTO_LOAN_CREDIT_REPORT_RESULT_QUESTION,
  AUTO_LOAN_APP_CREDIT_REPORT_RESULT,
  AUTO_LOAN_APP_FINAL,
  AUTO_LOAN_FINAL_PAGE_QUESTION,
  AUTO_LOAN_SALVAGE_TITLE_QUESTION,
  AUTO_LOAN_CREDIT_LIFE_QUOTE,
} from './constants';
import {
  hasCoBorrower,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoEmployee,
  isCoSelfEmployed,
  isEmployee,
  isEmploymentOther,
  isHomeowner,
  isRentFree,
  isRenting,
  isRetired,
  isSelfEmployed,
  LoanOfficerCondition,
  noCitizenship,
  coBorrowerHasDifferentAddress,
  isFromDealerAndUsed,
} from '../fields/conditionals';
import { AuthorizeCreditReport } from '../../components/FormAgreements/AuthorizeCreditReport';
import { FormParagraphs } from '../paragraphs';
import { ajaxValidateCoBorrowerResidence, ajaxValidateBorrowerResidence } from 'app/actions/form/address-validation/actions';
import { ajaxPostCreditReportForAutoLoan } from 'app/actions/form/credit-report/actions';
import { handleAutoLoanFetchLimits } from 'app/actions/form/actions';
import { handleAutoLoanRatesAndValuation } from 'app/actions/form/auto-valuation/actions';
import PoweredByTpImage from '../../../assets/img/powered-by-tp.png';
import { AutoAcknowledgeGapInsurance } from 'app/components/AutoLoan/AutoAcknowledgeGapInsurance';
import { AcknowledgeAutoInsuranceQuote } from '../../components/FormAgreements/AcknowledgeAutoInsuranceQuote';

export const getAutoLoanSectionById = (id: number): Section => {
  return AUTO_LOAN_SECTIONS.find((section) => section.id === id) as Section;
};

const AUTO_LOAN_SECTIONS: Section[] = [
  {
    id              : AUTO_LOAN_GET_PURCHASE_PLAN,
    questions       : autoLoanQuestionsByName([
      FieldNames.autoPurchasePlan,
    ]),
    hideBackButton  : true,
    hideProgressBar : true,
    title           : 'How are you planning to purchase the vehicle?',
    paragraph       : FormParagraphs.autoPurchasePlan,
  },
  {
    id              : AUTO_LOAN_SALVAGE_TITLE_QUESTION,
    questions       : autoLoanQuestionsByName([FieldNames.isVehicleSalvageTitle]),
    showIf          : [isFromDealerAndUsed],
    hideProgressBar : true,
  },
  {
    id       : AUTO_LOAN_GET_FICO_AND_ZIP,
    questions: autoLoanQuestionsByName([
      FieldNames.creditScore,
      FieldNames.propertyZip,
    ]),
    submitAction    : handleAutoLoanFetchLimits,
    submitText      : 'Next',
  },
  {
    id       : AUTO_LOAN_GET_LOAN_INFORMATION,
    questions: autoLoanQuestionsByName([
      FieldNames.purchasePrice,
      FieldNames.tradeInValue,
      FieldNames.downDollar,
      FieldNames.loanTermDesired,
    ]),
    agreement: <AutoAcknowledgeGapInsurance />,
  },
  {
    id       : AUTO_LOAN_GET_CONTACT_INFORMATION,
    title    : 'Enter your personal information',
    questions: autoLoanQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerUnderage,
        LoanOfficerCondition.isVehicleSalvageTitle,
        LoanOfficerCondition.hasLowSelfReportedFico,
      ],
    },
    hideProgressBar: true,
  },
  {
    id       : AUTO_LOAN_APP_CHOOSE_VEHICLE,
    title    : 'Tell us about the vehicle you are interested in purchasing',
    questions: autoLoanQuestionsByName([
      FieldNames.vehicleMileage,
      FieldNames.vehicle,
      FieldNames.vehicleId,
    ]),
    submitText   : 'Submit',
    submitAction : handleAutoLoanRatesAndValuation,
    agreement: <img src={PoweredByTpImage} style={{ height: 30, marginTop: 10 }} alt='Powered by TradePending' />,
  },
  {
    id        : AUTO_LOAN_APP_COBORROWER_PERSONAL_INFORMATION,
    questions : autoLoanQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerDOB,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerUnderage],
    },
  },
  {
    id        : AUTO_LOAN_APP_CURRENT_HOUSING_SITUATION,
    title     : 'What is your current housing situation?',
    questions : autoLoanQuestionsByName([FieldNames.livingRentOrOwn]),
  },
  {
    id        : AUTO_LOAN_APP_HOUSING_OWNER_INFORMATION,
    showIf    : [isHomeowner],
    title     : 'Provide your homeowner information',
    questions : autoLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : AUTO_LOAN_APP_HOUSING_RENTER_INFORMATION,
    showIf     : [isRenting],
    title      : 'Provide your renter information',
    questions  : autoLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : AUTO_LOAN_APP_HOUSING_FREE_INFORMATION,
    showIf     : [isRentFree],
    title      : 'Provide your housing information',
    questions  : autoLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id        : AUTO_LOAN_APP_COBORROWER_HOUSING_INFORMATION,
    showIf    : [hasCoBorrower],
    title     : 'Co-borrower at same address as borrower?',
    questions : autoLoanQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
    ]),
    submitAction : (formValues) =>
      coBorrowerHasDifferentAddress(formValues) ? ajaxValidateCoBorrowerResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id        : AUTO_LOAN_APP_BORROWER_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : autoLoanQuestionsByName([FieldNames.employmentType]),
  },
  {
    id         : AUTO_LOAN_APP_BORROWER_INCOME_EMPLOYED,
    showIf     : [isEmployee, isSelfEmployed],
    title      : 'Tell us about your income and employment',
    questions  : autoLoanQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.incomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerShortJobDuration,
        LoanOfficerCondition.isBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : AUTO_LOAN_APP_BORROWER_INCOME_RETIRED,
    showIf    : [isRetired],
    questions : autoLoanQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.incomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : AUTO_LOAN_APP_BORROWER_INCOME_OTHER,
    showIf    : [isEmploymentOther],
    questions : autoLoanQuestionsByName([
      FieldNames.otherIncome,
      FieldNames.incomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : AUTO_LOAN_APP_COBORROWER_INCOME_SOURCE,
    showIf    : [hasCoBorrower],
    title     : `What is your co-borrower's income source?`,
    questions : autoLoanQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
  },
  {
    id        : AUTO_LOAN_APP_COBORROWER_INCOME_EMPLOYED,
    showIf    : [isCoEmployee, isCoSelfEmployed],
    title     : `Tell us about your co-borrower's income and employment`,
    questions : autoLoanQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : AUTO_LOAN_APP_COBORROWER_INCOME_RETIRED,
    showIf    : [isCoBorrowerRetired],
    questions : autoLoanQuestionsByName([
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : AUTO_LOAN_APP_COBORROWER_INCOME_OTHER,
    showIf    : [isCoBorrowerEmploymentOther],
    questions : autoLoanQuestionsByName([
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : AUTO_LOAN_APP_CITIZENSHIP_AND_AUTHORIZATION,
    title     : 'A question and your authorization',
    agreement : <AuthorizeCreditReport />,
    questions : autoLoanQuestionsByName([
      `${FieldNames.decBorrower}.${FieldNames.hasCitizenship}`,
      `${FieldNames.decCoBorrower}.${FieldNames.hasCitizenship}`,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerNotCitizen,
        LoanOfficerCondition.isCoBorrowerNotCitizen,
      ],
    },
    submitIf    : [(formValues) =>
      !noCitizenship(FieldNames.decBorrower)(formValues) &&
      !noCitizenship(FieldNames.decCoBorrower)(formValues),
    ],
    submitAction : ajaxPostCreditReportForAutoLoan,
  },
  {
    id                : AUTO_LOAN_APP_CREDIT_REPORT_RESULT,
    title             : 'Congratulations! You have been pre-qualified!',
    questions         : [autoLoanQuestionById(AUTO_LOAN_CREDIT_REPORT_RESULT_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
  {
    id                : AUTO_LOAN_CREDIT_LIFE_QUOTE,
    agreement         : <AcknowledgeAutoInsuranceQuote/>,
    title             : 'Would you be interested in receiving a credit life insurance quote?',
    questions         : autoLoanQuestionsByName([FieldNames.wantsAutoInsurance]),
    hideProgressBar   : true,
  },
  {
    id                : AUTO_LOAN_APP_FINAL,
    questions         : [autoLoanQuestionById(AUTO_LOAN_FINAL_PAGE_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
];
