import React from 'react';
import { TFunction } from 'i18next';
import { Grid, Typography } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { FormErrors } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { localizeLabel, localizeQuestions, localizeDisplayValue } from 'app/i18n/helpers';
import { Question } from 'app/models/types';

class ToggleEditableFieldsComponent extends React.Component<any, any> {
  readonly state = {
    isEdit: false,
  };

  toggleIsEdit() {
    this.setState({
      isEdit: !this.state.isEdit,
    });
  }

  /**
   * Renders the subheading for the group of fields
   *
   * @param {string} subtitle1
   * @returns
   * @memberof ToggleEditableFields
   */
  renderSubheading(subtitle1: string, id: number, t: TFunction) {
    return (
      <Grid item xs={10}>
        <Typography variant="subtitle1" classes={{ subtitle1: 'large', root: 'specialFont' }}>
          {subtitle1 && t(`editableFields.${id}.subtitle1`, { defaultValue: `${subtitle1}` })}
        </Typography>
      </Grid>
    );
  }

  /**
   * Renders the button used to toggle between View and Edit
   * Becomes disabled if a user is editing and has an invalid value
   *
   * @param {boolean} isValid
   * @returns
   * @memberof ToggleEditableFields
   */
  renderToggleButton(isValid: boolean, t: TFunction) {
    const actionText = this.state.isEdit
      ? t('editableFields.button.save', { defaultValue: 'Save' })
      : t('editableFields.button.edit', { defaultValue: 'Edit' });
    const isInvalid = !isValid;
    return (
      <Grid item xs={2} classes={{ item: 'textRight' }}>
        <Button
          disableRipple
          color   = "primary"
          classes = {{
            root: 'link',
            text: 'noUnderline',
          }}
          onClick  = {() => this.toggleIsEdit()}
          disabled = {this.state.isEdit && isInvalid}
        >
          {actionText}
        </Button>
      </Grid>
    );
  }

  /**
   * Renders the label portion of the grid. (ie: First Name)
   * Rendered when isEdit is false
   *
   * @param {string} label
   * @param {number} index
   * @returns
   * @memberof ToggleEditableFields
   */
  renderLabel(label: string, index: number, name: string, t: TFunction) {
    return (
      <Grid item xs={6} sm={4} key={`${label}-${index}`}>
        <Typography
          variant = "body1"
          classes = {{ root: 'sectionData sectionDataTitle' }}
        >
          {localizeLabel(name, t, label)}
        </Typography>
      </Grid>
    );
  }

  /**
   * Renders the question with a display: 'none' style
   * set to the wrapping div. This prevents redux-form from
   * unregistering the field and stopping validation when
   * in "view" mode.
   *
   * @param {*} question
   * @returns
   * @memberof ToggleEditableFields
   */
  renderHiddenField(question: any) {
    const QuestionComponent = question.component;
    return (
      <div style={{ display: 'none' }}>
        <QuestionComponent
          key={question.name}
          {...question}
        />
      </div>
    );
  }

  /**
   * Takes in the redux-form syncErrors object and checks to see
   * if any of the questions being rendered are found in the syncErrors
   * object.
   *
   * @param syncErrors Redux Form SyncErrors
   * @param questions Array of questions this group renders
   * @returns {boolean} Is this group of questions valid
   * @memberof ToggleEditableFields
   */
  isGroupValid(syncErrors: FormErrors = {}, questions: any[] = []): boolean {
    return questions.every(({ name }) => {
      return typeof syncErrors[name] === 'undefined';
    });
  }

  /**
   * Renders the value portion of the grid. (ie: 'Jane')
   * Rendered when isEdit is false
   *
   * @param {*} values
   * @param {Question} question
   * @param {number} index
   * @param {TFunction} t
   * @returns {JSX.Element}
   * @memberof ToggleEditableFieldsComponent
   */
  renderValue(values: any, question: Question, index: number, t: TFunction): JSX.Element {
    const formValue    = values[question.name];
    let   displayValue;
    /* Show 'Required' validation error if the question is missing a value and is required */
    if ((typeof formValue === 'undefined' || formValue === '') && !question.isOptional) {
      displayValue = (
        <Typography component="span" color="error">
          {t('validation:error.requiredEditableField', { defaultValue: 'Required' })}
        </Typography>
      );
    /* Handle questions with localized options (ie: SFH to Single Family Home) */
    } else if (question.options) {
      /* No default value is being passed in here since these are already added to the database from localizeOptions */
      displayValue = t(`question.${question.name}.options.${formValue}`);
    } else {
      displayValue = localizeDisplayValue(question.name, t, formValue, question.displayFormat);
    }
    return (
      <Grid item xs={6} sm={8} key={`${question.name}-${index}`}>
        <Typography variant="body2" classes={{ root: 'sectionData' }}>
          {displayValue}
        </Typography>
      </Grid>
    );
  }

  render() {
    const { subtitle1, questions, values, syncErrors, id, t } = this.props;
    const isValid = this.isGroupValid(syncErrors, questions);
    // Helper component to wrap other components
    const Wrap = props => props.children;
    return (
      <Grid
        container
        spacing    = {8}
        alignItems = "flex-end"
        classes    = {{ container: 'section' }}
      >
        {this.renderSubheading(subtitle1, id, t)}
        {this.renderToggleButton(isValid, t)}
        {localizeQuestions(questions, t).map((question, index) => {
          if (this.state.isEdit) {
            const QuestionComponent = question.component;
            return (
              <Grid key={`${index} - ${question.name}`} item xs={12}>
                <QuestionComponent
                  key={`${question.name} - ${index}`}
                  {...question}
                />
              </Grid>
            );
          } else {
            return (
              <Wrap key={`${index} - ${question.name}.view`}>
                {this.renderLabel(question.label, index, question.name, t)}
                {this.renderValue(values, question, index, t)}
                {this.renderHiddenField(question)}
              </Wrap>
            );
          }
        })}
      </Grid>
    );
  }
}

export const ToggleEditableFields = (withTranslation()(ToggleEditableFieldsComponent));
