import { Question } from 'app/models/types';
import { AutoPrequalAccountSummary } from 'app/components/VerifiableSections/AutoPrequalAccountSummary';
import { Currency } from 'app/components/FormFields/Currency';
import { RadioField } from 'app/components/FormFields/RadioField';
import { REAL_ESTATE_LIABILITY_OPTIONS, YES_OR_NO_OPTIONS } from 'app/models/options/options';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import { CreditReportResult } from 'app/components/CreditReportResult';
import { RealEstateAccountsStatus } from 'app/components/FormFieldArrays/RealEstateAccountsStatus';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { PrequalAmount } from 'app/components/PrequalAmount';
import { FieldNames } from 'app/models/fields/names';
import { isChangedIncome, isCoBorrowerChangedIncome } from 'app/models/fields/conditionals';
import { PurchaseDownPayment } from 'app/components/FormFields/PurchaseDownPayment';

/**
 * Takes in a field name and returns the corresponding auto prequal
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const autoPrequalRenewQuestionByName = (name: string): Question => {
  return AUTO_PREQUAL_RENEW_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const autoPrequalRenewQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(autoPrequalRenewQuestionByName(name));
  }
  return questions;
};

export const AUTO_PREQUAL_RENEW_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: AutoPrequalAccountSummary,
    name     : FieldNames.accountSummary,
  },
  {
    id       : 1002,
    component: AuthorizeCreditReport,
    name     : FieldNames.creditAuthorize,
  },
  {
    id       : 1003,
    component: CreditReportResult,
    name     : FieldNames.creditScore,
  },
  {
    id       : 1004,
    component: RealEstateAccountsStatus,
    name     : FieldNames.realEstateLiabilities,
    options  : REAL_ESTATE_LIABILITY_OPTIONS,
  },
  {
    id       : 1005,
    component: RadioField,
    name     : FieldNames.changedIncomeYN,
    options  : YES_OR_NO_OPTIONS,
  },
  {
    id       : 1006,
    component: Currency,
    name     : FieldNames.employBase,
    label    : 'Monthly Base Pay',
    showIf   : [isChangedIncome],
  },
  {
    id          : 1007,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    showIf      : [isChangedIncome],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id          : 1008,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isChangedIncome],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1009,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isChangedIncome],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1010,
    component   : IncomeSources,
    name        : FieldNames.additionalIncome,
    fieldPopover: true,
    isOptional  : true,
    showIf      : [isChangedIncome],
    popover     :
      'Income derived from alimony, child support, or separate maintenance payments need not be revealed if the applicant does not want the creditor to consider it in determining the applicant’s creditworthiness.',
  },
  {
    id       : 1011,
    component: RadioField,
    title    : 'Is this income verifiable for the last 2 years?',
    name     : FieldNames.incomeVerifiable,
    options  : YES_OR_NO_OPTIONS,
    showIf   : [isChangedIncome],
  },
  {
    id       : 1012,
    component: RadioField,
    name     : FieldNames.coBorrowerChangedIncomeYN,
    options  : YES_OR_NO_OPTIONS,
  },
  {
    id       : 1013,
    component: Currency,
    name     : FieldNames.coBorrowerEmployBase,
    label    : 'Monthly Base Pay',
    showIf   : [isCoBorrowerChangedIncome],
  },
  {
    id          : 1014,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.coBorrowerEmployOvertime,
    showIf      : [isCoBorrowerChangedIncome],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id          : 1015,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.coBorrowerEmployCommission,
    showIf      : [isCoBorrowerChangedIncome],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1016,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.coBorrowerEmployBonus,
    showIf      : [isCoBorrowerChangedIncome],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1017,
    component   : IncomeSources,
    name        : FieldNames.coBorrowerAdditionalIncome,
    fieldPopover: true,
    isOptional  : true,
    popover     : 'Income derived from alimony, child support, or separate maintenance payments need not be revealed if the applicant does not want the creditor to consider it in determining the applicant’s creditworthiness.',
    showIf      : [isCoBorrowerChangedIncome],
  },
  {
    id       : 1018,
    component: RadioField,
    title    : 'Is this income verifiable for the last 2 years?',
    name     : FieldNames.coBorrowerIncomeVerifiable,
    options  : YES_OR_NO_OPTIONS,
    showIf   : [isCoBorrowerChangedIncome],
  },
  {
    id       : 1019,
    component: PrequalAmount,
    name     : FieldNames.prequalAmount,
  },
  {
    id           : 1020,
    component    : PurchaseDownPayment,
    hideTitle    : true,
    label        : 'Home Value',
    name         : FieldNames.homeValue,
    fieldPopover : true,
    popover      : 'Enter an estimated value here and our calculations will narrow everything down for you.',
  },
];
