import React from 'react';
import { refinanceLFQuestionsByName } from 'app/models/questions/application-refinance';
import { getLongFormDeclarationQuestions } from 'app/models/questions/declarations';
import { Confirmation } from 'app/components/Confirmation';
import { LastStep } from 'app/components/LastStep';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { FormTerms } from 'app/components/FormAgreements/FormTerms';
import { FormParagraphs } from 'app/models/paragraphs';
import { FieldNames } from 'app/models/fields/names';
import {
  isEmployee,
  isSelfEmployed,
  isRetired,
  isEmploymentOther,
  hasCoBorrower,
  schedulerEnabled,
  isCoEmployee,
  isCoSelfEmployed,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
  isExcludingSsnQuestions,
  hasLoanTypeQuestion,
} from 'app/models/fields/conditionals';
import { BorrowerType } from 'app/models/options/enums';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';
import {
  LF_REFINANCE_ADDITIONAL_PROPERTIES, LF_REFINANCE_CONFIRMATION, LF_REFINANCE_GET_ASSETS,
  LF_REFINANCE_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
  LF_REFINANCE_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
  LF_REFINANCE_GET_BORROWER_INCOME_SOURCE, LF_REFINANCE_GET_BORROWER_QUESTIONS, LF_REFINANCE_GET_BORROWER_SSN,
  LF_REFINANCE_GET_CITIZEN_AND_MILITARY,
  LF_REFINANCE_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
  LF_REFINANCE_GET_COBORROWER_HOUSING_INFORMATION,
  LF_REFINANCE_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF, LF_REFINANCE_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
  LF_REFINANCE_GET_COBORROWER_INCOME_SOURCE,
  LF_REFINANCE_GET_COBORROWER_PERSONAL_INFORMATION, LF_REFINANCE_GET_COBORROWER_QUESTIONS,
  LF_REFINANCE_GET_FICO_MARRIAGE_AND_DEPENDENTS,
  LF_REFINANCE_GET_LOAN_PURPOSE,
  LF_REFINANCE_GET_PERSONAL_INFO,
  LF_REFINANCE_GET_PRIMARY_HOME_LOCATION,
  LF_REFINANCE_GET_PROPERTY_INFO,
  LF_REFINANCE_GET_PROPERTY_INFO_2,
  LF_REFINANCE_GET_PROPERTY_LOCATION,
  LF_REFINANCE_GET_LOAN_TYPE,
  LF_REFINANCE_LAST_STEP,
} from './constants';

export const getApplicationRefinanceSectionById = (id: number): Section => {
  return APPLICATION_REFINANCE_SECTIONS.find((section) => section.id === id) as Section;
};

const APPLICATION_REFINANCE_SECTIONS: Section[] = [
  {
    id       : LF_REFINANCE_GET_LOAN_PURPOSE,
    questions: refinanceLFQuestionsByName([
      FieldNames.loanPurpose,
    ]),
    hideBackButton: true,
  },
  {
    id       : LF_REFINANCE_GET_LOAN_TYPE,
    questions: refinanceLFQuestionsByName([
      FieldNames.loanType,
    ]),
    showIf: [hasLoanTypeQuestion],
  },
  {
    id       : LF_REFINANCE_GET_PERSONAL_INFO,
    title    : 'Enter your personal information',
    questions: refinanceLFQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    agreement: <FormTerms />,
  },
  {
    id       : LF_REFINANCE_GET_PROPERTY_LOCATION,
    title    : 'Where is the location of the property you are refinancing?',
    questions: refinanceLFQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
  },
  {
    id       : LF_REFINANCE_GET_PROPERTY_INFO,
    title    : 'Tell us more about the property you are refinancing',
    questions: refinanceLFQuestionsByName([
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.homeValue,
      FieldNames.loanAmount,
      FieldNames.propertyMonthlyRentalIncome,
      FieldNames.builtYear,
      FieldNames.acquiredYear,
      FieldNames.propertyOriginalCost,
    ]),
  },
  {
    id       : LF_REFINANCE_GET_PROPERTY_INFO_2,
    title    : 'Tell us more about the property you are refinancing',
    questions: refinanceLFQuestionsByName([
      FieldNames.livingEscrowUsage,
      FieldNames.escrowUsage,
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
      FieldNames.yearAssociate,
    ]),
  },
  {
    id       : LF_REFINANCE_GET_PRIMARY_HOME_LOCATION,
    title    : 'Tell us about your primary home',
    questions: refinanceLFQuestionsByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
      FieldNames.isMailingDifferent,
      FieldNames.mailingStreet,
      FieldNames.mailingStreet2,
      FieldNames.mailingCity,
      FieldNames.mailingState,
      FieldNames.mailingZip,
    ]),
  },
  {
    id       : LF_REFINANCE_ADDITIONAL_PROPERTIES,
    questions: refinanceLFQuestionsByName([
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
    ]),
  },
  {
    id       : LF_REFINANCE_GET_FICO_MARRIAGE_AND_DEPENDENTS,
    questions: refinanceLFQuestionsByName([
      FieldNames.creditScore,
      FieldNames.maritalStatus,
      FieldNames.numOfDependent,
      FieldNames.dependentsAges,
    ]),
  },
  {
    id        : LF_REFINANCE_GET_CITIZEN_AND_MILITARY,
    questions : refinanceLFQuestionsByName([
      FieldNames.citizenshipType,
      FieldNames.militaryExperience,
    ]),
  },
  {
    id         : LF_REFINANCE_GET_COBORROWER_PERSONAL_INFORMATION,
    questions  : refinanceLFQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerOnTitle,
    ]),
  },
  {
    id         : LF_REFINANCE_GET_COBORROWER_HOUSING_INFORMATION,
    questions  : refinanceLFQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerLivingRentOrOwn,
      FieldNames.coBorrowerLivingMonthlyRentAmount,
      FieldNames.coBorrowerMaritalStatus,
    ]),
    showIf : [hasCoBorrower],
  },
  {
    id        : LF_REFINANCE_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
    showIf    : [hasCoBorrower],
    questions : refinanceLFQuestionsByName([
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerMilitaryExperience,
    ]),
  },
  {
    id       : LF_REFINANCE_GET_BORROWER_INCOME_SOURCE,
    title    : 'What is your income source?',
    questions: refinanceLFQuestionsByName([
      FieldNames.employmentType,
    ]),
  },
  {
    id       : LF_REFINANCE_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
    title    : 'Tell us about your income and employment',
    questions: refinanceLFQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.lineOfWorkYears,
      FieldNames.lineOfWorkMonths,
      FieldNames.employOwnershipShare,
      FieldNames.employPartyTransaction,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf: [isEmployee, isSelfEmployed],
  },
  {
    id       : LF_REFINANCE_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
    questions: refinanceLFQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [isRetired, isEmploymentOther],
  },
  {
    id        : LF_REFINANCE_GET_COBORROWER_INCOME_SOURCE,
    showIf    : [hasCoBorrower],
    title     : `What is your co-borrower's income source?`,
    questions : refinanceLFQuestionsByName([FieldNames.coBorrowerEmploymentType]),
  },
  {
    id         : LF_REFINANCE_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
    title      : `Tell us about your co-borrower's income and employment`,
    questions  : refinanceLFQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployPhone,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerLineOfWorkYears,
      FieldNames.coBorrowerLineOfWorkMonths,
      FieldNames.coBorrowerEmployOwnershipShare,
      FieldNames.coBorrowerEmployPartyTransaction,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
    ]),
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id       : LF_REFINANCE_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
    questions: refinanceLFQuestionsByName([
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerOtherIncome,
    ]),
    showIf: [isCoBorrowerRetired, isCoBorrowerEmploymentOther],
  },
  {
    id       : LF_REFINANCE_GET_ASSETS,
    title    : 'Enter available assets',
    paragraph: FormParagraphs.availableFunds,
    questions: refinanceLFQuestionsByName([
      FieldNames.assets,
    ]),
  },
  {
    id           : LF_REFINANCE_GET_BORROWER_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : FormActions.handleLongFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : LF_REFINANCE_GET_COBORROWER_QUESTIONS,
    title        : 'Co-Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.COBORROWER),
    showIf       : [hasCoBorrower],
    submitIf     : [isExcludingSsnQuestions],
    submitAction : FormActions.handleLongFormSubmit,
    submitText   : 'Submit',
  },
  {
    id            : LF_REFINANCE_GET_BORROWER_SSN,
    questions     : refinanceLFQuestionsByName([FieldNames.ssnQuestionRequirement]),
    showIf        : [(values) => !isExcludingSsnQuestions(values)],
    submitAction  : FormActions.handleLongFormSubmit,
    agreement     : <AuthorizeSsnFullPull />,
  },
  {
    id             : LF_REFINANCE_LAST_STEP,
    ifNoQuestions  : LastStep,
    clearWrapper   : true,
    hideProgressBar: true,
    // Conditional is redundant since the component is skipping the section itself,
    // but it is being used here for clarity.
    showIf         : [schedulerEnabled],
  },
  {
    id             : LF_REFINANCE_CONFIRMATION,
    ifNoQuestions  : Confirmation,
    hideProgressBar: true,
  },
];
