import React from 'react';
import { NoPurchasePrice } from 'app/components/StopGates/NoPurchasePrice';
import { Confirmation } from 'app/components/Confirmation';
import { LastStep } from 'app/components/LastStep';
import { Section } from 'app/models/types';
import { FormTerms } from 'app/components/FormAgreements/FormTerms';
import { FormParagraphs } from 'app/models/paragraphs';
import { FieldNames } from 'app/models/fields/names';
import {
  hasCoBorrower,
  hasProceedsFromSellingHome,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoEmployee,
  isCoSelfEmployed,
  isEmployee,
  isEmploymentOther,
  isExcludingSsnQuestions,
  isHomeowner,
  isRentFree,
  isRenting,
  isRetired,
  isSelfEmployed,
  schedulerEnabled,
} from 'app/models/fields/conditionals';
import { getLongFormDeclarationQuestions } from 'app/models/questions/declarations';
import { BorrowerType } from 'app/models/options/enums';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';
import { Questions } from '../questions/Questions';
import { APPLICATION_LAND_QUESTIONS } from '../questions/application-land';
import {
  LAND_GET_PERSONAL_INFORMATION,
  LAND_GET_GENERAL_INFO,
  LAND_GET_VALUE,
  LAND_GET_LOCATION,
  LAND_GET_AVAILABLE_FUNDS,
  LAND_DOWNPAYMENT_INFO,
  LAND_GET_FICO_MARRIAGE_AND_DEPENDENTS,
  LAND_GET_CITIZEN_AND_MILITARY,
  LAND_GET_COBORROWER_PERSONAL_INFORMATION,
  LAND_GET_COBORROWER_HOUSING_INFORMATION,
  LAND_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
  LAND_GET_CURRENT_HOUSING_SITUATION,
  LAND_GET_SELL_CURRENT_HOUSE,
  LAND_GET_HOUSING_OWNER_INFORMATION,
  LAND_GET_HOUSING_RENTER_INFORMATION,
  LAND_GET_HOUSING_FREE_INFORMATION,
  LAND_GET_ADDITIONAL_PROPERTIES,
  LAND_GET_BORROWER_INCOME_SOURCE,
  LAND_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
  LAND_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
  LAND_GET_COBORROWER_INCOME_SOURCE,
  LAND_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
  LAND_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
  LAND_GET_BORROWER_QUESTIONS,
  LAND_GET_COBORROWER_QUESTIONS,
  LAND_GET_BORROWER_SSN,
  LAND_LAST_STEP,
  LAND_CONFIRMATION,
} from './constants';
import { handleLongFormSubmit } from '../../actions/form/actions';

export const getApplicationLandSectionById = (id: number): Section => {
  return APPLICATION_LAND_SECTIONS.find((section) => section.id === id) as Section;
};
const landQuestions = new Questions(APPLICATION_LAND_QUESTIONS);
const APPLICATION_LAND_SECTIONS: Section[] = [
  {
    id       : LAND_GET_PERSONAL_INFORMATION,
    title    : 'Enter your personal information',
    questions: landQuestions.getByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    agreement     : <FormTerms />,
    hideBackButton: true,
  },
  {
    id       : LAND_GET_GENERAL_INFO,
    questions: landQuestions.getByName([
      FieldNames.agreementYN,
    ]),
  },
  {
    id       : LAND_GET_VALUE,
    title    : 'Tell us about your new purchase',
    questions: landQuestions.getByName([
      FieldNames.homeValue,
      FieldNames.expectedClosingDate,
    ]),
    ifNoQuestions: NoPurchasePrice,
  },
  {
    id       : LAND_GET_LOCATION,
    questions: landQuestions.getByName([
      FieldNames.verifyAddress,
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
      FieldNames.parcelId,
      FieldNames.yearTaxes,
    ]),
  },
  {
    id       : LAND_GET_AVAILABLE_FUNDS,
    title    : 'Available Funds',
    paragraph: FormParagraphs.availableFunds,
    questions: landQuestions.getByName([FieldNames.assets]),
  },
  {
    id        : LAND_DOWNPAYMENT_INFO,
    title     : 'Down Payment Funds Summary',
    questions : landQuestions.getByName([FieldNames.assetSummary]),
  },
  {
    id       : LAND_GET_FICO_MARRIAGE_AND_DEPENDENTS,
    questions: landQuestions.getByName([
      FieldNames.creditScore,
      FieldNames.maritalStatus,
      FieldNames.numOfDependent,
      FieldNames.dependentsAges,
    ]),
  },
  {
    id        : LAND_GET_CITIZEN_AND_MILITARY,
    questions : landQuestions.getByName([
      FieldNames.citizenshipType,
      FieldNames.militaryExperience,
    ]),
  },
  {
    id         : LAND_GET_COBORROWER_PERSONAL_INFORMATION,
    questions  : landQuestions.getByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerOnTitle,
    ]),
  },
  {
    id         : LAND_GET_COBORROWER_HOUSING_INFORMATION,
    showIf     : [hasCoBorrower],
    questions  : landQuestions.getByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerLivingRentOrOwn,
      FieldNames.coBorrowerLivingMonthlyRentAmount,
      FieldNames.coBorrowerMaritalStatus,
    ]),
  },
  {
    id        : LAND_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
    showIf    : [hasCoBorrower],
    questions : landQuestions.getByName([
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerMilitaryExperience,
    ]),
  },
  {
    id       : LAND_GET_CURRENT_HOUSING_SITUATION,
    title    : 'What is your current housing situation?',
    questions: landQuestions.getByName([FieldNames.livingRentOrOwn]),
  },
  {
    id       : LAND_GET_SELL_CURRENT_HOUSE,
    questions: landQuestions.getByName([FieldNames.homeSoldYN]),
    showIf   : [(values) => !hasProceedsFromSellingHome(values) && isHomeowner(values)],
  },
  {
    id       : LAND_GET_HOUSING_OWNER_INFORMATION,
    title    : 'Provide your homeowner information',
    questions: landQuestions.getByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
      FieldNames.livingEscrowUsage,
    ]),
    showIf: [isHomeowner],
  },
  {
    id         : LAND_GET_HOUSING_RENTER_INFORMATION,
    showIf     : [isRenting],
    title      : 'Provide your renter information',
    questions  : landQuestions.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
    ]),
  },
  {
    id         : LAND_GET_HOUSING_FREE_INFORMATION,
    showIf     : [isRentFree],
    title      : 'Provide your housing information',
    questions  : landQuestions.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
    ]),
  },
  {
    id         : LAND_GET_ADDITIONAL_PROPERTIES,
    title      : 'Do you own any additional properties?',
    questions  : landQuestions.getByName([
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
    ]),
    showIf: [isHomeowner],
  },
  {
    id       : LAND_GET_BORROWER_INCOME_SOURCE,
    title    : 'What is your income source?',
    questions: landQuestions.getByName([FieldNames.employmentType]),
  },
  {
    id         : LAND_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
    title      : 'Tell us about your income and employment',
    questions  : landQuestions.getByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOwnershipShare,
      FieldNames.employPartyTransaction,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf: [isEmployee, isSelfEmployed],
  },
  {
    id       : LAND_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
    questions: landQuestions.getByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [isRetired, isEmploymentOther],
  },
  {
    id        : LAND_GET_COBORROWER_INCOME_SOURCE,
    showIf    : [hasCoBorrower],
    title     : `What is your co-borrower's income source?`,
    questions : landQuestions.getByName([FieldNames.coBorrowerEmploymentType]),
  },
  {
    id         : LAND_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
    title      : `Tell us about your co-borrower's income and employment`,
    questions  : landQuestions.getByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployPhone,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployOwnershipShare,
      FieldNames.coBorrowerEmployPartyTransaction,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
    ]),
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id       : LAND_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
    questions: landQuestions.getByName([
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerOtherIncome,
    ]),
    showIf: [isCoBorrowerRetired, isCoBorrowerEmploymentOther],
  },
  {
    id           : LAND_GET_BORROWER_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : handleLongFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : LAND_GET_COBORROWER_QUESTIONS,
    title        : 'Co-Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.COBORROWER),
    showIf       : [hasCoBorrower],
    submitIf     : [isExcludingSsnQuestions],
    submitAction : handleLongFormSubmit,
    submitText   : 'Submit',
  },
  {
    id            : LAND_GET_BORROWER_SSN,
    questions     : landQuestions.getByName([FieldNames.ssnQuestionRequirement]),
    showIf        : [(values) => !isExcludingSsnQuestions(values)],
    submitAction  : handleLongFormSubmit,
    agreement     : <AuthorizeSsnFullPull />,
  },
  {
    id             : LAND_LAST_STEP,
    ifNoQuestions  : LastStep,
    clearWrapper   : true,
    hideProgressBar: true,
    // Conditional is redundant since the component is skipping the section itself,
    // but it is being used here for clarity.
    showIf         : [schedulerEnabled],
  },
  {
    id             : LAND_CONFIRMATION,
    ifNoQuestions  : Confirmation,
    hideProgressBar: true,
  },
];
