import { Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  borrowerSsnRequired,
  borrowerSsnValid,
  coBorrowerSsnRequired,
  coBorrowerSsnValid,
  ssnCannotMatch,
} from 'app/components/FormFields/Ssn/validators';
import { CollectSsnBase } from 'app/components/FormFields/Ssn/CollectSsnBase';
import { ssnQuestionRequirementSelector } from 'app/reducers/app-config';
import { RootState } from 'app/store/types';
import { FieldRequirementType } from 'app/models/options/enums';

type OwnProps = { name: string };
type ConnectProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & ConnectProps

/**
 * This component is used when we conditionally require the SSN to be collected.  This means it
 * is controlled by the client configuration.  Clients can choose to have the SSN collection as
 * Require, Optional, or Hidden.
 * 
 * @link https://maddogtechnology.atlassian.net/wiki/spaces/MMP/pages/2909503491/Collection+of+SSN+on+Forms
 */
export const CollectConditionallyRequiredSsnComponent = ({ ...props }: Props) => {

  const validators = [ssnCannotMatch, borrowerSsnValid, coBorrowerSsnValid];

  // when client requires it, we add required validators
  if (props.ssnQuestionRequirement === FieldRequirementType.REQUIRED) {
    validators.push(borrowerSsnRequired);
    validators.push(coBorrowerSsnRequired);
  }

  return (
    <Field
      component = { CollectSsnBase }
      name      = { props.name }
      validate  = { validators }
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  ssnQuestionRequirement: ssnQuestionRequirementSelector(state),
});

export const CollectConditionallyRequiredSsn = connect(mapStateToProps)(CollectConditionallyRequiredSsnComponent);
