import React, { useEffect } from 'react';
import { FormControl, FormHelperText, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { connect } from 'react-redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { ReduxActions } from 'app/actions';
import { hasCoBorrower } from 'app/models/fields/conditionals';
import { toFormattedSsn } from 'app/util/formatters';
import { toNormalizedSsn } from 'app/util/normalizers';
import { formNameSelector } from 'app/reducers/app-config';
import { getFormName } from 'app/routes/helpers';
import { FieldNames } from 'app/models/fields/names';
import { Dispatch } from 'redux';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ConnectProps = ReturnType<typeof mapStateToProps>;
type Props        = DispatchProps & ConnectProps & ReduxActions;

export let borrowerSsnRef = undefined;
export let coBorrowerSsnRef = undefined;
export const hasEitherSsn = () => borrowerSsnRef?.value || coBorrowerSsnRef?.value;

const mapStateToProps = (state: RootState) => ({
  formName               : getFormName(state.router.location.pathname),
  values                 : getFormValues(formNameSelector(state))(state),
  errors                 : getFormSyncErrors(formNameSelector(state))(state),
});

const isValidSsn = (number: string): boolean => {
  const ssnFormat = /\d{9}/; /* 9 digits long */
  const matchesForm = ssnFormat.test(number);
  return (!number || matchesForm) ? true : false;
};

/**
 * For both SSNs to match they must both be the same value, with the exception of them both being
 * empty values. (i.e nothing entered)
 * 
 * @param {string} borrowerSsn 
 * @param {string} coBorrowerSsn 
 * @returns {boolean}
 */
const isSsnMatch = (borrowerSsn: string, coBorrowerSsn?: string): boolean => {
  if (borrowerSsn && coBorrowerSsn && borrowerSsn === coBorrowerSsn) {
    return true;
  }
  return false;
};

const CollectSsnBaseComponent = (props: Props) => {
  const { t } = useTranslation();
  const {
    formName,
    values,
    errors,
    onChangeBorrowerSsn,
    onChangeCoBorrowerSsn,
  } = props;

  const borrowerSsnErrors = Object.values(errors).filter((message: string) => message.startsWith('Borrower SSN'));
  const coBorrowerSsnErrors = Object.values(errors).filter((message: string) => message.startsWith('Co-Borrower SSN'));

  // initial setting of values when component loads
  useEffect(() => {
    onChangeBorrowerSsn('', formName);
    onChangeCoBorrowerSsn('', formName);
  }, [onChangeBorrowerSsn, onChangeCoBorrowerSsn, formName]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6'>
            Social Security Number
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} classes={{ item: 'wrapper' }}>
          <FormControl error={borrowerSsnErrors.length > 0} fullWidth>
            <InputLabel color='primary' htmlFor='borrowerSsn'>
              {
                t('collectssn.question.borrower.label', { defaultValue: 'Borrower SSN' })
              }
            </InputLabel>
            <Input 
              id='borrowerSsn'
              type='text'
              onChange={(event) => onChangeBorrowerSsn(event.target.value, formName)}
              inputRef={ref => borrowerSsnRef = ref} />
            { 
              borrowerSsnErrors.map((message, index) => <FormHelperText key={index}>{message}</FormHelperText>)
            }
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        {
          hasCoBorrower(values) && <Grid item xs={12} classes={{ item: 'wrapper' }}>
            <FormControl error={coBorrowerSsnErrors.length > 0} fullWidth>
              <InputLabel color='primary' htmlFor='coBorrowerSsn'>
                {
                  t('collectssn.question.coborrower.label', { defaultValue: 'Co-Borrower SSN' })
                }
              </InputLabel>
              <Input 
                id='coBorrowerSsn'
                type='text'
                onChange={(event) => onChangeCoBorrowerSsn(event.target.value, formName)}
                inputRef={ref => coBorrowerSsnRef = ref} />
              {
                coBorrowerSsnErrors.map((message, index) => <FormHelperText key={index}>{message}</FormHelperText>)
              }
            </FormControl>
          </Grid>
        }
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onChangeBorrowerSsn: (value: string, formName: string) => {
    if (typeof borrowerSsnRef !== 'undefined') {
      borrowerSsnRef.value = toFormattedSsn(value);
      const normalizedBorrowerSsn = borrowerSsnRef ? toNormalizedSsn(borrowerSsnRef.value) : '';
      const normalizedCoBorrowerSsn = coBorrowerSsnRef ? toNormalizedSsn(coBorrowerSsnRef.value) : '';
    
      dispatch(change(formName, FieldNames.isBorrowerSsnEmpty, !normalizedBorrowerSsn));
      dispatch(change(formName, FieldNames.isBorrowerSsnValid, isValidSsn(normalizedBorrowerSsn)));
      dispatch(change(formName, FieldNames.isBothSsnMatch, isSsnMatch(normalizedBorrowerSsn, normalizedCoBorrowerSsn)));
    }
  },
  onChangeCoBorrowerSsn: (value: string, formName: string) => {
    if (typeof coBorrowerSsnRef !== 'undefined') {
      coBorrowerSsnRef.value = toFormattedSsn(value);
      const normalizedBorrowerSsn = borrowerSsnRef ? toNormalizedSsn(borrowerSsnRef.value) : '';
      const normalizedCoBorrowerSsn = coBorrowerSsnRef ? toNormalizedSsn(coBorrowerSsnRef.value) : '';

      dispatch(change(formName, FieldNames.isCoBorrowerSsnEmpty, !normalizedCoBorrowerSsn));
      dispatch(change(formName, FieldNames.isCoBorrowerSsnValid, isValidSsn(normalizedCoBorrowerSsn)));
      dispatch(change(formName, FieldNames.isBothSsnMatch, isSsnMatch(normalizedBorrowerSsn, normalizedCoBorrowerSsn)));
    }
  },
});

export const CollectSsnBase = connect(mapStateToProps, mapDispatchToProps)(CollectSsnBaseComponent);
