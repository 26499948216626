import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { FieldNames } from 'app/models/fields/names';
import { isAutoDownPaymentLow } from 'app/models/fields/conditionals';
import { getFormValues } from 'redux-form';
import { FormName } from 'app/models/options/enums';
import { Alert } from '@material-ui/lab';
import { getDownPaymentMinPercent } from 'app/reducers/app-config';

type ConnectProps = ReturnType<typeof mapStateToProps>;

const AutoAcknowledgeGapInsuranceComponent = ({ ...props }: ConnectProps) => {
  const { downPaymentMinPercent, values } = props;

  if (values[FieldNames.autoLoanMinLtvAction] !== 'DISPLAY_WARNING' || !isAutoDownPaymentLow(values, downPaymentMinPercent)) {
    return null;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Alert severity='warning'>
          <Trans i18nKey='autoLoanMinLtv.gapWarning' values={{ downPaymentMinPercent }}>
            {'Down payment + Trade-In totals that are less than {{ downPaymentMinPercent, percent.0 }} of the purchase price may require GAP insurance.  Please discuss with your loan officer.'}
          </Trans>
        </Alert>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  downPaymentMinPercent: getDownPaymentMinPercent(state),
  values: getFormValues(FormName.AUTOLOAN)(state),
});

export const AutoAcknowledgeGapInsurance = connect(mapStateToProps, null)(AutoAcknowledgeGapInsuranceComponent);
