import React from 'react';
import { Field } from 'redux-form';
import { required, noopValidate } from 'app/util/validators';
import { renderSelectBase } from 'app/components/FormFields/_renderSelectBase';

export const Select = ({ ...props }) => {
  const { label, name, options, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;

  return (
    <Field
      component     = {renderSelectBase}
      fieldPopover  = {props.fieldPopover}
      isSummaryPage = {props.isSummaryPage}
      label         = {label}
      name          = {name}
      options       = {options}
      popover       = {props.popover}
      validate      = {[isRequired]}
    />
  );
};
