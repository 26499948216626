import React from 'react';
import {
  createStyles,
  withStyles,
  CircularProgress,
  WithStyles,
} from '@material-ui/core';
import { compose } from 'redux';

// eslint-disable-next-line no-use-before-define
type HocProps = WithStyles<typeof styles>;
type OwnProps = {
  isTaskLoader?: boolean;
};

type Props = OwnProps & HocProps;

const styles = () =>
  createStyles({
    overlay: {
      height  : '100%',
      width   : '100%',
      top     : 0,
      left    : 0,
      zIndex  : 9998,
      background: 'rgba(255, 255, 255, 0.8)',
    },
    loaderWrapper: {
      top      : '50%',
      left     : '50%',
      transform: 'translate(-50%, -50%)',
      padding  : '16px',
    },
  });

const LoaderComponent = (props: Props) => {
  const { classes, isTaskLoader } = props;
  const loaderType                = isTaskLoader ? 'absolute' : 'fixed';
  return (
    <div className={classes.overlay} style={{ position: loaderType }}>
      <div className={classes.loaderWrapper} style={{ position: loaderType }}>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};

export const Loader = compose<React.ComponentType<OwnProps>>(
  withStyles(styles),
)(LoaderComponent);
