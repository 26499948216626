import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  NativeSelect,
} from '@material-ui/core';
import { Popover } from 'app/components/Popover';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const renderSelectOptions = (option, index) => {
  return (
    <option key={`${option.title}-${index}`} value={option.value}>
      {option.title}
    </option>
  );
};

export const renderSelectBase = ({ ...props }) => {
  const {
    fieldPopover,
    input,
    isSummaryPage,
    options,
    popover,
  } = props;
  const { touched, error } = props.meta;
  const name = props.name || input.name;

  return (
    <Grid container>
      <Grid item classes={{ item: 'wrapper' }}>
        {(popover && isSummaryPage) || (popover && fieldPopover) ? (
          <Popover
            isSummaryPage = {isSummaryPage}
            fieldPopover  = {fieldPopover}
            text          = {popover}
          />
        ) : null}
        <FormControl error={touched && !!error} fullWidth>
          <InputLabel htmlFor={name}>{props.label}</InputLabel>
          <NativeSelect
            IconComponent={KeyboardArrowDown}
            input={<Input {...input} autoComplete={props.autoComplete}/>}
          >
            <option />
            {options.map(renderSelectOptions)}
          </NativeSelect>
          {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  );
};
