import { Type } from 'app/actions/form/credit-report/types';
import { SoftCreditPullResponse } from 'app/api/credit-report';

type CreditReportState = SoftCreditPullResponse | {};

const initialState: CreditReportState = {};

export const creditReportReducer = (state = initialState, action): CreditReportState => {
  switch (action.type) {
    case Type.AJAX_POST_CREDIT_REPORT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
