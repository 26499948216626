import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  FINANCIAL_STATEMENT_APP_PERSONAL_INFO,
  FINANCIAL_STATEMENT_APP_ASSETS_STOCK_AND_BONDS,
  FINANCIAL_STATEMENT_APP_ASSETS_RETIREMENT,
  FINANCIAL_STATEMENT_APP_ASSETS_NOTES_AND_CONTRACTS,
  FINANCIAL_STATEMENT_APP_ASSETS_PERSONAL_PROPERTY,
  FINANCIAL_STATEMENT_APP_ASSETS_REAL_ESTATE,
  FINANCIAL_STATEMENT_APP_LIABILITIES_CREDIT_CARDS,
  FINANCIAL_STATEMENT_APP_LIABILITIES_MORTGAGES,
  FINANCIAL_STATEMENT_APP_LIABILITIES_LOANS_PAYABLE,
  FINANCIAL_STATEMENT_APP_LIABILITIES_TAXES_PAYABLE,
  FINANCIAL_STATEMENT_APP_FINAL,
  FINANCIAL_STATEMENT_FINAL_PAGE_QUESTION,
  FINANCIAL_STATEMENT_APP_HOUSING_INFO,
  FINANCIAL_STATEMENT_APP_BUSINESS,
  FINANCIAL_STATEMENT_APP_ASSETS_CASH,
  FINANCIAL_STATEMENT_APP_ASSETS_LIFE_INSURANCE,
  FINANCIAL_STATEMENT_APP_QUESTIONS,
} from './constants';
import { financialStatementQuestions } from '../questions/financial-statement';
import { handleFinancialStatementFormSubmit } from '../../actions/form/actions';
import { getFinancialStatementWhitelist } from 'app/models/questions/declarations';

export const getFinancialStatementSectionById = (id: number): Section => {
  return FINANCIAL_STATEMENT_SECTIONS.find((section) => section.id === id) as Section;
};

const FINANCIAL_STATEMENT_SECTIONS: Section[] = [
  {
    id       : FINANCIAL_STATEMENT_APP_PERSONAL_INFO,
    questions: financialStatementQuestions.getByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.phone,
      FieldNames.email,
      FieldNames.dateOfBirth,
    ]),
    hideBackButton : true,
  },
  {
    id        : FINANCIAL_STATEMENT_APP_HOUSING_INFO,
    title     : 'Provide your current housing information',
    questions : financialStatementQuestions.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_BUSINESS,
    title     : 'Enter your business information',
    questions : financialStatementQuestions.getByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialBusinessTitle,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_ASSETS_CASH,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasCashAccount,
      FieldNames.financialStatementCashAccount,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_ASSETS_STOCK_AND_BONDS,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasStock,
      FieldNames.financialStatementStocks,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_ASSETS_RETIREMENT,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasRetirementAccount,
      FieldNames.financialStatementRetirementAccounts,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_ASSETS_NOTES_AND_CONTRACTS,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasNote,
      FieldNames.financialStatementNotes,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_ASSETS_PERSONAL_PROPERTY,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasPersonalProperty,
      FieldNames.financialStatementPersonalProperties,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_ASSETS_REAL_ESTATE,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasRealEstate,
      FieldNames.financialStatementRealEstate,
    ]),
  },
  {
    id: FINANCIAL_STATEMENT_APP_ASSETS_LIFE_INSURANCE,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasLifeInsurance,
      FieldNames.financialStatementLifeInsurance,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_LIABILITIES_CREDIT_CARDS,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasCreditCard,
      FieldNames.financialStatementCreditCards,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_LIABILITIES_MORTGAGES,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasMortgage,
      FieldNames.financialStatementMortgages,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_LIABILITIES_LOANS_PAYABLE,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasLoan,
      FieldNames.financialStatementLoans,
    ]),
  },
  {
    id        : FINANCIAL_STATEMENT_APP_LIABILITIES_TAXES_PAYABLE,
    questions : financialStatementQuestions.getByName([
      FieldNames.financialStatementHasTaxes,
      FieldNames.financialStatementTaxes,
    ]),
    
  },
  {
    id           : FINANCIAL_STATEMENT_APP_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getFinancialStatementWhitelist(),
    submitAction : handleFinancialStatementFormSubmit,
    submitText   : 'Submit',
  },
  {
    id                : FINANCIAL_STATEMENT_APP_FINAL,
    questions         : [financialStatementQuestions.getById(FINANCIAL_STATEMENT_FINAL_PAGE_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
];
