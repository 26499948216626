import { FieldNames } from 'app/models/fields/names';
import {
  PROPERTY_USAGE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  ESCROW_USAGE_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  NAME_SUFFIX_OPTIONS,
  YES_OR_NO_OPTIONS,
  CITIZENSHIP_TYPE_OPTIONS,
  MILITARY_EXPERIENCE_OPTIONS,
  EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS,
} from 'app/models/options/options';
import { Name } from 'app/components/FormFields/Name';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { Email } from 'app/components/FormFields/Email';
import { Phone } from 'app/components/FormFields/Phone';
import { RadioField } from 'app/components/FormFields/RadioField';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { Select } from 'app/components/FormFields/Select';

import { hasCoBorrower, isCoLessThanTwoYrsAtJob, isCoSelfEmployed, isLessThanTwoYrsAtJob, isEmployee, isSelfEmployed, isCoEmployee, isInvestmentHome, isMultiFamilyHome } from 'app/models/fields/conditionals';
import { FormParagraphs } from 'app/models/paragraphs';
import { TextInput } from 'app/components/FormFields/TextInput';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { CreditScoresRadio } from '../../components/FormFields/CreditScoresRadio';

/**
 * List of questions shared by more than one form
 * These must be imported directly
 * import { SHARED } from 'app/models/questions/shared';
 */
export const SHARED = {
  [FieldNames.propertyUsage]: {
    component : RadioField,
    label     : 'Property Use',
    name      : FieldNames.propertyUsage,
    options   : PROPERTY_USAGE_OPTIONS,
    popover   :
      'Primary residence is the place you live more than 6 months per year; secondary homes are non-rented vacation or second homes; investment homes are generally rentals.',
  },
  [FieldNames.propertyType]: {
    component : RadioField,
    label     : 'Property Type',
    name      : FieldNames.propertyType,
    options   : PROPERTY_TYPE_OPTIONS,
    popover   :
      'Condominiums include attached condos that have homeowner association fees (HOA); Modular homes are assembled at the building site and built to local/state building codes.',
  },
  [FieldNames.creditScore]: {
    component : CreditScoresRadio,
    label     : 'Credit Score',
    hideLabel : true,
    name      : FieldNames.creditScore,
    popover   :
      'This is the estimate of your current credit score. After you authorize an official credit pull, the middle of 3 scores will be used for your mortgage application.',
  },
  [FieldNames.escrowUsage]: {
    component : RadioField,
    label     : 'Escrows and Insurance',
    hideLabel : true,
    name      : FieldNames.escrowUsage,
    options   : ESCROW_USAGE_OPTIONS,
    popover   :
      'An escrow is a separate account where funds are collected and deposited by your mortgage servicer to pay your taxes and home insurance; these funds come from an amount added to your monthly payment.',
  },
  [FieldNames.propertyZip]: {
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.propertyZip,
  },
  [FieldNames.firstName]: {
    component    : Name,
    label        : 'First Name',
    name         : FieldNames.firstName,
    popover      : 'Names only allow upper and lower case letters, hyphens, spaces and apostrophes.',
    fieldPopover : true,
  },
  [FieldNames.middleName]: {
    component : Name,
    label     : 'Middle Name',
    name      : FieldNames.middleName,
    isOptional: true,
  },
  [FieldNames.lastName]: {
    component: Name,
    label    : 'Last Name',
    name     : FieldNames.lastName,
  },
  [FieldNames.suffixName]: {
    component  : Select,
    options    : NAME_SUFFIX_OPTIONS,
    label      : 'Suffix',
    name       : FieldNames.suffixName,
    isOptional : true,
  },
  [FieldNames.mothersMaidenName]: {
    component: Name,
    label    : 'Mothers Maiden Name',
    name     : FieldNames.mothersMaidenName,
  },
  [FieldNames.coBorrowerSuffixName]: {
    component  : Select,
    options    : NAME_SUFFIX_OPTIONS,
    label      : 'Co-borrower Suffix',
    name       : FieldNames.coBorrowerSuffixName,
    isOptional : true,
    showIf     : [hasCoBorrower],
  },
  [FieldNames.email]: {
    component: Email,
    label    : 'Email',
    name     : FieldNames.email,
  },
  [FieldNames.phone]: {
    component: Phone,
    label    : 'Phone',
    name     : FieldNames.phone,
  },
  [FieldNames.maritalStatus]: {
    component: RadioField,
    name     : FieldNames.maritalStatus,
    options  : MARITAL_STATUS_OPTIONS,
    title    : 'What is your marital status?',
    popover  : 'Unmarried includes single, divorced, and widowed persons.',
  },
  [FieldNames.dateOfBirth]: {
    component: SinglePastDate,
    label    : 'Date of Birth',
    name     : FieldNames.dateOfBirth,

  },
  [FieldNames.hasAddlPropertiesYN]: {
    component : RadioField,
    name      : FieldNames.hasAddlPropertiesYN,
    options   : YES_OR_NO_OPTIONS,
    paragraph : FormParagraphs.additionalProperties,
  },
  [FieldNames.employPrevTitle]: {
    component  : TextInput,
    label      : 'Previous Job Title',
    isOptional : true,
    name       : FieldNames.employPrevTitle,
    showIf     : [isLessThanTwoYrsAtJob],
  },
  [FieldNames.coBorrowerEmployPrevTitle]: {
    component  : TextInput,
    label      : 'Previous Job Title',
    isOptional : true,
    name       : FieldNames.coBorrowerEmployPrevTitle,
    showIf     : [isCoLessThanTwoYrsAtJob],
  },
  [FieldNames.citizenshipType]: {
    component : Select,
    name      : FieldNames.citizenshipType,
    options   : CITIZENSHIP_TYPE_OPTIONS,
    label     : 'Citizenship Type',
    title     : 'What is your citizenship?',
  },
  [FieldNames.coBorrowerCitizenshipType]: {
    component : Select,
    name      : FieldNames.coBorrowerCitizenshipType,
    options   : CITIZENSHIP_TYPE_OPTIONS,
    label     : 'Citizenship Type',
    title     : `What is your co-borrower's citizenship?`,
  },
  [FieldNames.militaryExperience]: {
    component  : Select,
    name       : FieldNames.militaryExperience,
    options    : MILITARY_EXPERIENCE_OPTIONS,
    label      : 'Miltary Experience',
    title      : 'What is your military experience?',
  },
  [FieldNames.coBorrowerMilitaryExperience]: {
    component  : Select,
    name       : FieldNames.coBorrowerMilitaryExperience,
    options    : MILITARY_EXPERIENCE_OPTIONS,
    label      : 'Miltary Experience',
    title      : 'What is your co-borrower’s military experience?',
  },
  [FieldNames.employOwnershipShare]: {
    component : Select,
    name      : FieldNames.employOwnershipShare,
    options   : EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS,
    label     : 'Ownership Share',
    title     : 'For this business, I have:',
    showIf    : [isSelfEmployed],
  },
  [FieldNames.coBorrowerEmployOwnershipShare]: {
    component : Select,
    name      : FieldNames.coBorrowerEmployOwnershipShare,
    options   : EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS,
    label     : 'Co-Borrower\'s Ownership Share',
    title     : 'For this business, they have:',
    showIf    : [isCoSelfEmployed],
  },
  [FieldNames.employPartyTransaction]: {
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
    name      : FieldNames.employPartyTransaction,
    title     : 'I am employed by a family member, property seller, real estate agent, or other party to the transaction:',
    showIf    : [isEmployee, isSelfEmployed],
  },
  [FieldNames.coBorrowerEmployPartyTransaction]: {
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
    name      : FieldNames.coBorrowerEmployPartyTransaction,
    title     : 'They are employed by a family member, property seller, real estate agent, or other party to the transaction:',
    showIf    : [isCoEmployee, isCoSelfEmployed],
  },
  [FieldNames.propertyMonthlyRentalIncome]: {
    component : CurrencyAllowZero,
    name      : FieldNames.propertyMonthlyRentalIncome,
    label     : 'Rental Income',
    showIf    : [isInvestmentHome, isMultiFamilyHome],
  },
};

export const INCOME_DISCLAIMER = 'Alimony, child support, or separate maintenance income need not be revealed if the Borrower or Co-Borrower does not choose to have it considered for repaying this loan.';
