import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

export const AcknowledgeCreditCardDisclosure = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant   = 'body2'
          component = 'span'
          classes   = {{ body2: 'small' }}
        >
          <Trans i18nKey='formAgreement.creditCardDisclosure.paragraph1'>
            By clicking the "Next" button below, you acknowledge that you have reviewed the Credit Card Disclosures
            and agree to submit your application subject to the Credit Card Disclosures.
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};
