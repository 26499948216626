import React from 'react';
import { Field } from 'redux-form';
import {
  createStyles,
  FormControlLabel,
  FormGroup,
  colors,
  Switch,
  withStyles,
  Theme,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = (theme) => createStyles({
  switchContainer: {
    flexDirection : 'row-reverse',
    margin        : 0,
    padding       : '18px 0',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${colors.grey[300]}`,
      padding: '20px 0',
    },
    color: theme.palette.grey[800],
  },
  switchContainerThin: {
    flexDirection : 'row-reverse',
    margin        : 0,
    padding       : '9px 0',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${colors.grey[300]}`,
      padding: '20px 0',
    },
    color: theme.palette.grey[800],
  },
  toggleFields: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  fullWidth: {
    maxWidth: '100%',
    width   : '100%',
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

const SwitchToggleComponent = ({ classes, hasConditions, name, ...props }) => {
  const { switchLabelProps } = props;
  const toggleClass = hasConditions && props.input.value ? classes.toggleFields : '';
  const { t } = useTranslation();
  return (
    <FormGroup className={classes.fullWidth} {...props.input}>
      <FormControlLabel
        className = {`${props.isThinStyle ? classes.switchContainerThin : classes.switchContainer} ${toggleClass}`}
        checked   = {props.input.value === true}
        control   = {
          <Grid container style={{ justifyContent: 'flex-end', maxWidth: 100 }}>
            <Grid item style={{ marginRight: 5 }}>
              {t('option.NO', { defaultValue: 'No' })}
            </Grid>
            <Grid item>
              <AntSwitch checked={props.input.value === true} />
            </Grid>
            <Grid item style={{ marginLeft: 5 }}>
              {t('option.YES', { defaultValue: 'Yes' })}
            </Grid>
          </Grid>
        }
        label = {props.label}
        {...switchLabelProps}
      />
    </FormGroup>
  );
};

export const SwitchToggle = withStyles(styles)(
  ({ name, label, classes, hasConditions, isThinStyle, switchLabelProps }: any) => {
    return (
      <Field
        component        = {SwitchToggleComponent}
        label            = {label}
        name             = {name}
        classes          = {classes}
        hasConditions    = {hasConditions}
        switchLabelProps = {switchLabelProps}
        isThinStyle      = {isThinStyle}
      />
    );
  },
);
