import React from 'react';
import { homeEquityLFQuestionsByName } from 'app/models/questions/application-home-equity';
import { Section } from 'app/models/types';
import { Confirmation } from 'app/components/Confirmation';
import { MinimumLoanAmount } from 'app/components/StopGates/MinimumLoanAmount';
import { StateNotCovered } from 'app/components/StopGates/StateNotCovered';
import {
  handleHomeEquityLongFormSubmitFirst,
  handleHomeEquityLongFormSubmitSecond, handleHomeEquityLongFormZipCheck,
} from 'app/actions/form/actions';
import { FormTerms } from 'app/components/FormAgreements/FormTerms';
import { FieldNames } from 'app/models/fields/names';
import {
  isEmployee,
  isSelfEmployed,
  isRetired,
  isEmploymentOther,
  hasCoBorrower,
  isCoBorrowerEmployee,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoBorrowerSelfEmployed,
  schedulerEnabled,
  isExcludingSsnQuestions,
} from 'app/models/fields/conditionals';
import { getLongFormDeclarationQuestions } from 'app/models/questions/declarations';
import { BorrowerType } from 'app/models/options/enums';
import { LastStep } from 'app/components/LastStep';
import {
  HOME_EQUITY_ADDITIONAL_PROPERTIES,
  HOME_EQUITY_BORROWER_QUESTIONS,
  HOME_EQUITY_CITIZEN_TYPE,
  HOME_EQUITY_COBORROWER_CITIZEN_TYPE,
  HOME_EQUITY_COBORROWER_EMPLOYEE_INFO,
  HOME_EQUITY_COBORROWER_EMPLOYMENT_TYPE,
  HOME_EQUITY_COBORROWER_PERSONAL_INFORMATION,
  HOME_EQUITY_COBORROWER_QUESTIONS, HOME_EQUITY_CONFIRMATION,
  HOME_EQUITY_EMPLOYEE_INFO,
  HOME_EQUITY_EMPLOYMENT_TYPE, HOME_EQUITY_LAST_STEP,
  HOME_EQUITY_LOAN_AMOUNT, HOME_EQUITY_LOAN_PURPOSE,
  HOME_EQUITY_MARITAL_AND_CREDIT_SCORE,
  HOME_EQUITY_PERSONAL_INFORMATION, HOME_EQUITY_PROPERTY_ADDRESS,
  HOME_EQUITY_PROPERTY_INFO,
  HOME_EQUITY_RETIRED_INFO,
  HOME_EQUITY_SSN,
  HOME_EQUITY_TAXES_INSURANCE_HOA,
  LF_HELOC_MINIMUM_LOAN_STOP_GATE,
  LF_HELOC_STATE_NOT_COVERED_STOP_GATE,
} from 'app/models/sections/constants';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';

export const getApplicationHomeEquitySectionById = (id: number): Section => {
  return APPLICATION_HOME_EQUITY_SECTIONS.find((section) => section.id === id) as Section;
};

const APPLICATION_HOME_EQUITY_SECTIONS: Section[] = [
  {
    id       : HOME_EQUITY_LOAN_PURPOSE,
    questions: homeEquityLFQuestionsByName([
      FieldNames.homeEquityLoanPurpose,
      FieldNames.homeEquityLoanPurposeOther,
    ]),
    hideBackButton: true,
  },
  {
    id       : HOME_EQUITY_PERSONAL_INFORMATION,
    title    : 'Enter your personal information',
    questions: homeEquityLFQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.phone,
      FieldNames.email,
      FieldNames.dateOfBirth,
    ]),
    agreement: <FormTerms />,
  },
  {
    id       : HOME_EQUITY_PROPERTY_ADDRESS,
    title    : 'Where is the property you will use as collateral?',
    questions: homeEquityLFQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
    submitAction : handleHomeEquityLongFormZipCheck,
  },
  {
    id       : HOME_EQUITY_COBORROWER_PERSONAL_INFORMATION,
    questions: homeEquityLFQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerDOB,
    ]),
  },
  {
    id        : HOME_EQUITY_COBORROWER_CITIZEN_TYPE,
    questions : homeEquityLFQuestionsByName([
      FieldNames.coBorrowerCitizenshipType,
    ]),
    showIf: [hasCoBorrower],
  },
  {
    id       : HOME_EQUITY_MARITAL_AND_CREDIT_SCORE,
    questions: homeEquityLFQuestionsByName([
      FieldNames.creditScore,
      FieldNames.maritalStatus,
      FieldNames.numOfDependent,
      FieldNames.dependentsAges,
    ]),
  },
  {
    id        : HOME_EQUITY_CITIZEN_TYPE,
    questions : homeEquityLFQuestionsByName([
      FieldNames.citizenshipType,
    ]),
  },
  {
    id       : HOME_EQUITY_PROPERTY_INFO,
    title    : 'Tell us more about this property',
    questions: homeEquityLFQuestionsByName([
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.mortgageBalance,
      FieldNames.homeValue,
    ]),
    submitAction: handleHomeEquityLongFormSubmitFirst,
  },
  {
    id           : HOME_EQUITY_LOAN_AMOUNT,
    questions    : homeEquityLFQuestionsByName([FieldNames.maxLoanAmount, FieldNames.loanAmount]),
  },
  {
    id       : HOME_EQUITY_TAXES_INSURANCE_HOA,
    title    : 'Current taxes, insurance, and fees',
    questions: homeEquityLFQuestionsByName([
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
    ]),
  },
  {
    id       : HOME_EQUITY_ADDITIONAL_PROPERTIES,
    questions: homeEquityLFQuestionsByName([
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
    ]),
  },
  {
    id       : HOME_EQUITY_EMPLOYMENT_TYPE,
    questions: homeEquityLFQuestionsByName([FieldNames.employmentType]),
  },
  {
    id       : HOME_EQUITY_EMPLOYEE_INFO,
    title    : 'Tell us about your income and employment',
    questions: homeEquityLFQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
    ]),
    showIf: [isEmployee, isSelfEmployed],
  },
  {
    id       : HOME_EQUITY_RETIRED_INFO,
    questions: homeEquityLFQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [isRetired, isEmploymentOther],
  },
  {
    id       : HOME_EQUITY_COBORROWER_EMPLOYMENT_TYPE,
    questions: homeEquityLFQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
    showIf: [hasCoBorrower],
  },
  {
    id       : HOME_EQUITY_COBORROWER_EMPLOYEE_INFO,
    title    : 'Tell us about your co-borrower\'s income and employment',
    questions: homeEquityLFQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerRetirementIncome,
    ]),
    showIf         : [
      isCoBorrowerEmployee,
      isCoBorrowerEmploymentOther,
      isCoBorrowerRetired,
      isCoBorrowerSelfEmployed,
    ],
  },
  {
    id           : HOME_EQUITY_BORROWER_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.PRIMARY),
    submitAction : handleHomeEquityLongFormSubmitSecond,
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitText   : 'Submit',
  },
  {
    id           : HOME_EQUITY_COBORROWER_QUESTIONS,
    title        : 'Co-Borrower Questions',
    questions    : getLongFormDeclarationQuestions(BorrowerType.COBORROWER),
    submitAction : handleHomeEquityLongFormSubmitSecond,
    showIf       : [hasCoBorrower],
    submitIf     : [isExcludingSsnQuestions],
    submitText   : 'Submit',
  },
  {
    id            : HOME_EQUITY_SSN,
    questions     : homeEquityLFQuestionsByName([FieldNames.ssnQuestionRequirement]),
    showIf        : [(values) => !isExcludingSsnQuestions(values)],
    submitAction  : handleHomeEquityLongFormSubmitSecond,
    agreement     : <AuthorizeSsnFullPull />,
    submitText   : 'Submit',
  },
  {
    id             : HOME_EQUITY_LAST_STEP,
    ifNoQuestions  : LastStep,
    clearWrapper   : true,
    hideProgressBar: true,
    // Conditional is redundant since the component is skipping the section itself,
    // but it is being used here for clarity.
    showIf         : [schedulerEnabled],
  },
  {
    id             : HOME_EQUITY_CONFIRMATION,
    ifNoQuestions  : Confirmation,
    hideProgressBar: true,
  },
  {
    id             : LF_HELOC_STATE_NOT_COVERED_STOP_GATE,
    ifNoQuestions  : StateNotCovered,
    hideProgressBar: true,
  },
  {
    id: LF_HELOC_MINIMUM_LOAN_STOP_GATE,
    ifNoQuestions: MinimumLoanAmount,
  },
];
