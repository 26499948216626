import React from 'react';
import { Trans } from 'react-i18next';

export const CreditBoosterGeneralInfoComponent = () => {

  return (
    <>
      <Trans i18nKey='section.101.generalInfo'>&nbsp;</Trans>
    </>
  );
};
