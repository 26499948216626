import React from 'react';
import { Button } from 'app/components/Button';
import { ImageSection } from 'app/components/ImageSection';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { handleRefinanceTurboSummary } from 'app/actions/form/turbo/actions';

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type MappedProps = ReturnType<typeof mapStateToProps>;
interface Props extends DispatchProps, MappedProps {}

const NoOptimalProgramsComponent = (props: Props): any => {

  const { handleRefinanceTurboSummary, stopGateImage } = props;
  const { t } = useTranslation();

  return (
    <ImageSection image={stopGateImage}>
      <Typography variant='h6' gutterBottom={true} classes={{ h6: 'contentTitle' }}>
        {t('noOptimalPrograms.referToLoanOfficer.title', { defaultValue: 'Let us assist you' })}
      </Typography>
      <Typography variant='body2' gutterBottom={true} classes={{ root: 'paddingBottom' }}>
        <Trans i18nKey='noOptimalPrograms.referToLoanOfficer.paragraph1'>
          We’re sorry, but there don’t appear to be any options to achieve your refinance goals.  If you would like to see the available programs, click below.
        </Trans>
      </Typography>
      <Button
        classes = {{ root: 'stackedButton', fullWidth: 'tabletSizeLarge' }}
        color   = 'primary'
        fullWidth
        size    = 'large'
        variant = 'contained'
        onClick   = {handleRefinanceTurboSummary}
      >
        {t('noOptimalPrograms.seeAvailablePrograms.button', {
          defaultValue: 'See Available Programs',
        })}
      </Button>
    </ImageSection>
  );
};

const mapStateToProps = (state: any) => ({
  stopGateImage: state.config.stopGateImage,
});

const mapDispatchToProps = (dispatch) => ({
  handleRefinanceTurboSummary: () => dispatch(handleRefinanceTurboSummary()),
});

export const NoOptimalPrograms = connect(mapStateToProps, mapDispatchToProps)(NoOptimalProgramsComponent);
