import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { DownloadChecklist } from './DownloadChecklist';

interface Props extends WithTranslation {
  confirmationImage   : string;
}

const ConfirmationCommercialSmallBusinessComponent = (props: Props) => {
  const { confirmationImage, t } = props;

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('ConfirmationCommercialSmallBusinessComponent.title', { defaultValue: 'Thank you!' })}
          </Typography>
          <Typography>
            <Trans i18nKey='ConfirmationCommercialSmallBusinessComponent.paragraph1'>
              In order to process your request, you must upload all required documentation.  Once received, a loan officer will reach out to you.
            </Trans>
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <DownloadChecklist  />
            </Grid>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  confirmationImage : state.config.confirmationImage,
  ...ownProps,
});

export const ConfirmationCommercialSmallBusiness = connect(
  mapStateToProps,
  null,
)(withTranslation()(ConfirmationCommercialSmallBusinessComponent));
