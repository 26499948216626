import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { rootEpic } from 'app/store/root-epic';
import { persistedRootReducer } from 'app/store/root-reducer';

// Basename on production/staging is /v2 but we use / for local
const basename = window.location.href.indexOf('localhost') > 0 ? '/' : '/v2';

// Prepare store
const history = createBrowserHistory({ basename });

const epicMiddleware = createEpicMiddleware();
const middlewares = [routerMiddleware(history), epicMiddleware, thunk];

if (process.env.REACT_APP_IS_STAGING_OR_DEV === 'true') {
  const ignoredActions = [
    '@@redux-form/BLUR',
    '@@redux-form/FOCUS',
    '@@redux-form/REGISTER_FIELD',
    '@@redux-form/TOUCH',
    '@@redux-form/UNREGISTER_FIELD',
    '@@redux-form/UPDATE_SYNC_ERRORS',
  ];
  const logger = createLogger({
    duration: true,
    /* Collapse all actions that don't contain an error */
    collapsed: (getState, action, logEntry = {}) => !logEntry.error,
    /* Skip logging for all ignored actions */
    predicate: (getState, action) => ignoredActions.indexOf(action.type) === -1,
  });

  middlewares.push(logger);
}

const reducer = persistedRootReducer(history);
const store = createStore(
  reducer,
  composeWithDevToolsDevelopmentOnly(
    applyMiddleware(...middlewares),
  ),
);
epicMiddleware.run(rootEpic);

const persistor = persistStore(store);

export const getStore = () => store;

export const getHistory = () => history;

export const getPersistor = () => persistor;
