import React from 'react';
import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  HOME_EQUITY_TURBO_PERSONAL_INFO,
  HOME_EQUITY_TURBO_PROPERTY_COLLATERAL,
  HOME_EQUITY_TURBO_BORROW_AMOUNT,
  HOME_EQUITY_TURBO_PERSONAL_INFO_TWO,
  HOME_EQUITY_TURBO_LOAN_PURPOSE,
  HOME_EQUITY_TURBO_CITIZENSHIP,
  HOME_EQUITY_TURBO_TAX_INS_FEE,
  HOME_EQUITY_TURBO_COBORROWER,
  HOME_EQUITY_TURBO_COBORROWER_ADDRESS,
  HOME_EQUITY_TURBO_CREDIT_REPORT_ONE,
  HOME_EQUITY_TURBO_REAL_ESTATE_LIABILITIES,
  HOME_EQUITY_TURBO_REO_PRIMARY_QUESTIONS,
  HOME_EQUITY_TURBO_REO_SECONDARY_QUESTIONS,
  HOME_EQUITY_TURBO_PRIMARY_HOME,
  HOME_EQUITY_TURBO_UPLOAD_DOCUMENTS,
  HOME_EQUITY_TURBO_COBORROWER_DECLARATION,
  HOME_EQUITY_TURBO_VOIE_START_CONNECT,
  HOME_EQUITY_TURBO_AUTOMATIC_VOIE,
  HOME_EQUITY_TURBO_COLLECT_SSN,
  HOME_EQUITY_TURBO_BORROWER_DECLARATION,
  HOME_EQUITY_TURBO_HOME_VALUATION_ADJUST,
  HOME_EQUITY_TURBO_PROPERTY_VERIFIED,
  HOME_EQUITY_MINIMUM_LOAN_STOP_GATE,
  HOME_EQUITY_TURBO_VOIE_REPORTS,
  HOME_EQUITY_TURBO_CREDIT,
  HOME_EQUITY_TURBO_LOAN_AMOUNT,
  HOME_EQUITY_TURBO_EMPLOYMENT_TYPE,
  HOME_EQUITY_TURBO_EMPLOYEE_INFO,
  HOME_EQUITY_TURBO_RETIRED_INFO,
  HOME_EQUITY_TURBO_COBORROWER_EMPLOYMENT_TYPE,
  HOME_EQUITY_TURBO_COBORROWER_EMPLOYEE_INFO,
  HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION,
  HOME_EQUITY_TURBO_COLLECT_SSN_FINAL,
  HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION_FINAL,
  HOME_EQUITY_TURBO_ADDITIONAL_PROPERTIES,
} from 'app/models/sections/constants';
import { homeEquityTurboQuestionById, homeEquityTurboQuestionsByName } from 'app/models/questions/home-equity-turbo';
import {
  ajaxValidateBorrowerResidence,
  ajaxValidateCoBorrowerResidence,
  ajaxValidateSubjectProperty,
  updateHomeEquityTurboAddress,
} from 'app/actions/form/address-validation/actions';
import { BorrowSummary } from 'app/components/HomeEquity/BorrowSummary';
import {
  coBorrowerHasDifferentAddress,
  hasCoBorrower,
  hasAvmHomeValue,
  isPrimaryHome,
  LoanOfficerCondition,
  canVerifyOfIncomeEmployment,
  isBorrowerUnderage,
  isEmployee,
  isSelfEmployed,
  isRetired,
  isEmploymentOther,
  isCoBorrowerEmployee,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoBorrowerSelfEmployed,
  isManualIncomeAndEmploymentRequired,
  isExcludingSsnQuestions,
  hasEnabledCreditReportOnTurbo,
} from 'app/models/fields/conditionals';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import {
  ajaxPostCreditReportForTurbo,
} from 'app/actions/form/credit-report/actions';
import { FormParagraphs } from 'app/models/paragraphs';
import { UploadDocumentsStep } from 'app/components/Turbo/UploadDocumentsStep';
import { BorrowerType } from 'app/models/options/enums';
import { getTurboDeclarationQuestions } from 'app/models/questions/declarations';
import { AddressVerified } from 'app/components/Turbo/AddressVerified';
import { ajaxGetFinancialVerificationUrl } from 'app/actions/form/verification/actions';
import { MinimumLoanAmount } from '../../components/StopGates/MinimumLoanAmount';
import {
  handleTurboHomeEquityGetRates,
  handleHomeEquityTurboFormSubmit,
  handleTurboHomeEquityGetFinalRates,
  handleValidateLoanLimits,
  handleGoToEmployment,
} from '../../actions/form/turbo/actions';
import { handleGetSubjectPropertyInfo } from 'app/actions/form/property/actions';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';

export const getHomeEquityTurboSectionById = (id: number): Section => {
  return HOME_EQUITY_TURBO_SECTIONS.find((section) => section.id === id) as Section;
};

const HOME_EQUITY_TURBO_SECTIONS: Section[] = [
  {
    id        : HOME_EQUITY_TURBO_PERSONAL_INFO,
    title     : 'How Much Can I Borrow?',
    questions : homeEquityTurboQuestionsByName([
      FieldNames.homeValue,
      FieldNames.mortgageBalance,
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.hasUniqueHomeEquityProgram,
    ]),
  },
  {
    id        : HOME_EQUITY_TURBO_PROPERTY_COLLATERAL,
    title     : 'Where is the property you will use as collateral?',
    questions : homeEquityTurboQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
    submitAction : ajaxValidateSubjectProperty,
  },
  {
    id            : HOME_EQUITY_TURBO_PROPERTY_VERIFIED,
    ifNoQuestions : AddressVerified,
    submitAction  : handleGetSubjectPropertyInfo,
  },
  {
    id                : HOME_EQUITY_TURBO_HOME_VALUATION_ADJUST,
    hideForwardButton : true,
    hideBackButton    : true,
    questions         : homeEquityTurboQuestionsByName([FieldNames.avmHomeValue]),
    showIf            : [hasAvmHomeValue],
  },
  {
    id: HOME_EQUITY_TURBO_CREDIT,
    questions : homeEquityTurboQuestionsByName([
      FieldNames.creditScore,
    ]),
  },
  {
    id       : HOME_EQUITY_TURBO_LOAN_PURPOSE,
    questions: homeEquityTurboQuestionsByName([
      FieldNames.homeEquityLoanPurpose,
      FieldNames.homeEquityLoanPurposeOther,
    ]),
    submitAction: handleValidateLoanLimits,
  },
  {
    id              : HOME_EQUITY_TURBO_BORROW_AMOUNT,
    ifNoQuestions   : BorrowSummary,
  },
  {
    id        : HOME_EQUITY_TURBO_LOAN_AMOUNT,
    questions : homeEquityTurboQuestionsByName([
      FieldNames.loanAmount,
    ]),
  },
  {
    id       : HOME_EQUITY_TURBO_PERSONAL_INFO_TWO,
    title    : 'Enter your personal information',
    questions: homeEquityTurboQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.dateOfBirth,
      FieldNames.email,
      FieldNames.phone,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerUnderage],
    },
    submitIf     : [(values) => !isBorrowerUnderage(values)],
    submitText   : 'Submit',
    submitAction : handleTurboHomeEquityGetRates,
  },
  {
    id        : HOME_EQUITY_TURBO_CITIZENSHIP,
    questions : homeEquityTurboQuestionsByName([
      FieldNames.citizenshipType,
      FieldNames.maritalStatus,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerNotCitizen],
    },
  },
  {
    id        : HOME_EQUITY_TURBO_TAX_INS_FEE,
    title     : 'What are the current taxes and insurance on the home you will use for collateral?',
    questions : homeEquityTurboQuestionsByName([
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
      FieldNames.yearAssociate,
    ]),
    submitAction: updateHomeEquityTurboAddress,
  },
  {
    id: HOME_EQUITY_TURBO_PRIMARY_HOME,
    title: 'Tell us about your primary home',
    questions: homeEquityTurboQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingPropertyType,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
    ]),
    showIf: [(values) => !isPrimaryHome(values)],
    submitAction : (formValues) => ajaxValidateBorrowerResidence(formValues),
  },
  {
    id        : HOME_EQUITY_TURBO_COBORROWER,
    questions : homeEquityTurboQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerCitizenshipType,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerUnderage, LoanOfficerCondition.isCoBorrowerNotCitizen],
    },
  },
  {
    id        : HOME_EQUITY_TURBO_COBORROWER_ADDRESS,
    questions : homeEquityTurboQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerLivingRentOrOwn,
      FieldNames.coBorrowerMaritalStatus,
    ]),
    showIf       : [hasCoBorrower],
    submitIf     : [(formValues) => coBorrowerHasDifferentAddress(formValues)],
    submitAction : (formValues) => ajaxValidateCoBorrowerResidence(formValues),
  },
  {
    id           : HOME_EQUITY_TURBO_CREDIT_REPORT_ONE,
    questions    : homeEquityTurboQuestionsByName([FieldNames.loanOfficer]),
    agreement    : <AuthorizeCreditReport />,
    showIf       : [(values) => hasEnabledCreditReportOnTurbo(values)],
    submitAction : ajaxPostCreditReportForTurbo,
  },
  {
    id        : HOME_EQUITY_TURBO_REAL_ESTATE_LIABILITIES,
    title     : 'Real Estate Liabilities',
    questions : homeEquityTurboQuestionsByName([FieldNames.realEstateLiabilities]),
    showIf    : [(values) => hasEnabledCreditReportOnTurbo(values)],
  },
  {
    id        : HOME_EQUITY_TURBO_REO_PRIMARY_QUESTIONS,
    title     : 'In addition to your Primary Home, how many other homes do you own?',
    questions : homeEquityTurboQuestionsByName([
      FieldNames.liabilityCombinedNumberOtherHomes,
      FieldNames.liabilityCombinedYearTaxes,
      FieldNames.liabilityCombinedYearInsure,
      FieldNames.liabilityCombinedYearAssociate,
    ]),
    showIf: [(values) => isPrimaryHome(values) && hasEnabledCreditReportOnTurbo(values)],
    submitAction: handleTurboHomeEquityGetFinalRates,
  },
  {
    id        : HOME_EQUITY_TURBO_REO_SECONDARY_QUESTIONS,
    questions : homeEquityTurboQuestionsByName([
      FieldNames.isOwningOtherHomes,
      FieldNames.liabilityCombinedNumberOtherHomes,
      FieldNames.liabilityCombinedYearTaxes,
      FieldNames.liabilityCombinedYearInsure,
      FieldNames.liabilityCombinedYearAssociate,
    ]),
    showIf: [(values) => !isPrimaryHome(values) && hasEnabledCreditReportOnTurbo(values)],
    submitAction: handleTurboHomeEquityGetFinalRates,
  },
  {
    id       : HOME_EQUITY_TURBO_ADDITIONAL_PROPERTIES,
    questions: homeEquityTurboQuestionsByName([
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
    ]),
    showIf: [(values) => !hasEnabledCreditReportOnTurbo(values)],
    submitAction: handleGoToEmployment,
  },
  {
    id           : HOME_EQUITY_TURBO_AUTOMATIC_VOIE,
    paragraph    : FormParagraphs.automaticVoie,
    questions    : homeEquityTurboQuestionsByName([FieldNames.canVerifyOfIncomeEmployment]),
    title        : 'If you are a W2 employee, would you like to automatically receive verification of your income and employment?',
  },
  {
    id            : HOME_EQUITY_TURBO_COLLECT_SSN,
    questions     : [homeEquityTurboQuestionById(HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION)],
    showIf        : [canVerifyOfIncomeEmployment],
    submitIf      : [canVerifyOfIncomeEmployment],
    submitAction  : ajaxGetFinancialVerificationUrl,
    agreement     : <AuthorizeSsnFullPull />,
  },
  {
    id                : HOME_EQUITY_TURBO_VOIE_START_CONNECT,
    questions         : homeEquityTurboQuestionsByName([FieldNames.isFinancialVerificationComplete]),
    showIf            : [canVerifyOfIncomeEmployment],
    hideBackButton    : true,
    hideForwardButton : true,
  },
  {
    id             : HOME_EQUITY_TURBO_VOIE_REPORTS,
    questions      : homeEquityTurboQuestionsByName([FieldNames.isFinancialVerificationComplete]),
    showIf         : [canVerifyOfIncomeEmployment],
    hideBackButton : true,
  },
  {
    id        : HOME_EQUITY_TURBO_EMPLOYMENT_TYPE,
    questions : homeEquityTurboQuestionsByName([FieldNames.employmentType]),
    showIf: [isManualIncomeAndEmploymentRequired],
  },
  {
    id       : HOME_EQUITY_TURBO_EMPLOYEE_INFO,
    title    : 'Tell us about your income and employment',
    questions: homeEquityTurboQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && (isEmployee(values) || isSelfEmployed(values))],
  },
  {
    id       : HOME_EQUITY_TURBO_RETIRED_INFO,
    questions: homeEquityTurboQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && (isRetired(values) || isEmploymentOther(values))],
  },
  {
    id       : HOME_EQUITY_TURBO_COBORROWER_EMPLOYMENT_TYPE,
    questions: homeEquityTurboQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && hasCoBorrower(values)],
  },
  {
    id       : HOME_EQUITY_TURBO_COBORROWER_EMPLOYEE_INFO,
    title    : 'Tell us about your co-borrower\'s income and employment',
    questions: homeEquityTurboQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerRetirementIncome,
    ]),
    showIf: [
      (values) => isManualIncomeAndEmploymentRequired(values) &&
      (isCoBorrowerEmployee(values) ||
        isCoBorrowerEmploymentOther(values) ||
        isCoBorrowerRetired(values) ||
        isCoBorrowerSelfEmployed(values)),
    ],
  },
  {
    id           : HOME_EQUITY_TURBO_BORROWER_DECLARATION,
    title        : 'Borrower Questions',
    questions    : getTurboDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : handleHomeEquityTurboFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : HOME_EQUITY_TURBO_COBORROWER_DECLARATION,
    title        : 'Co-Borrower Questions',
    questions    : getTurboDeclarationQuestions(BorrowerType.COBORROWER),
    showIf       : [hasCoBorrower],
    submitIf     : [isExcludingSsnQuestions],
    submitAction : handleHomeEquityTurboFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : HOME_EQUITY_TURBO_COLLECT_SSN_FINAL,
    questions    : [homeEquityTurboQuestionById(HOME_EQUITY_TURBO_COLLECT_SSN_QUESTION_FINAL)],
    submitAction : handleHomeEquityTurboFormSubmit,
    submitText   : 'Submit',
    agreement    : <AuthorizeSsnFullPull />,
  },
  {
    id              : HOME_EQUITY_TURBO_UPLOAD_DOCUMENTS,
    ifNoQuestions   : UploadDocumentsStep,
    hideProgressBar : true,
  },
  {
    id: HOME_EQUITY_MINIMUM_LOAN_STOP_GATE,
    ifNoQuestions: MinimumLoanAmount,
  },
];
