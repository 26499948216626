import { getAuditMetadata, makeActionCreator } from '../../../util/actions';
import { Type } from '../types';
import {
  OVERDRAFT_PROTECTION_APP_CO_BORROWER_DATA, OVERDRAFT_PROTECTION_APP_FINAL_SECTION,
  OVERDRAFT_PROTECTION_GET_LOAN_AMOUNT_SECTION, SUMMARY_SECTION,
} from '../../../models/sections/constants';
import { routes } from '../../../routes/route-list';
import { push } from 'connected-react-router';
import {
  ajaxFetchConsumerLoanLimits,
  goToSection,
  referToLoanOfficer,
  setMaxLoanAmount,
  setMinLoanAmount,
} from '../actions';
import {
  ConsumerLoanLimitsResponse,
  ConsumerLoanRequestType,
  LoanProgramRatesResponse,
} from '../../../api/rates/types';
import { logger } from 'app/util/logger';
import { GeneralReferralCode } from '@lenderful/domain';
import { AuditUserEventRequest, AuditUserEventType, postAuditUserEvent } from '../../../api/audit-event';
import { Dispatch } from 'redux';
import { FieldNames } from '../../../models/fields/names';
import { fetchConsumerLoanRates } from '../../../api/rates';
import { mapComputedRateValues } from '../../../reducers/rates';

export const ajaxFetchOverdraftProtectionRatesFail    = makeActionCreator(Type.AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_FAIL, 'payload');
export const ajaxFetchOverdraftProtectionRatesSuccess = makeActionCreator(Type.AJAX_FETCH_OVERDRAFT_PROTECTION_RATES_SUCCESS, 'payload');

export function handleOverdraftProtectionRateSelect() {
  return (dispatch) => {
    dispatch(push(`${routes.overdraftProtection}#${OVERDRAFT_PROTECTION_APP_CO_BORROWER_DATA}`));
  };
}

export function handleOverdraftProtectionFetchLimits(payload) {
  return (dispatch) => {
    return Promise.resolve(dispatch(ajaxFetchConsumerLoanLimits(payload, ConsumerLoanRequestType.OVERDRAFT_PROTECTION)))
      .then((response: ConsumerLoanLimitsResponse) => {
        dispatch(setMaxLoanAmount(response.maxLoanAmount));
        dispatch(setMinLoanAmount(response.minLoanAmount));
        return dispatch(goToSection(OVERDRAFT_PROTECTION_GET_LOAN_AMOUNT_SECTION));
      });
  };
}

export function handleOverdraftProtectionFetchRates(payload) {
  return (dispatch) => {
    return Promise.resolve(dispatch(ajaxFetchOverdraftProtectionRates(payload)))
      .then((programs: LoanProgramRatesResponse) => {
        if (!programs || programs.length === 0) {
          // handle 200 response with no rates
          logger.warn('handleOverdraftProtectionFetchRates: No qualified rates returned', programs);
          return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
        }
        return dispatch(goToSection(SUMMARY_SECTION));
      });
  };
}

export function overdraftProtectionRecordAuditEventAndRedirect(payload) {
  return async (dispatch) => {
    const request: AuditUserEventRequest = {
      auditEvent: {
        email      : payload.email,
        firstName  : payload.firstName,
        middleName : payload.middleName,
        lastName   : payload.lastName,
        eventMeta  : JSON.stringify(getAuditMetadata('Continue')),
        eventType  : AuditUserEventType.USER_ACCEPTS_PREQUAL_READ,
      },
    };
    await postAuditUserEvent(request);
    dispatch(push(`${routes.overdraftProtection}#${OVERDRAFT_PROTECTION_APP_FINAL_SECTION}`));
  };
}

export function ajaxFetchOverdraftProtectionRates(formData) {
  return (dispatch: Dispatch) => {
    const request = {
      fico             : formData[FieldNames.creditScore],
      loanAmount       : formData[FieldNames.loanAmount],
      creditReportId   : formData[FieldNames.creditReportId],
      type             : ConsumerLoanRequestType.OVERDRAFT_PROTECTION,
      zipCode          : formData[FieldNames.propertyZip],
    };
    dispatch({ type: Type.AJAX_FETCH_OVERDRAFT_PROTECTION_RATES, request });
    return fetchConsumerLoanRates(request).then(
      (response) => {
        logger.debug('fetchConsumerLoanRates response: ', response);
        response.forEach((program: any) => {
          mapComputedRateValues(program, false, formData[FieldNames.loanPurpose]);
        });
        dispatch(ajaxFetchOverdraftProtectionRatesSuccess(response));
        return response;
      },
      (error) => {
        dispatch(ajaxFetchOverdraftProtectionRatesFail(error));
        throw error;
      },
    );
  };
}
