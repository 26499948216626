import React from 'react';
import { Grid, Typography, withStyles, createStyles, WithStyles } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { MaximumLoanExplanation } from 'app/components/MaximumLoanExplanation';
import { TileBox } from 'app/components/TileBox';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FieldNames } from 'app/models/fields/names';
import { formValueSelector, getFormValues } from 'redux-form';
import { goToSection, getNextSection, setMaxLoanAmount } from 'app/actions/form/actions';
import { getFormName } from 'app/routes/helpers';

const styles = (theme) =>
  createStyles({
    displayLabel: {
      fontSize    : 18,
      fontWeight  : 500,
      marginBottom: '10px',
    },
    displayDesc: {
      marginBottom: '1.75rem',
    },
    displayContainer: {
      margin    : '0 auto',
      maxWidth  : '635px',
      textAlign : 'center',
      padding: '25px 15px',
      [theme.breakpoints.down('xs')]: {
        padding: '15px',
      },
    },
  });

type DispatchProps = typeof mapDispatchToProps;
type MappedProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  nextSectionId?     : any;
  initialValues?     : any;
  isValidLoanAmount? : boolean;
};

type Props = OwnProps & DispatchProps & MappedProps & WithStyles<typeof styles>;

let BorrowSummaryComponent = (props: Props) => {
  const {
    goToSection,
    setMaxLoanAmount,
    getNextSection,
    nextSectionId,
    maxLoanAmount,
    classes,
    isValidLoanAmount,
    minLoanAmount,
  } = props;

  const { t }         = useTranslation();

  const clickHandler = async () => {
    await Promise.all([setMaxLoanAmount(maxLoanAmount)]);
    nextSectionId ? goToSection(nextSectionId) : getNextSection();
  };

  return (
    <Grid container classes={{ container: 'summaryContainer' }}>
      <Grid item xs={12}>
        <TileBox
          color        = 'primary'
          title        = {t('rateDisplay.title', { defaultValue: 'Fixed Home Equity Loan / HELOC' })}
          tileBoxProps = {{
            className: classes.displayContainer,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.displayLabel}>
                {t('rateDisplay.subtitle', { defaultValue: 'Your maximum loan amount:' })}
              </Typography>
              <Typography
                variant = 'h2'
                classes = {{
                  root: 'largeRateDisplay',
                }}
              >
                {t('rateDisplay.maxLoanAmount', {
                  defaultValue: '{{ maxLoanAmount, currency.0 }}',
                  maxLoanAmount,
                })}
              </Typography>
              <Typography className={classes.displayDesc}>

                { isValidLoanAmount === undefined || isValidLoanAmount ?
                  t('rateDisplay.bodyText', {
                    defaultValue:
                    'Click continue below to view your rates and monthly payment options for this loan.',
                  }) :
                  <Typography color='error' variant='body1' classes={ { body1: 'submitError' } }>
                    { t('isValidLoanAmount.errorMessage', { defaultValue: `Your recalculation is below the minimum loan amount of $${ minLoanAmount }` }) }
                  </Typography>
                }
              </Typography>
              <Button
                color   = 'primary'
                disabled = {isValidLoanAmount !== undefined ? !isValidLoanAmount : false}
                size    = 'large'
                variant = 'contained'
                classes = {{ root: 'mobileContained' }}
                onClick = {clickHandler}
              >
                {t('button.continue', { defaultValue: 'Continue' })}
              </Button>
              <MaximumLoanExplanation
                helocPropertyUse = {props.helocPropertyUse}
                maxLtvPercent    = {props.maxLtvPercent} />
            </Grid>
          </Grid>
        </TileBox>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  const formName = getFormName(state.router.location.pathname);
  const selector = formValueSelector(formName);

  return {
    values               : getFormValues(formName)(state),
    maxLtvPercent        : selector(state, FieldNames.maxLtvPercent),
    helocPropertyUse     : selector(state, FieldNames.propertyUsage),
    maxLoanAmount        : selector(state, FieldNames.maxLoanAmount),
    minLoanAmount        : selector(state, FieldNames.minLoanAmount),
  };
};

const mapDispatchToProps = {
  goToSection,
  setMaxLoanAmount,
  getNextSection,
};

export const BorrowSummary = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BorrowSummaryComponent));
