import { VariantIconKey } from 'app/containers/NotificationContainer';
import { RootState } from 'app/store/types';
import { Type } from 'app/actions/form/types';
import { createSelector } from 'reselect';

type NotificationsState = Readonly<{
  messages: NotificationMessage[];
}>

export interface NotificationMessage {
  type     : VariantIconKey;
  timestamp: Date;
  /**
   * The i18nKey to be used for this notification's message.
   * Note: The full key that is passed to the i18n t function is computed.
   * See examples below
   *
   * @example
   * i18nKey: 'default' // would result in 'notifications:${type}.default'
   *
   * @example
   * type: 'error'
   * i18nKey: 'locked' // would result in 'notifications:error.locked'
   */
  i18nKey: string;
  /**
   * The text to be passed to the i18next t function as the defaultValue
   */
  defaultText: string;
  /**
   * A flag to flip to true after a user has seen the message
   */
  hasShown?: boolean;
  /**
   * When set to true, `null` is set to the `autoHideDuration` property on
   * the snackbar notification. This prevents the notification from disappearing
   * until the user closes it. Useful for timeout related messages.
   */
  disableAutoHide?: boolean;
}

const initialState: NotificationsState = {
  messages: [],
};

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const notificationsReducer = (state = initialState, action): NotificationsState => {
  switch (action.type) {
    case Type.PUSH_NOTIFICATION:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case Type.UPDATE_NOTIFICATION:
      /* Separate index from props to be updated on message */
      const { index, ...payload } = action.payload;
      return {
        ...state,
        messages: state.messages.map((message, idx) => {
          return idx === index ? { ...message, ...payload } : message;
        }),
      };
    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

export const getNotifications = (state: RootState) => state.notifications;
export const getMessages = (state: RootState) => state.notifications.messages;

/**
 * Finds the details on the first message without the `hasShown` flag set to true.
 * The `hasShown` flag is updated when a notification is dismissed.
 */
export const getNewMessageDetails = createSelector(getMessages, (messages = []) => {
  const newMessageIdx = messages.findIndex((message) => !message.hasShown);
  if (newMessageIdx === -1) {
    return {
      hasNewMessage: false,
      messageIdx   : null,
      message      : null,
    };
  } else {
    return {
      hasNewMessage: true,
      messageIdx   : newMessageIdx,
      message      : messages[newMessageIdx],
    };
  }
});
