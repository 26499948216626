import React from 'react';
import { connect } from 'react-redux';
import * as FormActions from 'app/actions/form/actions';
import { Loader } from 'app/components/Loader';
import { RouteComponentProps } from 'react-router-dom';
import { ReduxActions } from 'app/actions';
import { logger } from 'app/util/logger';

export type ResumeType = 'partial' | 'prequal' | 'prequal-renew';
export interface ResumeParams {
  uuid: string;
  hash: string;
  type: ResumeType;
}

type Props = ReduxActions & RouteComponentProps<ResumeParams, {}>;

class ResumeSessionComponent extends React.Component<Props, any> {
  componentDidMount() {
    const { history, ajaxGetPartialApp, ajaxGetPrequalLetter } = this.props;
    const { uuid, hash, type } = this.props.match.params;
    if (!uuid || !hash) {
      logger.warn('Missing uuid or hash, navigating back');
      history.goBack();
    }
    switch (type) {
      /* Navigate user to edit prequal letter component */
      case 'prequal': ajaxGetPrequalLetter({ uuid, hash, renew: false }); break;
      /* Navigate user to auto prequal renew */
      case 'prequal-renew': ajaxGetPrequalLetter({ uuid, hash, renew: true }); break;
      /* Otherwise dispatch get partial app (User will be redirected to the route from the response) */
      case 'partial':
      default: ajaxGetPartialApp({ uuid, hash }, true);
    }
  }

  render() {
    return <Loader />;
  }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = FormActions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResumeSessionComponent);
