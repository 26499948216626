import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { colors } from '@material-ui/core';
import { BarChartLabel } from 'app/components/Charts/BarChartLabel';

interface Props<T> {
  rates     : T[];
  fillColor : string;
  dataKey   : keyof T;
}

class LoanBarChartComponent<T extends object> extends React.Component<Props<T>, any> {

  // Fixes issue with timing on chart animation and labels
  // https://github.com/recharts/recharts/issues/846
  shouldComponentUpdate(nextProps: Props<T>) {
    const dataKey = this.props.dataKey;
    if (this.props.rates.length !== nextProps.rates.length) {
      return true; // arrays not parallel
    }
    for (let i = 0; i < this.props.rates.length; i++) {
      if (this.props.rates[i][dataKey] !== nextProps.rates[i][dataKey]) {
        return true; // element differs in parallel array
      }
    }
    return false;
  }

  render() {
    const { rates, fillColor, dataKey } = this.props;

    /* Fix for IE */
    const responsiveWidth = { overflow: 'hidden' };

    return (
      <div style={responsiveWidth}>
        <ResponsiveContainer width='100%' aspect={8.0 / 3.5}>
          <BarChart width={300} height={150} data={rates}>
            <XAxis
              dataKey  = 'label'
              stroke   = {colors.grey[300]}
              tick     = {{ fill: colors.grey[800], fontSize: 14 }}
              tickLine = {false}
              tickSize = {20}
            />
            <YAxis domain={[0, 'dataMax + 50']} hide={true} />
            <Bar
              dataKey    = {dataKey as string}
              maxBarSize = {40}
              fill       = {fillColor}
              label      = {<BarChartLabel />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export const LoanBarChart = LoanBarChartComponent;
