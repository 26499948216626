import React from 'react';
import { Field } from 'redux-form';
import { required, noopValidate, isValidYear } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

export const Year = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      component    = {renderInputBase}
      label        = {props.label}
      fieldPopover = {props.fieldPopover}
      popover      = {props.popover}
      maxLength    = {4}
      name         = {name}
      type         = 'text'
      validate     = {[isRequired, isValidYear]}
    />
  );
};
