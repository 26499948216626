import React from 'react';
import { createStyles, Grid, Typography, withStyles } from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { useTranslation } from 'react-i18next';
import { CreditGraph } from 'app/components/CreditGraph/CreditGraph';

const styles = (theme) => createStyles({
  tipsList: {
    marginTop: '10px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '30px',
    },
  },
});

export const CreditReportResult = withStyles(styles)(({ ...props }: any) => {
  const { classes, values } = props;
  const { t }               = useTranslation();
  const creditScore         = values[FieldNames.creditScore];

  return (
    <Grid container={false}>
      <Grid item>
        <Typography variant="body2" paragraph={true}>
          {t('creditReportResult.paragraph1', {
            defaultValue:
              'Please see your current credit score which helps us determine eligibility for mortgage programs and rates.',
          })}
        </Typography>
        <CreditGraph creditScore={creditScore} />
        <Typography variant="body1">
          {t('creditReportResult.list.title', { defaultValue: 'Tips to maintain your score:' })}
        </Typography>
        <ul className={classes.tipsList}>
          <li>
            <Typography variant="body2">
              {t('creditReportResult.list.item1', {
                defaultValue:
                  'Don\'t increase credit use or close accounts until your loan is funded',
              })}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {t('creditReportResult.list.item2', {
                defaultValue:
                  'Don\'t open new lines of credit, this will impact your score for 12-18 months',
              })}
            </Typography>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
});
