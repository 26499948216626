import React from 'react';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { localizeLabel, localizeOptions } from 'app/i18n/helpers';
import { Name } from '../../FormFields/Name';
import { Select } from '../../FormFields/Select';
import { FormOption } from '../../../models/options/options';
import { TextInput } from '../../FormFields/TextInput';
import { SwitchToggle } from '../../FormFields/SwitchToggle';
import { RadioField } from '../../FormFields/RadioField';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import { Address } from '../../FormFields/Address';
import { ZipCode } from '../../FormFields/ZipCode';
import { State } from '../../FormFields/State';
import { CurrencyAllowZero } from '../../FormFields/CurrencyAllowZero';
import { Currency } from '../../FormFields/Currency';
import { IntegerAllowZero } from '../../FormFields/IntegerAllowZero';
import { SingleFutureDate } from '../../FormFields/SingleFutureDate';
import { SinglePastDate } from '../../FormFields/SinglePastDate';
import { SingleDate } from '../../FormFields/SingleDate';
import { Phone } from '../../FormFields/Phone';
import { Year } from '../../FormFields/Year';
import { Email } from '../../FormFields/Email';

export interface DynamicComponentInput {
  fieldName     : FieldNames;
  isOptional    : boolean;
  label         : string;
  options?      : FormOption<any>[];
  type          : DynamicFormFieldArrayType;
  validator?    : { type: string, param: string }
}

export interface DynamicFormFieldArray {
  addButtonText : string;
  initialValue  : {};
  inputs        : DynamicComponentInput[];
  allowEmpty?   : boolean;
}

export interface DynamicFormFieldDependency {
  initialValue  : {};
  inputs        : DynamicComponentInput[];
}

export const renderDynamicComponent = ({ ...props }) => {
  const { change, classes, dynamicProps, fields = [], name, t } = props;
  const inputs: DynamicComponentInput[] = dynamicProps ? dynamicProps.inputs : [];
  const percentValidator = { type: 'isPercentage', param: '' };

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={ `${ field }-div` } style={ { paddingBottom: 20 } }>

          {
            inputs.length > 0 && inputs.map(element => {
              switch (element.type) {
                case DynamicFormFieldArrayType.ADDRESS:
                  return <Address
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    isOptional = { element.isOptional }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.CURRENCY:
                  return <Currency
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.CURRENCY_ALLOW_ZERO:
                  return <CurrencyAllowZero
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.DATE_FUTURE:
                  return <SingleFutureDate
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.DATE_PAST:
                  return <SinglePastDate
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.DATE_SINGLE:
                  return <SingleDate
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.EMAIL:
                  return <Email
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    name       = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                  />;
                case DynamicFormFieldArrayType.INTEGER_ALLOW_ZERO:
                  return <IntegerAllowZero
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.NAME:
                  return <Name
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.PERCENT:
                  return <TextInput
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                    validator={ percentValidator }
                  />;
                case DynamicFormFieldArrayType.PHONE:
                  return <Phone
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.RADIO_BUTTON:
                  return <div className={classes.fieldWrapper}>
                    <RadioField
                      isOptional = { element.isOptional }
                      key        = { `${ field }.${ element.fieldName }` }
                      name       = { `${ field }.${ element.fieldName }` }
                      options    = { localizeOptions(name, t, element.options) }
                      onChange   = { () => change(`${field}.${ element.fieldName }`, '') }
                    />
                  </div>;
                case DynamicFormFieldArrayType.SELECT:
                  return <Select
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                    options    = { element.options }
                  />;
                case DynamicFormFieldArrayType.STATE:
                  return <State
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.TEXT_INPUT:
                  return <TextInput
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                    validator  = { element.validator }
                  />;
                case DynamicFormFieldArrayType.YEAR:
                  return <Year
                    isOptional = { element.isOptional }
                    key        = { `${ field }.${ element.fieldName }` }
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    name       = { `${ field }.${ element.fieldName }` }
                  />;
                case DynamicFormFieldArrayType.Y_N_SWITCH_TOGGLE:
                  return <div key={`${ index }-div-${ element.fieldName }`} style={ { maxWidth: '60%' } }>
                    <SwitchToggle
                      isOptional = { element.isOptional }
                      key        = { `${ field }.${ element.fieldName }` }
                      label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                      name       = { `${ field }.${ element.fieldName }`}
                    />
                  </div>;
                case DynamicFormFieldArrayType.ZIP_CODE:
                  return <ZipCode
                    label      = { localizeLabel(`${ name }.${ element.fieldName }`, t, element.label) }
                    key   = { `${ field }.${ element.fieldName }` }
                    name  = { `${ field }.${ element.fieldName }` }
                  />;
              }
            })

          }
          { ( fields.length > 1 || dynamicProps.allowEmpty ) && (
            <Button
              disableRipple
              color='primary'
              classes={ {
                root: 'link',
                text: 'remove',
              } }
              onClick={ () => fields.remove(index) }
            >
              { t(`question.${ name }.button.remove`, { defaultValue: 'Remove' }) }
            </Button>
          ) }
        </div>
      );
    });

  return (
    <div>
      { renderFields() }
      { dynamicProps.addButtonText && (
        <Button
          color    = "primary"
          disabled = { props.meta ? props.meta.invalid : false}
          variant  = "outlined"
          classes  = {{ root: 'add' }}
          onClick  = {() => fields.push(dynamicProps.initialValue)}
        >
          {t(`question.${name}.button.add`, { defaultValue: `+ Add ${ dynamicProps.addButtonText }`  })}
        </Button>
      ) }
    </div>
  );
};
