import { client } from 'app/api/_client';
import { I18NextXhrBackend } from 'i18next-xhr-backend';

/**
 * Gets the resource bundle containing all translations.
 * A callback is used to integrate with the XHR adapter
 * for i18next.
 *
 * @param {string} url
 * @param {I18NextXhrBackend.BackendOptions} options
 * @param {*} callback
 */
export const getResourceBundle = (
  url: string,
  options: I18NextXhrBackend.BackendOptions,
  callback: any,
) => {
  client
    .get(url)
    .then((response) => {
      callback(response.data, { status: response.status });
    })
    .catch((error) => {
      callback(null, { status: error.status || '500' });
    });
};

/**
 * Posts a translation key to the backend for adding to the database.
 *
 * @remarks
 * When a translation key is missing, the API is hit with the key name
 * and the default value (if present) passed to the translation function
 *
 * @param {string} url
 * @param {I18NextXhrBackend.BackendOptions} options
 * @param {*} callback
 * @param {(object | string)} data
 */
export const postMissingKey = (
  url: string,
  options: I18NextXhrBackend.BackendOptions,
  callback: any,
  data: object | string,
) => {
  client
    .post(url, data)
    .then((response) => {
      callback(response.data, { status: response.status });
    })
    .catch((error) => {
      callback(null, { status: error.status || '500' });
    });
};
