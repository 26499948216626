import { ActionCreator } from 'typesafe-actions';

// Generates an action creator
// See: https://redux.js.org/recipes/reducing-boilerplate
export const makeActionCreator = <T extends string>(type: T, ...argNames): ActionCreator<T> => {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};

export type AuditMetadata = {
  page?      : string;
  element?   : string;
  timestamp? : Date;
}

/**
 * This method generates the audit metadata passed in to verify that the user
 * has clicked on a specific element/page at the current time
 *
 * @param {string} element
 * @returns {AuditMetadata}
 */
export const getAuditMetadata = (element: string): AuditMetadata => {
  return { page: window.location.href, element, timestamp: new Date() };
};

/**
 * This method is used as as simple way to "wait" when prefixed with `await`.
 * It is used in actions to help facilitate polling.
 *
 * @param {number} ms milliseconds to wait
 * @returns {Promise<void>}
 */
export const timeout = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));
