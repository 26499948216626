import React from 'react';
import {
  withStyles,
  createStyles,
  Typography,
  Grid,
  Collapse,
  Paper,
  Theme,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { FormName } from 'app/models/options/enums';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes/route-list';
import { Button } from 'app/components/Button';
import { PrequalSummaryFields } from 'app/components/PrequalSummary/PrequalSummaryFields';
import { isSchedulerEnabled } from 'app/reducers/app-config';
import { GoToClosingCostButton } from 'app/components/ClosingCost/GoToClosingCostButton';

const styles = (theme: Theme) => createStyles({
  dialogBlock: {
    background: theme.palette.secondary.main,
    padding   : '1.25rem 1rem',
  },
  buttonsWrapper: {
    marginTop: '1rem',
  },
});

const PrequalSummaryComponent: any = withStyles(styles)(({ ...props }: any) => {
  const { classes, schedulerEnabled, t } = props;
  const [isVisible, setIsVisible]          = React.useState(true);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={0} classes={{ root: 'formWrapper' }}>
          <Typography variant="h6" classes={{ h6: 'withTopSection noMarginBottom' }}>
            {t('prequalSummary.subtitle', { defaultValue: 'Your Pre-Qualification Summary' })}
          </Typography>
          <PrequalSummaryFields values={props.values} paragraphText={null} />
          <GoToClosingCostButton />
        </Paper>
      </Grid>
      {schedulerEnabled && (
        <Grid item xs={12}>
          <Collapse in={isVisible}>
            <Grid container className={classes.dialogBlock}>
              <Grid item xs={12}>
                <Typography variant="body2" align="center" classes={{ root: 'white' }}>
                  {t('prequalSummary.loanOfficer.question', { defaultValue: 'Would you like to schedule an appointment with a Loan Officer?' })}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      size    = "small"
                      variant = "outlined"
                      classes = {{
                        root     : 'roundedButton',
                        outlined : 'white accentColor',
                        sizeSmall: 'roundedSmall buttonSpacing',
                      }}
                      onClick = {isVisible ? () => setIsVisible(false) : () => setIsVisible(true)}
                    >
                      {t('prequalSummary.loanOfficerShareYN.No', { defaultValue: 'No' })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size    = "small"
                      variant = "contained"
                      classes = {{
                        root     : 'roundedButton',
                        contained: 'white accentColorText',
                        sizeSmall: 'roundedSmall buttonSpacing',
                      }}
                      component = {Link}
                      to        = {routes.scheduleAppointment}
                    >
                      {t('prequalSummary.loanOfficerShareYN.Yes', { defaultValue: 'Yes' })}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )}
    </Grid>
  );
});

const mapStateToProps = (state, ownProps) => {
  return {
    rate : state.rates[0],
    values          : getFormValues(FormName.AUTOPREQUAL)(state),
    schedulerEnabled: isSchedulerEnabled(state),
    ...ownProps,
  };
};

export const PrequalSummary = connect(
  mapStateToProps,
  null,
)(withTranslation()(PrequalSummaryComponent));
