import React from 'react';
import { Field } from 'redux-form';
import { required, isEmail, noopValidate, VALIDATORS } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

declare global {
  interface Window {
    /* Fullstory sits on window.FS */
    FS: any;
  }
}

/**
 * Sends the user email to full story to associate with
 * the session
 *
 * @param {React.ChangeEvent<HTMLInputElement>} e
 * @returns {void}
 */
const setFullStoryEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
  if (window.FS) {
    window.FS.setUserVars({ email: e.target.value });
  }
};

export const Email = ({ ...props }) => {
  const { name, fieldProps, isOptional } = props;
  const isRequired =  isOptional ? noopValidate : required;

  /* The validator property comes from the question object */
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;

  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      component    = {renderInputBase}
      customProps  = {props.customProps}
      inputClasses = {props.inputClasses}
      isModal      = {props.isModal}
      label        = {props.label}
      labelClasses = {props.labelClasses}
      maxLength    = {50}
      name         = {name}
      onChange     = {setFullStoryEmail}
      type         = 'email'
      validate     = {[isRequired, isEmail, conditionalValidator]}
      {...fieldProps}
    />
  );
};
