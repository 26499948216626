import React from 'react';
import { withRouter } from 'react-router';

class ScrollToTop extends React.Component<any, {}> {
  public componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
