import React from 'react';
import {
  withStyles,
  createStyles,
  Typography,
  Grid,
  colors,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

const styles = (theme) => createStyles({
  wrapper: {
    background: theme.palette.secondary.main,
  },
  icon: {
    border      : `1px solid ${colors.common.white}`,
    borderRadius: '100%',
    color       : colors.common.white,
    display     : 'block',
    fontSize    : 40,
    margin      : '0 auto .75rem',
    padding     : '2px',
  },
});

export const PrequalSummaryHeader = withStyles(styles)(({ ...props }: any) => {
  const { classes } = props;
  const { t }       = useTranslation();
  return (
    <Grid
      alignItems = "center"
      classes    = {{ container: 'formWrapper topSectionPadding' }}
      className  = {classes.wrapper}
      container
      justifyContent = "center"
    >
      <Grid item xs={12}>
        <Check className={classes.icon} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant = "h4"
          align   = "center"
          classes = {{ root: 'white', h4: 'headerTitle' }}
        >
          {t('prequalSummary.header.title', { defaultValue: 'Awesome!' })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" align="center" classes={{ root: 'white' }}>
          {t('prequalSummary.paragraph1', {
            defaultValue:
              'Your Pre-Qualification letter has been sent',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
});
