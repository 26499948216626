import React from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, withStyles, createStyles, colors, WithStyles } from '@material-ui/core';
import { getNextSection } from 'app/actions/form/actions';
import { modalAddressValidationClose, setLivingAddress } from 'app/actions/form/address-validation/actions';
import { Modal } from 'app/components/Modals/Templates/Modal';
import { Button } from 'app/components/Button';
import { withTranslation, Trans, WithTranslation } from 'react-i18next';
import { AddressValidationResponse } from 'app/api/validate-address';

const styles = (theme) =>
  createStyles({
    infoWrapper: {
      marginBottom: '1rem',
      padding     : '0 2rem',
      [theme.breakpoints.down('xs')]: {
        padding: '0 1rem',
      },
    },
    addressWrapper: {
      margin: '1rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: '.5rem 0',
        margin: 0,
      },
    },
    buttonWrapper: {
      marginTop: '2rem',
      display  : 'flex',
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem',
      },
    },
    addressOptionWrapper: {
      borderRight: `1px solid ${colors.grey[400]}`,
      padding    : '1rem 2rem',
      width      : '100%',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
      },
    },
    enteredAddressOptionWrapper: {
      paddingRight: '4rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        paddingRight: '1rem',
      },
    },
    suggestAddressOptionWrapper: {
      paddingLeft: '4rem',
      border     : 'none',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '1rem',
      },
    },
    bottomMargin: {
      marginBottom: '1rem',
    },
    fullWidth: {
      width: '100%',
    },
  });

type DispatchProps = typeof mapDispatchToProps;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps & WithStyles<typeof styles> & WithTranslation;

class ModalAddressValidationComponent extends React.Component<Props, any> {

  handleClick = (address: AddressValidationResponse) => {
    return Promise.all([
      this.props.modalAddressValidationClose(),
      this.props.setLivingAddress(address),
    ]).then(() => {
      this.props.getNextSection();
    });
  };

  render() {
    const { classes, isOpen, suggestedAddress, enteredAddress, modalAddressValidationClose, t } = this.props;

    const renderAddressDetails = (address: AddressValidationResponse) => {
      const { state, zip, street, street2, city } = address;
      return address && (
        <div className={classes.addressWrapper}>
          <Typography variant='body2'>
            {street} <br />
            {street2} { street2 && <br/> }
            {city}, {state} <br />
            {zip}
          </Typography>
        </div>
      );
    };

    return isOpen ? (
      <Modal openModal={isOpen} closeModal={modalAddressValidationClose}>
        <Grid container>
          <Grid item sm={12}>
            <div className={classes.infoWrapper}>
              <Typography variant='body2' className={classes.bottomMargin}>
                <strong>
                  {t('modal.addressValidation.title', { defaultValue: 'Confirm your address' })}
                </strong>
              </Typography>
              <Typography variant='body2'>
                <Trans i18nKey='modal.addressValidation.paragraph1'>
                  <strong>The address you entered may be incorrect or incomplete.</strong> Please review our recommendation and choose from the options below:
                </Trans>
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} sm={12} className={`${classes.addressOptionWrapper} ${classes.enteredAddressOptionWrapper}`}>
            <Typography variant='body2'>
              <strong>
                {t('modal.addressValidation.subtitle.entered', { defaultValue: 'You Entered:' })}
              </strong>
            </Typography>
            {renderAddressDetails(enteredAddress)}
            <div className={classes.buttonWrapper}>
              <Button
                color     = 'primary'
                size      = 'large'
                variant   = 'outlined'
                onClick   = {() => this.handleClick(enteredAddress)}
                className = { classes.fullWidth }
              >
                {t('modal.addressValidation.button.entered', { defaultValue: 'Keep This Address' })}
              </Button>
            </div>
          </Grid>
          <Grid item md={6} sm={12} className={`${classes.addressOptionWrapper} ${classes.suggestAddressOptionWrapper}`}>
            <Typography variant='body2'>
              <strong>
                {t('modal.addressValidation.subtitle.suggested', { defaultValue: 'We Suggest:' })}
              </strong>
            </Typography>
            {renderAddressDetails(suggestedAddress)}
            <div className={classes.buttonWrapper}>
              <Button
                color     = 'primary'
                size      = 'large'
                variant   = 'contained'
                onClick   = {() => this.handleClick(suggestedAddress)}
                className = { classes.fullWidth }
              >
                {t('modal.addressValidation.button.suggested', { defaultValue: 'Use This Address' })}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state: any) => {
  const { addressValidation } = state;
  return ({
    isOpen          : addressValidation.isModalOpen,
    suggestedAddress: addressValidation.suggestedAddress,
    enteredAddress  : addressValidation.enteredAddress,
    borrowerType    : addressValidation.borrowerType,
  });
};

const mapDispatchToProps = {
  modalAddressValidationClose,
  setLivingAddress,
  getNextSection,
};

export const ModalAddressValidation =  withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ModalAddressValidationComponent)));
