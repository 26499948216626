import React from 'react';
import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { ovLoanQuestionsByName, ovLoanQuestionById } from '../questions/ov-loan';
import {
  OV_LOAN_APP_COBORROWER_PERSONAL_INFORMATION,
  OV_LOAN_APP_CURRENT_HOUSING_SITUATION,
  OV_LOAN_APP_HOUSING_OWNER_INFORMATION,
  OV_LOAN_APP_HOUSING_FREE_INFORMATION,
  OV_LOAN_APP_HOUSING_RENTER_INFORMATION,
  OV_LOAN_APP_PERSONAL_INFORMATION,
  OV_LOAN_GET_LOAN_INFORMATION,
  OV_LOAN_APP_COBORROWER_HOUSING_INFORMATION,
  OV_LOAN_APP_BORROWER_INCOME_SOURCE,
  OV_LOAN_APP_BORROWER_INCOME_EMPLOYED,
  OV_LOAN_APP_BORROWER_INCOME_RETIRED,
  OV_LOAN_APP_BORROWER_INCOME_OTHER,
  OV_LOAN_APP_COBORROWER_INCOME_SOURCE,
  OV_LOAN_APP_COBORROWER_INCOME_EMPLOYED,
  OV_LOAN_APP_COBORROWER_INCOME_RETIRED,
  OV_LOAN_APP_COBORROWER_INCOME_OTHER,
  OV_LOAN_APP_CITIZENSHIP_AND_AUTHORIZATION,
  OV_LOAN_GET_VEHICLE_TYPE,
  AUTO_LOAN_FINAL_PAGE_QUESTION,
  OV_LOAN_APP_FINAL_SECTION,
} from './constants';
import {
  hasCoBorrower,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoEmployee,
  isCoSelfEmployed,
  isEmployee,
  isEmploymentOther,
  isHomeowner,
  isRentFree,
  isRenting,
  isRetired,
  isSelfEmployed,
  coBorrowerHasDifferentAddress,
} from '../fields/conditionals';
import { AuthorizeCreditReport } from '../../components/FormAgreements/AuthorizeCreditReport';
import { ajaxValidateCoBorrowerResidence, ajaxValidateBorrowerResidence } from 'app/actions/form/address-validation/actions';
import { ajaxPostCreditReportForOtherVehicleLoan } from 'app/actions/form/credit-report/actions';

export const getOVLoanSectionById = (id: number): Section => {
  return OV_LOAN_SECTIONS.find((section) => section.id === id) as Section;
};

const OV_LOAN_SECTIONS: Section[] = [
  {
    id              : OV_LOAN_GET_VEHICLE_TYPE,
    questions       : ovLoanQuestionsByName([
      FieldNames.vehicleType,
      FieldNames.vehicle,
    ]),
    hideBackButton  : true,
    hideProgressBar : true,
  },
  {
    id       : OV_LOAN_GET_LOAN_INFORMATION,
    questions: ovLoanQuestionsByName([
      FieldNames.purchasePrice,
      FieldNames.tradeInValue,
      FieldNames.downDollar,
      FieldNames.loanTermDesired,
    ]),
  },
  {
    id       : OV_LOAN_APP_PERSONAL_INFORMATION,
    title    : 'Enter your personal information',
    questions: ovLoanQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
  },
  {
    id        : OV_LOAN_APP_COBORROWER_PERSONAL_INFORMATION,
    questions : ovLoanQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerDOB,
    ]),
  },
  {
    id        : OV_LOAN_APP_CURRENT_HOUSING_SITUATION,
    title     : 'What is your current housing situation?',
    questions : ovLoanQuestionsByName([FieldNames.livingRentOrOwn]),
  },
  {
    id        : OV_LOAN_APP_HOUSING_OWNER_INFORMATION,
    showIf    : [isHomeowner],
    title     : 'Provide your homeowner information',
    questions : ovLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : OV_LOAN_APP_HOUSING_RENTER_INFORMATION,
    showIf     : [isRenting],
    title      : 'Provide your renter information',
    questions  : ovLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : OV_LOAN_APP_HOUSING_FREE_INFORMATION,
    showIf     : [isRentFree],
    title      : 'Provide your housing information',
    questions  : ovLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id        : OV_LOAN_APP_COBORROWER_HOUSING_INFORMATION,
    showIf    : [hasCoBorrower],
    title     : 'Co-borrower at same address as borrower?',
    questions : ovLoanQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
    ]),
    submitAction : (formValues) =>
      coBorrowerHasDifferentAddress(formValues) ? ajaxValidateCoBorrowerResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id        : OV_LOAN_APP_BORROWER_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : ovLoanQuestionsByName([FieldNames.employmentType]),
  },
  {
    id         : OV_LOAN_APP_BORROWER_INCOME_EMPLOYED,
    showIf     : [isEmployee, isSelfEmployed],
    title      : 'Tell us about your income and employment',
    questions  : ovLoanQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.incomeOnDeclineYN,
    ]),
  },
  {
    id        : OV_LOAN_APP_BORROWER_INCOME_RETIRED,
    showIf    : [isRetired],
    questions : ovLoanQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.incomeOnDeclineYN,
    ]),
  },
  {
    id        : OV_LOAN_APP_BORROWER_INCOME_OTHER,
    showIf    : [isEmploymentOther],
    questions : ovLoanQuestionsByName([
      FieldNames.otherIncome,
      FieldNames.incomeOnDeclineYN,
    ]),
  },
  {
    id        : OV_LOAN_APP_COBORROWER_INCOME_SOURCE,
    showIf    : [hasCoBorrower],
    title     : `What is your co-borrower's income source?`,
    questions : ovLoanQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
  },
  {
    id        : OV_LOAN_APP_COBORROWER_INCOME_EMPLOYED,
    showIf    : [isCoEmployee, isCoSelfEmployed],
    title     : `Tell us about your co-borrower's income and employment`,
    questions : ovLoanQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
  },
  {
    id        : OV_LOAN_APP_COBORROWER_INCOME_RETIRED,
    showIf    : [isCoBorrowerRetired],
    questions : ovLoanQuestionsByName([
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
  },
  {
    id        : OV_LOAN_APP_COBORROWER_INCOME_OTHER,
    showIf    : [isCoBorrowerEmploymentOther],
    questions : ovLoanQuestionsByName([
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
  },
  {
    id        : OV_LOAN_APP_CITIZENSHIP_AND_AUTHORIZATION,
    title     : 'A question and your authorization',
    agreement : <AuthorizeCreditReport />,
    questions : ovLoanQuestionsByName([
      `${FieldNames.decBorrower}.${FieldNames.hasCitizenship}`,
      `${FieldNames.decCoBorrower}.${FieldNames.hasCitizenship}`,
    ]),
    submitAction : ajaxPostCreditReportForOtherVehicleLoan,
  },
  {
    id                : OV_LOAN_APP_FINAL_SECTION,
    questions         : [ovLoanQuestionById(AUTO_LOAN_FINAL_PAGE_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
];
