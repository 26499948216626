import React from 'react';
import {
  Grid,
  Modal as MaterialModal,
  IconButton,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

export class Modal extends React.Component<any, any> {
  render() {
    const { children, modalProps, paperClasses, openModal, closeModal } = this.props;
    return (
      <MaterialModal open={openModal} onClose={closeModal} { ...modalProps }>
        <Grid container classes={{ container: 'modalContainer' }} className={paperClasses}>
          <Grid container classes={{ container: 'closeWrapper' }}>
            <Grid item classes={{ item: 'closeContainer' }}>
              <IconButton
                onClick = {closeModal}
                classes = {{ root: 'buttonClose' }}
              >
                <Close classes={{ root: 'closeModal' }} />
              </IconButton>
            </Grid>
          </Grid>
          {children}
        </Grid>
      </MaterialModal>
    );
  }
}
