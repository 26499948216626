import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import * as React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import {
  commercialCollateralInitialValue,
} from '../../../util/initial-values';
import {
  NAME_SUFFIX_OPTIONS,
  STATE_OPTIONS,
} from '../../../models/options/options';

const DynamicOwnerInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.ownerFirstName,
    isOptional : false,
    label      : 'First Name',
    type       : DynamicFormFieldArrayType.NAME,
  },
  {
    fieldName  : FieldNames.ownerMiddleName,
    isOptional : true,
    label      : 'Middle Name',
    type       : DynamicFormFieldArrayType.NAME,
  },
  {
    fieldName  : FieldNames.ownerLastName,
    isOptional : false,
    label      : 'Last Name',
    type       : DynamicFormFieldArrayType.NAME,
  },
  {
    fieldName  : FieldNames.ownerSuffixName,
    isOptional : true,
    label      : 'Suffix',
    options    : NAME_SUFFIX_OPTIONS,
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.ownerStreet,
    isOptional : false,
    label      : 'Street Address',
    type       : DynamicFormFieldArrayType.ADDRESS,
  },
  {
    fieldName  : FieldNames.ownerStreet2,
    isOptional : true,
    label      : 'Street Address 2',
    type       : DynamicFormFieldArrayType.ADDRESS,
  },
  {
    fieldName  : FieldNames.ownerCity,
    isOptional : false,
    label      : 'City',
    type       : DynamicFormFieldArrayType.ADDRESS,
  },
  {
    fieldName  : FieldNames.ownerState,
    isOptional : false,
    label      : 'State',
    options    : STATE_OPTIONS,
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.ownerZip,
    isOptional : false,
    label      : 'Zip',
    type       : DynamicFormFieldArrayType.ZIP_CODE,
  },
  {
    fieldName  : FieldNames.ownerPhone,
    isOptional : false,
    label      : 'Phone',
    type       : DynamicFormFieldArrayType.PHONE,
  },
  {
    fieldName  : FieldNames.ownerEmail,
    isOptional : false,
    label      : 'Email',
    type       : DynamicFormFieldArrayType.EMAIL,
  },
  {
    fieldName  : FieldNames.ownerPersonalNetWorth,
    isOptional : true,
    label      : 'Personal Net Worth',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.ownerAnnualPersonalIncome,
    isOptional : true,
    label      : 'Annual Personal Income',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.ownerPercentage,
    isOptional : false,
    label      : '% Ownership',
    type       : DynamicFormFieldArrayType.PERCENT,
  },
  {
    fieldName  : FieldNames.ownerSinceYear,
    isOptional : true,
    label      : 'Owner Since (year)',
    type       : DynamicFormFieldArrayType.YEAR,
  },
  {
    fieldName  : FieldNames.ownerIsApplicant,
    isOptional : false,
    label      : 'Is this owner the applicant?',
    type       : DynamicFormFieldArrayType.Y_N_SWITCH_TOGGLE,
  },
];

const dynamicOwnerProps: DynamicFormFieldArray = {
  addButtonText : 'Owner',
  initialValue  : commercialCollateralInitialValue,
  inputs        : DynamicOwnerInputs,
};

export const DynamicOwnerComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: dynamicOwnerProps, name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
