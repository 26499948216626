import React from 'react';
import { FieldNames } from 'app/models/fields/names';
import { Section } from 'app/models/types';
import { personalLoanQuestionsByName, personalLoanQuestionById } from 'app/models/questions/personal-loan';
import * as FormActions from 'app/actions/form/actions';
import {
  PERSONAL_LOAN_APP_BORROWER_DATA,
  PERSONAL_LOAN_GET_LOAN_AMOUNT_SECTION,
  PERSONAL_LOAN_CREDIT_REPORT_RESULT_QUESTION,
  PERSONAL_LOAN_GET_FICO_AND_ZIP_SECTION,
  PERSONAL_LOAN_APP_FINAL_SECTION,
  PERSONAL_LOAN_FINAL_QUESTION,
  PERSONAL_LOAN_APP_CO_BORROWER_DATA,
  PERSONAL_LOAN_APP_HOUSING,
  PERSONAL_LOAN_APP_HOMEOWNER,
  PERSONAL_LOAN_APP_RENTING,
  PERSONAL_LOAN_APP_RENT_FREE,
  PERSONAL_LOAN_APP_CO_BORROWER_HOUSING,
  PERSONAL_LOAN_APP_INCOME_SOURCE,
  PERSONAL_LOAN_APP_EMPLOYED,
  PERSONAL_LOAN_APP_CONGRATS,
  PERSONAL_LOAN_APP_CREDIT_AUTHORIZATION,
  PERSONAL_LOAN_APP_CO_BORROWER_EMPLOYEMENT_OTHER,
  PERSONAL_LOAN_APP_CO_BORROWER_RETIRED,
  PERSONAL_LOAN_APP_CO_BORROWER_EMPLOYED,
  PERSONAL_LOAN_APP_CO_BORROWER_INCOME_SOURCE,
  PERSONAL_LOAN_APP_EMPLOYEMENT_OTHER,
  PERSONAL_LOAN_APP_RETIRED,
} from 'app/models/sections/constants';
import {
  isHomeowner,
  isRentFree,
  isRenting,
  hasCoBorrower,
  isEmployee,
  isSelfEmployed,
  isRetired,
  isEmploymentOther,
  isCoEmployee,
  isCoSelfEmployed,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
  LoanOfficerCondition,
  noCitizenship,
  coBorrowerHasDifferentAddress,
  isBorrowerUnderage,
  hasLowSelfReportedFico,
} from 'app/models/fields/conditionals';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import { ajaxValidateBorrowerResidence, ajaxValidateCoBorrowerResidence } from 'app/actions/form/address-validation/actions';
import { ajaxPostCreditReportForPersonalLoan } from 'app/actions/form/credit-report/actions';

export const getPersonalLoanById = (id: number): Section => {
  return PERSONAL_LOAN_SECTIONS.find((section) => section.id === id) as Section;
};

const PERSONAL_LOAN_SECTIONS: Section[] = [
  {
    id              : PERSONAL_LOAN_GET_FICO_AND_ZIP_SECTION,
    hideBackButton  : true,
    hideProgressBar : true,
    title           : 'What are the rates and payments?',
    paragraph       : <span>Answer these short questions and we'll provide you with options for a personal loan.</span>,
    questions       : personalLoanQuestionsByName([
      FieldNames.creditScore,
      FieldNames.hasUniquePersonalLoanProgram,
      FieldNames.propertyZip,
    ]),
  },
  {
    id        : PERSONAL_LOAN_APP_BORROWER_DATA,
    title     : 'Enter your personal information',
    questions : personalLoanQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.phone,
      FieldNames.email,
      FieldNames.dateOfBirth,
    ]),
    submitIf     : [
      (formValues) => !isBorrowerUnderage(formValues) &&
        !hasLowSelfReportedFico(formValues),
    ],
    submitAction : FormActions.handlePersonalLoanFetchLimits,
    submitText   : 'Next',
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.hasLowSelfReportedFico,
        LoanOfficerCondition.hasNoCurrentCheckingAccount,
        LoanOfficerCondition.isBorrowerUnderage,
      ],
    },
  },
  {
    id              : PERSONAL_LOAN_GET_LOAN_AMOUNT_SECTION,
    hideProgressBar : true,
    title           : 'What are the rates and payments?',
    paragraph       : <span>Answer these short questions and we'll provide you with options for a personal loan.</span>,
    questions       : personalLoanQuestionsByName([
      FieldNames.loanAmount,
    ]),
    submitAction : FormActions.handlePersonalLoanFetchRates,
    submitText   : 'Submit',
  },
  {
    id        : PERSONAL_LOAN_APP_CO_BORROWER_DATA,
    questions : personalLoanQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerDOB,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerUnderage],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_HOUSING,
    title     : 'What is your current housing situation?',
    questions : personalLoanQuestionsByName([FieldNames.livingRentOrOwn]),
  },
  {
    id        : PERSONAL_LOAN_APP_HOMEOWNER,
    showIf    : [isHomeowner],
    title     : 'Provide your homeowner information',
    questions : personalLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : PERSONAL_LOAN_APP_RENTING,
    showIf     : [isRenting],
    title      : 'Provide your renter information',
    questions  : personalLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id         : PERSONAL_LOAN_APP_RENT_FREE,
    showIf     : [isRentFree],
    title      : 'Provide your housing information',
    questions  : personalLoanQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
    ]),
    submitAction : ajaxValidateBorrowerResidence,
  },
  {
    id        : PERSONAL_LOAN_APP_CO_BORROWER_HOUSING,
    showIf    : [hasCoBorrower],
    title     : 'Co-borrower at same address as borrower?',
    questions : personalLoanQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
    ]),
    submitAction : (formValues) =>
      coBorrowerHasDifferentAddress(formValues) ? ajaxValidateCoBorrowerResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id        : PERSONAL_LOAN_APP_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : personalLoanQuestionsByName([FieldNames.employmentType]),
  },
  {
    id         : PERSONAL_LOAN_APP_EMPLOYED,
    showIf     : [isEmployee, isSelfEmployed],
    title      : 'Tell us about your income and employment',
    questions  : personalLoanQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.incomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerShortJobDuration,
        LoanOfficerCondition.isBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_RETIRED,
    showIf    : [isRetired],
    questions : personalLoanQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.incomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_EMPLOYEMENT_OTHER,
    showIf    : [isEmploymentOther],
    questions : personalLoanQuestionsByName([
      FieldNames.otherIncome,
      FieldNames.incomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_CO_BORROWER_INCOME_SOURCE,
    showIf    : [hasCoBorrower],
    title     : `What is your co-borrower's income source?`,
    questions : personalLoanQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
  },
  {
    id        : PERSONAL_LOAN_APP_CO_BORROWER_EMPLOYED,
    showIf    : [isCoEmployee, isCoSelfEmployed],
    title     : `Tell us about your co-borrower's income and employment`,
    questions : personalLoanQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_CO_BORROWER_RETIRED,
    showIf    : [isCoBorrowerRetired],
    questions : personalLoanQuestionsByName([
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_CO_BORROWER_EMPLOYEMENT_OTHER,
    showIf    : [isCoBorrowerEmploymentOther],
    questions : personalLoanQuestionsByName([
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerIncomeOnDeclineYN,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isCoBorrowerIncomeOnDecline,
      ],
    },
  },
  {
    id        : PERSONAL_LOAN_APP_CREDIT_AUTHORIZATION,
    title     : 'A question and your authorization',
    agreement : <AuthorizeCreditReport />,
    questions : personalLoanQuestionsByName([
      `${FieldNames.decBorrower}.${FieldNames.hasCitizenship}`,
      `${FieldNames.decCoBorrower}.${FieldNames.hasCitizenship}`,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerNotCitizen,
        LoanOfficerCondition.isCoBorrowerNotCitizen,
      ],
    },
    submitIf    : [(formValues) =>
      !noCitizenship(FieldNames.decBorrower)(formValues) &&
      !noCitizenship(FieldNames.decCoBorrower)(formValues),
    ],
    submitAction : ajaxPostCreditReportForPersonalLoan,
  },
  {
    id                : PERSONAL_LOAN_APP_CONGRATS,
    title             : 'Congratulations! You have been pre-qualified!',
    questions         : [personalLoanQuestionById(PERSONAL_LOAN_CREDIT_REPORT_RESULT_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
  {
    id                : PERSONAL_LOAN_APP_FINAL_SECTION,
    questions         : [personalLoanQuestionById(PERSONAL_LOAN_FINAL_QUESTION)],
    hideBackButton    : true,
    hideForwardButton : true,
    hideProgressBar   : true,
  },
];
