import { Type } from 'app/actions/form/property/types';
import { getNextSection } from 'app/actions/form/actions';
import { getPropertyInfo } from 'app/api/valuation/property';
import { FieldNames, getPropertyFieldNameGroup, PropertyFieldNameType } from 'app/models/fields/names';
import { change, clearFields } from 'redux-form';
import { logger } from 'app/util/logger';
import { getFormName } from 'app/routes/helpers';
import { FormName } from "../../../models/options/enums";
import { Type as FormType }  from 'app/actions/form/types';
import { isPrimaryHome } from 'app/models/fields/conditionals';

/**
 * This method updates the toPropertyType values with the fromPropertyType values
 *
 * @param formData
 * @param {FormName} formName
 * @param {PropertyFieldNameType} fromPropertyType
 * @param {PropertyFieldNameType} toPropertyType
 * @returns
 */
export const updateAddressByPropertyType = (formData, formName: FormName, fromPropertyType: PropertyFieldNameType, toPropertyType: PropertyFieldNameType) => {
  return (dispatch) => {
    const fromPropertyFieldNameGroup = getPropertyFieldNameGroup(fromPropertyType);
    const toPropertyFieldNameGroup = getPropertyFieldNameGroup(toPropertyType);
    dispatch(change(formName, toPropertyFieldNameGroup.street, formData[fromPropertyFieldNameGroup.street]));
    dispatch(change(formName, toPropertyFieldNameGroup.street2, formData[fromPropertyFieldNameGroup.street2]));
    dispatch(change(formName, toPropertyFieldNameGroup.city, formData[fromPropertyFieldNameGroup.city]));
    dispatch(change(formName, toPropertyFieldNameGroup.state, formData[fromPropertyFieldNameGroup.state]));
    dispatch(change(formName, toPropertyFieldNameGroup.zip, formData[fromPropertyFieldNameGroup.zip]));
    return dispatch(getNextSection());
  };
};

/**
 * This method makes a call to get the valuation and taxes on the primary borrower's
 * residence (living property).  It uses these taxes and valuation as limits for
 * referrals to keep the user from setting them too high or low.
 *
 * @param formData
 * @returns
 */
export const handleGetBorrowerResidenceInfo = (formData) => {
  const fullStreetAddress = (formData[FieldNames.livingPropertyStreet2]) ?
    `${formData[FieldNames.livingPropertyStreet]} ${formData[FieldNames.livingPropertyStreet2]}` :
    formData[FieldNames.livingPropertyStreet];

  const request = {
    city          : formData[FieldNames.livingPropertyCity],
    streetAddress : fullStreetAddress,
    state         : formData[FieldNames.livingPropertyState],
    zipCode       : formData[FieldNames.livingPropertyZip],
  };

  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    dispatch({ type: Type.AJAX_GET_PROPERTY_INFO, request });

    return getPropertyInfo(request)
      .then((response) => {
        const { taxes, valuation } = response;

        // pre-populate form with taxes and valuation
        if (taxes && taxes > 0) {
          dispatch(change(formName, FieldNames.livingYearTaxes, taxes));
        }
        if (valuation && valuation.value && valuation.value > 0) {
          const { value, low, high } = valuation;
          dispatch(change(formName, FieldNames.homeValue, value));
          dispatch(change(formName, FieldNames.avmHomeValue, value));
          dispatch(change(formName, FieldNames.avmHomeValueLowLimit, low));
          dispatch(change(formName, FieldNames.avmHomeValueHighLimit, high));
        }

        return dispatch(getNextSection());
      })
      .catch((error) => {
        logger.warn('Property info not available', { error, request });

        // reset tax and valuation limits
        dispatch(clearFields(formName, false, false, FieldNames.avmHomeValue));
        dispatch(clearFields(formName, false, false, FieldNames.avmHomeValueLowLimit));
        dispatch(clearFields(formName, false, false, FieldNames.avmHomeValueHighLimit));

        return dispatch(getNextSection());
      });
  };
};

/**
 * This method makes a call to get the valuation and taxes on the collateral property.
 * It sets the taxes in the form and uses the valuation numbers to perform a referral
 * if the user has their home value set too high or low.
 *
 * @param formData
 * @returns
 */
export const handleGetSubjectPropertyInfo = (formData) => {
  const fullStreetAddress = (formData[FieldNames.propertyStreet2]) ?
    `${formData[FieldNames.propertyStreet]} ${formData[FieldNames.propertyStreet2]}` :
    formData[FieldNames.propertyStreet];

  const request = {
    city          : formData[FieldNames.propertyCity],
    streetAddress : fullStreetAddress,
    state         : formData[FieldNames.propertyState],
    zipCode       : formData[FieldNames.propertyZip],
  };

  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    dispatch({ type: Type.AJAX_GET_PROPERTY_INFO, request });

    return getPropertyInfo(request)
      .then((response) => {
        const { avmReportId, county, taxes, valuation } = response;

        // pre-populate form with taxes
        if (taxes && taxes > 0) {
          if (isPrimaryHome(formData)) {
            dispatch(change(formName, FieldNames.yearTaxes, taxes));
          } else {
            dispatch(change(formName, FieldNames.livingYearTaxes, taxes));
          }
        }

        // set low/high range for valuation to constrain home value
        if (valuation?.value && valuation.value > 0) {
          const { low, high, value } = valuation;
          dispatch(change(formName, FieldNames.avmHomeValueLowLimit, low));
          dispatch(change(formName, FieldNames.avmHomeValueHighLimit, high));
          dispatch(change(formName, FieldNames.avmHomeValue, value));
        }

        if(avmReportId && avmReportId > 0) {
          dispatch({ type: FormType.HANDLE_AVM_RESPONSE, avmReportId });
        }
        if(county?.length > 0) {
          dispatch(change(formName, FieldNames.propertyCounty, county));
        }

        return dispatch(getNextSection());
      })
      .catch((error) => {
        logger.warn('Property info not available', { error, request });
        return dispatch(getNextSection());
      });
  };
};
