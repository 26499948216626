import React from 'react';
import {
  createStyles,
  colors,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { Popover } from 'app/components/Popover';
import css from './Question.module.css';
import ErrorIcon from '@material-ui/icons/Error';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Question as QuestionType } from 'app/models/types';
import { filterOptions } from 'app/util/question-helpers';

const styles = (theme) => createStyles({
  titleHeader: {
    borderTop : `1px solid ${colors.grey[300]}`,
    paddingTop: '40px',
    width     : '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      paddingTop  : '30px',
    },
  },
});

interface Styled extends WithStyles<typeof styles>, WithTranslation {
  [key: string]: any;
}

type Props = Styled & QuestionType;

const QuestionComponent = ({ classes, t, ...props }: Props) => {
  const Component   = props.component;
  const titleClass  = props.borderTitle ? classes.titleHeader : '';
  const label       = props.label && !props.hideLabel ? props.label : '';

  return (
    <React.Fragment>
      { props.title && (
        <div className={`${css.titleContainer} ${titleClass}`}>
          <Typography variant="h6" className={css.questionTitle}>
            {props.title}
          </Typography>
          {props.popover && !props.isSummaryPage && !props.fieldPopover ? (
            <Popover text={props.popover} />
          ) : null}
        </div>
      )}
      {props.disclaimer && (
        <Typography
          variant = "body2"
          classes = {{ body2: 'questionDisclaimer' }}
        >
          <ErrorIcon style={{ fontSize: 16, marginTop: 2, marginRight: 5 }} /> {props.disclaimer}
        </Typography>
      )}
      {props.paragraph && (
        <Typography variant="body2" gutterBottom={true}>
          <Trans i18nKey={`question.${props.name}.paragraph`}>
            {props.paragraph}
          </Trans>
        </Typography>
      )}
      <Component
        /* ...props provides these */
        // name={name}
        // component={component}
        // getNextSection={getNextSection}
        // change={change}
        // blur={blur}
        {...props}
        label   = {label}
        options = {filterOptions(props.options, props.excludedOptions)}
      />
    </React.Fragment>
  );
};

export const Question = withStyles(styles)(withTranslation()(QuestionComponent));
