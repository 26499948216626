import React from 'react';
import { logger } from 'app/util/logger';
import { Grid } from '@material-ui/core';

export class CookiesDisabledWarning extends React.Component<any, any> {
  componentDidMount() {
    logger.warn('You will need to enable cookies to use this site.');
  }

  render() {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '95vh' }}
      >
        <Grid item xs={6}>
          <h1>You will need to enable cookies to use this site.</h1>
        </Grid>
      </Grid>
    );
  }
}
