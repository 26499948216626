import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import { realEstateInitialValue } from '../../../util/initial-values';

const realEstateInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.financialStatementLocation,
    isOptional : false,
    label      : 'Location',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
  {
    fieldName  : FieldNames.financialStatementMarketValue,
    isOptional : false,
    label      : 'Market Value',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.financialStatementMortgageBalance,
    isOptional : false,
    label      : 'Mortgage Balance',
    type       : DynamicFormFieldArrayType.CURRENCY_ALLOW_ZERO,
  },
  {
    fieldName  : FieldNames.financialStatementPurchaseDate,
    isOptional : true,
    label      : 'Purchase Date',
    type       : DynamicFormFieldArrayType.DATE_PAST,
  },
  {
    fieldName  : FieldNames.financialStatementDescription,
    isOptional : true,
    label      : 'Description',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
  {
    fieldName  : FieldNames.financialStatementJointAccount,
    isOptional : false,
    label      : 'Is this held jointly',
    type       : DynamicFormFieldArrayType.Y_N_SWITCH_TOGGLE,
  },
];

const realEstateProps: DynamicFormFieldArray = {
  addButtonText : 'Account',
  initialValue  : realEstateInitialValue,
  inputs        : realEstateInputs,
};

export const RealEstateFFAComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: realEstateProps, name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
