import React from 'react';
import {
  colors,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
} from '@material-ui/core';

const styles = (theme) => createStyles({
  brick: {
    background  : colors.common.white,
    borderRadius: '3px',
    boxShadow   : `0 2px 5px ${colors.grey[400]}`,
    minHeight   : '65px',
    height      : '100%;',
    padding     : '.5rem .5rem .5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${colors.grey[300]}`,
      borderRadius: 0,
      boxShadow   : 'none',
      height      : 'auto',
      margin      : 0,
      padding     : '1rem 0',
    },
  },
  brickChild: {
    alignSelf: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  [key: string]: any;
}

export class BrickComponent extends React.Component<Props, any> {
  readonly state = {
    isEdit: false,
  };

  toggleIsEdit(isEdit: boolean) {
    this.setState({
      isEdit,
    });
  }

  render() {
    const { classes, children } = this.props;
    return (
      <Grid container className={classes.brick}>
        <Grid item xs={12} className={classes.brickChild}>
          {children}
        </Grid>
      </Grid>
    );
  }
}

export const Brick = withStyles(styles)(BrickComponent);
