import { useEffect, useState } from 'react';

export enum ScriptState {
  IDLE    = 'idle',
  LOADING = 'loading',
  READY   = 'ready',
  ERROR   = 'error',
}

/**
 * This hook loads an external script into the DOM in an async way.  It returns
 * the script state (loading, etc) so the component will know what it is ready for use.
 * It also cleans-up both events and the DOM on unmount.
 *
 * @param {string} url URL of external script to load
 * @returns {StateScript} the loading state of that script
 */
export const useExternalScript = (url: string) => {
  let [state, setState] = useState(url ? ScriptState.LOADING : ScriptState.IDLE);

  useEffect(() => {
    if (!url) {
      setState(ScriptState.IDLE);
      return;
    }

    let script: HTMLScriptElement = document.querySelector(`script[src='${url}']`);

    const handleScript: EventListener = (event: Event) => {
      setState(event.type === 'load' ? ScriptState.READY : ScriptState.ERROR);
    };

    if (!script) {
      script       = document.createElement('script');
      script.type  = 'application/javascript';
      script.src   = url;
      script.async = true;

      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    // clean-up on unmount
    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
      script.remove();
    };
  }, [url]);

  return state;
};
