import { PartialSource } from 'app/models/options/enums';
import { createBaseUrlFromLocation, Endpoints } from 'app/api/helpers';
import { client } from 'app/api/_client';
import { getFormName, sectionFromHash } from 'app/routes/helpers';
import { getProduct, getSlug, PRODUCT } from 'app/util/headers';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { ReferralCode } from '@lenderful/domain';
import moment from 'moment';

interface PartialRequest {
  sectionId       : number;
  path            : string;
  formVersion     : string;
  associateName?  : string;
  loanOfficerId   : number;
  formValues      : any;
  baseUrl         : string;
  source          : keyof typeof PartialSource;
  code?           : ReferralCode;
  slug            : string;
  product         : PRODUCT;
  formName        : string;
}

interface ReferToLoDetails {
  source: keyof typeof PartialSource;
  code  : ReferralCode;
}

export const getReferToLoDetails = (state): ReferToLoDetails => {
  return {
    source: state.partial.source,
    code  : state.referToLo.code,
  };
};

export const mapPartialData = (formData, state): PartialRequest => {
  const loanOfficerId      = getLoanOfficer(state).id;
  const { hash, pathname } = state.router.location;
  const slug               = getSlug();
  const product            = getProduct();
  const baseUrl            = createBaseUrlFromLocation('resume/partial');
  const formName           = getFormName(state.router.location.pathname);

  // Removes featureToggle values from formData
  const toggles = state.config.featureToggles;
  const filteredFormData = { ...formData };
  Object.keys(toggles).forEach(toggle => {
    if (filteredFormData.hasOwnProperty(toggle)) {
      delete filteredFormData[toggle];
    }
  });

  // updates date values to only be the date

  Object.keys(filteredFormData).forEach(key => {
    if(
      moment(filteredFormData[key], moment.ISO_8601, true).isValid() &&
      filteredFormData[key]?.toString().includes('000Z')
    ) {
      filteredFormData[key] = moment(filteredFormData[key]).format( 'YYYY-MM-DD');
    }
  });

  return {
    ...getReferToLoDetails(state),
    formVersion: `${process.env.REACT_APP_VERSION || ''}`,
    path       : pathname,
    sectionId  : sectionFromHash(hash),
    formValues : filteredFormData,
    baseUrl,
    loanOfficerId,
    slug,
    product,
    formName,
  };
};

export const postPartialApp = (body) => {
  return client
    .post(Endpoints.PARTIAL_APPS, body)
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw new Error('No data returned');
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const putPartialApp = (body, hash, uuid) => {
  return client
    .put(`${Endpoints.PARTIAL_APPS}/${uuid}/${hash}`, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPartialApp = (body) => {
  const slug = getSlug();
  return client
    .get(`${Endpoints.PARTIAL_APPS}?uuid=${body.uuid}&hash=${body.hash}&slug=${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
