import {
  postCreditReport,
  SoftCreditPullPayload,
  SoftCreditPullResponse,
} from 'app/api/credit-report';
import { FieldNames } from 'app/models/fields/names';
import { isOverFrontEndRatio } from 'app/models/fields/conditionals';
import { getSlug } from 'app/util/headers';
import { logger } from 'app/util/logger';
import { Type } from 'app/actions/form/credit-report/types';
import { getAuditMetadata, makeActionCreator, timeout } from 'app/util/actions';
import { change, getFormValues } from 'redux-form';
import { getFormName } from 'app/routes/helpers';
import { FormName } from 'app/models/options/enums';
import {
  ajaxFetchAutoLoanRates,
  ajaxFetchPersonalLoanRates,
  getNextSection,
  goToSection,
  handleConsumerApplication,
  referToLoanOfficer,
} from 'app/actions/form/actions';
import { ConsumerLoanRequestType, LoanProgramRatesResponse } from 'app/api/rates/types';
import { ActionCreator } from 'redux';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { paymentToIncomeRatio } from 'app/reducers/app-config';
import { fetchConsumerLoanDebtToIncome } from 'app/api/rates';
import { getBorrowerTotalIncome, getCoBorrowerTotalIncome, getMonthlyLiabilities } from 'app/util/formulas';
import { GeneralReferralCode } from '@lenderful/domain';
import {
  HOME_EQUITY_TURBO_REAL_ESTATE_LIABILITIES,
  HOME_EQUITY_TURBO_REO_PRIMARY_QUESTIONS,
  REFINANCE_TURBO_AUTOMATIC_VOIE,
  REFINANCE_TURBO_REAL_ESTATE_LIABILITIES,
} from 'app/models/sections/constants';
import { ajaxFetchOverdraftProtectionRates } from '../overdraft-protection/actions';

export const ajaxPostCreditReportFail    = makeActionCreator(Type.AJAX_POST_CREDIT_REPORT_FAIL, 'payload');
export const ajaxPostCreditReportSuccess = makeActionCreator(Type.AJAX_POST_CREDIT_REPORT_SUCCESS, 'payload');

/**
 * This method wraps the call to get the credit report.  It is used primary to handle either
 * the success or response by dispatching standard actions.
 *
 * @param dispatch
 * @param {SoftCreditPullPayload} payload
 * @returns {Promise<SoftCreditPullResponse>}
 */
const pollCreditReport = async (dispatch, payload: SoftCreditPullPayload): Promise<SoftCreditPullResponse> => {
  dispatch({ type: Type.POLL_CREDIT_API, payload });
  try {
    logger.debug('Polling for credit report...');
    const pollingResponse = await postCreditReport(payload);
    dispatch(ajaxPostCreditReportSuccess(pollingResponse));
    return pollingResponse;
  } catch (error) {
    dispatch(ajaxPostCreditReportFail(error));
    logger.error('Credit report error when polling', error);
  }
};

/**
 * This method updates both the payload and fields in the form with the response that comes
 * back as the credit report
 *
 * @param dispatch
 * @param payload
 * @param {FormName} formName
 * @param {SoftCreditPullResponse} creditReportResponse
 */
const updateFormWithCreditReport = async (dispatch, payload, formName: FormName, creditReportResponse: SoftCreditPullResponse) => {
  const { creditReportId, realEstateLiabilities } = creditReportResponse;

  // update payload to accurately reflect data pulled from credit report
  payload[FieldNames.creditReportId] = creditReportId;
  payload[FieldNames.realEstateLiabilities] = realEstateLiabilities;

  // update form fields to accurately reflect data pulled from credit report
  Promise.all([
    dispatch(change(formName, FieldNames.creditReportId, creditReportId)),
    dispatch(change(formName, FieldNames.realEstateLiabilities, realEstateLiabilities)),
  ]);
};

/**
 * This method updates both the payload and fields in the form with the passed in rate and payment
 *
 * @param dispatch
 * @param payload
 * @param formName
 * @param rate
 * @param principalAndInterestPayment
 */
const updateRateAndMonthlyPayment = (dispatch, payload, formName: FormName, rate, principalAndInterestPayment) => {
  // update payload to accurately reflect data pulled from updated rates request
  payload[FieldNames.rate] = rate;
  payload[FieldNames.monthlyPayment] = principalAndInterestPayment;

  // update form fields to accurately reflect data pulled from updated rates request
  Promise.all([
    dispatch(change(formName, FieldNames.rate, rate)),
    dispatch(change(formName, FieldNames.monthlyPayment, principalAndInterestPayment)),
  ]);
};

/**
 * This method controls the basic action workflow for all of the credit reports across
 * all the forms.
 *
 * @param payload
 * @param {Map<Function, ReferToLoCode>} referralMap optional referral conditions for this form
 * @param {ActionCreator<any>} successAction action to perform upon successful credit pull
 * @returns
 */
const ajaxPostCreditReport = (payload, successAction: ActionCreator<any>) => {
  const auditMetadata = getAuditMetadata('Next button');
  payload = { ...payload, slug: getSlug(), auditMetadata };

  return async (dispatch, getState) => {
    try {
      const { router } = getState();
      const formName = getFormName(router.location.pathname);

      dispatch({ type: Type.AJAX_POST_CREDIT_REPORT, payload });

      // poll to get credit report
      let response = await pollCreditReport(dispatch, { ...payload, formName });
      while(!response || !response.isComplete) {
        response = await pollCreditReport(dispatch, { ...payload, creditReportId: response.creditReportId, formName });
        await timeout(1000);
      }

      // dispatch change events for form in redux store
      if (response.creditReportId) {
        await updateFormWithCreditReport(dispatch, payload, formName, response);
      }

      // dispatch referral if applicable
      if (response.hasReferral) {
        return dispatch(referToLoanOfficer(GeneralReferralCode.CREDIT_REFERRAL));
      }

      // when no referal perform success action
      return dispatch(successAction(payload));
    } catch (error) {
      dispatch(ajaxPostCreditReportFail(error));
      logger.error('Credit report error on initial post', error);
      return dispatch(referToLoanOfficer(GeneralReferralCode.VENDOR_ERROR_CRS));
    }
  };
};

/**
 * This is the action to perform upon a successful credit report on the auto loan forms.
 *
 * @param payload
 * @returns
 */
const successAutoLoanAction = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();
    const programs: LoanProgramRatesResponse = await dispatch(ajaxFetchAutoLoanRates(payload));

    // update rate and payment based on potential differences in credit score (self reported vs. credit report)
    const { rate, principalAndInterestPayment } = programs.find((program) => program.term === parseInt(payload[FieldNames.loanTerm]));
    updateRateAndMonthlyPayment(dispatch, payload, FormName.AUTOLOAN, rate, principalAndInterestPayment);

    // check frontend ratio with potential new rate/payment
    const frontendRatio = paymentToIncomeRatio(state);
    if (isOverFrontEndRatio(frontendRatio)(payload)) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.OVER_FRONT_END_RATIO));
    }

    // check backend ratio with potential new rate/payment
    const totalIncome        = getBorrowerTotalIncome(payload) + getCoBorrowerTotalIncome(payload);
    const monthlyLiabilities = getMonthlyLiabilities(payload);
    const creditReportId     = payload[FieldNames.creditReportId];
    const type               = ConsumerLoanRequestType.AUTO;
    const formName           = getFormName(state.router.location.pathname);

    const { isOverLimit } = await fetchConsumerLoanDebtToIncome({ creditReportId, formName, totalIncome, monthlyLiabilities, type });
    if (isOverLimit) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.OVER_BACK_END_RATIO));
    }
    const loanOfficer = getLoanOfficer(state);
    return handleConsumerApplication(dispatch, loanOfficer, payload, FormName.AUTOLOAN);
  };
};

/**
 * This is the action to perform upon a successful credit report on the other vehicle loan forms.
 *
 * @param payload
 * @returns
 */
const successOtherVehicleLoanAction = (payload) => {
  return async (dispatch, getState) => {
    const loanOfficer = getLoanOfficer(getState());
    return handleConsumerApplication(dispatch, loanOfficer, payload, FormName.OTHER_VEHICLE);
  };
};

/**
 * This is the action to perform upon a successful credit report on the personal loan forms.
 *
 * @param payload
 * @returns
 */
const successPersonalLoanAction = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();
    const programs: LoanProgramRatesResponse = await dispatch(ajaxFetchPersonalLoanRates(payload));

    // update rate and payment based on potential differences in credit score (self reported vs. credit report)
    const { rate, principalAndInterestPayment } = programs.find((program) => program.term === payload[FieldNames.loanTerm]);
    updateRateAndMonthlyPayment(dispatch, payload, FormName.PERSONALLOAN, rate, principalAndInterestPayment);

    // check frontend ratio with potential new rate/payment
    const frontendRatio = paymentToIncomeRatio(state);
    if (isOverFrontEndRatio(frontendRatio)(payload)) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.OVER_FRONT_END_RATIO));
    }

    // check backend ratio with potential new rate/payment
    const totalIncome        = getBorrowerTotalIncome(payload) + getCoBorrowerTotalIncome(payload);
    const monthlyLiabilities = getMonthlyLiabilities(payload);
    const creditReportId     = payload[FieldNames.creditReportId];
    const type               = ConsumerLoanRequestType.PERSONAL;
    const formName           = getFormName(state.router.location.pathname);

    const { isOverLimit } = await fetchConsumerLoanDebtToIncome({ creditReportId, formName, totalIncome, monthlyLiabilities, type });
    if (isOverLimit) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.OVER_BACK_END_RATIO));
    }
    const loanOfficer = getLoanOfficer(state);
    return handleConsumerApplication(dispatch, loanOfficer, payload, FormName.PERSONALLOAN);
  };
};

/**
 * This is the action to perform upon a successful credit report on the overdraft protection forms.
 *
 * @param payload
 * @returns
 */
const successOverdraftProtectionAction = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();
    const programs: LoanProgramRatesResponse = await dispatch(ajaxFetchOverdraftProtectionRates(payload));

    // update rate and payment based on potential differences in credit score (self reported vs. credit report)
    const { rate, principalAndInterestPayment } = programs.find((program) => program.term === payload[FieldNames.loanTerm]);
    updateRateAndMonthlyPayment(dispatch, payload, FormName.OVERDRAFTPROTECTION, rate, principalAndInterestPayment);

    // check frontend ratio with potential new rate/payment
    const frontendRatio = paymentToIncomeRatio(state);
    if (isOverFrontEndRatio(frontendRatio)(payload)) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.OVER_FRONT_END_RATIO));
    }

    // check backend ratio with potential new rate/payment
    const totalIncome        = getBorrowerTotalIncome(payload) + getCoBorrowerTotalIncome(payload);
    const monthlyLiabilities = getMonthlyLiabilities(payload);
    const creditReportId     = payload[FieldNames.creditReportId];
    const type               = ConsumerLoanRequestType.OVERDRAFT_PROTECTION;
    const formName           = getFormName(state.router.location.pathname);

    const { isOverLimit } = await fetchConsumerLoanDebtToIncome({ creditReportId, formName, totalIncome, monthlyLiabilities, type });
    if (isOverLimit) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.OVER_BACK_END_RATIO));
    }
    const loanOfficer = getLoanOfficer(state);
    return handleConsumerApplication(dispatch, loanOfficer, payload, FormName.OVERDRAFTPROTECTION);
  };
};

/**
 * This is the action to perform upon a successful credit report on the home equity turbo form.
 *
 * @param payload
 * @returns
 */
const successHomeEquityTurboAction = (payload) => {
  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const values = getFormValues(formName)(state);

    const realEstateLiabilities = values[FieldNames.realEstateLiabilities] || [];
    if (realEstateLiabilities.length > 0) {
      return dispatch(goToSection(HOME_EQUITY_TURBO_REAL_ESTATE_LIABILITIES));
    } else {
      return dispatch(goToSection(HOME_EQUITY_TURBO_REO_PRIMARY_QUESTIONS));
    }
  };
};

/**
 * This is a generic action to go to the next section when the credit report
 * is completed.
 *
 * @param payload
 * @returns
 */
const successNextSectionAction = () => {
  return (dispatch) => dispatch(getNextSection());
};

/**
 * This is the action used to pull credit on the home equity turbo forms.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForTurbo(payload) {
  return ajaxPostCreditReport(payload, successHomeEquityTurboAction);
}

/**
 * This is the action used to pull credit on the auto loan forms.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForAutoLoan(payload) {
  return ajaxPostCreditReport(payload, successAutoLoanAction);
}

/**
 * This is the action used to pull credit on the other vehicle loan forms.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForOtherVehicleLoan(payload) {
  return ajaxPostCreditReport(payload, successOtherVehicleLoanAction);
}

/**
 * This is the action used to pull credit on the personal loan forms.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForPersonalLoan(payload) {
  return ajaxPostCreditReport(payload, successPersonalLoanAction);
}

/**
 * This is the action used to pull credit on the overdraft protection form.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForOverdraftProtection(payload) {
  return ajaxPostCreditReport(payload, successOverdraftProtectionAction);
}

/**
 * This is the action used to pull credit on the auto prequal forms.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForAutoPrequal(payload) {
  return ajaxPostCreditReport(payload, successNextSectionAction);
}

/**
 * This is the action to perform upon a successful credit report on the refinance turbo form.
 *
 * @param payload
 * @returns
 */
const successRefinanceTurboAction = (payload) => {
  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const values = getFormValues(formName)(state);

    const realEstateLiabilities = values[FieldNames.realEstateLiabilities] || [];
    if (realEstateLiabilities.length > 0) {
      return dispatch(goToSection(REFINANCE_TURBO_REAL_ESTATE_LIABILITIES));
    } else {
      return dispatch(goToSection(REFINANCE_TURBO_AUTOMATIC_VOIE));
    }
  };
};

/**
 * This is the action used to pull credit on the refinance turbo form.  It delegates
 * to the more generic ajaxPostCreditReport action.
 *
 * @param payload
 * @returns
 */
export function ajaxPostCreditReportForRefinanceTurbo(payload) {
  return ajaxPostCreditReport(payload, successRefinanceTurboAction);
}
