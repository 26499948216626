import * as React from 'react';
import { FormControl, FormHelperText, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useTranslation } from 'react-i18next';
import css from './View.module.css';
import { Button } from 'app/components/Button';
import { RootState } from 'app/store/types';
import { connect } from 'react-redux';
import { InjectedFormProps, getFormValues } from 'redux-form';
import { ReduxActions } from 'app/actions';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';
import { toFormattedSsn } from 'app/util/formatters';
import { toNormalizedSsn } from 'app/util/normalizers';
import { formNameSelector } from 'app/reducers/app-config';
import { ButtonDelay } from 'app/components/ButtonDelay';
import { FieldNames } from '../models/fields/names';

type ConnectProps = ReturnType<typeof mapStateToProps>;
type Props        = ConnectProps & InjectedFormProps & ReduxActions;

const mapStateToProps = (state: RootState) => ({
  values                 : getFormValues(formNameSelector(state))(state),
});

export let ownerSsnRefs = [];

const SmallBusinessCollectSsnComponent = (props: Props) => {
  const { t } = useTranslation();
  const {
    values,
    submitting,
  } = props;

  const [ownerNormalizedSsns, setOwnerNormalizedSsns] = React.useState([]);

  const isValidSsn = (number: string): boolean => {
    const ssnFormat = /\d{9}/; /* 9 digits long */
    const matchesForm = ssnFormat.test(number);
    return (!number || matchesForm) ? true : false;
  };
  const owners = values[FieldNames.commercialOwnerList];

  /**
   * This method formats the value in the backing REF array to be a formatted SSN
   *
   * @param event
   * @param index
   * @returns
   */
  const formatOwnerSsn = (event, index) => ownerSsnRefs[index].value = toFormattedSsn(event.target.value);

  /**
   * This method calls format to make sure the value in the REF is formatted correctly.  It also
   * sets a value in state that contains all the "normalized" SSNs which will do two things:
   *
   * 1. force a re-render of this component since updating the REF does not do that
   * 2. provides an easily accessible array of normlized SSNs by which to validate
   *
   * @param event
   * @param index
  */
  const onChangeOwnerSsn = (event, index) => {
    formatOwnerSsn(event, index);

    const ownerNormalizedSsns = ownerSsnRefs
      .filter(owner => owner !== null)
      .map(owner => toNormalizedSsn(owner.value));

    setOwnerNormalizedSsns(ownerNormalizedSsns);
  };

  const areOwnerSsnsValid = ownerNormalizedSsns.every(ownerNormalizedSsn => isValidSsn(ownerNormalizedSsn));

  return (
    <div style={{ padding: '16px 26px' }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6'>
            Social Security Number
          </Typography>
        </Grid>
      </Grid>

      {
        owners.map((owner, index) => {
          const isOwnerSsnValid = isValidSsn(ownerNormalizedSsns[index]);

          return owner.ownerFirstName && (<Grid container key={ `grid-container-${index}` }>
            <Grid item xs={12} classes={{ item: 'wrapper' }} style={{ paddingTop: '3%' }} key={ `grid-${index}` }>
              <FormControl error={ !isOwnerSsnValid } fullWidth key={ `formcontrol-${index}` }>
                <InputLabel color='primary' htmlFor={`owner-${index}-ssn`} key={ `input-label-${index}` }>
                  {t(`smallbusinesscollectssn.question.owner${index}.label`, {
                    defaultValue:
                      `${owner.ownerFirstName} ${owner.ownerLastName}'s SSN`,
                  })}
                </InputLabel>
                <Input id={`owner-${index}-ssn`}
                  error={ !isOwnerSsnValid }
                  type='text'
                  key={`owner-${index}-ssn`}
                  onChange={(event) => onChangeOwnerSsn(event,index) }
                  inputRef={ref => ownerSsnRefs[index] = ref} />
                { !isOwnerSsnValid && <FormHelperText key={ `error-${index}` }>Invalid social security number</FormHelperText> }
              </FormControl>
            </Grid>
          </Grid>);
        })
      }

      <AuthorizeSsnFullPull />
      <div className={css.navWrapper}>
        <div className={css.pageNav}>
          <Grid container className={css.navContainer}>
            <div className={`${css.navButton} ${css.saveLink}`}></div>
            <div className={css.navButton}>
              <Button
                disableRipple
                size      = 'small'
                className = {css.backButton}
                onClick   = {props.getPreviousSection}
              >
                <ChevronLeft /> {t('global:button.back', { defaultValue: 'Back' })}
              </Button>
            </div>
            <div className={`${css.navButton}`} style={{ marginRight: 16 }}>
              <ButtonDelay
                loading     = {submitting}
                buttonProps = {{
                  color    : 'primary',
                  disabled : !areOwnerSsnsValid || submitting,
                  variant  : 'contained',
                  type     : 'submit',
                }}
              >
                {t('global:button.submit', { defaultValue: 'Submit' })}
              </ButtonDelay>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export const SmallBusinessCollectSsnStep = connect(mapStateToProps)(SmallBusinessCollectSsnComponent);
