import React from 'react';
import { Field } from 'redux-form';
import { required, noopValidate, VALIDATORS } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

export const Address = ({ ...props }) => {
  const { label, name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired = isOptional ? noopValidate : required;

  /* The validator property comes from the question object */
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;

  return (
    <Field
      autoComplete = { mapAutoCompleteName(name) }
      component    = { renderInputBase }
      label        = { label }
      maxLength    = { 50 }
      name         = { name }
      type         = 'text'
      validate     = { [isRequired, conditionalValidator] }
    />
  );
};
