import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  clientName: string;
}

export const AuthorizeCreditReportComponent = ({ t, ...props }: Props) => {
  const { clientName } = props;
  return (
    <>
      <Grid container style = {{ marginTop: 10, marginBottom: 10 }}>
        <Grid item>
          <Typography
            variant = 'body2'
            component = 'span'
            classes = {{ body2: 'small' }}>
            {/* Verbiage from CRS, do not override by client */}
            <Trans i18nKey = 'formAgreement.crsVerbiage'>
              I understand that by clicking on the 'Next' button, I am providing “written instructions” under the Fair Credit Reporting Act to obtain information from my personal credit profile or other information. I am authorizing a soft credit pull to obtain such information solely to prequalify me for credit options. Credit Information accessed for my pre-qualification request may be different from the Credit Information accessed by a credit grantor on a date after the date of my original prequalification request. This does <strong>NOT</strong> impact your credit score but is used to verify your current score and liabilities.
            </Trans>
          </Typography>
        </Grid>
      </Grid>

      <Grid container classes={{ container: 'formTermsWrapper' }}>
        <Grid item classes={{ item: 'termItem' }}>
          <Typography
            variant   = 'body2'
            component = 'span'
            classes   = {{ body2: 'small formTerms' }}
          >
            <Trans i18nKey = 'formAgreement.authorizeCreditReport' values = {{ clientName }}>
              I authorize {{ clientName }} to obtain copies of my credit report. <strong>This will not affect your credit score</strong>.
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  clientName: state.config.clientDisplayName,
  ...ownProps,
});

export const AuthorizeCreditReport = connect(
  mapStateToProps,
  null,
)(withTranslation()(AuthorizeCreditReportComponent));
