import { FieldNames } from 'app/models/fields/names';
import { Section } from 'app/models/types';
import { commercialQuestionsByName } from '../questions/commercial';
import { handleCommercialFormTypeSelect } from '../../actions/form/actions';
import { COMMERCIAL_LOAN_TYPE } from './constants';

export const getCommercialSectionById = (id: number): Section => {
  return COMMERCIAL_SECTIONS.find((section) => section.id === id) as Section;
};

const COMMERCIAL_SECTIONS: Section[] = [
  {
    id             : COMMERCIAL_LOAN_TYPE,
    questions      : commercialQuestionsByName([
      FieldNames.loanType,
    ]),
    hideBackButton : true,
    hideProgressBar: true,
    submitAction : handleCommercialFormTypeSelect,
    submitText : 'Next',
  },
];
