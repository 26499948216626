import React from 'react';
import { Field } from 'redux-form';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { toNumber } from 'app/util/parsers';
import { toNonZeroNumber } from 'app/util/normalizers';
import { noopValidate, required, VALIDATORS } from '../../util/validators';
import { InputAdornment } from '@material-ui/core';
import { commaSeparated } from 'app/util/formatters';

export const IntegerWithSeparators = ({ ...props }) => {
  const { isOptional, name } = props;
  const isRequired =  isOptional ? noopValidate : required;

  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;

  return (
    <Field
      component    = {renderInputBase}
      customProps  = {{
        endAdornment:
          <InputAdornment position="end">{ props.endAdornment ? props.endAdornment : '' }</InputAdornment>,
      }}
      fieldPopover = {props.fieldPopover}
      label        = {props.label}
      name         = {name}
      format       = {commaSeparated}
      normalize    = {toNonZeroNumber}
      parse        = {toNumber}
      popover      = {props.popover}
      type         = "tel"
      validate     = {[isRequired, conditionalValidator]}
    />
  );
};
