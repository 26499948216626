import { RadioField } from 'app/components/FormFields/RadioField';
import { TextInput } from 'app/components/FormFields/TextInput';
import {
  YES_OR_NO_OPTIONS,
  STATE_OPTIONS,
  COMMERCIAL_ENTITY_TYPE_2_OPTIONS,
  SMALL_BUSINESS_PURCHASE_TIMING_OPTIONS,
  COMMERCIAL_COLLATERAL_DESCRIPTION_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { hasDifferentMailingAddress, hasEIDL } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { State } from 'app/components/FormFields/State';
import { Address } from 'app/components/FormFields/Address';
import { Currency } from 'app/components/FormFields/Currency';
import { Name } from 'app/components/FormFields/Name';
import { Select } from 'app/components/FormFields/Select';
import { TextArea } from '../../components/FormFields/TextArea';
import { Integer } from '../../components/FormFields/Integer';
import { CommercialCollateralComponent } from '../../components/FormFieldArrays/Dynamic/CommercialCollateral';
import { DynamicOwnerComponent } from '../../components/FormFieldArrays/Dynamic/DynamicOwner';
import { Phone } from '../../components/FormFields/Phone';
import { CommercialCollateralDescriptionType } from 'app/models/options/enums';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { EconomicInjuryDisasterLoanFFAComponent } from '../../components/FormFieldArrays/Dynamic/EconomicInjuryDisasterLoan';

export const SB_LINE_OF_CREDIT_QUESTIONS: Question[] = [
  {
    id        : 1001,
    component : Currency,
    label     : 'Requested Loan Amount',
    name      : FieldNames.loanAmount,
    validator : {
      type : 'isSmallBusinessLoanAmountValid',
      param: FieldNames.loanAmount,
    },
  },
  {
    id        : 1002,
    component : TextArea,
    label     : 'Intended Use for Loan',
    name      : FieldNames.commercialLoanPurpose,
  },
  {
    id         : 1003,
    component  : CommercialCollateralComponent,
    options    : COMMERCIAL_COLLATERAL_DESCRIPTION_OPTIONS.filter(option => option.value === CommercialCollateralDescriptionType.ABA),
    label      : 'Collateral Description',
    name       : FieldNames.commercialCollateralList,
  },
  {
    id        : 1004,
    component : RadioField,
    label     : 'Purchase Timing',
    name      : FieldNames.commercialLoanTiming,
    options   : SMALL_BUSINESS_PURCHASE_TIMING_OPTIONS,
  },
  {
    id         : 1005,
    component  : Name,
    label      : 'Business Name',
    name       : FieldNames.commercialBusinessName,
  },
  {
    id         : 1006,
    component  : TextInput,
    isOptional : true,
    label      : 'DBA',
    name       : FieldNames.commercialDBA,
  },
  {
    id         : 1007,
    component  : Integer,
    isOptional : true,
    label      : 'TIN#',
    name       : FieldNames.commercialTIN,
  },
  {
    id        : 1008,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.commercialStreet,
    title     : 'Where is the business located?',
  },
  {
    id         : 1009,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.commercialStreet2,
  },
  {
    id        : 1010,
    component : Address,
    label     : 'City',
    name      : FieldNames.commercialCity,
  },
  {
    id        : 1011,
    component : State,
    label     : 'State',
    name      : FieldNames.commercialState,
  },
  {
    id        : 1012,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.commercialZip,
  },
  {
    id        : 1013,
    component : Phone,
    label     : 'Phone',
    name      : FieldNames.commercialPhone,
  },
  {
    id        : 1014,
    component : RadioField,
    name      : FieldNames.commercialSameMailingAddress,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Is the Mailing Address the same?',
  },
  {
    id        : 1015,
    component : Address,
    label     : 'Mailing Street Address',
    name      : FieldNames.commercialMailingStreet,
    showIf    : [hasDifferentMailingAddress],
  },
  {
    id         : 1016,
    component  : Address,
    isOptional : true,
    label      : 'Mailing Street Address 2',
    name       : FieldNames.commercialMailingStreet2,
    showIf    : [hasDifferentMailingAddress],
  },
  {
    id        : 1017,
    component : Address,
    label     : 'Mailing City',
    name      : FieldNames.commercialMailingCity,
    showIf    : [hasDifferentMailingAddress],
  },
  {
    id        : 1018,
    component : State,
    label     : 'Mailing State',
    name      : FieldNames.commercialMailingState,
    showIf    : [hasDifferentMailingAddress],
  },
  {
    id        : 1019,
    component : ZipCode,
    label     : 'Mailing Zip Code',
    name      : FieldNames.commercialMailingZip,
    showIf    : [hasDifferentMailingAddress],
  },
  {
    id         : 1020,
    component  : TextInput,
    isOptional : true,
    label      : 'Business Website',
    name       : FieldNames.commercialWebsite,
  },
  {
    id          : 1021,
    component   : Select,
    label       : 'State of Organization',
    name        : FieldNames.commercialStateOfOrganization,
    options     : STATE_OPTIONS,
  },
  {
    id         : 1022,
    component  : Select,
    label      : 'Entity Type',
    name       : FieldNames.commercialEntityType,
    options    : COMMERCIAL_ENTITY_TYPE_2_OPTIONS,
  },
  {
    id         : 1023,
    component  : DynamicOwnerComponent,
    name       : FieldNames.commercialOwnerList,
  },
  {
    id         : 1024,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Cash and Equivalents',
    name       : FieldNames.commercialBusinessCashAndEquivalents,
  },
  {
    id         : 1025,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Total Assets',
    name       : FieldNames.commercialBusinessTotalAssets,
  },
  {
    id         : 1026,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Total Liabilities',
    name       : FieldNames.commercialBusinessTotalLiabilities,
  },
  {
    id         : 1027,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Net Worth',
    name       : FieldNames.commercialBusinessNetWorth,
  },
  {
    id         : 1028,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Total Sales',
    name       : FieldNames.commercialBusinessTotalSales,
  },
  {
    id         : 1029,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Annual Interest Expense',
    name       : FieldNames.commercialBusinessAnnualInterestExpense,
  },
  {
    id           : 1030,
    component    : CurrencyAllowZero,
    isOptional   : true,
    label        : 'EBITDA',
    fieldPopover : true,
    popover      : 'Earnings before Interest, Taxes, Depreciation and Amortization',
    name         : FieldNames.commercialBusinessEBITA,
  },
  {
    id         : 1031,
    component  : CurrencyAllowZero,
    isOptional : true,
    label      : 'Current Checking Account Balance',
    name       : FieldNames.commercialBusinessCheckingAccountBalance,
  },
  {
    id        : 1032,
    component : RadioField,
    name      : FieldNames.hasEconomicInjuryDisasterLoan,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Does your business have any outstanding business loans, including EIDL loans?',
  },
  {
    id         : 1033,
    component  : EconomicInjuryDisasterLoanFFAComponent,
    name       : FieldNames.economicInjuryDisasterLoans,
    showIf     : [hasEIDL],
  },

];
