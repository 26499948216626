import { hasCoBorrower } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';

/**
 * This validator checks to make sure the borrower SSN is a valid SSN.  An empty
 * SSN is a valid SSN.
 * 
 * @param value 
 * @param formValues 
 * @returns 
 */
export const borrowerSsnValid = (value: string, formValues: any): string | undefined => {
  if(!formValues[FieldNames.isBorrowerSsnValid]) {
    return 'Borrower SSN must be a valid SSN.';
  }
  return undefined;
};

/**
 * This validator checks to make sure the co-borrower SSN is a valid SSN.  It will only
 * perform this check if the form has a co-borrower.  An empty SSN is a valid SSN.
 * 
 * @param value 
 * @param formValues 
 * @returns 
 */
export const coBorrowerSsnValid = (value: string, formValues: any): string | undefined => {
  if (hasCoBorrower(formValues)) {
    if(!formValues[FieldNames.isCoBorrowerSsnValid]) {
      return 'Co-Borrower SSN must be a valid SSN.';
    }
  }
  return undefined;
};

/**
 * This validator checks to make sure the borrower SSN is both valid and not empty.
 * 
 * @param value 
 * @param formValues 
 * @returns 
 */
export const borrowerSsnRequired = (value: string, formValues: any): string | undefined => {
  if(!formValues[FieldNames.isBorrowerSsnValid] || formValues[FieldNames.isBorrowerSsnEmpty]) {
    return 'Borrower SSN is required.';
  }
  return undefined;
};

/**
 * This validator checks to make sure the co-borrower SSN is both valid and not empty.  It will
 * only perform this check if the form contains a co-borrower;
 * 
 * @param value 
 * @param formValues 
 * @returns 
 */
export const coBorrowerSsnRequired = (value: string, formValues: any): string | undefined => {
  if (hasCoBorrower(formValues)) {
    if(!formValues[FieldNames.isCoBorrowerSsnValid] || formValues[FieldNames.isCoBorrowerSsnEmpty]) {
      return 'Co-Borrower SSN is required.';
    }
  }
  return undefined;
};

/**
 * This validator checks to make sure the borrower SSN and co-borrower SSN are not the
 * same value.  This is because downstream LOS will fail as they rely on unique SSN for
 * each borrower.
 * 
 * @param value 
 * @param formValues 
 * @returns 
 */
export const ssnCannotMatch = (value: string, formValues: any): string | undefined => {
  if (formValues[FieldNames.isBothSsnMatch] === true) {
    return 'Co-Borrower SSN and Borrower SSN cannot match.';
  }
  return undefined;
};