import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes/route-list';
import { Button } from 'app/components/Button';

export const ContactLink = ({ children, ...props }: any) => {
  const { buttonProps } = props;
  return (
    <Button
      component={Link}
      to        = {{
        pathname: routes.contact,
        state   : { prevPath: window.location.pathname },
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
