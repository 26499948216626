import React from 'react';
import { FieldArray } from 'redux-form';
import { Select } from 'app/components/FormFields/Select';
import { Currency } from 'app/components/FormFields/Currency';
import { OTHER_INCOME_SOURCE_OPTIONS } from 'app/models/options/options';
import { Button } from 'app/components/Button';
import { FieldNames } from 'app/models/fields/names';
import { requiredArray, noopValidate } from 'app/util/validators';
import { incomeInitialValue } from 'app/util/initial-values';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';

const RenderOtherIncomeSourcesComponent = ({ ...props }) => {
  const { fields, name, isOptional, t } = props;
  const incomeSourceField               = `${name}.${FieldNames.incomeSource}`;
  const incomeMonthlyField              = `${name}.${FieldNames.incomeMonthly}`;

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={`${field}-${index}`} style={{ paddingBottom: 20 }}>
          <Select
            label      = {localizeLabel(incomeSourceField, t, 'Other Income Source')}
            name       = {`${field}.${FieldNames.incomeSource}`}
            options    = {localizeOptions(incomeSourceField, t, OTHER_INCOME_SOURCE_OPTIONS)}
            isOptional = {isOptional}
          />
          <Currency
            label      = {localizeLabel(incomeMonthlyField, t, 'Other Monthly Income')}
            name       = {`${field}.${FieldNames.incomeMonthly}`}
            isOptional = {isOptional}
          />
          {fields.length > 1 && (
            <Button
              disableRipple
              color   = "primary"
              classes = {{
                root: 'link',
                text: 'remove',
              }}
              onClick = {() => fields.remove(index)}
            >
              {t(`question.${name}.button.remove`, { defaultValue: 'Remove' })}
            </Button>
          )}
        </div>
      );
    });

  React.useEffect(() => {
    if (fields.length === 0) {
      fields.push(incomeInitialValue);
    }
  }, [fields]);

  return (
    <div>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(incomeInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Source' })}
      </Button>
    </div>
  );
};

export const OtherIncomeSources = ({ ...props }) => {
  const { name, isOptional } = props;
  const isRequired           = isOptional ? noopValidate : requiredArray([FieldNames.incomeSource, FieldNames.incomeMonthly]);
  const { t }                = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {RenderOtherIncomeSourcesComponent}
        name      = {name}
        validate  = {[isRequired]}
        props     = {{
          name,
          isOptional,
          t,
        }}
      />
    </FormControl>
  );
};
