/*
 * REDUX-FORM FORMATTERS
 *
 * These are functions that are passed into the "format"
 * param on an <Input /> component. These are used to translate
 * the value from the redux store into a format we want shown
 * to the user.
 *
 * See: https://goo.gl/Dqwnoy
 *
 * @param {any} value The value as stored in the redux store
 * @param {string} name The name of the input field
 *
 * @returns {any} The value to be displayed to the user
 */

import { toNumberString } from 'app/util/normalizers';

/**
 * Formats the number values to return with commas
 *
 * @param {(string | number)} value Value from redux store
 * @returns Formatted value with commas
 */
export const commaSeparated = (value: string | number) => {
  if (typeof value === 'undefined' || value === '') {
    return 0;
  }
  return value.toLocaleString();
};

/**
 * Formats the user input to display a blank string, or
 * the user's input value with a $ prefix and comma separated
 * numbers as a string
 *
 * @param {number} value Value in the redux store
 * @returns {string} (ie: $ 150,000)
 */
export const dollarOrEmptyString = (value: number): string => {
  if (!value) {
    return '';
  }
  return `$ ${value.toLocaleString()}`;
};

/**
 * Formats the user input to display a zero dollar amount,
 * or the user's input value. Both with a $ prefix. This is
 * used to allow zero dollars to be input on first touch.
 *
 * @param {number} value
 * @returns {(string | undefined)}
 */
export const dollarOrZeroDollarString = (value: number): string | undefined => {
  if (typeof value === 'number') {
    if (Number.isNaN(value)) {
      return `$ 0`;
    }
    return `$ ${value.toLocaleString()}`;
  }
  return undefined;
};

/**
 * Formats the user input to display a blank string,
 * if the value is zero.
 *
 * @param {number} value Value in the redux store
 * @returns {(string | number)}
 */
export const emptyStringIfZero = (value: number): (string | number) => {
  if (value === 0) {
    return '';
  }
  return value;
};

/**
 * Formats a number value to be used in a string
 * with a $ sign prefix and comma separated values
 *
 * @param {number} value
 * @returns {string}
 */
export const toDollar = (value: number, minimumFractionDigits: number = 0): string => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits,
    style: 'currency',
  });

  return typeof value === 'number' ? currencyFormatter.format(value) : value;
};

/**
 * Formats a SSN value to have dashes in the expected positions
 * (ex. 111-22-3333)
 *
 * @param {string} value
 * @returns {string}
 */
export const toFormattedSsn = (value: string): string => {
  if (!value) {
    return '';
  }
  const numbersOnly = toNumberString(value);
  if (numbersOnly.length <= 3) {
    return `${numbersOnly}`; // 123
  }
  if (numbersOnly.length <= 5) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 5)}`; // 123-45
  }
  return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 5)}-${numbersOnly.slice(5, 9)}`; // 123-45-6789
};

/**
 * Formats the user input to display a 0, or
 * the user's input value with as a comma separated
 * number
 *
 * @param {number} value Value in the redux store
 * @returns {string} (ie: 150,000)
 */
export const toFormattedNumber = (value: number): string => {
  if (!value) {
    return '0';
  }
  return value.toLocaleString('en-us');
};
