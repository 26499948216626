import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'typeface-roboto';
import 'typeface-roboto-slab';
import 'app/i18n/i18next';
import { logger } from './app/util/logger';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './app/App';
import { CookiesDisabledWarning } from './app/CookiesDisabledWarning';
import { getStore, getHistory, getPersistor } from 'app/store';
import * as serviceWorker from './serviceWorker';
import * as AppConfigActions from 'app/actions/app-config/actions';
import ScrollToTop from 'app/containers/hocs/ScrollToTop';
import HttpsRedirect from 'react-https-redirect';
import {
  setQueryParamsToLocalStorage,
} from 'app/api/helpers';
import { hasSlug } from 'app/util/headers';

logger.init();

// Print app version info to console
logger.debug(`App Version: ${process.env.REACT_APP_VERSION}`);
logger.debug(`Hash: ${process.env.REACT_APP_GIT_COMMIT_HASH}`);

const store     = getStore();
const persistor = getPersistor();
const history   = getHistory();

const isSlugInCookie = hasSlug();

if (isSlugInCookie) {
  store.dispatch<any>(AppConfigActions.ajaxFetchConfig());
  store.dispatch<any>(AppConfigActions.ajaxGetLoanOfficerDetail());
}

/* Check url for locale */
setQueryParamsToLocalStorage();

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            { isSlugInCookie ? <App /> : <CookiesDisabledWarning /> }
          </ScrollToTop>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root'),
);

if (isSlugInCookie) {
  serviceWorker.unregister();
}
