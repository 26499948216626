import React from 'react';
import { DateInput } from 'app/components/DateInput';
import { Grid } from '@material-ui/core';

export const renderDateBase = ({ ...props }: any) => {
  const { inputGridProps } = props;

  return (
    <Grid container {...inputGridProps}>
      <Grid item classes={{ item: 'wrapper' }}>
        <DateInput
          {...props}
        />
      </Grid>
    </Grid>
  );
};
