import { Endpoints } from 'app/api/helpers';
import { getSlug, getEmployeeId } from 'app/util/headers';
import { client } from 'app/api/_client';
import { LandingType } from '@lenderful/domain';

export interface LoanOfficerDetail {
  id              : number;
  email           : string;
  firstName       : string;
  middleName?     : string;
  lastName        : string;
  nmls?           : string;
  phone?          : string;
  phoneExt?       : string;
  title?          : string;
  website?        : string;
  username        : string;
  profilePicture? : string;            // Contains a url to an image hosted on s3
  forms           : string[];          // Determines ownership of form
  landingTypes    : LandingType[];
  states          : string[];
}

interface GetLoanOfficerRequest {
  loanOfficerId?      : number;
  loanOfficerUsername?: string;
}

export const getLoanOfficer = async ({ loanOfficerId, loanOfficerUsername }: GetLoanOfficerRequest) => {
  let endpoint: string = Endpoints.LOAN_OFFICER;
  const slug = getSlug();
  const employeeId = getEmployeeId(); // employeeId comes from a cookie that is specific to APQ broker pages only

  if (employeeId) {
    endpoint = `${endpoint}/${slug}/?loanOfficerId=${employeeId}`;
  } else if (loanOfficerId) {
    endpoint = `${endpoint}/${slug}/?loanOfficerId=${loanOfficerId}`;
  } else if (loanOfficerUsername) {
    endpoint = `${endpoint}/${slug}/?loanOfficerUsername=${loanOfficerUsername}`;
  } else {
    endpoint = `${endpoint}/${slug}`;
  }

  try{
    const response = await client.get(endpoint);
    if(response.data) {
      return response.data as LoanOfficerDetail;
    }
    throw response;
  } catch(error) {
    throw error;
  }
};
