import React from 'react';
import { Field } from 'redux-form';
import { required, noopValidate } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import { toNormalizedName } from 'app/util/normalizers';

/**
 * Sends the user's name to full story to associate with their
 * session
 *
 * @param {React.ChangeEvent<HTMLInputElement>} e
 * @returns {void}
 */
const setFullStoryName = (e: React.ChangeEvent<HTMLInputElement>): void => {
  if (window.FS) {
    window.FS.setUserVars({ [e.target.name]: e.target.value });
  }
};

export const Name = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete       = {mapAutoCompleteName(name)}
      component          = {renderInputBase}
      customProps        = {props.customProps}
      fieldPopover       = {props.fieldPopover}
      formControlClasses = {props.formControlClasses}
      inputClasses       = {props.inputClasses}
      inputGridProps     = {props.inputGridProps}
      normalize          = {toNormalizedName}
      label              = {props.label}
      maxLength          = {50}
      name               = {name}
      onChange           = {setFullStoryName}
      popover            = {props.popover}
      type               = 'text'
      validate           = {[isRequired]}
    />
  );
};
