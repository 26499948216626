import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { SmallBusinessCollectSsnStep } from '../../components/SmallBusinessCollectSsnStep';
import { ConfirmationCommercialSmallBusiness } from 'app/components/ConfirmationCommercialSmallBusinessComponent';
import {
  SB_TERM_BUSINESS_INFO,
  SB_TERM_FINAL,
  SB_TERM_FINANCIAL_INFO,
  SB_TERM_LOAN_INFO,
  SB_TERM_OWNER_INFO,
  SB_TERM_SSN,
} from 'app/models/sections/constants';
import { SB_TERM_QUESTIONS } from '../questions/sb-term';
import { FormTerms } from '../../components/FormAgreements/FormTerms';
import * as React from 'react';
import { Questions } from '../questions/Questions';
import { isExcludingSsnQuestions, isNonProfit } from '../fields/conditionals';
import { handleSBFormSubmit } from '../../actions/form/small-business/actions';

export const getSBTermSectionById = (id: number): Section => {
  return SB_TERM_SECTIONS.find((section) => section.id === id) as Section;
};

const sbTermQuestions = new Questions(SB_TERM_QUESTIONS);
const SB_TERM_SECTIONS: Section[] = [
  {
    id        : SB_TERM_LOAN_INFO,
    questions : sbTermQuestions.getByName([
      FieldNames.loanAmount,
      FieldNames.commercialLoanPurpose,
      FieldNames.loanTermDesired,
      FieldNames.commercialCollateralList,
      FieldNames.commercialLoanTiming,
    ]),
    title : 'Tell us more about your loan',
    agreement: <FormTerms />,
  },
  {
    id        : SB_TERM_BUSINESS_INFO,
    title     : 'Tell us about your business',
    questions : sbTermQuestions.getByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialDBA,
      FieldNames.commercialTIN,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialSameMailingAddress,
      FieldNames.commercialMailingStreet,
      FieldNames.commercialMailingStreet2,
      FieldNames.commercialMailingCity,
      FieldNames.commercialMailingState,
      FieldNames.commercialMailingZip,
      FieldNames.commercialWebsite,
      FieldNames.commercialStateOfOrganization,
      FieldNames.commercialEntityType,
      FieldNames.hasEconomicInjuryDisasterLoan,
      FieldNames.economicInjuryDisasterLoans,
    ]),
  },
  {
    id             : SB_TERM_OWNER_INFO,
    questions      : sbTermQuestions.getByName([
      FieldNames.commercialOwnerList,
    ]),
    showIf: [(values) => !isNonProfit(values)],
    title          : 'Tell us about all of the Business Owners that have at least 20%',
  },
  {
    id        : SB_TERM_FINANCIAL_INFO,
    questions : sbTermQuestions.getByName([
      FieldNames.commercialBusinessCashAndEquivalents,
      FieldNames.commercialBusinessTotalAssets,
      FieldNames.commercialBusinessTotalLiabilities,
      FieldNames.commercialBusinessNetWorth,
      FieldNames.commercialBusinessTotalSales,
      FieldNames.commercialBusinessAnnualInterestExpense,
      FieldNames.commercialBusinessEBITA,
      FieldNames.commercialBusinessCheckingAccountBalance,
    ]),
    title      : 'Tell us about the financials of the business',
    submitIf        : [(values) => isNonProfit(values) || isExcludingSsnQuestions(values)],
    submitAction  : handleSBFormSubmit,
  },
  {
    id            : SB_TERM_SSN,
    ifNoQuestions : SmallBusinessCollectSsnStep,
    showIf        : [(values) => !isExcludingSsnQuestions(values) && !isNonProfit(values)],
    submitAction  : handleSBFormSubmit,
  },
  {
    id              : SB_TERM_FINAL,
    ifNoQuestions   : ConfirmationCommercialSmallBusiness,
    hideProgressBar : true,
  },
];
