
export enum Type {
  AJAX_VALIDATE_ZIP_CODE          = 'AJAX_VALIDATE_ZIP_CODE',
  AJAX_VALIDATE_ZIP_CODE_FAIL     = 'AJAX_VALIDATE_ZIP_CODE_FAIL',
  AJAX_VALIDATE_ZIP_CODE_SUCCESS  = 'AJAX_VALIDATE_ZIP_CODE_SUCCESS',
  MODAL_ZIP_CODE_VALIDATION_CLOSE = 'MODAL_ZIP_CODE_VALIDATION_CLOSE',
}

export interface ZipCodeValidationState {
  isZipModalOpen      : boolean;
  zipCode             : number;
}

export type ValidateZipCodePayload = {
  isTestCase   : boolean;
  zipCode      : string;
};
