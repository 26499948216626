import moment from 'moment';
import { FieldNames } from 'app/models/fields/names';
import { getBorrowerTotalIncome, getCoBorrowerTotalIncome } from 'app/util/formulas';
import {
  AccountType,
  CitizenshipType,
  CommercialEntityType,
  CommercialLoanHolder,
  EquipmentOrInventory,
  FieldRequirementType,
  FormName,
  LiabilityUsage,
  LivingSituation,
  PropertyType,
  PropertyUsage,
  Purpose,
  VehiclePurchasePlan,
  YesOrNo,
} from 'app/models/options/enums';
import { LoanPurpose } from 'app/api/rates/types';
import { BorrowerJobDurationAlgorithm } from 'app/reducers/app-config';
import {
  EmploymentType,
  Ethnicity,
  EthnicityHispanic,
  GeneralReferralCode,
  HomeEquityLoanPurpose,
  ProductType,
  Race,
  RaceAsian,
  RaceIslander,
  ReferralCode,
  RefinancePurpose,
  ReverseMortgageLoanPurpose,
} from '@lenderful/domain';

export type DeclarationFieldName = FieldNames.decBorrower | FieldNames.decCoBorrower | FieldNames.decRelative;

/**
 * Gets a form section related to the location provided
 * Returns a boolean based on both conditionals passed in
 * @param {Function} check1 conditional #1 that is passed in
 * @param {Function} check2 conditional #2 that is passed in
 * @returns {boolean}
 */
export const hasBoth = (check1: (values) => boolean, check2: (values) => boolean) => (values): boolean => check1(values) && check2(values);

export const arrayContains                 = (field, value) => Array.isArray(field) && field.indexOf(value) > -1;
export const coBorrowerHasDifferentAddress = (values) => isNo(values[FieldNames.coBorrowerSameAddress]);
export const hasAddlProperties             = (values) => isYes(values[FieldNames.hasAddlPropertiesYN]);
export const hasAddress                    = (values) => isYes(values[FieldNames.verifyAddress]);
export const hasReverseAgeQuestion         = (values) => values[FieldNames.hasReverseAgeQuestion];
export const hasAgreement                  = (values) => isYes(values[FieldNames.agreementYN]);
export const hasBankrupt                   = (borrower: DeclarationFieldName) => (values) => values[borrower][FieldNames.hasBankrupt];
export const hasBorrower                   = (values) => values[FieldNames.decBorrower];
export const hasChosenHome                 = (values) => isYes(values[FieldNames.homeChosenYN]);
export const hasCoBorrower                 = (values) => isYes(values[FieldNames.coBorrowerYN]);
export const hasDifferentMailingAddress    = (values) => isNo(values[FieldNames.commercialSameMailingAddress]);
export const hasEIDL                       = (values) => isYes(values[FieldNames.hasEconomicInjuryDisasterLoan]);
export const hasFSCashAccount              = (values) => isYes(values[FieldNames.financialStatementHasCashAccount]);
export const hasFSCreditCard               = (values) => isYes(values[FieldNames.financialStatementHasCreditCard]);
export const hasFSLifeInsurance            = (values) => isYes(values[FieldNames.financialStatementHasLifeInsurance]);
export const hasFSLoan                     = (values) => isYes(values[FieldNames.financialStatementHasLoan]);
export const hasFSMortgage                 = (values) => isYes(values[FieldNames.financialStatementHasMortgage]);
export const hasFSNote                     = (values) => isYes(values[FieldNames.financialStatementHasNote]);
export const hasFSPersonalProperty         = (values) => isYes(values[FieldNames.financialStatementHasPersonalProperty]);
export const hasFSRealEstate               = (values) => isYes(values[FieldNames.financialStatementHasRealEstate]);
export const hasFSRetirementAccount        = (values) => isYes(values[FieldNames.financialStatementHasRetirementAccount]);
export const hasFSStock                    = (values) => isYes(values[FieldNames.financialStatementHasStock]);
export const hasFSTax                      = (values) => isYes(values[FieldNames.financialStatementHasTaxes]);
export const hasForeclosed                 = (borrower: DeclarationFieldName) => (values) => values[borrower][FieldNames.hasForeclosed];
export const isHidePurchasePropertyAddress = (values) => values[FieldNames.isHidePurchasePropertyAddress];
export const hasHomeSold                   = (values) => isYes(values[FieldNames.homeSoldYN]);
export const hasLandOwnership              = (values) => isYes(values[FieldNames.constructionLandOwner]);
export const hasCurrentCheckingAccount     = (values) => isYes(values[FieldNames.hasCurrentCheckingAccount]);
export const hasLineOfWorkQuestion         = (values) => values[FieldNames.hasLineOfWorkQuestion];
export const hasLoanTypeQuestion           = (values) => values[FieldNames.hasLoanTypeQuestion] && !values[FieldNames.selectedRate];
export const isChangedIncome               = (values) => isYes(values[FieldNames.changedIncomeYN]);
export const isCoBorrowerChangedIncome     = (values) => isYes(values[FieldNames.coBorrowerChangedIncomeYN]);
export const hasMortgageOnProperty         = (values) => isYes(values[FieldNames.propertyHasMortgage]);
export const hasOccupyPrimary              = (borrower: DeclarationFieldName) => (values) => values[borrower][FieldNames.hasOccupyPrimary];
export const hasOtherManagement            = (values) => isYes(values[FieldNames.hasOtherManagement]);
export const hasOwnerInterest              = (borrower: DeclarationFieldName) => (values) => values[borrower][FieldNames.hasOwnerInterest];
export const hasPrequalAmount              = (values) => isYes(values[FieldNames.prequalAmountYN]);
export const hasRealEstateLiabilities      = (values) => values[FieldNames.realEstateLiabilities] && values[FieldNames.realEstateLiabilities].length >= 1;
export const isBorrowerIncomeVerified      = (values) => isYes(values[FieldNames.incomeVerifiable]);
export const isCoBorrowerIncomeVerified    = (values) => isYes(values[FieldNames.coBorrowerIncomeVerifiable]);
export const isCollectingMailingAddress    = (values) => values[FieldNames.isCollectingMailingAddress];
export const isAsian                       = (borrower: DeclarationFieldName) => (values) => arrayContains(values[borrower][FieldNames.race], Race.ASIAN);
export const isAsianOther                  = (borrower: DeclarationFieldName) => (values) => arrayContains(values[borrower][FieldNames.raceAsian], RaceAsian.OTHER);
export const isCoBorrowerEmployee          = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.EMPLOYEE;
export const isCoBorrowerEmploymentOther   = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.OTHER;
export const isCoBorrowerRetired           = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.RETIRED;
export const isCoBorrowerSelfEmployed      = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.SELF;
export const isCoBorrowerNotEmployed       = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.NOT_EMPLOYED;
export const isCoBorrowerRenting           = (values) => values[FieldNames.coBorrowerLivingRentOrOwn] === LivingSituation.RENT;
export const isDownPaymentLessThanMin      = (values) => values[FieldNames.downDollar] < 3500;
export const isEmployee                    = (values) => values[FieldNames.employmentType] === EmploymentType.EMPLOYEE;
export const isEquipment                   = (values) => values[FieldNames.commercialEquipmentOrInventory] === EquipmentOrInventory.EQUIPMENT;
export const isCoEmployee                  = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.EMPLOYEE;
export const isEmploymentOther             = (values) => values[FieldNames.employmentType] === EmploymentType.OTHER;
export const isExistingCommercial          = (values) => isYes(values[FieldNames.isCommercialExistingCustomer]);
export const isFromDealer                  = (values) => (values[FieldNames.autoPurchasePlan] === VehiclePurchasePlan.PURCHASE_FROM_DEALER_NEW || values[FieldNames.autoPurchasePlan] === VehiclePurchasePlan.PURCHASE_FROM_DEALER_USED);
export const isFromDealerAndUsed           = (values) => values[FieldNames.autoPurchasePlan] === VehiclePurchasePlan.PURCHASE_FROM_DEALER_USED;
export const isHispanic                    = (borrower: DeclarationFieldName) => (values) => arrayContains(values[borrower][FieldNames.ethnicity], Ethnicity.HISPANIC);
export const isHispanicOther               = (borrower: DeclarationFieldName) => (values) => arrayContains(values[borrower][FieldNames.ethnicityHispanic], EthnicityHispanic.OTHER);
export const isHomeEquityLoanPurposeOther  = (values) => values[FieldNames.homeEquityLoanPurpose] === HomeEquityLoanPurpose.OTHER;
export const isHomeEquityLoan              = (values) => values[FieldNames.loanPurpose] === LoanPurpose.HELOC;
export const isHomeowner                   = (values) => values[FieldNames.livingRentOrOwn] === LivingSituation.OWN;
export const isIndividualLoanHolder        = (values) => values[FieldNames.commercialLoanHolder] === CommercialLoanHolder.INDIVIDUAL;
export const isInventory                   = (values) => values[FieldNames.commercialEquipmentOrInventory] === EquipmentOrInventory.INVENTORY;
export const isIslander                    = (borrower: DeclarationFieldName) => (values) => arrayContains(values[borrower][FieldNames.race], Race.ISLANDER);
export const isIslanderOther               = (borrower: DeclarationFieldName) => (values) => arrayContains(values[borrower][FieldNames.raceIslander], RaceIslander.OTHER);
export const isMailingDifferent            = (values) => isYes(values[FieldNames.isMailingDifferent]);
export const isNo                          = (value) => value === YesOrNo.NO;
export const isNonProfit                   = (values) => values[FieldNames.commercialEntityType] === CommercialEntityType.NON_PROFIT;
export const isPrequal                     = (values) => values[FieldNames.purpose] === Purpose.PREQUAL;
export const isPurchasing                  = (values) => values[FieldNames.purpose] === Purpose.PURCHASE;
export const isRentFree                    = (values) => values[FieldNames.livingRentOrOwn] === LivingSituation.NORENT;
export const isRenting                     = (values) => values[FieldNames.livingRentOrOwn] === LivingSituation.RENT;
export const isRetired                     = (values) => values[FieldNames.employmentType] === EmploymentType.RETIRED;
export const isVehicleSalvageTitle         = (values) => isYes(values[FieldNames.isVehicleSalvageTitle]);
export const isSelfEmployed                = (values) => values[FieldNames.employmentType] === EmploymentType.SELF;
export const isCoSelfEmployed              = (values) => values[FieldNames.coBorrowerEmploymentType] === EmploymentType.SELF;
export const isVeteran                     = (values) => isYes(values[FieldNames.veteranYN]);
export const isVeteranLoan                 = (values) => isYes(values[FieldNames.veteranLoanYN]);
export const isYes                         = (value) => value === YesOrNo.YES;
export const isBorrowerTooYoung            = (values) =>
  values[FieldNames.dateOfBirth] ?
    isYoungerThan(values[FieldNames.dateOfBirth], 62) :
    values[FieldNames.age] < 62;
export const isCoBorrowerTooYoung          = (values) =>
  values[FieldNames.coBorrowerDOB] ?
    isYoungerThan(values[FieldNames.coBorrowerDOB], 62) :
    values[FieldNames.coBorrowerAge] < 62;
export const isBorrowerUnderage            = (values) => isYoungerThan(values[FieldNames.dateOfBirth], 18);
export const isBorrowerUnderageDynamic     = (values) => isYoungerThan(values[FieldNames.dateOfBirth], values[FieldNames.ageLimit]);
export const isCoBorrowerUnderageDynamic   = (values) => isYoungerThan(values[FieldNames.coBorrowerDOB], values[FieldNames.ageLimit]);
export const isCoBorrowerUnderage          = (values) => isYoungerThan(values[FieldNames.coBorrowerDOB], 18);
export const isBorrowerUnderTwentyOne      = (values) => isYoungerThan(values[FieldNames.dateOfBirth], 21);
export const isCoBorrowerUnderTwentyOne    = (values) => isYoungerThan(values[FieldNames.coBorrowerDOB], 21);
export const isBorrowerIncomeOnDecline     = (values) => isYes(values[FieldNames.incomeOnDeclineYN]);
export const isCoBorrowerIncomeOnDecline   = (values) => isYes(values[FieldNames.coBorrowerIncomeOnDeclineYN]);
export const hasLowerThanCreditScore       = (score: number = 0) => (values) => values[FieldNames.creditScore] < score;
export const relativeHasDifferentAddress   = (values) => isNo(values[FieldNames.relativeSameAddress]);
export const isEmploySameBusiness          = (values) => isYes(values[FieldNames.commercialEmploySameBusiness]);
export const isEmployCoBorrowerSameBusiness = (values) => isYes(values[FieldNames.commercialEmployCoBorrowerSameBusiness]);
export const isSameBusiness                = (values) => isYes(values[FieldNames.commercialSameBusiness]);
export const isUsingReverseToPurchase      = (values) => values[FieldNames.reverseMortgageLoanPurpose] === ReverseMortgageLoanPurpose.PURCHASE_NEW_HOME;
export const hasSelectedNewHome            = (values) => isYes(values[FieldNames.hasSelectedNewHome]);
export const isExcludingQuestion           = (fieldName: string = '') => (values): boolean => values[FieldNames.excludedQuestions].includes(fieldName);
export const wantsAutoInsuranceQuote       = (values) => isYes(values[FieldNames.wantsAutoInsurance]);
export const hasEnabledCreditReportOnTurbo = (values) => values[FieldNames.hasEnabledCreditReportOnTurbo];
export const isAffinityProgram             = (values) => values[FieldNames.isAffinityProgram];

export const hasLowFicoForSecondaryHomes   = (values) => {
  // Home-Equity uses strings like "780+" or "720-779" instead of numbers for credit score
  const ficoScoreAsNumber = values[FieldNames.creditScore] ? parseInt(values[FieldNames.creditScore].slice(0, 3)) : 0;
  return ficoScoreAsNumber <= values[FieldNames.lowFicoSecondaryHomeLimit];
};

export const isPrimaryHome                 = (values) => values[FieldNames.propertyUsage] === PropertyUsage.PRIMARY;
export const isSecondaryHome               = (values) => values[FieldNames.propertyUsage] === PropertyUsage.SECONDARY;
export const isInvestmentHome              = (values) => values[FieldNames.propertyUsage] === PropertyUsage.INVESTMENT;
export const isMultiFamilyHome             = (values) => values[FieldNames.propertyType] === PropertyType.MULTI;
export const noAgreement                   = (values) => isNo(values[FieldNames.agreementYN]);
export const noChosenHome                  = (values) => isNo(values[FieldNames.homeChosenYN]);
export const hasCurrentEscrow              = (values) => isYes(values[FieldNames.livingEscrowUsage]);
export const wantsFutureEscrow             = (values) => isYes(values[FieldNames.escrowUsage]);
export const hasChosenVehicle              = (values) => values[FieldNames.vehicle] && values[FieldNames.vehicle].length > 0;

export const getTotalRealEstateLiabilities = (values) => {
  const realEstateLiabilities = values[FieldNames.realEstateLiabilities];
  return realEstateLiabilities ? realEstateLiabilities.length : 0;
};

export const getNumberOfHomesbyUsage = (usage: LiabilityUsage) => (values) => {
  const realEstateLiabilities = values[FieldNames.realEstateLiabilities] || [];
  return realEstateLiabilities
    .filter(liability => liability.liabilityUsage === usage)
    .length;
};

export const hasMoreThanOneHomeOrLien      = (values) => {
  const totalRealEstateLiabilities = getTotalRealEstateLiabilities(values);
  const numberOfPrimaryHomes       = getNumberOfHomesbyUsage(LiabilityUsage.PRIMARY_HOME)(values);
  const numberOfOtherHomes         = getNumberOfHomesbyUsage(LiabilityUsage.OTHER_HOME)(values);
  const numberOfNotMyHomes         = getNumberOfHomesbyUsage(LiabilityUsage.NOT_MY_HOME)(values);

  // never exit if you have no liabilites
  if (totalRealEstateLiabilities === 0) {
    return false;
  }
  // single borrower with multiple homes or liens, then exit
  if (!hasCoBorrower(values) && totalRealEstateLiabilities > 1) {
    return true;
  }
  // if you ever select multiple primary homes, then exit
  if (numberOfPrimaryHomes > 1) {
    return true;
  }
  // if you ever select that you own another home, then exit
  if (numberOfOtherHomes > 0) {
    return true;
  }
  // if you have one liability and claim you do not own it, then exit
  if (totalRealEstateLiabilities === 1 && numberOfNotMyHomes === 1) {
    return true;
  }
  return false;
};

export const isOwningHomeOutright = (values) => isYes(values[FieldNames.isOwningHomeOutright]);
export const isOwningOtherHomes   = (values) => isYes(values[FieldNames.isOwningOtherHomes]);
export const isCollectOtherHomeLiabilties = (values) => (values[FieldNames.liabilityCombinedNumberOtherHomes] && values[FieldNames.liabilityCombinedNumberOtherHomes] > 0);

export const isTakingCashOut = (values) =>
  values[FieldNames.loanPurpose] === RefinancePurpose.CASHOUT ||
  values[FieldNames.loanPurpose] === RefinancePurpose.CONSOLIDATE;

export const CASH_OUT_MIN = 3000;
export const CASH_OUT_MAX_LTV = 0.80;

/**
 * This is the amount of cash out available at closing.
 *
 * @param values
 * @returns {number}
 */

export const cashOutAvailableAtClosing = (values): number => {
  const maxLtv = values[FieldNames.cashOutMaxLTV] ? values[FieldNames.cashOutMaxLTV] * .01 : CASH_OUT_MAX_LTV;
  return (values[FieldNames.homeValue] * maxLtv) - values[FieldNames.loanAmount];
};

/**
 * This checks to see if the home owner has enough equity to perform a cash out.
 *
 * @param values
 * @returns {boolean}
 */
export const hasRoomForCashOut = (values): boolean => cashOutAvailableAtClosing(values) > CASH_OUT_MIN;

export const hasHomeValueSetTooLow         = (values) => values[FieldNames.avmHomeValueLowLimit] &&
  values[FieldNames.homeValue] < values[FieldNames.avmHomeValueLowLimit];
export const hasHomeValueSetTooHigh        = (values) => values[FieldNames.avmHomeValueHighLimit] &&
  values[FieldNames.homeValue] > values[FieldNames.avmHomeValueHighLimit];
export const hasAvmHomeValue        = (values) => values[FieldNames.avmHomeValueHighLimit] > 0 && values[FieldNames.avmHomeValueLowLimit] > 0 && values[FieldNames.avmHomeValue] > 0;

export const hasLowSelfReportedFico             = (values) => values[FieldNames.creditScore] < values[FieldNames.lowFicoLimit];

// TODO: [LP-3148] unify how we deal with citizenship type on the front-end
export const noCitizenship                 = (borrower: DeclarationFieldName) => (values) => {
  if (borrower === FieldNames.decBorrower) {
    return (!values[FieldNames.decBorrower][FieldNames.hasCitizenship]) ||
      (values[FieldNames.citizenshipType] && values[FieldNames.citizenshipType] !== CitizenshipType.US_CITIZEN);
  }
  if (borrower === FieldNames.decCoBorrower) {
    return (!values[FieldNames.decCoBorrower][FieldNames.hasCitizenship]) ||
      (values[FieldNames.coBorrowerCitizenshipType] && values[FieldNames.coBorrowerCitizenshipType] !== CitizenshipType.US_CITIZEN);
  }
  return true;
};

export const hasBorrowedDown               = (borrower: DeclarationFieldName) => (values) => values[borrower][FieldNames.hasBorrowedDown];
export const schedulerEnabled              = (values) => values[FieldNames.schedulerEnabled] === true;
export const isExcludingSsnQuestions       = (values) => values[FieldNames.ssnQuestionRequirement] === FieldRequirementType.HIDDEN;
export const hasRate                       = (values) => !!values[FieldNames.rate];
export const schedulerDisabled             = (borrower: DeclarationFieldName) => (values) =>
  borrower === FieldNames.decBorrower && values[FieldNames.schedulerEnabled] === false;

export const canVerifyOfIncomeEmployment         = (values) => isYes(values[FieldNames.canVerifyOfIncomeEmployment]);
export const hasExitedFinicity                   = (values) => isYes(values[FieldNames.hasExitedFinicity]);

/**
 * The user must manually enter their income and employment if either of the following are true:
 *
 * 1. the user declines the VOIE check
 * 2. the user started through the VOIE process (Finicity) and exits before completing
 *
 * @param values form values
 * @returns {boolean}
 */
export const isManualIncomeAndEmploymentRequired = (values) => !canVerifyOfIncomeEmployment(values) || hasExitedFinicity(values);

export const isOverFrontEndRatio = (ratio: number) => (values) => {
  const totalIncome = getBorrowerTotalIncome(values) + getCoBorrowerTotalIncome(values);
  if (totalIncome === 0) {
    return true;
  }
  return values[FieldNames.monthlyPayment] / totalIncome > ratio;
};

const isYoungerThan = (value, age: number) => {
  const now         = moment().startOf('day');
  const dateOfBirth = moment(value).format('YYYY-MM-DD');
  const ageInYears  = moment.duration(now.diff(dateOfBirth)).as('years');
  return value && Math.floor(ageInYears) < age;
};

export const isLessThanTwoYrsAtJob = (values) => {
  const startDate = values[FieldNames.employStart];
  const days = moment({}).diff(startDate, 'days');
  return (days && days < 730) || false;
};

export const isCoLessThanTwoYrsAtJob = (values) => {
  const startDate = values[FieldNames.coBorrowerEmployStart];
  const days = moment({}).diff(startDate, 'days');
  return (days && days < 730) || false;
};

/**
 * This method returns true if the duration between now and the passed in
 * date is less than the number of months.
 *
 * @param {moment.MomentInput} date
 * @param {number} months
 * @returns
 */
const isDurationShort = (date: moment.MomentInput, months: number): boolean => {
  const now              = moment();
  const durationInMonths = moment.duration(now.diff(date)).as('months');
  return Math.floor(durationInMonths) < months;
};

/**
 * This method returns true if the borrower has been at the job for a short duration.  It either uses
 * the current job or the previous job based on which algorithm is configured for the specific client.
 * We ignore gaps in the employment between jobs for now.
 *
 * @param values
 * @returns {boolean}
 */
export const isBorrowerShortJobDuration = (values): boolean => {
  if (values[FieldNames.borrowerJobDurationAlgorithm] === BorrowerJobDurationAlgorithm.CURRENT_JOB) {
    return isDurationShort(values[FieldNames.employStart], values[FieldNames.borrowerJobDurationMonths]);
  } else if (values[FieldNames.borrowerJobDurationAlgorithm] === BorrowerJobDurationAlgorithm.CONSECUTIVE_JOBS) {
    // ignore gaps in employment for now
    const startDate = values[FieldNames.employPrevStart] ? values[FieldNames.employPrevStart] : values[FieldNames.employStart];
    return isDurationShort(startDate, values[FieldNames.borrowerJobDurationMonths]);
  }
  return false;
};

export const isLessThanTwoYrsAtLivingAddress = (values) => {
  if (values[FieldNames.livingOccupancyStart]) {
    const now                = moment();
    const endDate            = moment().year(now.year() - 2);
    const occupancyStartDate = moment(values[FieldNames.livingOccupancyStart]);
    return occupancyStartDate.isBetween(endDate, now, 'days', '[]');
  }
  return false;
};

export const isRefinance = (values) => {
  const loanPurpose = values[FieldNames.loanPurpose];
  return loanPurpose === RefinancePurpose.MONTHLY ||
    loanPurpose === RefinancePurpose.TERMS ||
    loanPurpose === RefinancePurpose.CASHOUT ||
    loanPurpose === RefinancePurpose.CONSOLIDATE;
};

/**
 * Returns true if none of the assets the user has provided contain
 * an account type of "Proceeds from Sale of Home"
 *
 * @param {object} [values={}]
 * @returns {boolean}
 */
export const hasProceedsFromSellingHome = (values: object = {}): boolean => {
  const assets = values[FieldNames.assets] || [];
  return assets.some((asset) => {
    return asset[FieldNames.accountType] === AccountType.SALE_PROCEEDS;
  });
};

/**
 * Returns true when the down payment for the auto loan is too low based on the clients down payment
 * minimum percentage.  Includes trade-in when applicable.
 *
 * @param values
 * @param {number} downPaymentMinPercent
 * @returns {boolean}
 */
export const isAutoDownPaymentLow = (values, downPaymentMinPercent: number): boolean => {
  const purchasePrice         = values[FieldNames.purchasePrice];
  const downPayment           = values[FieldNames.downDollar];
  const tradeIn               = isFromDealer(values) && values[FieldNames.tradeInValue] ? values[FieldNames.tradeInValue] : 0;

  if ((tradeIn + downPayment) / purchasePrice < (downPaymentMinPercent / 100)) {
    return true;
  }
  return false;
};

export const hasShowNumberOfDependents = (values) => {
  return values[FieldNames.isHideDependentsAges] === true;
};

export const hasShowAgesOfDependents = (values) => {
  return values[FieldNames.isHideDependentsAges] === false;
};

/**
 * Disables the Government Monitoring Questions if the user selected a fixed rate Home Equity Loan. This is only checked if the client has
 * the feature toggle, isHideGovtMonitoringHelocArm, enabled.
 * Disables the Government Monitoring Questions if the user selected a Home Equity Line of Credit Loan.
 *
 * @param values
 * @returns {boolean}
 */
export const hasShowGovtMonitoring = (values) => {
  if (values[FieldNames.isHideGovtMonitoringHelocArm]) {
    if ((values[FieldNames.selectedRate]?.productType === ProductType.ARM && values[FieldNames.selectedRate]?.productFamily === 'HELOC')) {
      return false;
    }
  }
  return true;
};

export interface ReferToLoConditional {
  code     : ReferralCode;
  condition: (values: any) => boolean;
}

/**
 * Conditions which indicate a referral to loan officer
 */
export const LoanOfficerCondition: LoanOfficerConditions = {
  hasBankrupt: {
    code: GeneralReferralCode.BANKRUPT,
    condition: hasBankrupt(FieldNames.decBorrower),
  },
  hasNoCurrentCheckingAccount   : {
    code: GeneralReferralCode.HAS_NO_CURRENT_CHECKING_ACCOUNT,
    condition: (form) => !hasCurrentCheckingAccount(form),
  },
  hasForeclosed: {
    code: GeneralReferralCode.FORECLOSURE,
    condition: hasForeclosed(FieldNames.decBorrower),
  },
  isBorrowerIncomeUnverified: {
    code: GeneralReferralCode.BORROWER_UNVERIFIED_INCOME,
    condition: (form) => !isBorrowerIncomeVerified(form),
  },
  isCoBorrowerIncomeUnverified: {
    code: GeneralReferralCode.CO_BORROWER_UNVERIFIED_INCOME,
    condition: (form) => !isCoBorrowerIncomeVerified(form),
  },
  isVeteranLoan: {
    code: GeneralReferralCode.VETERAN,
    condition: isVeteranLoan,
  },
  isBorrowerNotCitizen: {
    code: GeneralReferralCode.NON_CITIZEN,
    condition: noCitizenship(FieldNames.decBorrower),
  },
  isCoBorrowerNotCitizen: {
    code: GeneralReferralCode.CO_BORROWER_NON_CITIZEN,
    condition: noCitizenship(FieldNames.decCoBorrower),
  },
  isBorrowerUnderage: {
    code: GeneralReferralCode.BORROWER_UNDERAGE,
    condition: isBorrowerUnderage,
  },
  isBorrowerUnderageDynamic: {
    code: GeneralReferralCode.BORROWER_UNDERAGE,
    condition: isBorrowerUnderageDynamic,
  },
  isCoBorrowerUnderageDynamic: {
    code: GeneralReferralCode.CO_BORROWER_UNDERAGE,
    condition: isCoBorrowerUnderageDynamic,
  },
  isCoBorrowerUnderage: {
    code: GeneralReferralCode.CO_BORROWER_UNDERAGE,
    condition: isCoBorrowerUnderage,
  },
  isBorrowerUnderTwentyOne: {
    code: GeneralReferralCode.BORROWER_UNDER_TWENTY_ONE,
    condition: isBorrowerUnderTwentyOne,
  },
  isCoBorrowerUnderTwentyOne: {
    code: GeneralReferralCode.CO_BORROWER_UNDER_TWENTY_ONE,
    condition: isCoBorrowerUnderTwentyOne,
  },
  isBorrowerShortJobDuration: {
    code: GeneralReferralCode.BORROWER_SHORT_JOB_DURATION,
    condition: isBorrowerShortJobDuration,
  },
  isBorrowerIncomeOnDecline: {
    code: GeneralReferralCode.BORROWER_INCOME_ON_DECLINE,
    condition: isBorrowerIncomeOnDecline,
  },
  isCoBorrowerIncomeOnDecline: {
    code: GeneralReferralCode.CO_BORROWER_INCOME_ON_DECLINE,
    condition: isCoBorrowerIncomeOnDecline,
  },
  isBorrowerTooYoung: {
    code: GeneralReferralCode.BORROWER_IS_TOO_YOUNG,
    condition: isBorrowerTooYoung,
  },
  isCoBorrowerTooYoung: {
    code: GeneralReferralCode.CO_BORROWER_IS_TOO_YOUNG,
    condition: isCoBorrowerTooYoung,
  },
  hasCurrentEscrow: {
    code: GeneralReferralCode.HAS_CURRENT_ESCROW,
    condition: hasCurrentEscrow,
  },
  wantsFutureEscrow: {
    code: GeneralReferralCode.WANTS_FUTURE_ESCROW,
    condition: wantsFutureEscrow,
  },
  isRefinancingOtherHome: {
    code: GeneralReferralCode.REFINANCING_OTHER_HOME,
    condition: (form) => !isPrimaryHome(form),
  },
  hasLowFicoAndSecondaryHome: {
    code: GeneralReferralCode.LOW_FICO_FOR_SECOND_HOME,
    condition: (form) => hasLowFicoForSecondaryHomes(form) && isSecondaryHome(form),
  },
  hasMoreThanOneHomeOrLien : {
    code      : GeneralReferralCode.HAS_MORE_THAN_ONE_HOME_OR_LIEN,
    condition : (form) => hasMoreThanOneHomeOrLien(form) || isOwningHomeOutright(form),
  },
  hasHomeValueSetTooLow: {
    code      : GeneralReferralCode.HAS_HOME_VALUE_SET_TOO_LOW,
    condition : (form) => hasHomeValueSetTooLow(form),
  },
  hasHomeValueSetTooHigh: {
    code      : GeneralReferralCode.HAS_HOME_VALUE_SET_TOO_HIGH,
    condition : (form) => hasHomeValueSetTooHigh(form),
  },
  verifyOfIncomeAndEmploymentNotAvailable: {
    code      : GeneralReferralCode.VOIE_NOT_AVAILABLE,
    condition : (form) => !canVerifyOfIncomeEmployment(form),
  },
  hasLowSelfReportedFico: {
    code      : GeneralReferralCode.LOW_SELF_REPORTED_FICO,
    condition : (form) => hasLowSelfReportedFico(form),
  },
  isVehicleSalvageTitle: {
    code: GeneralReferralCode.IS_SALVAGE_TITLE,
    condition: (form) => isVehicleSalvageTitle(form),
  },
  hasNoRoomForCashOut : {
    code: GeneralReferralCode.CASH_OUT_NOT_AVAILABLE,
    condition: (form) => isTakingCashOut(form) && !hasRoomForCashOut(form),
  },
};

export interface LoanOfficerConditions {
  hasBankrupt                             : ReferToLoConditional;
  hasForeclosed                           : ReferToLoConditional;
  isVeteranLoan                           : ReferToLoConditional;
  isBorrowerNotCitizen                    : ReferToLoConditional;
  isCoBorrowerNotCitizen                  : ReferToLoConditional;
  isBorrowerIncomeUnverified              : ReferToLoConditional;
  isCoBorrowerIncomeUnverified            : ReferToLoConditional;
  isBorrowerUnderage                      : ReferToLoConditional;
  isBorrowerUnderageDynamic               : ReferToLoConditional;
  isCoBorrowerUnderageDynamic             : ReferToLoConditional;
  isCoBorrowerUnderage                    : ReferToLoConditional;
  isBorrowerUnderTwentyOne                : ReferToLoConditional;
  isCoBorrowerUnderTwentyOne              : ReferToLoConditional;
  isBorrowerShortJobDuration              : ReferToLoConditional;
  isBorrowerIncomeOnDecline               : ReferToLoConditional;
  isCoBorrowerIncomeOnDecline             : ReferToLoConditional;
  hasLowFicoAndSecondaryHome              : ReferToLoConditional;
  hasCurrentEscrow                        : ReferToLoConditional;
  hasMoreThanOneHomeOrLien                : ReferToLoConditional;
  hasNoCurrentCheckingAccount             : ReferToLoConditional;
  hasNoRoomForCashOut                     : ReferToLoConditional;
  wantsFutureEscrow                       : ReferToLoConditional;
  isRefinancingOtherHome                  : ReferToLoConditional;
  hasLowSelfReportedFico                  : ReferToLoConditional;
  hasHomeValueSetTooLow                   : ReferToLoConditional;
  hasHomeValueSetTooHigh                  : ReferToLoConditional;
  verifyOfIncomeAndEmploymentNotAvailable : ReferToLoConditional;
  isBorrowerTooYoung                      : ReferToLoConditional;
  isCoBorrowerTooYoung                    : ReferToLoConditional;
  isVehicleSalvageTitle                   : ReferToLoConditional;
}

// Codes below can be reused, they just need a unique TransferToFormConditions & TransferToFormCondition
export enum TransferToFormCode {
  COMMERCIAL_RENEW_TO_FINANCIAL_STATEMENT = 'COMMERCIAL_RENEW_TO_FINANCIAL_STATEMENT',
  HOME_EQUITY_RATES_TO_TURBO = 'HOME_EQUITY_RATES_TO_TURBO',
}

export interface TransferToFormConditional {
  answeredQuestions : FieldNames[];
  code              : TransferToFormCode;
  condition         : (values: any) => boolean;
  toForm            : FormName;
  fromForm          : FormName;
  sectionId         : string;
}

export interface TransferToFormConditions {
  commercialRenewToFinancialStatement : TransferToFormConditional;      // Used in Commercial Renew  -> Financial Statement
}

/**
 * @deprecated Home Equity Turbo should no longer transfer forms, referral only
 */
export const TransferToFormCondition: TransferToFormConditions = {
  commercialRenewToFinancialStatement         : {
    answeredQuestions : [
    ],
    code              : TransferToFormCode.COMMERCIAL_RENEW_TO_FINANCIAL_STATEMENT,
    condition         : () => { return true; },
    fromForm          : FormName.COMMERCIALRENEW,
    toForm            : FormName.FINANCIALSTATEMENT,
    sectionId         : '101',
  },
};
