import { ReverseMortgageLoanType } from '@lenderful/domain';
import { isReverseMortgageType } from 'app/api/loan-application/universal/mappings';
import { hasMortgageOnProperty, isUsingReverseToPurchase } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';

/**
 * Returns estimated home value for Reverse Mortgage product.
 * 
 * @param form 
 * @returns {number}
 */
export const getEstimatedHomeValue = (form): number => {
  return isUsingReverseToPurchase(form) ? form[FieldNames.purchasePrice] : form[FieldNames.homeValue];
};

/**
 * Returns morgage balance for Reverse Mortgage product (when applicable).
 * 
 * @param form 
 * @returns {number}
 */
export const getMortgageBalance = (form): number => {
  return hasMortgageOnProperty(form) && !isUsingReverseToPurchase(form) ? form[FieldNames.mortgageBalance] : 0; 
};

/**
 * Returns line of credit for Reverse Mortgage product (when applicable).
 * 
 * @param form 
 * @returns {number | undefined}
 */
export const getLineOfCredit = (form): number | undefined => {
  return isReverseMortgageType(form, ReverseMortgageLoanType.LINE_OF_CREDIT) ? Math.max(0, form[FieldNames.netPrincipalLimit] - form[FieldNames.expectedLumpSum]) : undefined;
};

/**
 * Returns loan term for Reverse Mortgage product (when applicable).
 * 
 * @param form 
 * @returns {number | undefined}
 */
export const getLoanTerm = (form): number | undefined => {
  return isReverseMortgageType(form, ReverseMortgageLoanType.MONTHLY_PAYMENTS) ? form[FieldNames.loanTerm] : undefined;
};

/**
 * Returns tenure payment for Reverse Mortgage product (when applicable).
 * 
 * @param form 
 * @returns {number | undefined}
 */
export const getTenurePayment = (form): number | undefined => {
  return isReverseMortgageType(form, ReverseMortgageLoanType.MONTHLY_PAYMENTS) ? form[FieldNames.tenurePayment] : undefined;
};

/**
 * Returns term payment for Reverse Mortgage product (when applicable).
 * 
 * @param form 
 * @returns {number | undefined}
 */
export const getTermPayment = (form): number | undefined => {
  return isReverseMortgageType(form, ReverseMortgageLoanType.MONTHLY_PAYMENTS) ? form[FieldNames.termPayment] : undefined;
};

/**
 * Returns down payment payment for Reverse Mortgage product.
 * 
 * @param form 
 * @returns {number}
 */
export const getDownPayment = (form): number => {
  return form[FieldNames.purchasePrice] - form[FieldNames.netPrincipalLimit] + form[FieldNames.totalUpfrontCosts];
};