import { FormControl } from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { DynamicFormFieldArrayType } from 'app/models/options/enums';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'redux-form';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';

const agesInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.dependentsAges,
    isOptional : false,
    label      : 'Age (years)',
    type       : DynamicFormFieldArrayType.INTEGER_ALLOW_ZERO,
  },
];

const agesProps: DynamicFormFieldArray = {
  addButtonText : 'Dependent',
  allowEmpty    : true,
  initialValue  : 0,
  inputs        : agesInputs,
};

export const AgesFFAComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: agesProps, name, t, options } }
      />
    </FormControl>
  );
};
