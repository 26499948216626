import { Type } from 'app/actions/form/types';
import { LoanOfficerDetail } from 'app/api/loan-officer';

interface UserState {
  isAuth          : boolean;
  isLoading       : boolean;
  data            : {
    profile?: LoanOfficerDetail;
  };
  /**
   * Used to determine if user has previously logged in.
   */
  hasActiveAccount: boolean;
}

const initialUserState: UserState = {
  isAuth          : false,
  isLoading       : false,
  hasActiveAccount: false,
  data            : {},
};

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const userReducer = (state = initialUserState, action): UserState => {
  switch (action.type) {
    /* Set isLoading for auto prequal returning users */
    case Type.AJAX_GET_PREQUAL_LETTER:
      return { ...state, isLoading: true };
    /* Set isAuth for auto prequal returning users */
    case Type.AJAX_GET_PREQUAL_LETTER_SUCCESS:
      return { ...state, isLoading: false, isAuth: true };
    default:
      return state;
  }
};
