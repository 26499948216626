import React from 'react';
import { FieldArray } from 'redux-form';
import { Select } from 'app/components/FormFields/Select';
import { FieldNames } from 'app/models/fields/names';
import { PROPERTY_TYPE_OPTIONS, REO_PROPERTY_USAGE_OPTIONS } from 'app/models/options/options';
import { Button } from 'app/components/Button';
import { requiredArray } from 'app/util/validators';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { Currency } from 'app/components/FormFields/Currency';
import { PropertyUsage } from 'app/models/options/enums';

const initialValue = {
  [FieldNames.propertyType]                    : '',
  [FieldNames.propertyUsage]                   : '',
  [FieldNames.propertyStreet]                  : '',
  [FieldNames.propertyStreet2]                 : '',
  [FieldNames.propertyCity]                    : '',
  [FieldNames.propertyState]                   : '',
  [FieldNames.propertyZip]                     : '',
  [FieldNames.propertyValue]                   : '',
  [FieldNames.propertyAnnualRentAmount]        : '',
  [FieldNames.propertyAnnualInsuranceAmount]   : '',
  [FieldNames.propertyAnnaulTaxAmount]         : '',
  [FieldNames.propertyAnnualAssociationAmount] : '',
};

const RenderPropertiesComponent = ({ ...props }) => {
  const { fields, values, name, t } = props;

  const propertyTypeLabel                  = localizeLabel(`${name}.${FieldNames.propertyType}`, t, 'Property Type');
  const propertyUsageLabel                 = localizeLabel(`${name}.${FieldNames.propertyUsage}`, t, 'Property Use');
  const propertyStreetLabel                = localizeLabel(`${name}.${FieldNames.propertyStreet}`, t, 'Street Address');
  const propertyStreet2Label               = localizeLabel(`${name}.${FieldNames.propertyStreet2}`, t, 'Street Address 2');
  const propertyCityLabel                  = localizeLabel(`${name}.${FieldNames.propertyCity}`, t, 'City');
  const propertyStateLabel                 = localizeLabel(`${name}.${FieldNames.propertyState}`, t, 'State');
  const propertyZipLabel                   = localizeLabel(`${name}.${FieldNames.propertyZip}`, t, 'Zip Code');
  const propertyValueLabel                 = localizeLabel(`${name}.${FieldNames.propertyValue}`, t, 'Present Value');
  const propertyMonthlyRentAmountLabel     = localizeLabel(`${name}.${FieldNames.propertyAnnualRentAmount}`, t, 'Annual Rental Income');
  const propertyAnnualInsuranceLabel       = localizeLabel(`${name}.${FieldNames.propertyAnnualInsuranceAmount}`, t, 'Annual Insurance');
  const propertyAnnualTaxesLabel           = localizeLabel(`${name}.${FieldNames.propertyAnnaulTaxAmount}`, t, 'Annual Taxes');
  const propertyAnnualAssociationFeesLabel = localizeLabel(`${name}.${FieldNames.propertyAnnualAssociationAmount}`, t, 'Annual Association Fees');
  const propertyTypeOptions                = localizeOptions(`${name}.${FieldNames.propertyType}`, t, PROPERTY_TYPE_OPTIONS);
  const propertyUsageOptions               = localizeOptions(`${name}.${FieldNames.propertyUsage}`, t, REO_PROPERTY_USAGE_OPTIONS);

  const renderFields = () =>
    fields.map((field, index) => {
      const isInvestmentProperty = values[fields.name][index].propertyUsage === PropertyUsage.INVESTMENT;
      const renderMonthlyRentAmount = () => {
        return (
          <Currency
            label = {propertyMonthlyRentAmountLabel}
            name  = {`${field}.${FieldNames.propertyAnnualRentAmount}`}
          />
        );
      };
      return (
        <div key = {`${field}-${index}`} style={{ paddingBottom: 20 }}>
          <Select
            label   = {propertyTypeLabel}
            name    = {`${field}.${FieldNames.propertyType}`}
            options = {propertyTypeOptions}
          />
          <Select
            label   = {propertyUsageLabel}
            name    = {`${field}.${FieldNames.propertyUsage}`}
            options = {propertyUsageOptions}
          />
          { isInvestmentProperty && renderMonthlyRentAmount() }
          <Address
            label = {propertyStreetLabel}
            name  = {`${field}.${FieldNames.propertyStreet}`}
          />
          <Address
            label      = {propertyStreet2Label}
            isOptional = {true}
            name       = {`${field}.${FieldNames.propertyStreet2}`}
          />
          <Address
            label = {propertyCityLabel}
            name  = {`${field}.${FieldNames.propertyCity}`}
          />
          <State
            label = {propertyStateLabel}
            name  = {`${field}.${FieldNames.propertyState}`}
          />
          <ZipCode
            label = {propertyZipLabel}
            name  = {`${field}.${FieldNames.propertyZip}`}
          />
          <Currency
            label = {propertyValueLabel}
            name  = {`${field}.${FieldNames.propertyValue}`}
          />
          <Currency
            label = {propertyAnnualTaxesLabel}
            name  = {`${field}.${FieldNames.propertyAnnaulTaxAmount}`}
          />
          <Currency
            label = {propertyAnnualInsuranceLabel}
            name  = {`${field}.${FieldNames.propertyAnnualInsuranceAmount}`}
          />
          <Currency
            label = {propertyAnnualAssociationFeesLabel}
            isOptional = {true}
            name  = {`${field}.${FieldNames.propertyAnnualAssociationAmount}`}
          />

          {fields.length > 1 && (
            <Button
              disableRipple
              color   = 'primary'
              classes = {{
                root: 'link',
                text: 'remove',
              }}
              onClick = {() => fields.remove(index)}
            >
              {t(`question.${name}.button.remove`, { defaultValue: 'Remove' })}
            </Button>
          )}
        </div>
      );
    });

  React.useEffect(() => {
    if (fields.length === 0) {
      fields.push(initialValue);
    }
  }, [fields]);

  return (
    <div>
      {renderFields()}
      <Button
        color    = 'primary'
        disabled = {props.meta.invalid}
        variant  = 'outlined'
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(initialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Property' })}
      </Button>
    </div>
  );
};

export const AdditionalProperties = ({ ...props }) => {
  const { name, values } = props;
  const { t }            = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {RenderPropertiesComponent}
        name      = {name}
        props     = {{ name, values, t }}
        validate  = {[
          requiredArray([FieldNames.propertyType, FieldNames.propertyUsage]),
        ]}
      />
    </FormControl>
  );
};
