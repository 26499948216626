import i18next from 'i18next';
import { SubmissionError } from 'redux-form';
import { logger } from 'app/util/logger';
import { makeActionCreator } from 'app/util/actions';
import { FieldNames } from 'app/models/fields/names';
import { getNextSection } from 'app/actions/form/actions';
import { Type, ValidateZipCodePayload } from '../zip-code-validation/types';
import { validateZipCode } from '../../../api/validate-zip-code';

export const ajaxValidateZipCodeFail     = makeActionCreator(Type.AJAX_VALIDATE_ZIP_CODE_FAIL, 'payload');
export const ajaxValidateZipCodeSuccess  = makeActionCreator(Type.AJAX_VALIDATE_ZIP_CODE_SUCCESS, 'payload');
export const modalZipCodeValidationClose = makeActionCreator(Type.MODAL_ZIP_CODE_VALIDATION_CLOSE);

export function ajaxValidateBorrowerZipCode(formData) {
  return ajaxValidateZipCode({
    isTestCase       : formData.isTestCase,
    zipCode          : formData[FieldNames.propertyZip],
  });
}

function ajaxValidateZipCode(payload: ValidateZipCodePayload) {
  return (dispatch) => {
    dispatch({ type: Type.AJAX_VALIDATE_ZIP_CODE, payload });

    return validateZipCode(payload.zipCode)
      .then((response) => {
        if (response.isValid) {
          logger.debug('Zip Code is valid, getting next section');
          dispatch(getNextSection());
        } else {
          dispatch(ajaxValidateZipCodeSuccess({ zipCode: response.zipCode }));
        }
      })
      .catch((error) => {
        dispatch(ajaxValidateZipCodeFail(error));
        const errorKey = 'validation:error.validateZipCode.';
        const errors = {
          '[errorCodeMap[error.code]]': i18next.t(errorKey, { defaultValue: error.description }),
        };
        throw new SubmissionError(errors);
      });
  };
}
