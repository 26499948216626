import React from 'react';
import {
  createStyles,
  Typography,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden,
  WithStyles,
  withTheme,
  WithTheme,
  PropTypes,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = () => {
  return createStyles({
    bottomBox: {
      minHeight: '220px',
    },
  });
};

interface Props extends WithStyles<typeof styles, true>, WithTheme {
  children : React.ReactChild;
  color    : PropTypes.Color;
  title    : string;
}

class PaymentMobileWrapperComponent extends React.Component<Props, any> {

  render() {
    const { color, theme, children, title } = this.props;
    return (
      <Hidden mdUp>
        <Accordion elevation={2} square={true} classes={{ root: 'tileBoxExpansion' }}>
          <AccordionSummary
            classes={{
              root       : 'condensed',
              content    : 'alignCenter removeMargin',
              expandIcon : 'condensedIcon',
            }}
            expandIcon={<ExpandMore color={"primary"} />}
            style={{
              border       : `1px solid ${theme.palette[color].main}`,
              borderRadius : '4px',
            }}
          >
            <Typography
              align   = 'center'
              variant = 'body1'
              color   = "primary"
              classes = {{ body1: 'condensedAlign' }}
            >
              { title }
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'condensedDetails' }}>
            { children }
          </AccordionDetails>
        </Accordion>
      </Hidden>
    );
  }
}

export const PaymentMobileWrapper = withTheme(withStyles(styles)(PaymentMobileWrapperComponent));
