import React from 'react';
import { connect } from 'react-redux';
import { ContactLink } from 'app/components/ContactLink';
import { ImageSection } from 'app/components/ImageSection';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

export const StateNotCoveredComponent = ({ ...props }: any) => {
  const { clientName, stopGateHeloc, t } = props;
  return (
    <ImageSection image={stopGateHeloc}>
      <Typography variant="h6" gutterBottom={true} classes={{ h6: 'contentTitle' }}>
        {t('stopGate.stateNotCovered.title', { defaultValue: 'Oops!' })}
      </Typography>
      <Typography variant="body2" gutterBottom={true}>
        {t('stopGate.stateNotCovered.paragraph1', {
          clientName,
          defaultValue:
            'Unfortunately we cannot process this application online. Please call {{ clientName }} for further details.',
        })}
      </Typography>
      <ContactLink
        buttonProps={{
          classes  : { root: 'stackedButton', fullWidth: 'tabletSizeLarge' },
          color    : 'primary',
          fullWidth: true,
          size     : 'large',
          variant  : 'contained',
        }}
      >
        {t('stopGate.stateNotCovered.button.contact', { defaultValue: 'Contact Us' })}
      </ContactLink>
    </ImageSection>
  );
};

const mapStateToProps = (state, ownProps) => ({
  stopGateHeloc: state.config.stopGateHelocImage,
  clientName   : state.config.clientDisplayName,
  ...ownProps,
});

export const StateNotCovered = connect(
  mapStateToProps,
  null,
)(withTranslation()(StateNotCoveredComponent));
