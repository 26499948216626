import React, { useState } from 'react';
import { FormGroup, FormHelperText, FormLabel } from '@material-ui/core';
import { Field } from 'redux-form';
import { renderCheckboxBase } from 'app/components/FormFields/_renderCheckboxBase';
import { Button } from 'app/components/Button';
import { useTranslation } from 'react-i18next';
import { nestedValueByString } from 'app/util/misc';
import { hasAtLeastOneChecked } from 'app/util/validators';
import { DECLARATION_NO_SHARE } from 'app/models/options/options';

const DeclarationCheckboxesComponent = (props) => {
  const { borderTitle, name, label, values, syncErrors } = props;
  const { t } = useTranslation();

  const error = nestedValueByString(syncErrors, name);
  const value = nestedValueByString(values, name);

  const hasError = !!error;
  const hasNoShareValueOrEmpty = value?.length === 0 || value?.includes(DECLARATION_NO_SHARE);
  const [isViewingAll, setIsViewingAll] = useState(!hasNoShareValueOrEmpty);
  const isDisplayingAllOptions = isViewingAll || hasError || !hasNoShareValueOrEmpty;
  const options = isDisplayingAllOptions ? props.options : [props.options[0]];

  const handleClick = () => {
    setIsViewingAll(!isViewingAll);
  };

  const isFirstGroup = borderTitle ? 'first' : '';
  return (
    <div>
      <FormGroup classes={{ root: 'topLevel' }} className={isFirstGroup}>
        <FormLabel classes={{ root: 'legend' }}>
          { label }
          { hasError && <FormHelperText error={hasError}>{error}</FormHelperText> }
        </FormLabel>
        {
          !isDisplayingAllOptions ? <Button
            disableRipple
            color   = "primary"
            classes = {{
              root: 'link',
              text: 'viewAll',
            }}
            onClick = {handleClick}
          >
            { t('button.decCheckbox.viewAll', { defaultValue: 'View All' }) }
          </Button> : <></>
        }

        <Field
          component = {renderCheckboxBase}
          name      = {name}
          validate  = {hasAtLeastOneChecked}
          options   = {options}
          values    = {values}
        />
      </FormGroup>
    </div>
  );
};

export const DeclarationCheckboxes = (DeclarationCheckboxesComponent);
