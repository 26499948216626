import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { logger } from 'app/util/logger';
import { getSlug } from 'app/util/headers';
import { PropertyType, PropertyUsage } from 'app/models/options/enums';
import { ProductFamily } from 'app/models/types';
import { LiabilityAccount } from 'app/api/credit-report';
import { RefinancePurpose } from '@lenderful/domain';

export interface LoanEstimateRequest {
  annualInsurance         : number;
  annualTaxes             : number;
  coBorrowerFirstName?    : string;
  coBorrowerMiddleName?   : string;
  coBorrowerLastName?     : string;
  coBorrowerSuffixName?   : string;
  closingCostType         : ClosingCostType;
  firstName               : string;
  hasEscrow               : boolean;
  lastName                : string;
  loanAmount              : number;
  loanPurpose             : RefinancePurpose;
  loanTerm                : number;
  loanType                : ProductFamily;
  middleName              : string;
  monthlyPayment          : number;
  pointFee                : number;
  propertyAppraisedValue  : number;
  propertyStreet          : string;
  propertyStreet2?        : string;
  propertyCity            : string;
  propertyState           : string;
  propertyType            : PropertyType;
  propertyUsage           : PropertyUsage;
  propertyZip             : string;
  rate                    : number;
  realEstateLiabilities   : LiabilityAccount[];
  slug?                   : string;
  suffixName?             : string;
  totalNumberOfProperties : number;
}

/** Closing costs will differ if we use our "Express" quick closing method */
export enum ClosingCostType {
  STANDARD = 'STANDARD',
  EXPRESS  = 'EXPRESS',
}

export interface LoanEstimateResponse {
  summaryPdf    : string,
  settlementPdf : string,
}

/**
 * This API call returns the closing costs PDF as binary
 *
 * @param {LoanEstimateRequest} request
 * @returns {Blob} file-like object that represents binary data for the PDF
 */
export const postLoanEstimate = (request: LoanEstimateRequest): Promise<LoanEstimateResponse> => {
  const headers = { accept: 'application/pdf' };
  request.slug = getSlug();
  return client
    .post<LoanEstimateResponse>(Endpoints.LOAN_ESTIMATE, request, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('ERROR: Failed to get closing costs PDF');
      throw error;
    });
};
