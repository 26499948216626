import Axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { Endpoints } from 'app/api/helpers';
import {
  appConfigMock,
  contactUsMockReponse,
  ratesMockResponse,
  longformMockResponse,
  equityMock } from 'app/api/mocks';
import axiosRetry from 'axios-retry';

export const API_ROOT_PROXY = '/api';

const client = Axios.create({
  baseURL: API_ROOT_PROXY,
  timeout: 45000,
});

const retryClient = Axios.create({
  baseURL: API_ROOT_PROXY,
  timeout: 45000,
});

axiosRetry(retryClient, { retries: 3, retryDelay: (retryCount) => {
  return retryCount * 500;
} });

let mock: MockAdapter;
if (process.env.NODE_ENV === 'test') {
  console.log('TEST MODE: API calls are being mocked');
  mock = new MockAdapter(client);
  /* API Mocks */
  mock.onGet(Endpoints.CONFIG).reply(200, appConfigMock);
  mock.onPost(Endpoints.CONTACT_US_EMAIL).reply(200, contactUsMockReponse);
  mock.onPost(Endpoints.LONGFORM).reply(200, longformMockResponse);
  mock.onPost(Endpoints.RATES).reply(200, ratesMockResponse);
  mock.onPost(Endpoints.EQUITYRATES).reply(200, equityMock);
}

export { client, retryClient, mock };
