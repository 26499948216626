import { SHARED } from 'app/models/questions/shared';
import { Currency } from 'app/components/FormFields/Currency';
import { Name } from 'app/components/FormFields/Name';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { Address } from 'app/components/FormFields/Address';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { State } from 'app/components/FormFields/State';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import {
  REVERSE_MORTGAGE_LOAN_PURPOSE,
  REVERSE_PROPERTY_TITLE_HELD,
  YES_OR_NO_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  hasAddlProperties,
  hasCoBorrower,
  hasMortgageOnProperty,
  hasReverseAgeQuestion,
  hasSelectedNewHome,
} from 'app/models/fields/conditionals';
import { CollectConditionallyRequiredSsn } from 'app/components/FormFields/Ssn/CollectConditionallyRequiredSsn';
import { Integer } from '../../components/FormFields/Integer';

/**
 * Takes in a field name and returns the corresponding reverse
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const reverseQuestionByName = (name: string): Question => {
  return APPLICATION_REVERSE_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const reverseQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(reverseQuestionByName(name));
  }
  return questions;
};

export const APPLICATION_REVERSE_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.propertyUsage],
    name: FieldNames.propertyUsage,
    component: Select,
  },
  {
    id        : 1007,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.propertyZip,
    showIf    : [hasSelectedNewHome],
  },
  {
    id        : 1008,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.propertyStreet,
    title     : 'Where is the property located?',
    showIf    : [hasSelectedNewHome],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id        : 1009,
    component : Address,
    label     : 'City',
    name      : FieldNames.propertyCity,
    showIf    : [hasSelectedNewHome],
  },
  {
    id        : 1010,
    component : State,
    label     : 'State',
    name      : FieldNames.propertyState,
    showIf    : [hasSelectedNewHome],
  },
  {
    id       : 1011,
    component: CurrencyAllowZero,
    name     : FieldNames.mortgageBalance,
    showIf   : [hasMortgageOnProperty],
    title    : 'What is the current mortgage balance?',
  },
  {
    id: 1012,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id: 1013,
    ...SHARED[FieldNames.dateOfBirth],
    showIf: [(values) => !hasReverseAgeQuestion(values)],
  },
  {
    id: 1014,
    component: RadioField,
    name     : FieldNames.coBorrowerYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will you have a co-borrower?',
  },
  {
    id: 1015,
    ...SHARED[FieldNames.firstName],
    name  : FieldNames.coBorrowerFirstName,
    title : 'Tell us about the co-borrower',
    showIf: [hasCoBorrower],
  },
  {
    id: 1016,
    ...SHARED[FieldNames.lastName],
    name  : FieldNames.coBorrowerLastName,
    showIf: [hasCoBorrower],
  },
  {
    id: 1017,
    ...SHARED[FieldNames.dateOfBirth],
    name  : FieldNames.coBorrowerDOB,
    showIf: [(values) => !hasReverseAgeQuestion(values) && hasCoBorrower(values)],
  },
  {
    id       : 1018,
    component: RadioField,
    name     : FieldNames.coBorrowerOnTitle,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Is the Co-Borrower on this title?',
    showIf   : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1019,
    component    : Select,
    fieldPopover : true,
    name         : FieldNames.propertyType,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id: 1020,
    name  : FieldNames.livingPropertyZip,
  },
  {
    id       : 1023,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id       : 1024,
    component: Address,
    label    : 'City',
    name     : FieldNames.livingPropertyCity,
  },
  {
    id       : 1025,
    component: State,
    label    : 'State',
    name     : FieldNames.livingPropertyState,
  },
  {
    id        : 1026,
    component : Name,
    label     : 'Middle Name',
    name      : FieldNames.coBorrowerMiddleName,
    isOptional: true,
    showIf    : [hasCoBorrower],
  },
  {
    id        : 1027,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    ...SHARED[FieldNames.email],
    id     : 1028,
    name   : FieldNames.coBorrowerEmail,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1029,
    name   : FieldNames.coBorrowerPhone,
    showIf : [hasCoBorrower],
  },

  {
    id: 1030,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1031,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
  {
    id: 1032,
    ...SHARED[FieldNames.citizenshipType],
    title: "What is your citizenship?",
  },
  {
    id: 1033,
    ...SHARED[FieldNames.coBorrowerCitizenshipType],
  },
  {
    id        : 1034,
    component : RadioField,
    name      : FieldNames.propertyHasMortgage,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have a mortgage on your home?',
  },
  {
    id        : 1035,
    component : RadioField,
    name      : FieldNames.propertyTitleHeld,
    options   : REVERSE_PROPERTY_TITLE_HELD,
    title     : 'How is the property title held?',
  },
  {
    id       : 1041,
    component: CurrencyAllowZero,
    label    : 'Value of Additional Real Estate Assets',
    name     : FieldNames.realEstateTotalValue,
    showIf   : [hasAddlProperties],
  },
  {
    id       : 1042,
    component: CurrencyAllowZero,
    label    : 'Total Available Assets',
    name     : FieldNames.assetTotalValue,
  },
  {
    id        : 1043,
    component : Currency,
    name      : FieldNames.homeValue,
    title     : 'not used but is essential for translations',
  },
  {
    id        : 1044,
    component: CurrencyAllowZero,
    label    : 'Co-Borrower Total Available Assets',
    name     : FieldNames.coBorrowerAssetTotalValue,
    showIf: [hasCoBorrower],
  },
  {
    id        : 1045,
    ...SHARED[FieldNames.maritalStatus],
    name   : FieldNames.coBorrowerMaritalStatus,
    title  : `Co-borrower's marital status?`,
    showIf : [hasCoBorrower],
  },
  {
    id       : 1047,
    component: CurrencyAllowZero,
    label    : 'Monthly Income',
    name     : FieldNames.incomeMonthly,
    title    : 'Let\'s collect some information on income and assets',
  },
  {
    id       : 1048,
    component: CurrencyAllowZero,
    title    : 'And for your Co-Borrower',
    label    : 'Co-Borrower Monthly Income',
    name     : FieldNames.coBorrowerIncomeMonthly,
    showIf   : [hasCoBorrower],
  },
  {
    id       : 1049,
    component: CurrencyAllowZero,
    label    : 'Co-Borrower Total Real Estate Assets',
    name     : FieldNames.coBorrowerRealEstateTotalValue,
    showIf   : [hasCoBorrower],
  },
  {
    id         : 1050,
    component  : Currency,
    label      : 'Annual Taxes',
    name       : FieldNames.livingYearTaxes,
  },
  {
    id         : 1051,
    component  : Currency,
    label      : 'Annual Insurance',
    name       : FieldNames.livingYearInsure,
  },
  {
    id         : 1052,
    component  : Currency,
    isOptional : true,
    label      : 'Annual Homeowners Association Fees',
    name       : FieldNames.livingYearAssociate,
  },
  {
    id        : 1053,
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Have you paid any of the above property charges late in the last 2 years?',
    name      : FieldNames.hasLatePropertyCharges,
  },
  {
    ...SHARED[FieldNames.hasAddlPropertiesYN],
    id    : 1054,
    title : 'Do you own additional real estate other than this property?',
  },
  {
    id        : 1055,
    component : RadioField,
    title     : 'What is your goal with the Reverse Mortgage?',
    options   : REVERSE_MORTGAGE_LOAN_PURPOSE,
    name      : FieldNames.reverseMortgageLoanPurpose,
  },
  {
    id        : 1056,
    component : Currency,
    label     : 'Expected Purchase Price',
    name      : FieldNames.purchasePrice,
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1057,
    component    : Select,
    name         : FieldNames.livingPropertyType,
    fieldPopover : true,
  },
  {
    id        : 1058,
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
    name      : FieldNames.hasSelectedNewHome,
    title     : 'Have you selected a new home?',
  },
  {
    id         : 1059,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.propertyStreet2,
    showIf     : [hasSelectedNewHome],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id        : 1060,
    component : CollectConditionallyRequiredSsn,
    name      : FieldNames.ssnQuestionRequirement,
  },
  {
    id: 1061,
    component : Integer,
    name      : FieldNames.age,
    label     : 'Age',
    showIf    : [hasReverseAgeQuestion],
  },
  {
    id: 1062,
    name  : FieldNames.coBorrowerAge,
    component : Integer,
    label     : 'Age',
    showIf: [(values) => hasReverseAgeQuestion(values) && hasCoBorrower(values)],
  },
];
