
export enum Type {
  AJAX_VERIFICATION_ADD_CUSTOMERS                     = 'AJAX_VERIFICATION_ADD_CUSTOMERS',
  AJAX_VERIFICATION_ADD_CUSTOMERS_SUCCESS             = 'AJAX_VERIFICATION_ADD_CUSTOMERS_SUCCESS',
  AJAX_VERIFICATION_ADD_CUSTOMERS_FAIL                = 'AJAX_VERIFICATION_ADD_CUSTOMERS_FAIL',
  AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS              = 'AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS',
  AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS_SUCCESS      = 'AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS_SUCCESS',
  AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS_FAIL         = 'AJAX_VERIFICATION_DELETE_ALL_CUSTOMERS_FAIL',
  AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS             = 'AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS',
  AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS_SUCCESS     = 'AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS_SUCCESS',
  AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS_FAIL        = 'AJAX_VERIFICATION_GET_CUSTOMER_ACCOUNTS_FAIL',
  AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS         = 'AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS',
  AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_SUCCESS = 'AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_SUCCESS',
  AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_FAIL    = 'AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_FAIL',
  AJAX_VERIFICATION_GET_CUSTOMER_REPORTS              = 'AJAX_VERIFICATION_GET_CUSTOMER_REPORTS',
  AJAX_VERIFICATION_GET_CUSTOMER_REPORTS_SUCCESS      = 'AJAX_VERIFICATION_GET_CUSTOMER_REPORTS_SUCCESS',
  AJAX_VERIFICATION_GET_CUSTOMER_REPORTS_FAIL         = 'AJAX_VERIFICATION_GET_CUSTOMER_REPORTS_FAIL',
  VERIFICATION_CONNECT_COMPLETE                       = 'VERIFICATION_CONNECT_COMPLETE',
  VERIFICATION_REFRESH_ACCOUNT_COMPLETE               = 'VERIFICATION_REFRESH_ACCOUNT_COMPLETE',
  VERIFICATION_REPORT_GENERATION_COMPLETE             = 'VERIFICATION_REPORT_GENERATION_COMPLETE',
}

// The order of the status indicates the order of the process
export enum StatusType {
  NOT_STARTED,
  URL_GENERATED,
  CONNECT_COMPLETE,
  REFRESH_ACCOUNTS_COMPLETE,
  CREATE_REPORTS_STARTED,
  CREATE_REPORTS_COMPLETE,
}

export type FinancialVerificationState = {
  borrowerCustomerId    : string;
  coBorrowerCustomerId? : string;
  status                : StatusType
  verificationUrl       : string;
}
