import download from 'downloadjs';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Fade, Grid, IconButton, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { documentUploaderSelector } from 'app/reducers/app-config';
import { getFormValues } from 'redux-form';
import { FormName } from '../../models/options/enums';
import { ButtonDelay } from '../ButtonDelay';
import { FieldNames } from 'app/models/fields/names';
import { handleAutoCreateInsuranceQuote } from 'app/actions/form/insurance-quote/actions';
import { isYes } from 'app/models/fields/conditionals';
import CloseIcon from '@material-ui/icons/Close';

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = WithTranslation & MappedProps & DispatchProps;

const AutoConfirmationComponent = (props: Props) => {
  const { confirmationImage, documentUploader, formData, handleAutoCreateInsuranceQuote } = props;
  const hasDocumentUploader = !!documentUploader;
  const [isLoading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    handleAutoCreateInsuranceQuote(formData)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          download(response.data, 'Credit Life Insurance Quote', 'application/pdf');
        } else {
          setFailed(true);
        }
      });
  };

  const uploadDocumentsMessage = <React.Fragment>
    <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
      <Trans i18nKey='autoLoanConfirmation.documentUpload.title'>
        Please upload the following documents:
      </Trans>
    </Typography>
    <Typography variant='body2' paragraph={true}>
      <Trans i18nKey='autoLoanConfirmation.documentUpload.paragraph1'>
        <ul>
          <li>Copy of Driver's License</li>
          <li>Proof of Income: W2s and Paystubs (2)</li>
        </ul>
      </Trans>
    </Typography>
  </React.Fragment>;

  const thankYouMessage = <React.Fragment>
    <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
      <Trans i18nKey='autoLoanConfirmation.thankYou.title'>
        Thank you!
      </Trans>
    </Typography>
    <Typography variant='body2' paragraph={true}>
      <Trans i18nKey='autoLoanConfirmation.thankYou.paragraph1'>
        A bank officer will be in touch with you in the next business day or two.
      </Trans>
    </Typography>
  </React.Fragment>;
  const failedDownload = <React.Fragment>
    <div>
      <Snackbar
        anchorOrigin={{
          vertical  : 'top',
          horizontal: 'center',
        }}
        open                = {failed}
        autoHideDuration    = { 10000}
        TransitionComponent = {Fade}
        onClose             = {() => setFailed(false)}
      >
        <SnackbarContent
          message={
            <p>Insurance Quote pdf failed to download</p>
          }
          action={
            <IconButton key="close" aria-label="close" color="inherit" onClick={() => setFailed(false)}>
              <CloseIcon  />
            </IconButton>
          }
        />
      </Snackbar>
    </div>
  </React.Fragment>;

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        { failed ? failedDownload : <></> }
        <ImageSection image={confirmationImage}>
          { hasDocumentUploader ? uploadDocumentsMessage : thankYouMessage }
          <Grid container justifyContent='center'>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
          </Grid>
          { isYes(formData[FieldNames.wantsAutoInsurance]) ?
            <Grid container justifyContent='center'>
              <Grid item>
                <ButtonDelay
                  loading={ isLoading }
                  buttonProps={ {
                    classes  : { root: 'longButton' },
                    color    : 'primary',
                    disabled : isLoading,
                    variant  : 'contained',
                    target   : '_blank',
                    onClick  : handleClick,
                  } }
                  pendingText={ 'Downloading' }
                >
                  { 'Download Credit Life Ins Quote' }
                </ButtonDelay>
              </Grid>
            </Grid> : <></>
          }
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationImage : state.config.confirmationImage,
  documentUploader  : documentUploaderSelector(state),
  formData          : getFormValues(FormName.AUTOLOAN)(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleAutoCreateInsuranceQuote: (request) => dispatch(handleAutoCreateInsuranceQuote(request)),
});

export const AutoConfirmation = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AutoConfirmationComponent));
