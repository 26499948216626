import { RadioField } from 'app/components/FormFields/RadioField';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from '../../components/FormFields/Currency';
import { SHARED } from 'app/models/questions/shared';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { SinglePastDate } from '../../components/FormFields/SinglePastDate';
import { TextInput } from '../../components/FormFields/TextInput';
import { ZipCode } from '../../components/FormFields/ZipCode';
import { CREDIT_BOOSTER_ACCOUNTS } from '../options/options';
import { CreditBoosterGeneralInfoComponent } from '../../components/CreditBooster/CreditBoosterGeneralInfo';
import { CreditBoosterConfirmation } from '../../components/CreditBooster/CreditBoosterConfirmation';
import { CreditBoosterLoanOptions } from 'app/components/CreditBooster/CreditBoosterLoanOptions';

/**
 * Takes in a field name and returns the corresponding credit booster loan
 * question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const creditBoosterQuestionByName = (name: string): Question => {
  return CREDIT_BOOSTER_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const creditBoosterQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(creditBoosterQuestionByName(name));
  }
  return questions;
};

export const creditBoosterQuestionById = (id: number): Question => {
  return CREDIT_BOOSTER_QUESTIONS.find((question) => question.id === id) as Question;
};
export const CREDIT_BOOSTER_QUESTIONS: Question[] = [
  {
    id: 1000,
    component: CreditBoosterGeneralInfoComponent,
    name: 'CreditBoosterGeneralInfo',
  },
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
    title: 'Enter your personal information',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id           : 1008,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id           : 1009,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id           : 1010,
    component    : Address,
    label        : 'City',
    name         : FieldNames.livingPropertyCity,
  },
  {
    id           : 1011,
    component    : State,
    label        : 'State',
    name         : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id           : 1012,
    name         : FieldNames.livingPropertyZip,
    hideTitle : true,
  },
  {
    id           : 1013,
    component    : TextInput,
    label        : 'Company Name',
    name         : FieldNames.employCompanyName,
  },
  {
    id           : 1014,
    component    : TextInput,
    label        : 'Job Title',
    isOptional   : true,
    name         : FieldNames.employTitle,
  },
  {
    id           : 1015,
    component    : SinglePastDate,
    label        : 'Start Date',
    name         : FieldNames.employStart,
  },
  {
    id           : 1016,
    component    : Currency,
    label        : 'Monthly Gross Pay',
    name         : FieldNames.employBase,
    fieldPopover : true,
    popover      : 'Monthly gross pay can include base pay, OT, commission, bonuses and similar other pay.',
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1017,
    name : FieldNames.employPhone,
  },
  {
    id       : 1018,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.employStreet,
  },
  {
    id        : 1019,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.employStreet2,
  },
  {
    id       : 1020,
    component: Address,
    label    : 'City',
    name     : FieldNames.employCity,
  },
  {
    id       : 1021,
    component: State,
    label    : 'State',
    name     : FieldNames.employState,
  },
  {
    id       : 1022,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.employZip,
  },
  {
    id        : 1023,
    component : RadioField,
    options   : CREDIT_BOOSTER_ACCOUNTS,
    name      : FieldNames.accountSummary,
  },
  {
    id        : 1024,
    component : CreditBoosterLoanOptions,
    name      : FieldNames.creditBoosterLoanOption,
  },
  {
    id          : 1025,
    component   : CreditBoosterConfirmation,
    name        : 'FinalPage',
  },
  {
    id        : 1026,
    component : Currency,
    label     : 'Loan Amount',
    name      : FieldNames.loanAmount,
    validator : {
      type: 'isBetween',
      param: [FieldNames.minLoanAmount, FieldNames.maxLoanAmount],
    },
  },
];
