import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import { lifeInsuranceInitialValue } from '../../../util/initial-values';

const lifeInsuranceInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.financialStatementCompany,
    isOptional : false,
    label      : 'Company',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
  {
    fieldName  : FieldNames.financialStatementBeneficiary,
    isOptional : false,
    label      : 'Beneficiary',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
  {
    fieldName  : FieldNames.financialStatementFaceValue,
    isOptional : false,
    label      : 'Face Value',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.financialStatementCashValue,
    isOptional : false,
    label      : 'Cash',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.financialStatementJointAccount,
    isOptional : false,
    label      : 'Is this held jointly',
    type       : DynamicFormFieldArrayType.Y_N_SWITCH_TOGGLE,
  },
];

const lifeInsuranceProps: DynamicFormFieldArray = {
  addButtonText : 'Policy',
  initialValue  : lifeInsuranceInitialValue,
  inputs        : lifeInsuranceInputs,
};

export const LifeInsuranceFFAComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: lifeInsuranceProps, name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
