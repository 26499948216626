import React from 'react';
import { Button } from 'app/components/Button';
import { Grid, Typography, createStyles, withStyles, WithStyles, colors, Theme, PropTypes, WithTheme, withTheme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RateInfo } from 'app/models/types';

const styles = (theme: Theme) => {
  return createStyles({
    row: {
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    loanItems: {
      '&:nth-child(even)': {
        background: lighten(colors.grey[100], .2),
      },
    },
    maxWidth: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '420px',
        width   : '100%',
        margin  : '0 auto',
      },
    },
  });
};

interface Props extends WithStyles<typeof styles, true>, WithTheme, WithTranslation {
  rates                        : RateInfo[];
  color                        : PropTypes.Color;
  setSelectedRate              : (payload) => (dispatch, getState) => void;
  handleAutoLoanRateSelect : () => (dispatch, getState) => void;
}

class AutoLoanTermsComponent extends React.Component<Props, any> {

  renderApplyButton = (rateInfo: RateInfo): JSX.Element => {
    const { setSelectedRate, handleAutoLoanRateSelect, color } = this.props;
    const onClick = (): void => {
      setSelectedRate(rateInfo);
      handleAutoLoanRateSelect();
    };
    return (
      <Grid item>
        <Button
          classes = {{ sizeSmall: 'mobileSmall' }}
          color   = { color }
          onClick = { onClick }
          size    = 'small'
          variant = 'contained'
        >
          Apply Now
        </Button>
      </Grid>
    );
  };

  renderTerm = (rateInfo: RateInfo): JSX.Element => {
    const { classes } = this.props;
    const { rate, productName } = rateInfo;
    return (
      <Grid container alignItems='center' justifyContent='space-between' className={classes.maxWidth}>
        <Grid item>
          <Typography component='h3' variant='body2'>
            <Trans i18nKey='AutoLoan.summary.termDetails' values={{ productName, rate }}>
              {'{{ productName }}: <strong>{{ rate, percent.3 }} Rate</strong>'}
            </Trans>
          </Typography>
        </Grid>
        { this.renderApplyButton(rateInfo) }
      </Grid>
    );
  };

  render() {
    const { classes, rates } = this.props;
    return rates.map((rate, index) => (
      <Grid
        item
        xs        = {12}
        className = {`${classes.row} ${classes.loanItems}`}
        key       = {`${rate.loanType}-${index}`}
      >
        { this.renderTerm(rate) }
      </Grid>
    ));
  }
}

export const AutoLoanTerms = withTheme(withStyles(styles)(withTranslation()(AutoLoanTermsComponent)));
