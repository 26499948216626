import React from 'react';
import { Field } from 'redux-form';
import { required, noopValidate, VALIDATORS } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import { InputAdornment } from '@material-ui/core';

export const TextInput = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : isRequired;
  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      customProps  = {{
        endAdornment:
          <InputAdornment position="end">{ props.endAdornment ? props.endAdornment : '' }</InputAdornment>,
      }}
      component    = {renderInputBase}
      label        = {props.label}
      fieldPopover = {props.fieldPopover}
      popover      = {props.popover}
      maxLength    = {50}
      name         = {name}
      type         = "text"
      validate     = {conditionalValidator}
    />
  );
};
