import { INCOME_DISCLAIMER, SHARED } from 'app/models/questions/shared';
import { RadioField } from 'app/components/FormFields/RadioField';
import { TextInput } from 'app/components/FormFields/TextInput';
import {
  COMMERCIAL_LIVING_SITUATION_OPTIONS,
  COMMERCIAL_BUSINESS_TYPE_OPTIONS,
  COMMERCIAL_ENTITY_TYPE_OPTIONS,
  COMMERCIAL_LOAN_HOLDER_OPTIONS,
  COMMERCIAL_TREND_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  YES_OR_NO_OPTIONS,
  YEAR_1970PLUS_TO_NOW,
  STATE_OPTIONS,
  EQUIPMENT_OR_INVENTORY_OPTIONS,
  COMMERCIAL_PURCHASE_TIMING_OPTIONS, COMMERCIAL_BUSINESS_OWNERSHIP_TYPE_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  isLessThanTwoYrsAtJob,
  isEmploymentOther,
  isRetired,
  isEmploySameBusiness,
  isEquipment,
  isInventory,
  hasOtherManagement,
} from 'app/models/fields/conditionals';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { State } from 'app/components/FormFields/State';
import { Address } from 'app/components/FormFields/Address';
import { Currency } from 'app/components/FormFields/Currency';
import { Name } from 'app/components/FormFields/Name';
import { Select } from 'app/components/FormFields/Select';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { SingleDate } from 'app/components/FormFields/SingleDate';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { TextArea } from '../../components/FormFields/TextArea';
import { Owners } from '../../components/FormFieldArrays/Owners';
import { BusinessManagement } from '../../components/FormFieldArrays/BusinessManagement';
import { Integer } from '../../components/FormFields/Integer';
import { CurrencyAllowZero } from '../../components/FormFields/CurrencyAllowZero';
import {
  COMMERCIAL_EQUIPMENT_BUSINESS_NEED,
  COMMERCIAL_EQUIPMENT_DESCRIPTION,
  COMMERCIAL_EQUIPMENT_DOWN_PAYMENT,
  COMMERCIAL_EQUIPMENT_EXPECTED_COSTS,
  COMMERCIAL_EQUIPMENT_LIFE_EXPECTANCY,
  COMMERCIAL_EQUIPMENT_PURCHASE_TIMING,
  COMMERCIAL_INVENTORY_BUSINESS_NEED,
  COMMERCIAL_INVENTORY_DESCRIPTION,
  COMMERCIAL_INVENTORY_EXPECTED_COSTS,
  COMMERCIAL_INVENTORY_SHELF_LIFE,
} from '../sections/constants';

/**
 * Takes in a field id and returns the corresponding commercial equipment question
 *
 * @param {number} id Field id
 * @returns {Question} Question with matching field id
 */
export const getCommercialEquipmentQuestionById = (id: number): Question => {
  return COMMERCIAL_EQUIPMENT_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field ids and returns an array of questions
 *
 * @param {number[]} [ids=[]] Field ids
 * @returns {Question[]} Questions matching field ids
 */
export const getCommercialEquipmentQuestionsById = (ids: number[] = []): Question[] => {
  const questions: Question[] = [];
  for (const id of ids) {
    questions.push(getCommercialEquipmentQuestionById(id));
  }
  return questions;
};

/**
 * Takes in a field name and returns the corresponding commercial equipment question
 *
 * @param {string} name Field name
 * @returns {Question} Question with matching field name
 */
export const getCommercialEquipmentQuestionByName = (name: string): Question => {
  return COMMERCIAL_EQUIPMENT_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns {Question[]} Questions matching field names
 */
export const getCommercialEquipmentQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(getCommercialEquipmentQuestionByName(name));
  }
  return questions;
};

export const COMMERCIAL_EQUIPMENT_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id        : 1007,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.propertyStreet,
    title     : 'Where is the property located?',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id         : 1008,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.propertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id        : 1009,
    component : Address,
    label     : 'City',
    name      : FieldNames.propertyCity,
  },
  {
    id        : 1010,
    component : State,
    label     : 'State',
    name      : FieldNames.propertyState,
  },
  {
    id        : 1011,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.propertyZip,
  },
  {
    id        : 1012,
    component : RadioField,
    name      : FieldNames.livingRentOrOwn,
    options   : COMMERCIAL_LIVING_SITUATION_OPTIONS,
  },
  {
    ...SHARED[FieldNames.propertyType],
    id           : 1013,
    component    : Select,
    fieldPopover : true,
    name         : FieldNames.livingPropertyType,
  },
  {
    id        : 1014,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id         : 1015,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 1016,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
  },
  {
    id        : 1017,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id   : 1018,
    name : FieldNames.livingPropertyZip,
  },
  {
    id        : 1019,
    component : Currency,
    label     : 'Monthly Rent Payment',
    name      : FieldNames.livingMonthlyRentAmount,
  },
  {
    id         : 1020,
    component  : RadioField,
    name       : FieldNames.employmentType,
    options    : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer : INCOME_DISCLAIMER,
  },
  {
    id        : 1021,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.employCompanyName,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1022,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.employStreet,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id         : 1023,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.employStreet2,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1024,
    component : Address,
    label     : 'City',
    name      : FieldNames.employCity,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1025,
    component : State,
    label     : 'State',
    name      : FieldNames.employState,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 1026,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.employZip,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id         : 1027,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.employTitle,
  },
  {
    ...SHARED[FieldNames.phone],
    id   : 1028,
    name : FieldNames.employPhone,
  },
  {
    id        : 1029,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employStart,
  },
  {
    id        : 1030,
    component : Currency,
    label     : 'Monthly Base Pay',
    name      : FieldNames.employBase,
  },
  {
    id           : 1031,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Overtime',
    name         : FieldNames.employOvertime,
    title        : 'Additional income sources?',
    popover      : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id          : 1032,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    popover     : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id           : 1033,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Bonus',
    name         : FieldNames.employBonus,
    popover      : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id        : 1034,
    component : IncomeSources,
    name      : FieldNames.additionalIncome,
  },
  {
    id        : 1035,
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.employPrevName,
    title     : 'Previous employment information',
    showIf    : [isLessThanTwoYrsAtJob],
    popover   : 'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id        : 1036,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.employPrevStreet,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id         : 1037,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.employPrevStreet2,
    showIf     : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1038,
    component : Address,
    label     : 'City',
    name      : FieldNames.employPrevCity,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1039,
    component : State,
    label     : 'State',
    name      : FieldNames.employPrevState,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1040,
    component : ZipCode,
    label     : 'Zip',
    name      : FieldNames.employPrevZip,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1041,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employPrevStart,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 1042,
    component : SingleDate,
    label     : 'End Date',
    name      : FieldNames.employPrevEnd,
    showIf    : [isLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id        : 1043,
    component : RetirementIncomeSources,
    title     : 'Tell us about your retirement income',
    name      : FieldNames.retirementIncome,
    showIf    : [isRetired],
  },
  {
    id        : 1044,
    component : OtherIncomeSources,
    title     : 'Tell us about your other income',
    name      : FieldNames.otherIncome,
    showIf    : [isEmploymentOther],
  },
  {
    id: 1045,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1046,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id        : 1047,
    component : Integer,
    label     : 'Number of Employees',
    name      : FieldNames.commercialEmployeeCount,
  },
  {
    id         : 1048,
    component  : TextInput,
    isOptional : true,
    label      : 'Business Website',
    name       : FieldNames.commercialWebsite,
  },
  {
    id         : 1049,
    component  : Select,
    label      : 'Entity Type',
    name       : FieldNames.commercialEntityType,
    options   : COMMERCIAL_ENTITY_TYPE_OPTIONS,
  },
  {
    id         : 1050,
    component  : Select,
    label      : 'Business Type',
    name       : FieldNames.commercialBusinessType,
    options   : COMMERCIAL_BUSINESS_TYPE_OPTIONS,
  },
  {
    id         : 1051,
    component  : Select,
    label      : '3 Year Revenue Trend',
    name       : FieldNames.commercialRevenueTrend3,
    options    : COMMERCIAL_TREND_OPTIONS,
  },
  {
    id         : 1052,
    component  : Select,
    label      : '3 Year Profitability Trend',
    name       : FieldNames.commercialProfitTrend3,
    options    : COMMERCIAL_TREND_OPTIONS,
  },
  {
    id        : 1053,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.commercialStreet,
    title     : 'Where is the business located?',
  },
  {
    id         : 1054,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.commercialStreet2,
  },
  {
    id        : 1055,
    component : Address,
    label     : 'City',
    name      : FieldNames.commercialCity,
  },
  {
    id        : 1056,
    component : State,
    label     : 'State',
    name      : FieldNames.commercialState,
  },
  {
    id        : 1057,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.commercialZip,
  },
  {
    ...SHARED[FieldNames.phone],
    id     : 1058,
    name   : FieldNames.commercialPhone,
  },
  {
    id        : 1059,
    component : RadioField,
    title     : 'Will the loan be held by the business or an individual?',
    name      : FieldNames.commercialLoanHolder,
    options   : COMMERCIAL_LOAN_HOLDER_OPTIONS,
  },
  {
    id        : 1060,
    component : RadioField,
    title     : 'Do you work for the business seeking the loan?',
    name      : FieldNames.commercialEmploySameBusiness,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 1061,
    component : RadioField,
    title     : 'Do you work for the business seeking the loan?',
    name      : FieldNames.commercialEmployCoBorrowerSameBusiness,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id         : 1062,
    component  : TextInput,
    isOptional : true,
    label      : 'DBA',
    name       : FieldNames.commercialDBA,
  },
  {
    id         : 1063,
    component  : Name,
    label      : 'Business Name',
    name       : FieldNames.commercialBusinessName,
  },
  {
    id: 1064,
    label       : 'Revenue Explanation',
    component   : TextArea,
    name        : FieldNames.commercialRevenueExplanation,
  },
  {
    id: 1065,
    label       : 'Profit Explanation',
    component   : TextArea,
    name        : FieldNames.commercialProfitExplanation,
  },
  {
    id: 1066,
    label       : 'List of Key Collateral',
    component   : TextArea,
    name        : FieldNames.commercialCollateralList,
  },
  {
    id: 1067,
    component   : Owners,
    name        : FieldNames.commercialOwnerList,
  },
  {
    id: 1068,
    component   : BusinessManagement,
    name        : FieldNames.commercialManagementList,
    showIf      : [hasOtherManagement],
  },
  {
    id: 1069,
    component   : Select,
    label       : 'State of Incorporation',
    name        : FieldNames.commercialStateOfIncorporation,
    options     : STATE_OPTIONS,
  },
  {
    id: 1070,
    component   : Select,
    label       : 'Year Started',
    name        : FieldNames.commercialYearStarted,
    options     : YEAR_1970PLUS_TO_NOW,
  },
  {
    id        : 1071,
    component : RadioField,
    title     : 'What are you looking to purchase?',
    name      : FieldNames.commercialEquipmentOrInventory,
    options   : EQUIPMENT_OR_INVENTORY_OPTIONS,
  },
  {
    id        : 1072,
    component : RadioField,
    title     : 'Are there other key business management personnel?',
    name      : FieldNames.hasOtherManagement,
    options   : YES_OR_NO_OPTIONS,
  },

  {
    id         : 1073,
    component  : Integer,
    isOptional : true,
    label      : 'Census Tract',
    name       : FieldNames.commercialCensusTract,
  },
  {
    id         : 1074,
    component  : Integer,
    isOptional : true,
    label      : 'NAICS Code',
    name       : FieldNames.commercialNAICSCode,
  },
  {
    id        : 1075,
    component : Currency,
    label     : 'Revenue for Last Fiscal Year',
    name      : FieldNames.commercialRevenueLastYear,
  },
  {
    id         : 1076,
    component  : Select,
    label      : 'Tell us about business ownership',
    name       : FieldNames.commercialBusinessOwnershipType,
    options   : COMMERCIAL_BUSINESS_OWNERSHIP_TYPE_OPTIONS,
  },
  {
    id        : COMMERCIAL_INVENTORY_EXPECTED_COSTS,
    component : Currency,
    label     : 'Expected Cost',
    title     : 'Tell us more about your inventory purchase',
    name      : FieldNames.commercialExpectedCost,
    showIf    : [isInventory],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_EXPECTED_COSTS,
    component : Currency,
    label     : 'Expected Cost',
    title     : 'Tell us more about your equipment purchase',
    name      : FieldNames.commercialExpectedCost,
    showIf    : [isEquipment],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_DOWN_PAYMENT,
    component : CurrencyAllowZero,
    label     : 'Down Payment',
    name      : FieldNames.downDollar,
  },
  {
    id        : COMMERCIAL_EQUIPMENT_DESCRIPTION,
    component : TextInput,
    label     : 'Equipment Description',
    name      : FieldNames.commercialDescription,
    showIf    : [isEquipment],
  },
  {
    id        : COMMERCIAL_INVENTORY_DESCRIPTION,
    component : TextInput,
    label     : 'Inventory Description',
    name      : FieldNames.commercialDescription,
    showIf    : [isInventory],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BUSINESS_NEED,
    component : TextInput,
    label     : 'Business Need for Equipment',
    name      : FieldNames.commercialBusinessNeed,
    showIf    : [isEquipment],
  },
  {
    id        : COMMERCIAL_INVENTORY_BUSINESS_NEED,
    component : TextInput,
    label     : 'Business Need for Inventory',
    name      : FieldNames.commercialBusinessNeed,
    showIf       : [isInventory],
  },
  {
    id           : COMMERCIAL_INVENTORY_SHELF_LIFE,
    component    : Integer,
    endAdornment : 'Years',
    label        : 'Inventory Shelf Life',
    name         : FieldNames.commercialLifeExpectancy,
    showIf       : [isInventory],
    validator: {
      type : 'isGreaterThanZero',
      param: FieldNames.commercialLifeExpectancy,
    },
  },
  {
    id           : COMMERCIAL_EQUIPMENT_LIFE_EXPECTANCY,
    component    : Integer,
    endAdornment : 'Years',
    label        : 'Equipment Life Expectancy',
    name         : FieldNames.commercialLifeExpectancy,
    showIf       : [isEquipment],
    validator: {
      type : 'isGreaterThanZero',
      param: FieldNames.commercialLifeExpectancy,
    },
  },
  {
    id        : COMMERCIAL_EQUIPMENT_PURCHASE_TIMING,
    component : RadioField,
    label     : 'Purchase Timing',
    name      : FieldNames.commercialLoanTiming,
    options   : COMMERCIAL_PURCHASE_TIMING_OPTIONS,
  },
  {
    id        : 1077,
    component : TextInput,
    label     : 'Loan Number',
    name      : FieldNames.existingLoanNumber,
  },
  {
    id       : 1078,
    component: RadioField,
    name     : FieldNames.isCommercialExistingCustomer,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Are you an existing Commercial Loan customer?',
  },

];
