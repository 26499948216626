import React from 'react';
import { connect } from 'react-redux';
import * as AppConfigActions from 'app/actions/app-config/actions';
import { routes } from 'app/routes/route-list';
import { ReferralLanding } from 'app/components/ReferralLanding';
import { logger } from 'app/util/logger';

/**
 * A redirect referral component to do the following:
 * 1) Take a loan officer username from the URL (ie: /auto-prequal/referral/jsmith)
 * 2) Set the loan officer ID in local storage after doing a lookup by username
 * 3) Navigate user back to the original form they were being referred to (ie: /auto-prequal/ )
 *
 * @class EmployeeReferralComponent
 * @extends {React.Component<any, any>}
 */
class EmployeeReferralComponent extends React.Component<any, any> {
  componentDidMount() {
    const { loanOfficerUsername } = this.props.match.params;
    if (!loanOfficerUsername) {
      logger.debug('No employee username passed, redirecting user to new route');
      return;
    }
  }

  render() {
    const routeFromPath = this.props.match.params['0'];
    const nextRoute     = routes.root + routeFromPath;
    return(
      <ReferralLanding route={nextRoute} />
    );
  }
}

const mapDispatchToProps = AppConfigActions;

export const EmployeeReferral = connect(
  null,
  mapDispatchToProps,
)(EmployeeReferralComponent);
