import React from 'react';
import { Typography, withStyles, createStyles, WithStyles, Container } from '@material-ui/core';
import { Button } from 'app/components/Button';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getFormName } from 'app/routes/helpers';
import { getFormValues } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { getNextSection } from 'app/actions/form/actions';

const styles = (theme) =>
  createStyles({
    displayLabel: {
      fontSize    : 18,
      fontWeight  : 500,
      marginBottom: '10px',
    },
    displayDesc: {
      marginBottom: '1.75rem',
    },
    displayContainer: {
      margin    : '0 auto',
      maxWidth  : '635px',
      textAlign : 'center',
      padding: '25px 15px',
      [theme.breakpoints.down('xs')]: {
        padding: '15px',
      },
    },
  });

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MappedProps = ReturnType<typeof mapStateToProps>;
type OwnProps = { valid: boolean };

type Props = OwnProps & DispatchProps & MappedProps & WithStyles<typeof styles>;

let AdjustHomeValueComponent = (props: Props) => {
  const {
    getNextSection,
    values,
    valid,
    classes,
  } = props;

  const { t } = useTranslation();

  const maxHomeValue = values[FieldNames.avmHomeValueHighLimit];
  const minHomeValue = values[FieldNames.avmHomeValueLowLimit];
  const homeValue    = values[FieldNames.homeValue];

  const adjustmentText = homeValue > maxHomeValue || homeValue < minHomeValue ?
    t('adjustHomeValue.bodyText.mustAdjust', { defaultValue: 'Please adjust your home value to fall within the above range.' }) :
    t('adjustHomeValue.bodyText.mayAdjust', { defaultValue: 'You may adjust your home value.' });

  return (
    <div className={classes.displayContainer}>
      <Typography className={classes.displayLabel}>
        {t('adjustHomeValue.subtitle', { defaultValue: 'Your home value range:' })}
      </Typography>
      <Typography
        variant = 'h2'
        classes = {{
          root: 'largeRateDisplay',
        }}
      >
        {t('adjustHomeValue.maxLoanAmount', {
          defaultValue: '{{ minHomeValue, currency.0 }} - {{ maxHomeValue, currency.0 }}',
          maxHomeValue,
          minHomeValue,
        })}
      </Typography>
      <Typography className={classes.displayDesc}>
        { adjustmentText }
      </Typography>
      <Container maxWidth='xs'>
        <Currency
          label     = 'Estimated Home Value'
          name      = {FieldNames.homeValue}
          validator = {{
            type  : 'isBetween',
            param : [FieldNames.avmHomeValueLowLimit, FieldNames.avmHomeValueHighLimit],
          }}
        />
      </Container>
      <Button
        color    = 'primary'
        size     = 'large'
        variant  = 'contained'
        classes  = {{ root: 'mobileContained' }}
        disabled = {!valid}
        onClick  = {() => getNextSection()}
      >
        {t('button.continue', { defaultValue: 'Continue' })}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const formName = getFormName(state.router.location.pathname);
  return {
    values : getFormValues(formName)(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNextSection: () => dispatch(getNextSection()),
});

export const AdjustHomeValue = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdjustHomeValueComponent));
