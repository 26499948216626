import { getFormName } from '../../../routes/helpers';
import { getFormValues } from 'redux-form';
import { getLoanOfficer } from '../../../reducers/loan-officer';
import { mapUniversalLoanRequest, postUniversalLoanApplication } from '../../../api/loan-application/universal/loan';
import { logger } from '../../../util/logger';
import { getNextSection } from '../actions';
import { getAuditMetadata } from 'app/util/actions';

/**
 * Handles the all the Small Business Form Submits
 */
export function handleSBFormSubmit() {
  return async (dispatch) => {
    return dispatch(submitSBApplication());
  };
}

/**
 * Builds the Small Business form request and submits to the Universal Loan Application end point
 */
export function submitSBApplication() {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);
    const auditMetaData = getAuditMetadata('Submit button');

    const universalLoanRequest = mapUniversalLoanRequest({ ...formData, auditMetaData }, loanOfficer, formName);

    return postUniversalLoanApplication(universalLoanRequest).then(
      (response) => {
        logger.debug('Post SB Form Response', response);
        dispatch(getNextSection());
      },
      (error) => {
        logger.error('Error: Post SB Form', error);
      },
    );
  };
}
