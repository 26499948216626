import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { Trans, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';

type MappedProps = ReturnType<typeof mapStateToProps>;

interface Props extends WithTranslation, MappedProps {}

const ReverseAdvisorStopGateComponent = (props: Props) => {
  const { confirmationImage } = props;

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            <Trans i18nKey='reverseMortgageAdvisorStopGate.title'>
              Thank you!
            </Trans>
          </Typography>
          <Typography variant='body2' paragraph={true} component='div'>
            <Trans i18nKey='reverseMortgageAdvisorStopGate.paragraph1'>
              A mortgage loan officer will contact you to discuss your options and next steps.
            </Trans>
          </Typography>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationImage   : state.config.confirmationImage,
});

export const ReverseAdvisorStopGate = connect(mapStateToProps, null)(ReverseAdvisorStopGateComponent);
