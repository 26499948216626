import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { ReverseSummaryPdfRequest } from 'app/api/reverse-summary/types';
import { getSlug } from 'app/util/headers';

export const postEmailReverseSummary = (request: ReverseSummaryPdfRequest) => {
  const slug = getSlug();
  return client
    .post(Endpoints.REVERSE_SUMMARY, { ...request, slug })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
