import { PropertyFieldNameType } from 'app/models/fields/names';

export enum Type {
  AJAX_VALIDATE_ADDRESS          = 'AJAX_VALIDATE_ADDRESS',
  AJAX_VALIDATE_ADDRESS_FAIL     = 'AJAX_VALIDATE_ADDRESS_FAIL',
  AJAX_VALIDATE_ADDRESS_SUCCESS  = 'AJAX_VALIDATE_ADDRESS_SUCCESS',
  AJAX_VALIDATE_ADDRESS_SKIP     = 'AJAX_VALIDATE_ADDRESS_SKIP',
  MODAL_ADDRESS_VALIDATION_CLOSE = 'MODAL_ADDRESS_VALIDATION_CLOSE',
  SET_NEW_LIVING_ADDRESS         = 'SET_NEW_LIVING_ADDRESS',
}

export interface AddressValidationState {
  enteredAddress        : null | Address;
  propertyFieldNameType : PropertyFieldNameType;
  isModalOpen           : boolean;
  suggestedAddress      : null | Address;
}

export type Address = {
  city                  : string;
  state                 : string;
  street                : string;
  street2?              : string;
  zip                   : string;
};
