import { retryClient } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { getSlug } from 'app/util/headers';

export const fetchConfigs = () => {
  const slug = getSlug();

  return retryClient.get(Endpoints.CONFIG + `?slug=${slug}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
