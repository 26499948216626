import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import { eidlInitialValue } from '../../../util/initial-values';
import { EIDL_COLLATERAL_OPTIONS, EIDL_LOAN_TYPE_OPTIONS } from '../../../models/options/options';

const eidlInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.eidlBankName,
    isOptional : false,
    label      : 'Bank Name',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
  {
    fieldName  : FieldNames.eidlLoanType,
    isOptional : false,
    options    : EIDL_LOAN_TYPE_OPTIONS,
    label      : 'Loan Type',
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.eidlCollateral,
    isOptional : false,
    options    : EIDL_COLLATERAL_OPTIONS,
    label      : 'Collateral Being Used',
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.eidlBalance,
    isOptional : false,
    label      : 'Outstanding Balance',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
];

const eidlProps: DynamicFormFieldArray = {
  addButtonText : 'Loan',
  initialValue  : eidlInitialValue,
  inputs        : eidlInputs,
};

export const EconomicInjuryDisasterLoanFFAComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: eidlProps, name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
