import { makeActionCreator } from 'app/util/actions';
import { getLoanOfficer } from 'app/api/loan-officer';
import { fetchConfigs } from 'app/api/app-config';
import { Type } from './types';
import { Dispatch } from 'redux';
import { logger } from 'app/util/logger';

/**
 * BASIC ACTIONS
 *
 * These actions are basic actions without a payload, or with a payload
 * and handled by the reducer
 */
export const ajaxGetLoanOfficerDetailFail    = makeActionCreator(Type.AJAX_GET_LOAN_OFFICER_DETAIL_FAIL, 'payload');
export const ajaxGetLoanOfficerDetailSuccess = makeActionCreator(Type.AJAX_GET_LOAN_OFFICER_DETAIL_SUCCESS, 'payload');
export const fetchConfigFulfilled            = makeActionCreator(Type.AJAX_FETCH_CONFIG_SUCCESS, 'payload');
export const fetchConfigRejected             = makeActionCreator(Type.AJAX_FETCH_CONFIG_FAIL, 'payload');
/**
 * ASYNC/THUNK ACTIONS
 *
 * These actions handle async requests like hitting the API for data
 */
export function ajaxFetchConfig() {
  return (dispatch: Dispatch) => {
    dispatch({ type: Type.AJAX_FETCH_CONFIG });
    return fetchConfigs().then((response) => {
      logger.debug('Fetch Configs response: ', response);
      dispatch(fetchConfigFulfilled(response));
    }, (error) => {
      dispatch(fetchConfigRejected(error));
    });
  };
}

export function ajaxGetLoanOfficerDetail(payload = {}) {
  return (dispatch) => {
    dispatch({ type: Type.AJAX_GET_LOAN_OFFICER_DETAIL, payload });
    return getLoanOfficer(payload)
      .then((response) => {
        logger.debug('Get Loan Officer response: ', response);
        dispatch(ajaxGetLoanOfficerDetailSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(ajaxGetLoanOfficerDetailFail(error));
      });
  };
}
