import { FieldNames } from 'app/models/fields/names';
import { landingQuestionsByName } from 'app/models/questions/landing';
import { Section } from 'app/models/types';

export const getLandingSectionById = (id: number): Section => {
  return LANDING_SECTIONS.find((section) => section.id === id) as Section;
};

const LANDING_SECTIONS: Section[] = [
  {
    id             : 101,
    questions      : landingQuestionsByName([FieldNames.landingLoanType]),
    hideBackButton : true,
    hideProgressBar: true,
    /* The submitAction is added on the container level for landing pages */
  },
];
