import React from 'react';
import { Grid } from '@material-ui/core';
import { ToggleEditableFields } from 'app/components/ToggleEditableFields';
import { filterQuestions } from 'app/util/question-helpers';
import { Question } from 'app/models/types';
import { SHARED } from 'app/models/questions/shared';
import { FieldNames } from 'app/models/fields/names';
import { YES_OR_NO_OPTIONS } from 'app/models/options/options';
import { hasCoBorrower } from 'app/models/fields/conditionals';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { Select } from 'app/components/FormFields/Select';

const AUTO_PREQUAL_RENEW_CONTACT_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
    displayFormat: 'phone',
  },
];

const AUTO_PREQUAL_RENEW_MARITAL_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.maritalStatus],
    component   : Select,
    label       : 'Martial Status',
    fieldPopover: true,
  },
];

const AUTO_PREQUAL_RENEW_COBORROWER_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: Select,
    options  : YES_OR_NO_OPTIONS,
    name     : FieldNames.coBorrowerYN,
    label    : 'Co-Borrower on Loan',
  },
  {
    ...SHARED[FieldNames.firstName],
    id     : 1002,
    label  : 'Co-Borrower First Name',
    name   : FieldNames.coBorrowerFirstName,
    showIf : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.middleName],
    id         : 1003,
    label      : 'Co-Borrower Middle Name',
    name       : FieldNames.coBorrowerMiddleName,
    isOptional : true,
    showIf     : [hasCoBorrower],
  },
  {
    ...SHARED[FieldNames.lastName],
    id     : 1004,
    label  : 'Co-Borrower Last Name',
    name   : FieldNames.coBorrowerLastName,
    showIf : [hasCoBorrower],
  },
  {
    id           : 1005,
    component    : SinglePastDate,
    label        : 'Co-Borrower Date of Birth',
    name         : FieldNames.coBorrowerDOB,
    displayFormat: 'date',
    showIf       : [hasCoBorrower],
  },
];

const AUTO_PREQUAL_RENEW_NEW_HOME_QUESTIONS: Question[] = [
  {
    id       : 1003,
    ...SHARED[FieldNames.propertyType],
    component: Select,
  },
  {
    id       : 1004,
    ...SHARED[FieldNames.propertyUsage],
    component: Select,
  },
  {
    id       : 1005,
    ...SHARED[FieldNames.propertyZip],
  },
];

const AUTO_PREQUAL_RENEW_NEW_HOME_ANNUAL_QUESTIONS: Question[] = [
  {
    id           : 1001,
    component    : CurrencyAllowZero,
    label        : 'Property Tax',
    name         : FieldNames.yearTaxes,
    displayFormat: 'currency.0',
  },
  {
    id           : 1002,
    component    : CurrencyAllowZero,
    label        : 'Insurance',
    name         : FieldNames.yearInsure,
    displayFormat: 'currency.0',
  },
  {
    id           : 1003,
    component    : CurrencyAllowZero,
    label        : 'HOA Fees',
    name         : FieldNames.yearAssociate,
    isOptional   : true,
    displayFormat: 'currency.0',
  },
];

export const AutoPrequalAccountSummary = ({ ...props }) => {
  const filteredCoBorrowerQuestions = filterQuestions(AUTO_PREQUAL_RENEW_COBORROWER_QUESTIONS, props.values);
  return (
    <Grid container classes={{ container: 'verifyContainer' }}>
      <Grid item xs={12}>
        <ToggleEditableFields
          id         = {101}
          subtitle1 = {'Contact Information'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {AUTO_PREQUAL_RENEW_CONTACT_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {102}
          subtitle1 = {'Marital & Dependent Status'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {AUTO_PREQUAL_RENEW_MARITAL_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {103}
          subtitle1 = {'Co-Borrower Information'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {filteredCoBorrowerQuestions}
        />
        <ToggleEditableFields
          id         = {104}
          subtitle1 = {'New Home Information'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {AUTO_PREQUAL_RENEW_NEW_HOME_QUESTIONS}
        />
        <ToggleEditableFields
          id         = {105}
          subtitle1 = {'New Home\'s Annual Costs'}
          values     = {props.values}
          syncErrors = {props.syncErrors}
          questions  = {AUTO_PREQUAL_RENEW_NEW_HOME_ANNUAL_QUESTIONS}
        />
      </Grid>
    </Grid>
  );
};
