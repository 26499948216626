import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'app/components/FormFields/Select';
import { getFicoRangesByForm } from 'app/reducers/app-config';
import { RootState } from 'app/store/types';

const CreditScoresComponent = ({ ...props }: any) => {
  return (
    <Select
      classes  = {props.classes}
      label    = {props.label}
      name     = {props.name}
      onChange = {props.onChange}
      options  = {props.ficoRanges}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  ficoRanges: getFicoRangesByForm(state),
});

export const CreditScoresSelect = connect(mapStateToProps)(CreditScoresComponent);
