import React from 'react';
import {
  createStyles,
  Grid,
  Typography,
  withStyles,
  withTheme,
  Paper,
  WithStyles,
  WithTheme,
  Theme,
} from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { autoLoanQuestionsByName } from 'app/models/questions/auto-loan';
import { Question } from '../Question';

const styles = (theme: Theme) => {
  return createStyles({
    maxWidth: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '420px',
        width   : '100%',
        margin  : '0 auto',
      },
    },
    recalculateButton: {
      minHeight : '50px',
      lineHeight: '50px',
      minWidth  : '240px',
      padding   : '0 16px',
      margin    : '0 15px',
    },
    recalculateWrapper: {
      minHeight   : '200px',
    },
    recalculateContainer: {
      padding: '50px 15px',
      [theme.breakpoints.down('xs')]: {
        padding: '30px 15px',
      },
    },
  });
};

interface Props extends WithStyles<typeof styles>, WithTheme, WithTranslation {
  isFromDealer  : boolean;
  minLoanAmount : number;
  maxLoanAmount : number;
  values        : any;
}

class AutoLoanAmountEditableComponent extends React.Component<Props, any> {

  render() {
    const { classes, minLoanAmount, maxLoanAmount,values } = this.props;
    const questions = autoLoanQuestionsByName([
      FieldNames.purchasePrice,
      FieldNames.tradeInValue,
      FieldNames.downDollar,
      FieldNames.loanTermDesired,
      FieldNames.creditScore,
    ]);
    return (
      <Paper elevation={2} square={true} className={classes.recalculateWrapper}>
        <Grid container className={classes.recalculateContainer}>
          <Grid item xs={12}>
            <Grid className={classes.maxWidth}>
              <Typography
                align     = 'center'
                variant   = 'h2'
                component = 'h2'
                classes   = {{ h2: 'maxLoanTitle' }}
              >
                <Trans i18nKey='AutoLoan.summary.minMaxLoanTitle' values={{ minLoanAmount, maxLoanAmount }}>
                  {'Your loan amount must be between<br/><strong>{{ minLoanAmount, currency.0 }}</strong> and <strong>{{ maxLoanAmount, currency.0 }}</strong>'}
                </Trans>
              </Typography>
            </Grid>
            {questions.map((question, index) => {
              return (
                <Question
                  key = {`${question.name}-${index}`}
                  {...question}
                  isOptional      = {question.isOptional}
                  values          = {values}
                />
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export const AutoLoanAmountEditable = withTheme(withStyles(styles)(withTranslation()(AutoLoanAmountEditableComponent)));
