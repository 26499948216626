import React from 'react';
import css from '../Question.module.css';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getFormName } from 'app/routes/helpers';
import { getFormValues } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { CASH_OUT_MIN, cashOutAvailableAtClosing } from 'app/models/fields/conditionals';

type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = MappedProps;

const TakeCashOutComponent = (props: Props) => {
  const { values } = props;
  const { t } = useTranslation();
  
  const cashAvailableAtClosing = cashOutAvailableAtClosing(values);
  return (
    <React.Fragment>
      <div className={css.titleContainer}>
        <Typography variant='h6' className={css.questionTitle}>
          {
            t('adjustHomeValue.subtitle', { 
              defaultValue: 'Based on your Estimated Home Value and Current Mortgage Balance you may be able to receive {{ value, currency.0 }} in cash at closing:',
              value: cashAvailableAtClosing,
            })
          }
        </Typography>
        <Typography variant='body2' gutterBottom={true}>
          Home much of this amount would you like to receive?
        </Typography>
      </div>
      <div>
        <Currency
          label     = 'Cash Out Amount'
          name      = {FieldNames.cashOut}
          validator = {{
            type  : 'isBetweenValues',
            param : [CASH_OUT_MIN, cashAvailableAtClosing],
          }}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const formName = getFormName(state.router.location.pathname);
  return {
    values : getFormValues(formName)(state),
  };
};

export const TakeCashOut = connect(mapStateToProps)(TakeCashOutComponent);
