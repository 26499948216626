import React from 'react';
import { Section } from 'app/models/types';
import { FormTerms } from 'app/components/FormAgreements/FormTerms';
import { FieldNames } from 'app/models/fields/names';
import { getCommercialPurchaseQuestionsByName } from 'app/models/questions/commercial-purchase';
import {
  hasChosenHome,
  isEmployee,
  isEmploymentOther,
  isExistingCommercial,
  isHomeowner,
  isRenting,
  isRetired,
  isSelfEmployed,
} from 'app/models/fields/conditionals';
import { FormParagraphs } from 'app/models/paragraphs';
import { ConfirmationCommercial } from 'app/components/ConfirmationCommercial';
import { getCommercialDeclarationQuestions } from '../questions/declarations';
import { BorrowerType } from '../options/enums';
import {
  COMMERCIAL_PURCHASE_PERSONAL_INFO,
  COMMERCIAL_PURCHASE_AGREEMENT,
  COMMERCIAL_PURCHASE_PROPERTY_INFO,
  COMMERCIAL_PURCHASE_HOUSING,
  COMMERCIAL_PURCHASE_AVAILABLE_FUNDS,
  COMMERCIAL_PURCHASE_ASSETS,
  COMMERCIAL_PURCHASE_BUSINESS_INFO,
  COMMERCIAL_PURCHASE_LOAN_HOLDER,
  COMMERCIAL_PURCHASE_BORROWER_LIVING_SITUATION,
  COMMERCIAL_PURCHASE_BORROWER_HOME_INFO,
  COMMERCIAL_PURCHASE_BORROWER_RENTER_INFO,
  COMMERCIAL_PURCHASE_BORROWER_INCOME_SOURCE,
  COMMERCIAL_PURCHASE_BORROWER_EMPLOYMENT,
  COMMERCIAL_PURCHASE_BORROWER_RETIREMENT,
  COMMERCIAL_PURCHASE_BORROWER_QUESTIONS,
  COMMERCIAL_PURCHASE_FINAL,
  COMMERCIAL_PURCHASE_PROPERTY_DETAILS,
  COMMERCIAL_PURCHASE_BUSINESS_MANAGEMENT,
  COMMERCIAL_PURCHASE_CHOSEN_PROPERTY,
  COMMERCIAL_PURCHASE_BUSINESS_FINANCIALS,
  COMMERCIAL_PURCHASE_BUSINESS_OWNERS,
  COMMERCIAL_PURCHASE_EXISTING_CUSTOMER,
  COMMERCIAL_PURCHASE_EXISTING_CUSTOMER_INFO,
} from 'app/models/sections/constants';
import { handleCommercialBackButton, handleCommercialFormSubmit } from 'app/actions/form/actions';
import { getCommercialBridgeLoanQuestionsByName } from '../questions/commercial-bridge-loan';

export const getCommercialPurchaseSectionById = (id: number): Section => {
  return COMMERCIAL_PURCHASE_SECTIONS.find((section) => section.id === id) as Section;
};

const COMMERCIAL_PURCHASE_SECTIONS: Section[] = [
  {
    id        : COMMERCIAL_PURCHASE_CHOSEN_PROPERTY,
    title     : 'Have you chosen a commercial property to purchase',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.homeChosenYN,
    ]),
    agreement     : <FormTerms />,
    hideBackButton: handleCommercialBackButton,
  },
  {
    id        : COMMERCIAL_PURCHASE_AGREEMENT,
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.agreementYN,
    ]),
    showIf: [hasChosenHome],
  },
  {
    id        : COMMERCIAL_PURCHASE_PROPERTY_INFO,
    title     : 'Tell us about your new purchase',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.homeValue,
      FieldNames.commercialPropertyType,
      FieldNames.expectedClosingDate,
    ]),
  },
  {
    id        : COMMERCIAL_PURCHASE_AVAILABLE_FUNDS,
    title     : 'Available Funds',
    questions : getCommercialPurchaseQuestionsByName([FieldNames.assets]),
    paragraph : FormParagraphs.commercialAvailableFunds,
  },
  {
    id        : COMMERCIAL_PURCHASE_ASSETS,
    title     : 'Down Payment Funds Summary',
    questions : getCommercialPurchaseQuestionsByName([FieldNames.assetSummary]),
  },
  {
    id        : COMMERCIAL_PURCHASE_HOUSING,
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
    ]),
    showIf    : [hasChosenHome],
  },
  {
    id        : COMMERCIAL_PURCHASE_PROPERTY_DETAILS,
    title     : 'Tell us more about your purchase',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.landSize,
      FieldNames.numOfStructures,
      FieldNames.buildingSquareFootage,
      FieldNames.builtYear,
      FieldNames.expectedOwnerOccupancy,
      FieldNames.currentZoningClassification,
      FieldNames.buildingContactName,
      FieldNames.buildingContactPhone,
    ]),
    showIf: [hasChosenHome],
  },
  {
    id             : COMMERCIAL_PURCHASE_EXISTING_CUSTOMER,
    questions      : getCommercialPurchaseQuestionsByName([
      FieldNames.isCommercialExistingCustomer,
    ]),
  },
  {
    id        : COMMERCIAL_PURCHASE_BUSINESS_INFO,
    title     : 'Tell us about your business',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialDBA,
      FieldNames.commercialCensusTract,
      FieldNames.commercialNAICSCode,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialEmployeeCount,
      FieldNames.commercialWebsite,
      FieldNames.commercialEntityType,
      FieldNames.commercialBusinessType,
      FieldNames.commercialStateOfIncorporation,
      FieldNames.commercialYearStarted,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BUSINESS_FINANCIALS,
    title     : 'Tell us about your business financials',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.commercialRevenueTrend3,
      FieldNames.commercialRevenueLastYear,
      FieldNames.commercialRevenueExplanation,
      FieldNames.commercialProfitTrend3,
      FieldNames.commercialProfitExplanation,
      FieldNames.commercialCollateralList,
      FieldNames.commercialBusinessOwnershipType,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BUSINESS_OWNERS,
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.commercialOwnerList,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BUSINESS_MANAGEMENT,
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.hasOtherManagement,
      FieldNames.commercialManagementList,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_LOAN_HOLDER,
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.commercialLoanHolder,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id       : COMMERCIAL_PURCHASE_PERSONAL_INFO ,
    title    : 'Enter your personal information',
    questions: getCommercialPurchaseQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BORROWER_LIVING_SITUATION,
    title     : 'What is your current housing situation?',
    questions : getCommercialPurchaseQuestionsByName([FieldNames.livingRentOrOwn]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id       : COMMERCIAL_PURCHASE_BORROWER_HOME_INFO,
    title    : 'Provide your homeowner information',
    questions: getCommercialPurchaseQuestionsByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    showIf: [(values) => isHomeowner(values) && !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BORROWER_RENTER_INFO,
    title     : 'Provide your renter information',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
    ]),
    showIf : [(values) => isRenting(values) && !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BORROWER_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : getCommercialPurchaseQuestionsByName([FieldNames.employmentType]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BORROWER_EMPLOYMENT,
    title     : 'Tell us about your income and employment',
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.commercialEmploySameBusiness,
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf       : [(values) => (isEmployee(values) || isSelfEmployed(values)) && !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_PURCHASE_BORROWER_RETIREMENT,
    questions : getCommercialPurchaseQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf       : [(values) => (isRetired(values) ||  isEmploymentOther(values)) && !isExistingCommercial(values)],
  },
  {
    id           : COMMERCIAL_PURCHASE_EXISTING_CUSTOMER_INFO,
    title        : 'Please enter your Loan and Business Information',
    questions    : getCommercialBridgeLoanQuestionsByName([
      FieldNames.existingLoanNumber,
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.firstName,
      FieldNames.lastName,
      FieldNames.email,
      FieldNames.phone,
    ]),
    showIf       : [isExistingCommercial],
    submitAction : handleCommercialFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : COMMERCIAL_PURCHASE_BORROWER_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getCommercialDeclarationQuestions(BorrowerType.PRIMARY),
    showIf       : [(values) => !isExistingCommercial(values)],
    submitAction : handleCommercialFormSubmit,
    submitText   : 'Submit',
  },
  {
    id              : COMMERCIAL_PURCHASE_FINAL,
    ifNoQuestions   : ConfirmationCommercial,
    hideProgressBar : true,
  },
];
