import React, { useEffect } from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import { noopValidate, required } from 'app/util/validators';
import { connect } from 'react-redux';
import { RootState } from 'app/store/types';
import { getAutoValuationVariants } from 'app/reducers/auto-valuation';
import { ajaxGetVehicleVariants } from 'app/actions/form/auto-valuation/actions';
import { FieldNames } from 'app/models/fields/names';
import { FormName } from 'app/models/options/enums';
import {
  createStyles,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';
import { AutoDetailsTableProps } from 'app/components/AutoLoan/types';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps;

const AutoDetailsTableBaseComponent = (props: Props) => {
  const { onVehicleVariantChange, ajaxGetVehicleVariants, vehicle, vehicleId, variants } = props;

  useEffect(() => {
    if (vehicle) {
      ajaxGetVehicleVariants(vehicle);
    }
  }, [ajaxGetVehicleVariants, vehicle]);

  return (
    <TableContainer component={Paper} style={{ marginBottom: 20 }}>
      <Table size='small' aria-label='auto-details-table'>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ padding: 0 }}>&nbsp;</StyledTableCell>
            <StyledTableCell align='left'>Body</StyledTableCell>
            <StyledTableCell align='right'>Doors</StyledTableCell>
            <StyledTableCell align='left'>Drivetrain</StyledTableCell>
            <StyledTableCell align='left'>Engine</StyledTableCell>
            <StyledTableCell align='left'>Fuel Type</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variants.map((row) => (
            <TableRow key={row.id}>
              <TableCell style={{ padding: 0 }}>
                <Radio
                  checked    = {vehicleId === row.id}
                  onChange   = {(event) => { onVehicleVariantChange(row.id, row.year, row.make, row.model, row.body); }}
                  value      = {row.id}
                  name       = {`radio-button-${row.id}`}
                  inputProps = {{ 'aria-label': row.id }}
                />
              </TableCell>
              <TableCell align='left'>{row.body}</TableCell>
              <TableCell align='right'>{row.doors}</TableCell>
              <TableCell align='left'>{row.drivetrain}</TableCell>
              <TableCell align='left'>{row.engine}</TableCell>
              <TableCell align='left'>{row.fuel_type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ajaxGetVehicleVariants : (vehicle: string) => dispatch(ajaxGetVehicleVariants(vehicle)),
  onVehicleVariantChange : (vehicleId: string, vehicleYear: number, vehicleMake: string, vehicleModel: string, vehicleBody: string) => {
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleId, vehicleId));
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleYear, vehicleYear));
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleMake, vehicleMake));
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleModel, vehicleModel));
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleBody, vehicleBody));
  },
});

const mapStateToProps = (state: RootState) => ({
  vehicle   : formValueSelector(FormName.AUTOLOAN)(state, FieldNames.vehicle),
  vehicleId : formValueSelector(FormName.AUTOLOAN)(state, FieldNames.vehicleId),
  variants  : getAutoValuationVariants(state),
});

const AutoDetailsTableComponent = connect(mapStateToProps, mapDispatchToProps)(AutoDetailsTableBaseComponent);

export const AutoSelectDetailsTable = ({ name, label, title, isOptional }: AutoDetailsTableProps) => {
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      component = {AutoDetailsTableComponent}
      label     = {label}
      name      = {name}
      title     = {title}
      type      = 'text'
      validate  = {isRequired}
    />
  );
};
