import React from 'react';
import { FieldArray } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { requiredArray } from 'app/util/validators';
import { commercialManagementInitialValue } from 'app/util/initial-values';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeLabel } from 'app/i18n/helpers';
import { Name } from '../FormFields/Name';
import { Select } from '../FormFields/Select';
import { NAME_SUFFIX_OPTIONS } from '../../models/options/options';

const renderBusinessManagement = ({ ...props }) => {
  const { fields, name, t }    = props;
  const firstNameField        = `${name}.${FieldNames.managementFirstName}`;
  const middleNameField       = `${name}.${FieldNames.managementMiddleName}`;
  const lastNameField         = `${name}.${FieldNames.managementLastName}`;
  const suffixNameField       = `${name}.${FieldNames.managementSuffixName}`;
  const titleOfPositionField  = `${name}.${FieldNames.managementTitleHeld}`;

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={ `${ field }-${ index }` } style={ { paddingBottom: 20 } }>
          <Name
            isOptional={ false }
            label={ localizeLabel(firstNameField, t, 'First Name') }
            name={ `${ field }.${ FieldNames.managementFirstName }` }
          />
          <Name
            isOptional={ true }
            label={ localizeLabel(middleNameField, t, 'Middle Name') }
            name={ `${ field }.${ FieldNames.managementMiddleName }` }
          />
          <Name
            isOptional={ false }
            label={ localizeLabel(lastNameField, t, 'Last Name') }
            name={ `${ field }.${ FieldNames.managementLastName }` }
          />
          <Select
            label={ localizeLabel(suffixNameField, t, 'Suffix') }
            name={ `${ field }.${ FieldNames.managementSuffixName }` }
            options={ NAME_SUFFIX_OPTIONS }
            isOptional={ true }
          ></Select>
          <Name
            isOptional={ false }
            label={ localizeLabel(titleOfPositionField, t, 'Title of Position') }
            name={ `${ field }.${ FieldNames.managementTitleHeld }` }
          />
          { fields.length > 1 && (
            <Button
              disableRipple
              color='primary'
              classes={ {
                root: 'link',
                text: 'remove',
              } }
              onClick={ () => fields.remove(index) }
            >
              { t(`question.${ name }.button.remove`, { defaultValue: 'Remove' }) }
            </Button>
          ) }
        </div>
      );
    });

  return (
    <div>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(commercialManagementInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Manager' })}
      </Button>
    </div>
  );
};

export const BusinessManagement = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderBusinessManagement}
        name      = {name}
        props     = {{ name, t, options }}
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
