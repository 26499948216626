import { FieldNames } from 'app/models/fields/names';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { refiQuestionsByName } from 'app/models/questions/refinance';
import { ajaxValidateBorrowerZipCode } from '../../actions/form/zip-code-validation/actions';

export const getRefinanceSectionById = (id: number): Section => {
  return REFINANCE_SECTIONS.find((section) => section.id === id) as Section;
};

const REFINANCE_SECTIONS: Section[] = [
  {
    id            : 101,
    hideBackButton: true,
    questions     : refiQuestionsByName([
      FieldNames.loanPurpose,
    ]),
  },
  {
    id       : 102,
    questions: refiQuestionsByName([
      FieldNames.propertyUsage,
      FieldNames.propertyType,
    ]),
  },
  {
    id       : 103,
    questions: refiQuestionsByName([
      FieldNames.creditScore,
      FieldNames.escrowUsage,
    ]),
  },
  {
    id       : 104,
    questions: refiQuestionsByName([
      FieldNames.homeValue,
      FieldNames.loanAmount,
      FieldNames.propertyZip,
    ]),
    submitAction: ajaxValidateBorrowerZipCode,
  },
  {
    id       : 105,
    questions: refiQuestionsByName([
      FieldNames.firstName,
      FieldNames.lastName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.employCompanyName,
    ]),
    title        : 'Get your personalized loan programs and rates',
    submitAction : FormActions.handleShortFormSubmit,
    submitText   : 'Submit',
  },
];
