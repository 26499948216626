import React, { useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { DECLARATION_NO_SHARE } from 'app/models/options/options';
import { FieldNames } from '../../models/fields/names';
import { change } from 'redux-form';
import { nestedValueByString } from 'app/util/misc';

export const renderCheckboxBase: any = ({ ...props }) => {
  const { input, meta, options, values } = props;
  const { name } = input;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (values) {
      const hmdaFieldNames = [FieldNames.sex, FieldNames.ethnicity, FieldNames.race];
      const value = nestedValueByString(values, name);
      if (value?.length === 0 && values[FieldNames.isDefaultedHMDAAnswer] === true &&
        hmdaFieldNames.some(fieldName => name.includes(fieldName))) {
        meta.dispatch(change(meta.form, name, [DECLARATION_NO_SHARE]));
      }
    }
  });

  const val = input.value || [];
  return options.map((option, index) => {
    if (option.isHidden) {
      return null;
    } else {
      return (
        <FormControlLabel
          label   = {option.title}
          key     = {`${input.name}${index}`}
          control = {
            <Checkbox
              checked  = {val.indexOf(option.value) > -1}
              color    = "primary"
              name     = {`${input.name}[${index}]`}
              value    = {option.value}
              onChange = {(event, checked) => {
                const newValue = [...val];
                const noShareIndex = newValue.indexOf(DECLARATION_NO_SHARE);
                /* If 'NO_SHARE' is being checked, remove all other values */
                if (event.target.value === DECLARATION_NO_SHARE) {
                  return input.onChange([DECLARATION_NO_SHARE]);
                /* Remove 'NO_SHARE' if another option is being checked */
                } else if (noShareIndex > -1) {
                  newValue.splice(newValue[noShareIndex], 1);
                }

                if (checked) {
                  newValue.push(option.value);
                } else {
                  newValue.splice(newValue.indexOf(option.value), 1);
                }
                return input.onChange(newValue);
              }}
            />
          }
        />
      );
    }
  });
};
