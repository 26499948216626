import i18next from 'i18next';
import { change, SubmissionError } from 'redux-form';
import { logger } from 'app/util/logger';
import { makeActionCreator } from 'app/util/actions';
import { FieldNames, getPropertyFieldNameGroup, PropertyFieldNameType } from 'app/models/fields/names';
import { Type, Address } from 'app/actions/form/address-validation/types';
import { getNextSection } from 'app/actions/form/actions';
import { validateAddress } from 'app/api/validate-address';
import { getFormName } from 'app/routes/helpers';
import { getSlug } from '../../../util/headers';
import { updateAddressByPropertyType } from "../property/actions";
import { FormName } from "../../../models/options/enums";
import { isPrimaryHome } from "../../../models/fields/conditionals";

export const ajaxValidateAddressFail     = makeActionCreator(Type.AJAX_VALIDATE_ADDRESS_FAIL, 'payload');
export const ajaxValidateAddressSuccess  = makeActionCreator(Type.AJAX_VALIDATE_ADDRESS_SUCCESS, 'payload');
export const modalAddressValidationClose = makeActionCreator(Type.MODAL_ADDRESS_VALIDATION_CLOSE);

export function ajaxValidateSubjectProperty(formData) {
  return ajaxValidateAddress(formData, PropertyFieldNameType.SUBJECT_PROPERTY);
}

export function ajaxValidateBorrowerResidence(formData) {
  return ajaxValidateAddress(formData, PropertyFieldNameType.BORROWER_RESIDENCE);
}

export function ajaxValidateBorrowerResidenceNoNavigate(formData) {
  return ajaxValidateAddress(formData, PropertyFieldNameType.BORROWER_RESIDENCE, true);
}

export function ajaxValidateCoBorrowerResidence(formData) {
  return ajaxValidateAddress(formData, PropertyFieldNameType.COBORROWER_RESIDENCE);
}

export function ajaxValidateRelativeResidence(formData) {
  return ajaxValidateAddress(formData, PropertyFieldNameType.RELATIVE_RESIDENCE);
}

/**
 * This updates the residence for the borrower to be the subject property.  This is used
 * in Home Equity Express because the user will not fill out their living property if
 * they have indicated that it is also the collateral home.
 *
 * @param formData
 * @returns
 */
export function updateHomeEquityTurboAddress(formData) {
  // Only update address if the borrower's collateral house is their primary residence
  if(isPrimaryHome(formData)) {
    return updateAddressByPropertyType(
      formData,
      FormName.HOME_EQUITY_TURBO,
      PropertyFieldNameType.SUBJECT_PROPERTY,
      PropertyFieldNameType.BORROWER_RESIDENCE);
  }
  return (dispatch) => {
    return dispatch(getNextSection());
  };
}

/**
 * This method performs the call to the backend to validate a specific address.  It takes
 * the propertyFieldNameType which determines which "set" of address fields we are validating
 * Today there are several sets of fields on the front end: subject property, borrower residence
 * co-borrower residence, and relative residence.
 *
 * @param formData
 * @param {PropertyFieldNameType} propertyFieldNameType
 * @returns
 */
function ajaxValidateAddress(formData, propertyFieldNameType: PropertyFieldNameType, skipNavigation?: boolean) {
  const propertyFieldNameGroup = getPropertyFieldNameGroup(propertyFieldNameType);
  const slug = getSlug();
  const payload: Address = {
    city         : formData[propertyFieldNameGroup.city],
    state        : formData[propertyFieldNameGroup.state],
    street       : formData[propertyFieldNameGroup.street],
    street2      : formData[propertyFieldNameGroup.street2],
    zip          : formData[propertyFieldNameGroup.zip],
  };

  return (dispatch, getState) => {

    const { isHideStreetAddressFields } = getState().config.featureToggles;

    if (isHideStreetAddressFields) {
      dispatch({ type: Type.AJAX_VALIDATE_ADDRESS_SKIP });
      dispatch(getNextSection());
      return;
    }

    dispatch({ type: Type.AJAX_VALIDATE_ADDRESS, payload });

    /* Skip validation if a soft pull test case is being used */
    if (formData.isTestCase) {
      dispatch((getNextSection()));
      logger.debug('Credit pull test case being used, skipping address validation');
      return;
    }

    return validateAddress({ slug, ...payload })
      .then((response) => {
        if (response.isValid) {
          /* The address they provided is a match, no need to prompt them */
          logger.debug('Address is valid, getting next section',skipNavigation);
          if(!skipNavigation) {
            dispatch(getNextSection());
          }
        } else {
          /* Prompt user with choice */
          dispatch(ajaxValidateAddressSuccess({ suggestedAddress: response, enteredAddress: payload, propertyFieldNameType }));
        }
      })
      .catch((error) => {
        dispatch(ajaxValidateAddressFail(error));
        /* The key names are the error codes returned by the service */

        const propertyFieldNameGroup = getPropertyFieldNameGroup(propertyFieldNameType);
        const errorCodeMap = {
          'INVALID_ZIP'    : propertyFieldNameGroup.zip,
          'INVALID_ADDRESS': propertyFieldNameGroup.street,
          'INVALID_CITY'   : propertyFieldNameGroup.city,
          'INVALID_STATE'  : propertyFieldNameGroup.state,
        };
        /*
          * Results in an object looking like { livingPropertyZip: 'Invalid zip code.'}
          * See: https://redux-form.com/8.1.0/docs/api/submissionerror.md/
          */
        const errorKey = `validation:error.validateAddress.${error.code}`;
        const errors = {
          [errorCodeMap[error.code]]: i18next.t(errorKey, { defaultValue: error.description }),
        };
        throw new SubmissionError(errors);
      });
  };
}

export function setLivingAddress(address: Address) {
  return (dispatch, getState) => {
    const { city, state, street, street2, zip } = address;

    const { router, addressValidation } = getState();
    const { propertyFieldNameType } = addressValidation;

    dispatch({ type: Type.SET_NEW_LIVING_ADDRESS, address });

    let cityFieldName      = FieldNames.propertyCity;
    let stateFieldName     = FieldNames.propertyState;
    let streetFieldName    = FieldNames.propertyStreet;
    let street2FieldName   = FieldNames.propertyStreet2;
    let zipFieldName       = FieldNames.propertyZip;

    if (propertyFieldNameType === PropertyFieldNameType.BORROWER_RESIDENCE) {
      cityFieldName    = FieldNames.livingPropertyCity;
      stateFieldName   = FieldNames.livingPropertyState;
      streetFieldName  = FieldNames.livingPropertyStreet;
      street2FieldName = FieldNames.livingPropertyStreet2;
      zipFieldName     = FieldNames.livingPropertyZip;
    } else if (propertyFieldNameType === PropertyFieldNameType.COBORROWER_RESIDENCE) {
      cityFieldName      = FieldNames.coBorrowerLivingPropertyCity;
      stateFieldName     = FieldNames.coBorrowerLivingPropertyState;
      streetFieldName    = FieldNames.coBorrowerLivingPropertyStreet;
      street2FieldName   = FieldNames.coBorrowerLivingPropertyStreet2;
      zipFieldName       = FieldNames.coBorrowerLivingPropertyZip;
    } else if (propertyFieldNameType === PropertyFieldNameType.RELATIVE_RESIDENCE) {
      cityFieldName      = FieldNames.relativeLivingPropertyCity;
      stateFieldName     = FieldNames.relativeLivingPropertyState;
      streetFieldName    = FieldNames.relativeLivingPropertyStreet;
      street2FieldName   = FieldNames.relativeLivingPropertyStreet2;
      zipFieldName       = FieldNames.relativeLivingPropertyZip;
    }

    const formName = getFormName(router.location.pathname);
    /* Tell redux form to change the values */
    dispatch(change(formName, cityFieldName, city));
    dispatch(change(formName, stateFieldName, state));
    dispatch(change(formName, streetFieldName, street));
    dispatch(change(formName, street2FieldName, street2));
    dispatch(change(formName, zipFieldName, zip));
  };
}
