import { createSelector } from 'reselect';
import { FieldNames } from 'app/models/fields/names';
import { Type } from 'app/actions/app-config/types';
import { RootState } from 'app/store/types';
import { getLocation } from 'connected-react-router';
import { routes } from 'app/routes/route-list';
import { getFormName } from 'app/routes/helpers';
import { FieldRequirementType, FormName } from 'app/models/options/enums';
import { FeatureToggles, Product } from '@lenderful/domain';

export interface AppConfigState {
  aboutUsImage                       : string;
  accountImage                       : string;
  autoLoanMinLtvAction?              : AutoMinLtvAction;
  branchHomeEquityImage              : string;
  branchLandingImage                 : string;
  branchMortgageImage                : string;
  cashOutMaxLTV                      : number;
  clientDisplayName                  : string;
  clientMinLoan                      : number;
  confirmationImage                  : string;
  contactImage                       : string;
  contactMethods                     : { label: string, phone: string }[];
  creditCards                        : CreditCard[];
  documentUploaders                  : { product: string, url: string }[];
  externalContactUsUrl?              : string;
  featureToggles                     : FeatureToggles;
  ficoRanges                         : FicoRange[];
  host                               : string;
  isLoading                          : boolean;
  logo                               : string;
  logoWhite                          : string;
  menu?                              : Menu;
  name                               : string;
  prefooterTemplate                  : string;
  prequalSummaryImage                : string;
  primaryColor                       : string;
  products                           : ClientProduct[];
  secondaryColor                     : string;
  slug                               : string;
  stopGateHelocImage                 : string;
  stopGateImage                      : string;
  stopGateReferralImage              : string;
  stopGateVeteranImage               : string;
  tertiaryColor                      : string;
  referralLimits                     : ReferralLimit[];
}

// TODO: move to @lenderful/domain
export type ClientProduct = {
  id?: number;
  name: Product;
  downPaymentMin?: number;
  downPaymentMinPercent?: number;
  isMultiLangEnabled: boolean;
}

export type Menu = MenuItem[];

export type MenuItem = {
  title   : string;
  url     : string;
  content : string;
};

export enum AutoMinLtvAction {
  DISPLAY_ERROR   = 'DISPLAY_ERROR', // front-end error, user cannot proceed
  DISPLAY_WARNING = 'DISPLAY_WARNING', // front-end warning, user is notified of GAP insurance requirements
  NOTHING = 'NOTHING', // do nothing
}
export interface FicoRange {
  formName : string;
  title    : string;
  value    : string;
}

export interface CreditCard {
  value         : string;
  title         : string;
  description   : string;
  disclosureUrl : string;
}

export interface ReferralLimit {
  formName : FormName;
  value    : string;
  type     : ReferralType;
}

export enum ReferralType {
  AUTO_HIGH_LIMIT_PERCENT      = 'AUTO_HIGH_LIMIT_PERCENT',
  BORROWER_JOB_DURATION_MONTHS = 'BORROWER_JOB_DURATION_MONTHS',
  BORROWER_JOB_DURATION_ALGO   = 'BORROWER_JOB_DURATION_ALGO',
  BORROWER_UNDERAGE            = 'BORROWER_UNDERAGE',
  DEBT_TO_INCOME_RATIO         = 'DEBT_TO_INCOME_RATIO',
  LOW_FICO                     = 'LOW_FICO',
  LOW_FICO_SECONDARY_HOME      = 'LOW_FICO_SECONDARY_HOME',
  PAYMENT_TO_INCOME_RATIO      = 'PAYMENT_TO_INCOME_RATIO',
}

export enum BorrowerJobDurationAlgorithm {
  CURRENT_JOB      = 'CURRENT_JOB',
  CONSECUTIVE_JOBS = 'CONSECUTIVE_JOBS',
}

const ALL_PRODUCTS = Object.values(Product).map(product => ({ name: product, isMultiLangEnabled: false }));

export const initialConfigState: AppConfigState = {
  aboutUsImage          : 'ImageSection0.jpg',
  accountImage          : 'ImageSection0.jpg',
  branchHomeEquityImage : 'HeroImage0.jpg',
  branchLandingImage    : 'HeroImage0.jpg',
  branchMortgageImage   : 'HeroImage0.jpg',
  cashOutMaxLTV         : undefined,
  clientDisplayName     : '',
  clientMinLoan         : 50000,
  confirmationImage     : 'ImageSection0.jpg',
  contactImage          : 'HeroImage0.jpg',
  contactMethods        : [],
  host                  : 'http://localhost',
  isLoading             : true,
  logo                  : 'Logo0.svg',
  logoWhite             : 'LogoWhite0.svg',
  menu                  : [{ title: '', url: '', content: '' }],
  name                  : 'Flagstar Mortgage',
  prefooterTemplate     : '',
  prequalSummaryImage   : 'HeroImage0.jpg',
  primaryColor          : '#E0E0E0',
  products              : ALL_PRODUCTS,
  secondaryColor        : '#E0E0E0',
  slug                  : 'flagstar',
  stopGateHelocImage    : 'ImageSection0.jpg',
  stopGateImage         : 'ImageSection0.jpg',
  stopGateReferralImage : 'ImageSection0.jpg',
  stopGateVeteranImage  : 'ImageSection0.jpg',
  tertiaryColor         : '#E0E0E0',
  featureToggles        : {
    isDefaultedHMDAAnswer               : false,
    isHideDependentsAges                : false,
    isHideGovtMonitoring                : false,
    isHideGovtMonitoringHelocArm        : true,
    isAutoPrequalEnabled                : false,
    isTermsOfUseEnabled                 : false,
    isBrokerEnabled                     : false,
    isCollectingMailingAddress          : false,
    isHideFeesOnRates                   : false,
    isHideVeteranQuestions              : false,
    isHideConstructionLoanTypeQuestion  : false,
    isHideEscrowQuestions               : false,
    isHideStreetAddressFields           : false,
    isHideUniqueHomeEquityQuestion      : true,
    isHideUniquePersonalLoanQuestion    : true,
    isHideCreditCardLimitQuestion       : false,
    hasEnabledCreditReportOnTurbo       : true,
    hasCreditBoosterPrograms            : false,
    hideDigitalMortgageOptions          : "",
    isHidePurchasePropertyAddress         : false,
    hideHomeEquityOptions               : "",
    hideReverseMortgageOptions          : "",
    ssnQuestionRequirement              : FieldRequirementType.HIDDEN,
    hasAssetFinancialInstitutionField   : false,
    hasLineOfWorkQuestion               : false,
    hasLoanTypeQuestion                 : false,
    hasReverseAgeQuestion               : false,
    hasDisableLogoRedirect              : false,
    hasAutoLoanSpecialCalculation       : false,
  },
  creditCards: [],
  ficoRanges: [],
  referralLimits: [],
  documentUploaders: [],
};

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const appConfigsReducer = (state = initialConfigState, action): AppConfigState => {
  switch (action.type) {
    case Type.AJAX_FETCH_CONFIG_FAIL:
      return { ...state, isLoading: false };
    case Type.AJAX_FETCH_CONFIG_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };

    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

export const appConfigSelector      = (state: RootState) => state.config;
export const featureTogglesSelector = (state: RootState) => state.config.featureToggles;
export const productsSelector       = (state: RootState) => state.config.products;
export const ficoRangesSelector     = (state: RootState) => state.config.ficoRanges;
export const creditCardsSelector    = (state: RootState) => state.config.creditCards;
export const referralLimitsSelector = (state: RootState) => state.config.referralLimits;

/**
 * Selects name of form based on the router location pathname
 */
export const formNameSelector = (state: RootState) => {
  const pathName = state.router.location.pathname;
  return getFormName(pathName);
};

/**
 * Selects field names to be excluded from the forms based on
 * app config feature toggles
 */
export const excludedQuestionsSelector = createSelector(featureTogglesSelector, (toggles): string[] => {
  let excludedQuestions: string[] = [];
  for (const key in excludedQuestionMap) {
    /* If this feature toggle is set to true */
    if (toggles[key]) {
      /* Add the fieldnames found in the map to the list of excluded questions */
      excludedQuestions = excludedQuestions.concat(excludedQuestionMap[key]);
    }
  }
  return excludedQuestions;
});

/**
 * Selects field names to be excluded from the forms based on
 * app config feature toggles
 */
export const excludedQuestionsExceptPrequalSelector = createSelector(featureTogglesSelector, (toggles): string[] => {
  let excludedQuestions: string[] = [];
  for (const key in excludedQuestionMap) {
    /* If this feature toggle is set to true */
    if (toggles[key]) {
      /* Add the fieldnames found in the map to the list of excluded questions */
      excludedQuestions = excludedQuestions.concat(excludedQuestionMap[key]);
    }
  }
  excludedQuestions = excludedQuestions.filter(question => question !== 'livingPropertyStreet').filter(question => question !== 'livingPropertyStreet2');
  return excludedQuestions;
});

/**
 * Selects field names to be required from the forms based on
 * app config feature toggles
 */
export const ssnQuestionRequirementSelector = createSelector(featureTogglesSelector, (toggles): string => {
  return toggles.ssnQuestionRequirement;
});

/**
 * Selects the client specific auto valuation high limit multiplier for referral
 */
export const autoHighLimitPercent = createSelector(referralLimitsSelector,
  (referralLimits: ReferralLimit[]): number => {
    const percent = referralLimits.find(limit => limit.type === ReferralType.AUTO_HIGH_LIMIT_PERCENT && FormName[limit.formName] === FormName.AUTOLOAN);
    return percent ? parseInt(percent.value) : 100;
  },
);

/**
 * Selects the client specific payment to income ratio to use based on the referral limits
 */
export const paymentToIncomeRatio = createSelector(referralLimitsSelector, formNameSelector,
  (referralLimits: ReferralLimit[], formName: FormName): number => {
    const ratio = referralLimits
      .find(limit => limit.type === ReferralType.PAYMENT_TO_INCOME_RATIO && FormName[limit.formName] === formName);
    return ratio ? parseFloat(ratio.value) : 1;
  },
);

/**
 * Selects the client specific action to perform when auto loan minimum loan-to-value is too low
 */
export const autoLoanMinLtvAction = createSelector(appConfigSelector, (config: AppConfigState): AutoMinLtvAction => {
  return config.autoLoanMinLtvAction ? config.autoLoanMinLtvAction : AutoMinLtvAction.NOTHING;
});

/**
 * Selects the client specific Underage limit for referral
 */
export const borrowerUnderageLimit = createSelector(referralLimitsSelector, formNameSelector,
  (referralLimits: ReferralLimit[], formName: FormName): number => {
    const ageLimit = referralLimits.find(limit => limit.type === ReferralType.BORROWER_UNDERAGE && FormName[limit.formName] === formName);
    return ageLimit ? parseInt(ageLimit.value) : 18;
  },
);

/**
 * Selects field option values to be excluded from questions on the forms based on
 * app config feature toggles
 */
export const excludedOptionsSelector = createSelector(
  formNameSelector, featureTogglesSelector,
  (formName, toggles) => {

    // hide options on home equity related forms
    if (formName === FormName.HOMEEQUITY || formName === FormName.HOMEEQUITYLONGFORM || formName === FormName.HOME_EQUITY_TURBO) {
      return toggles.hideHomeEquityOptions ? toggles.hideHomeEquityOptions.split(",") : [];
    }
    // hide options on reverse mortgage form
    if(formName === FormName.REVERSE) {
      return toggles.hideReverseMortgageOptions ? toggles.hideReverseMortgageOptions.split(",") : [];
    }
    // hide options on all other forms
    return toggles.hideDigitalMortgageOptions ? toggles.hideDigitalMortgageOptions.split(",") : [];
  });

/**
 * This gets the fico ranges, filtering out only the ones that pertain to the current form.  The ranges
 * are sorted in order from highest value to lowest.
 */
export const getFicoRangesByForm = createSelector(formNameSelector, ficoRangesSelector,
  (formName, ficoRanges) => {
    return ficoRanges
      .filter(range => range.formName.toUpperCase() === formName.toUpperCase())
      .sort((aRange, bRange) => aRange.value < bRange.value ? 1 : -1);
  },
);

/**
 * Returns value for if auto prequal is enabled from state.config.isAutoPrequalEnabled
 */
export const isAutoPrequalEnabled = createSelector(featureTogglesSelector, (toggles) => toggles.isAutoPrequalEnabled);

/**
 * Returns value for if Terms Of Use is enabled from state.config.isTermsOfUseEnabled
 */
export const isTermsOfUseEnabled = createSelector(featureTogglesSelector, (toggles) => toggles.isTermsOfUseEnabled);

/**
 * Returns value for if Scheduler is enabled from state.config.isSchedulerEnabled
 *
 * @deprecated scheduler removed, work to remove this selector from components
 */
export const isSchedulerEnabled = (state: RootState) => false;

/**
 * Returns value for if Broker is enabled from state.config.isBrokerEnabled
 */
export const isBrokerEnabled = createSelector(featureTogglesSelector, (toggles) => toggles.isBrokerEnabled);

/**
 * Hide/Show fees for rates and programs is enabled from state.config.isHideFeesOnRates
 */
export const isHideFeesOnRates = createSelector(featureTogglesSelector, (toggles) => toggles.isHideFeesOnRates);

/**
 * Selects the client specific low FICO score for the current form based on the referral limits
 */
export const lowFicoSelector = createSelector(referralLimitsSelector, formNameSelector,
  (referralLimits: ReferralLimit[], formName: FormName): number => {
    const lowFicoReferral = referralLimits.find(limit => limit.type === ReferralType.LOW_FICO && FormName[limit.formName] === formName);
    return lowFicoReferral ? parseInt(lowFicoReferral.value) : 0;
  },
);

/**
 * Selects the client specific low FICO score for secondary homes.  Based on the current form and the referral limits.
 */
export const lowFicoForSecondaryHomeSelector = createSelector(referralLimitsSelector, formNameSelector,
  (referralLimits: ReferralLimit[], formName: FormName): number => {
    const lowFicoSecondary = referralLimits.find(limit => limit.type === ReferralType.LOW_FICO_SECONDARY_HOME && FormName[limit.formName] === formName);
    return lowFicoSecondary ? parseInt(lowFicoSecondary.value) : 0;
  },
);

/**
 * Selects the client specific minimum required job duraction for the borrower (in months)
 */
export const borrowerJobDurationMonths = createSelector(referralLimitsSelector, formNameSelector,
  (referralLimits: ReferralLimit[], formName: FormName): number => {
    const monthsReferral = referralLimits
      .find(limit => limit.type === ReferralType.BORROWER_JOB_DURATION_MONTHS && FormName[limit.formName] === formName);
    return monthsReferral ? parseInt(monthsReferral.value) : 24;
  },
);

/**
 * Selects the client specific algorithm for the minimum job required duration for the borrower. The
 * algorithms either look at only the current job or a combination of current and previous jobs.
 */
export const borrowerJobDurationAlgorithm = createSelector(referralLimitsSelector, formNameSelector,
  (referralLimits: ReferralLimit[], formName: FormName): string => {
    const algorithReferral = referralLimits
      .find(limit => limit.type === ReferralType.BORROWER_JOB_DURATION_ALGO && FormName[limit.formName] === formName);
    return algorithReferral ? algorithReferral.value : 'CURRENT_JOB';
  },
);

/**
 * This returns the first contact phone number configured for a client.  Most clients will only have
 * one number.
 */
export const contactPhoneSelector = createSelector(
  [appConfigSelector],
  (config) => config.contactMethods && config.contactMethods.length > 0 ? config.contactMethods.pop().phone : '',
);

/**
 * This returns all the contact methods for a client.  This consists of label/phone number pairs that are
 * to be displayed on the Contact Us page.
 */
export const contactMethodsSelector = createSelector(
  [appConfigSelector],
  (config) => config.contactMethods,
);

type FeatureToggleMap = {
  [key in keyof FeatureToggles]?: string[]
}

/* Map of feature toggles related to hiding fields */
const excludedQuestionMap: FeatureToggleMap = {
  isHideGovtMonitoring: [
    `${FieldNames.decBorrower}.${FieldNames.ethnicity}`,
    `${FieldNames.decBorrower}.${FieldNames.ethnicityHispanic}`,
    `${FieldNames.decBorrower}.${FieldNames.ethnicityOther}`,
    `${FieldNames.decBorrower}.${FieldNames.race}`,
    `${FieldNames.decBorrower}.${FieldNames.raceAsian}`,
    `${FieldNames.decBorrower}.${FieldNames.raceAsianOther}`,
    `${FieldNames.decBorrower}.${FieldNames.raceIslander}`,
    `${FieldNames.decBorrower}.${FieldNames.raceIslanderOther}`,
    `${FieldNames.decBorrower}.${FieldNames.sex}`,
    `${FieldNames.decCoBorrower}.${FieldNames.ethnicity}`,
    `${FieldNames.decCoBorrower}.${FieldNames.ethnicityHispanic}`,
    `${FieldNames.decCoBorrower}.${FieldNames.ethnicityOther}`,
    `${FieldNames.decCoBorrower}.${FieldNames.race}`,
    `${FieldNames.decCoBorrower}.${FieldNames.raceAsian}`,
    `${FieldNames.decCoBorrower}.${FieldNames.raceAsianOther}`,
    `${FieldNames.decCoBorrower}.${FieldNames.raceIslander}`,
    `${FieldNames.decCoBorrower}.${FieldNames.raceIslanderOther}`,
    `${FieldNames.decCoBorrower}.${FieldNames.sex}`,
  ],
  isHideVeteranQuestions: [
    FieldNames.veteranYN,
    FieldNames.veteranLoanYN,
  ],
  isHideUniqueHomeEquityQuestion: [
    FieldNames.hasUniqueHomeEquityProgram,
  ],
  isHideUniquePersonalLoanQuestion: [
    FieldNames.hasUniquePersonalLoanProgram,
  ],
  isHideConstructionLoanTypeQuestion: [
    FieldNames.constructionLoanType,
  ],
  isHideEscrowQuestions: [
    FieldNames.escrowUsage,
    FieldNames.livingEscrowUsage,
  ],
  isHideStreetAddressFields: [
    FieldNames.propertyStreet,
    FieldNames.propertyStreet2,
    FieldNames.livingPropertyStreet,
    FieldNames.livingPropertyStreet2,
    FieldNames.coBorrowerLivingPropertyStreet,
    FieldNames.coBorrowerLivingPropertyStreet2,
  ],
  isHideCreditCardLimitQuestion: [
    FieldNames.creditCardLimit,
  ],
  hasAssetFinancialInstitutionField: [
    FieldNames.hasAssetFinancialInstitution,
  ],
};

export const productByFormSelector = createSelector(
  [getLocation],
  (location) => {
    switch (location.pathname) {
      /* Personal Loan */
      case routes.personalLoan:
        return Product.PERSONAL_LOAN;
      /* Overdraft Protection */
      case routes.overdraftProtection:
        return Product.OVERDRAFT_PROTECTION;
      /* Auto-Prequal */
      case routes.autoPrequal:
      case routes.autoPrequalEdit:
      case routes.autoPrequalRenew:
        return Product.AUTO_PREQUAL;
      /* Home Equity */
      case routes.homeEquity:
      case routes.applicationHomeEquity:
        return Product.HOME_EQUITY;
      /* Home Equity Turbo */
      case routes.homeEquityRates:
      case routes.homeEquityTurbo:
        return Product.HOME_EQUITY_TURBO;
      /* Refinance Turbo */
      case routes.refinanceTurbo:
        return Product.REFINANCE_TURBO;
      /* Digital Mortgage */
      case routes.purchase:
      case routes.refinance:
      case routes.application:
      case routes.applicationPurchase:
      case routes.applicationRefinance:
        return Product.DIGITAL_MORTGAGE;
      /* Commercial Loans Form */
      case routes.commercial:
      case routes.commercialBridgeLoan:
      case routes.commercialEquipment:
      case routes.commercialPurchase:
      case routes.commercialRefinance:
      case routes.commercialVehicle:
        return Product.COMMERCIAL;
      /* Closing Cost Form */
      case routes.closingCostsPurchase:
      case routes.closingCostsRefinance:
        return Product.CLOSING_COSTS;
      /* Credit Card Form */
      case routes.creditCard:
        return Product.CREDIT_CARD;
      /* Auto Loan Form */
      case routes.autoLoan:
        return Product.AUTO_LOAN;
      /* Other Vehicles Form */
      case routes.otherVehicle:
        return Product.OTHER_VEHICLE;
      /* Construction Application Form */
      case routes.applicationConstruction:
        return Product.CONSTRUCTION;
      /* Land Application Form */
      case routes.applicationLand:
        return Product.LAND;
      /* Credit Booster Form */
      case routes.creditBooster:
        return Product.CREDIT_BOOSTER;
      /* Reverse Mortgage Form */
      case routes.reverse:
        return Product.REVERSE_MORTGAGE;
      /* Financial Statement Form */
      case routes.financialStatement:
        return Product.FINANCIAL_STATEMENT;
      /* Small Business Forms */
      case routes.sbLineOfCredit:
      case routes.sbTerm:
        return Product.SMALL_BUSINESS;
      default:
        return undefined;
    }
  },
);

/**
 * Matches the current route with the value on the route toggle.
 */
export const isRouteEnabled = createSelector(
  [productsSelector, productByFormSelector],
  (clientProducts, productByForm) => {
    const products = clientProducts.map(product => product.name);
    return productByForm ? products.includes(productByForm) : true;
  },
);

/**
 * This selector returns the client down payment minimum for the form the user is on.
 */
export const getDownPaymentMin = createSelector(
  [productsSelector, productByFormSelector],
  (clientProducts, productByForm) => {
    const product = clientProducts.find(clientProduct => clientProduct.name === productByForm);
    return product?.downPaymentMin ? product.downPaymentMin : 0;
  },
);

/**
 * This selector returns the client down payment minimum percent for the form the user is on.
 */
export const getDownPaymentMinPercent = createSelector(
  [productsSelector, productByFormSelector],
  (clientProducts, productByForm) => {
    const product = clientProducts.find(clientProduct => clientProduct.name === productByForm);
    return product?.downPaymentMinPercent ? product.downPaymentMinPercent : 0;
  },
);

/**
 * This selector returns the is multi language enabled for the form the user is on.
 */
export const isMultiLangEnabled = createSelector(
  [productsSelector, productByFormSelector, getLocation],
  (clientProducts, productByForm, location) => {
    // if any client's form allows Spanish, contact us form will allow spanish
    if (location.pathname === routes.contact) {
      return clientProducts.some(product => product.isMultiLangEnabled);
    }
    const product = clientProducts.find(clientProduct => clientProduct.name === productByForm);
    return product?.isMultiLangEnabled ? product.isMultiLangEnabled : false;
  },
);

/**
 * This selector returns the appropriate document URL based on the current form.
 * If no form specific URL is found, it will try to use the first URL configured.
 */
export const documentUploaderSelector = createSelector(
  [appConfigSelector, productByFormSelector],
  (config, productByForm) => {
    const documentUploader = config.documentUploaders.find(documentUploaders => documentUploaders.product === productByForm);
    if (!documentUploader) {
      return config.documentUploaders[0];
    }
    return documentUploader;
  },
);

export const getMenu = (state: RootState): Menu => state.config.menu;
