import React from 'react';
import { Section } from 'app/models/types';
import { FormParagraphs } from 'app/models/paragraphs';
import * as FormActions from 'app/actions/form/actions';
import { autoPrequalRenewQuestionsByName } from 'app/models/questions/auto-prequal-renew';
import { FieldNames } from 'app/models/fields/names';
import {
  LoanOfficerCondition,
  hasBankrupt,
  hasForeclosed,
  noCitizenship,
  hasRealEstateLiabilities,
  hasCoBorrower,
  isCoBorrowerIncomeVerified,
  isBorrowerIncomeVerified,
} from 'app/models/fields/conditionals';
import { getAutoPrequalDeclarationQuestions } from 'app/models/questions/declarations';
import { PrequalSummary } from 'app/components/PrequalSummary/PrequalSummary';
import { PrequalSummaryHeader } from 'app/components/PrequalSummary/PrequalSummaryHeader';
import { ajaxPostCreditReportForAutoPrequal } from 'app/actions/form/credit-report/actions';
import {
  AUTO_PREQUAL_RENEW_AMOUNT_INFO,
  AUTO_PREQUAL_RENEW_COBORROWER_EMPLOYMENT_INFO,
  AUTO_PREQUAL_RENEW_CREDIT_AUTHORIZATON,
  AUTO_PREQUAL_RENEW_EMPLOYMENT_INFO,
  AUTO_PREQUAL_RENEW_FINANCIAL_HISTORTY,
  AUTO_PREQUAL_RENEW_HOME_VALUE_DOWN_PAYMENT,
  AUTO_PREQUAL_RENEW_REAL_ESTATE_LIABILITIES,
  AUTO_PREQUAL_RENEW_SUMMARY,
  AUTO_PREQUAL_RENEW_WELCOME_BACK,
} from "./constants";

export const getAutoPrequalRenewSectionById = (id: number): Section => {
  return AUTO_PREQUAL_RENEW_SECTIONS.find((section) => section.id === id) as Section;
};

const AUTO_PREQUAL_RENEW_SECTIONS: Section[] = [
  {
    id            : AUTO_PREQUAL_RENEW_WELCOME_BACK,
    title         : 'Welcome Back',
    paragraph     : FormParagraphs.autoPrequalRenewVerifyAccount,
    hideBackButton: true,
    questions     : autoPrequalRenewQuestionsByName([
      FieldNames.accountSummary,
    ]),
  },
  {
    id       : AUTO_PREQUAL_RENEW_HOME_VALUE_DOWN_PAYMENT,
    title    : 'New Home Value',
    questions: autoPrequalRenewQuestionsByName([FieldNames.homeValue]),
  },
  {
    id       : AUTO_PREQUAL_RENEW_CREDIT_AUTHORIZATON,
    title    : 'Credit Authorization',
    paragraph: FormParagraphs.autoPrequalRenewAuthorizeCredit,
    questions: autoPrequalRenewQuestionsByName([
      FieldNames.creditAuthorize,
    ]),
    // @TODO: Look into section agreement and required question component issue
    // agreement: <AuthorizeCreditReport />
  },
  {
    id             : AUTO_PREQUAL_RENEW_FINANCIAL_HISTORTY,
    title          : 'Financial History',
    paragraph      : FormParagraphs.autoPrequalRenewFinancialHistory,
    paragraphBorder: true,
    questions      : getAutoPrequalDeclarationQuestions(),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.hasBankrupt,
        LoanOfficerCondition.hasForeclosed,
        LoanOfficerCondition.isBorrowerNotCitizen,
      ],
    },
    submitAction: ajaxPostCreditReportForAutoPrequal,
    /* Only pull credit report if borrower is citizen, and has no bankrupt or foreclosure */
    submitIf: [(formValues) =>
      !hasBankrupt(FieldNames.decBorrower)(formValues) &&
      !hasForeclosed(FieldNames.decBorrower)(formValues) &&
      !noCitizenship(FieldNames.decBorrower)(formValues),
    ],
  },
  {
    id       : AUTO_PREQUAL_RENEW_REAL_ESTATE_LIABILITIES,
    title    : 'Real Estate Liabilities',
    paragraph: FormParagraphs.autoPrequalRenewRealEstateLiabilities,
    questions: autoPrequalRenewQuestionsByName([FieldNames.realEstateLiabilities]),
    showIf   : [hasRealEstateLiabilities],
  },
  {
    id       : AUTO_PREQUAL_RENEW_EMPLOYMENT_INFO,
    title    : 'Has your employment or income changed?',
    questions: autoPrequalRenewQuestionsByName([
      FieldNames.changedIncomeYN,
      FieldNames.employBase,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.incomeVerifiable,
    ]),
    submitIf     : [(values) => !hasCoBorrower(values) && isBorrowerIncomeVerified(values)],
    submitAction : FormActions.handleAutoPrequalGetRates,
    submitText   : 'Submit',
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerIncomeUnverified],
    },
  },
  {
    id       : AUTO_PREQUAL_RENEW_COBORROWER_EMPLOYMENT_INFO,
    title    : 'Has your co-borrower\'s employment or income changed?',
    questions: autoPrequalRenewQuestionsByName([
      FieldNames.coBorrowerChangedIncomeYN,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerIncomeVerifiable,
    ]),
    submitAction : FormActions.handleAutoPrequalGetRates,
    showIf       : [hasCoBorrower],
    submitIf     : [(values) => isCoBorrowerIncomeVerified(values)],
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerIncomeUnverified],
    },
  },
  {
    id       : AUTO_PREQUAL_RENEW_AMOUNT_INFO,
    questions: autoPrequalRenewQuestionsByName([
      FieldNames.prequalAmount,
    ]),
    centerSubmitButton: true,
    fixedSubmitButton : true,
    hideBackButton    : true,
    hidePartialButton : true,
    removePadding     : true,
    submitAction      : FormActions.handleAutoPrequalSubmit,
    submitText        : 'Get Your Pre-Qualification Letter',
  },
  {
    id               : AUTO_PREQUAL_RENEW_SUMMARY,
    hideBackButton   : true,
    hideForwardButton: true,
    hideProgressBar  : true,
    ifNoQuestions    : PrequalSummary,
    removePadding    : true,
    showLoanOfficer  : true,
    useTopSection    : <PrequalSummaryHeader />,
  },
];
