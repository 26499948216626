import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { DownloadChecklist } from 'app/components/DownloadChecklist';
import { RootState } from 'app/store/types';
import { routes } from 'app/routes/route-list';
import { GoToClosingCostButton } from 'app/components/ClosingCost/GoToClosingCostButton';
import { getFormName } from '../routes/helpers';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { GoToInsuranceQuoteButton } from 'app/components/InsuranceQuote/GoToInsuranceQuoteButton';

type MappedProps = ReturnType<typeof mapStateToProps>;

const ConfirmationComponent = (props: MappedProps) => {
  const { confirmationImage, isCommercialApp, isPurchaseOrRefiApp } = props;
  const { t } = useTranslation();

  const isDisplayChecklist = !isCommercialApp;

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('confirmation.title', { defaultValue: 'Thank you!' })}
          </Typography>
          <Typography variant='body2' paragraph={true}>
            {t('confirmation.paragraph1', {
              defaultValue:
                'A mortgage loan officer will contact you to discuss your options and next steps.',
            })}
          </Typography>
          {/* Since the LastStep section includes the checklist and is not shown if the scheduler is disabled, show it here */}
          { isDisplayChecklist && <DownloadChecklist /> }
          <Grid container justifyContent='center'>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
          </Grid>
          <GoToClosingCostButton/>
          { isPurchaseOrRefiApp && <GoToInsuranceQuoteButton /> }
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  confirmationImage : state.config.confirmationImage,
  formName : getFormName(state.router.location.pathname),
  isPurchaseOrRefiApp:
    state.router.location.pathname === routes.applicationPurchase ||
    state.router.location.pathname === routes.applicationRefinance,
  isCommercialApp   :
    state.router.location.pathname === routes.commercialPurchase ||
    state.router.location.pathname === routes.commercialRefinance,
  ...ownProps,
});

export const Confirmation = connect(mapStateToProps)(ConfirmationComponent);
