import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { 
  HomeEquityAcceptedLocationRequest, 
  HomeEquityAcceptedLocationResponse, 
  HomeEquityLoanAmountLimitsRequest, 
  HomeEquityLoanAmountLimitsResponse, 
  HomeEquityRatesRequest,
  HomeEquityRatesResponse,
} from 'app/api/home-equity/types';
import { mapRatesPropertyType, mapRatesPropertyUsage } from 'app/api/rates';
import { FieldNames } from 'app/models/fields/names';
import { YesOrNo } from 'app/models/options/enums';
import { getSlug } from 'app/util/headers';
import { logger } from 'app/util/logger';

/**
 * This method fetches the rates for the home-equity products.  The response is
 * passed along unltess there is no data.  If no data is returned, we pass
 * an empty array to keep the user moving through the forms.
 *
 * @param {HomeEquityRatesRequest} request
 * @returns
 */
export const fetchHomeEquityRates = (request: HomeEquityRatesRequest): Promise<HomeEquityRatesResponse> => {
  const params = { ...request, slug: getSlug() };
  return client.get<HomeEquityRatesResponse>(Endpoints.EQUITYRATES, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('Error in rates API call: ', { error });
      throw error;
    });
};

/***
 * This method checks to see if the current location (based on zip) is a valid location
 * for the current client's home equity.
 *
 * @param {HomeEquityAcceptedLocationRequest} request
 * @returns {Promise<HomeEquityAcceptedLocationResponse>}
 */
export const fetchHomeEquityAcceptedLocation = (request: HomeEquityAcceptedLocationRequest): Promise<HomeEquityAcceptedLocationResponse> => {
  const params = { ...request, slug: getSlug() };
  return client.get<HomeEquityAcceptedLocationResponse>(Endpoints.HOME_EQUITY_ACCEPTED_LOCATION, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('Error in accepted location API call: ', { error });
      throw error;
    });
};

/**
 * This method checks to see what the max and min loan amount limits are given
 * the users location, ltv, etc.
 *
 * @param {HomeEquityLoanAmountLimitsRequest} request
 * @returns {Promise<HomeEquityLoanAmountLimitsResponse>}
 */
export const fetchHomeEquityLoanAmountLimits = (request: HomeEquityLoanAmountLimitsRequest): Promise<HomeEquityLoanAmountLimitsResponse> => {
  const params = { ...request, slug: getSlug() };
  return client.get<HomeEquityLoanAmountLimitsResponse>(Endpoints.HOME_EQUITY_LOAN_AMOUNT_LIMITS, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('Error in loan amount limits API call: ', { error });
      throw error;
    });
};

/**
 * This method maps all home-equity related backing forms to create a request to get the loan
 * amount limits for that client.
 *
 * @param formData
 * @returns {HomeEquityLoanAmountLimitsRequest}
 */
export const mapHomeEquityLoanAmountLimitsRequest = (formData): HomeEquityLoanAmountLimitsRequest => ({
  creditReportId             : formData[FieldNames.creditReportId],
  fico                       : formData[FieldNames.creditScore],
  hasUniqueHomeEquityProgram : formData[FieldNames.hasUniqueHomeEquityProgram] === YesOrNo.YES,
  loanPurpose                : formData[FieldNames.homeEquityLoanPurpose],
  maxMonthlyPayment          : formData[FieldNames.maxMonthlyPayment],
  mortgageBalance            : formData[FieldNames.mortgageBalance],
  propertyAppraisedValue     : formData[FieldNames.homeValue],
  propertyType               : mapRatesPropertyType(formData[FieldNames.propertyType]),
  propertyUsage              : mapRatesPropertyUsage(formData[FieldNames.propertyUsage]),
  propertyZip                : formData[FieldNames.propertyZip],
});
