import React from 'react';
import { colors } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const BarChartLabel = ({ value = '0', width, x, y }: any) => {
  const { t } = useTranslation();
  const formattedValue = t('barChart.label', { defaultValue: '{{ value, currency.0 }}', value });
  const numberOfChars = formattedValue.toString().length;
  const paddingToAdd = ((numberOfChars - 3) * 5);

  // If the value is longer than 3 characters, add the extra width
  const valueWidth = (numberOfChars > 3) ? paddingToAdd : 0;

  /* Element positioning */
  const wrapperX   = x + 20;
  const wrapperY   = y + 5;
  const rectangleX = x + 10;
  const rectangleY = y - 10;
  const textX      = x + 18;
  const textY      = y + 6;

  return (
    <g x={wrapperX} y={wrapperY}>
      <defs>
        <filter id="dropShadow" x={0} y={0}>
          <feGaussianBlur in={'SourceAlpha'} stdDeviation={0.75} />
          <feOffset result="offsetblur" dx="1" dy="1" />
          <feComponentTransfer>
            <feFuncA type="linear" slope=".4" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <rect
        fill   = {colors.grey[100]}
        filter = {'url(#dropShadow)'}
        height = {21}
        ry     = {3}
        width  = {(width + 6) + valueWidth}
        x      = {rectangleX}
        y      = {rectangleY}
      />
      <text x={textX} y={textY} fontSize={'14px'}>
        { formattedValue }
      </text>
    </g>
  );
};
