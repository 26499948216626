import React from 'react';
import { Field } from 'redux-form';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { toNumber } from 'app/util/parsers';
import { InputAdornment } from '@material-ui/core';
import { noopValidate, required } from 'app/util/validators';
import { toFormattedNumber } from '../../util/formatters';

export const Mileage = ({ ...props }) => {
  const { fieldPopover, isOptional, label, name, popover } = props;
  const isRequired = isOptional ? noopValidate : required;
  return (
    <Field
      component    = {renderInputBase}
      fieldPopover = {fieldPopover}
      format       = {toFormattedNumber}
      label        = {label}
      name         = {name}
      parse        = {toNumber}
      popover      = {popover}
      type         = 'text'
      validate     = {[isRequired]}
      customProps = {{
        endAdornment: <InputAdornment position='end'>Miles</InputAdornment>,
      }}
    />
  );
};
