import { FieldNames } from 'app/models/fields/names';
import { homeEquityQuestionsByName } from 'app/models/questions/home-equity';
import { StateNotCovered } from 'app/components/StopGates/StateNotCovered';
import { handleHomeEquityLongFormZipCheckDisplayError, handleHomeEquitySubmitFirst, handleHomeEquitySubmitSecond } from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { FormParagraphs } from 'app/models/paragraphs';
import { LoanOfficerCondition } from 'app/models/fields/conditionals';
import {
  HOME_EQUITY_BRANCH_INFORMATION,
  SF_HOME_EQUITY_CREDIT_SCORE,
  SF_HOME_EQUITY_LOAN_AMOUNT,
  SF_HOME_EQUITY_LOAN_PURPOSE,
  SF_HOME_EQUITY_MIN_LOAN_STOP_GATE,
  SF_HOME_EQUITY_PERSONAL_INFORMATION,
  SF_HOME_EQUITY_PROPERTY_ADDRESS,
  SF_HOME_EQUITY_PROPERTY_INFORMATION,
  SF_HOME_EQUITY_STATE_NOT_COVERED,
} from './constants';
import { MinimumLoanAmount } from '../../components/StopGates/MinimumLoanAmount';

export const getHomeEquitySectionById = (id: number): Section => {
  return HOME_EQUITY_SECTIONS.find((section) => section.id === id) as Section;
};

const HOME_EQUITY_SECTIONS: Section[] = [
  {
    id       : HOME_EQUITY_BRANCH_INFORMATION,
    title    : 'Branch Information',
    questions: homeEquityQuestionsByName([
      FieldNames.branchNumber,
      FieldNames.branchEmployee,
    ]),
    hideBackButton : true,
    hideProgressBar: true,
  },
  {
    id       : SF_HOME_EQUITY_PROPERTY_INFORMATION,
    title    : 'How Much Can I Borrow?',
    paragraph: FormParagraphs.homeEquityBorrow,
    questions: homeEquityQuestionsByName([
      FieldNames.homeValue,
      FieldNames.mortgageBalance,
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.hasUniqueHomeEquityProgram,
    ]),
    hideBackButton : true,
    hideProgressBar: true,
  },
  {
    id       : SF_HOME_EQUITY_PROPERTY_ADDRESS,
    title    : 'Where is the property you will use as collateral?',
    questions: homeEquityQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
    hideProgressBar: true,
    submitAction   : handleHomeEquityLongFormZipCheckDisplayError,
  },
  {
    id       : SF_HOME_EQUITY_CREDIT_SCORE,
    questions: homeEquityQuestionsByName([
      FieldNames.creditScore,
    ]),
    hideProgressBar: true,
  },
  {
    id       : SF_HOME_EQUITY_LOAN_PURPOSE,
    questions: homeEquityQuestionsByName([
      FieldNames.homeEquityLoanPurpose,
      FieldNames.homeEquityLoanPurposeOther,
    ]),
    hideProgressBar: true,
    submitAction   : handleHomeEquitySubmitFirst,
  },
  {
    id       : SF_HOME_EQUITY_LOAN_AMOUNT,
    questions: homeEquityQuestionsByName([
      FieldNames.loanAmount,
    ]),
    hideProgressBar: true,
  },
  {
    id       : SF_HOME_EQUITY_PERSONAL_INFORMATION,
    title    : 'Enter your personal information',
    questions: homeEquityQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
      FieldNames.employCompanyName,
    ]),
    hideProgressBar: true,
    submitText     : 'Submit',
    submitAction   : handleHomeEquitySubmitSecond,
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.hasLowFicoAndSecondaryHome],
    },
    submitIf       : [form => !LoanOfficerCondition.hasLowFicoAndSecondaryHome.condition(form)],
  },
  {
    id             : SF_HOME_EQUITY_STATE_NOT_COVERED,
    ifNoQuestions  : StateNotCovered,
    hideProgressBar: true,
  },
  {
    id             : SF_HOME_EQUITY_MIN_LOAN_STOP_GATE,
    ifNoQuestions  : MinimumLoanAmount,
    hideProgressBar: true,
  },
];
