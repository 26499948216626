import { FinancialVerificationState, StatusType, Type } from 'app/actions/form/verification/types';
import { RootState } from 'app/store/types';

const initialState: FinancialVerificationState = {
  borrowerCustomerId   : undefined,
  coBorrowerCustomerId : undefined,
  status               : StatusType.NOT_STARTED,
  verificationUrl      : undefined,
};

export const financialVerificationReducer = (state = initialState, action): FinancialVerificationState => {
  switch (action.type) {
    case Type.AJAX_VERIFICATION_ADD_CUSTOMERS:
      return {
        ...state,
        verificationUrl: undefined,
      };
    case Type.AJAX_VERIFICATION_ADD_CUSTOMERS_SUCCESS:
      return {
        ...state,
        borrowerCustomerId   : action.payload.borrowerCustomerId,
        coBorrowerCustomerId : action.payload.coBorrowerCustomerId,
        status               : StatusType.URL_GENERATED,
        verificationUrl      : action.payload.connectUrl,
      };
    case Type.AJAX_VERIFICATION_ADD_CUSTOMERS_FAIL:
      return { ...state };
    case Type.VERIFICATION_CONNECT_COMPLETE:
      return { ...state, status: StatusType.CONNECT_COMPLETE };
    case Type.VERIFICATION_REFRESH_ACCOUNT_COMPLETE:
      return { ...state, status: StatusType.REFRESH_ACCOUNTS_COMPLETE };
    case Type.AJAX_VERIFICATION_CREATE_ADDITIONAL_REPORTS_SUCCESS:
      return { ...state, status: StatusType.CREATE_REPORTS_STARTED };
    case Type.VERIFICATION_REPORT_GENERATION_COMPLETE: {
      return { ...state, status: StatusType.CREATE_REPORTS_COMPLETE };
    }

    default:
      return state;
  }
};

export const getVerificationUrl = (state: RootState): string | undefined => {
  return state.financialVerification.verificationUrl;
};

export const getVerificationCustomerIds = (state: RootState): string[] => {
  const customerIds = [];
  const { borrowerCustomerId, coBorrowerCustomerId } = state.financialVerification;
  if (borrowerCustomerId) {
    customerIds.push(borrowerCustomerId);
  }
  if (coBorrowerCustomerId) {
    customerIds.push(coBorrowerCustomerId);
  }
  return customerIds;
};

export const getVerificationBorrowerCustomerId = (state: RootState): string | undefined => {
  return state.financialVerification.borrowerCustomerId;
};

export const getVerificationCoBorrowerCustomerId = (state: RootState): string | undefined => {
  return state.financialVerification.coBorrowerCustomerId;
};

export const getVerificationStatus = (state: RootState): StatusType  => {
  return state.financialVerification.status;
};
