import { Type } from 'app/actions/form/types';

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers

export const emailedRatesReducer = (state = false, action) => {
  switch (action.type) {
    case Type.AJAX_EMAIL_RATES_SUCCESS:
      return true;
    case Type.AJAX_EMAIL_RATES_FAIL:
      return true;

    default:
      return state;
  }
};

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors

// Helpers
// -------
