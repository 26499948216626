import React from 'react';
import { Field } from 'redux-form';
import { createStyles, withStyles } from '@material-ui/core';
import { noopValidate } from 'app/util/validators';
import { renderInputBase } from 'app/components/FormFields/_renderInputBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

const styles = (theme) => createStyles({
  root: {
    marginLeft: '16px',
  },
});

export const DeclarationText = withStyles(styles)(({ ...props }: any) => {
  const { name, classes } = props;
  return (
    <div className={classes.root}>
      <Field
        autoComplete = {mapAutoCompleteName(name)}
        component    = {renderInputBase}
        label        = {props.label}
        maxLength    = {50}
        name         = {name}
        type         = "text"
        validate     = {[noopValidate]}
      />
    </div>
  );
});
