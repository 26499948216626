import { AutoValuationState, Type } from 'app/actions/form/auto-valuation/types';
import { TPVehicleBasic, TPVehicleDetailed } from 'app/api/valuation/auto';
import { RootState } from 'app/store/types';

const initialState: AutoValuationState = {
  vehicles: [],
  variants: [],
};

export const autoValuationReducer = (state = initialState, action): AutoValuationState => {
  switch (action.type) {
    case Type.AJAX_GET_AUTO_VALUATION_TYPEAHEAD:
      return { ...state, vehicles: [] };
    case Type.AJAX_GET_AUTO_VALUATION_TYPEAHEAD_SUCCESS:
      return { ...state, vehicles: action.payload };
    case Type.AJAX_GET_AUTO_VALUATION_TYPEAHEAD_FAIL:
      return { ...state };

    case Type.AJAX_GET_AUTO_VALUATION_VARIANTS:
      return { ...state, variants: [] };
    case Type.AJAX_GET_AUTO_VALUATION_VARIANTS_SUCCESS:
      return { ...state, variants: action.payload };
    case Type.AJAX_GET_AUTO_VALUATION_VARIANTS_FAIL:
      return { ...state };

    default:
      return state;
  }
};

export const findVehicleByString = (state: RootState, vehicle: string): TPVehicleBasic | undefined => {
  const vehicles = state.autoValuation.vehicles;
  return vehicles.find(({ year, make, model, trim }: TPVehicleBasic) => `${year} ${make} ${model} ${trim}` === vehicle);
};

export const getAutoValuationTypeaheadVehicles = (state: RootState): string[] => {
  const vehicles = state.autoValuation.vehicles;
  return vehicles.map(({ year, make, model, trim }: TPVehicleBasic) => `${year} ${make} ${model} ${trim}`);
};

export const getAutoValuationVariants = (state: RootState): TPVehicleDetailed[] => {
  return state.autoValuation.variants;
};
