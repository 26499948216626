import React from 'react';
import { Button } from 'app/components/Button';
import { Modal } from 'app/components/Modals/Templates/Modal';

export class ModalWithButton extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { buttonText, buttonClasses, children, modalClasses, paperClasses, ...other } = this.props;

    return (
      <div className={modalClasses}>
        <Button classes={buttonClasses} onClick={this.handleOpen} {...other}>
          {buttonText}
        </Button>
        <Modal
          openModal    = {this.state.open}
          closeModal   = {this.handleClose}
          paperClasses = {paperClasses}
          children     = {children}
        />
      </div>
    );
  }
}
