import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { ModalTerms } from 'app/components/Modals/ModalTerms';
import { isTermsOfUseEnabled } from 'app/reducers/app-config';

interface Props extends WithTranslation {
  /* Dispatch is passed in but not currently used */
  dispatch         : React.Dispatch<any>;
  termsOfUseEnabled: boolean;
}
const FormTermsComponent = ({ ...props }: Props) => {
  const { termsOfUseEnabled, t } = props;
  return (
    termsOfUseEnabled ? (
      <Grid container classes={{ container: 'formTermsWrapper' }}>
        <Grid item classes={{ item: 'termItem' }}>
          <Typography
            variant   = "body2"
            component = "span"
            classes   = {{ body2: 'small formTerms' }}
          >
            {t('formAgreement.terms', {
              defaultValue: 'By clicking Next, you agree to our',
            })}{' '}
            <ModalTerms t={t}/>
          </Typography>
        </Grid>
      </Grid>
    ) : null
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  termsOfUseEnabled: isTermsOfUseEnabled(state),
  ...ownProps,
});

export const FormTerms = connect(
  mapStateToProps,
  null,
)(withTranslation()(FormTermsComponent));
