import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { logger } from 'app/util/logger';
import { getSlug } from 'app/util/headers';

export enum AuditUserEventType {
  USER_ACCEPTS_SOFT_CREDIT_PULL   = 'SCP',
  USER_ACCEPTS_FULL_TRI_MERGE     = 'FTM',
  USER_ACCEPTS_PREQUAL_READ       = 'PQR',
  USER_ACKNOWLEDGES_CC_DISCLOSURE = 'CCD',
  USER_ACKNOWLEDGES_ACRISURE      = 'ACR',
}

export type AuditUserEventRequest = {
  auditEvent: {
    email       : string;
    firstName   : string;
    middleName? : string;
    lastName    : string;
    eventType   : AuditUserEventType;
    eventMeta   : string;
  }
}

/**
 * This API call posts a user audit event to persist in the database
 *
 * @param {AuditUserEventRequest} request
 * @returns {void}
 */
export const postAuditUserEvent = (request: AuditUserEventRequest): Promise<void> => {
  const data = { ...request, slug: getSlug(), type: 'UserEvent' };
  return client
    .post<any>(Endpoints.AUDIT_EVENT, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('ERROR: Failed to post user audit event', { error });
    });
};
