import React from 'react';
import { FieldArray } from 'redux-form';
import { RadioField } from 'app/components/FormFields/RadioField';
import { TextInput } from 'app/components/FormFields/TextInput';
import { FieldNames } from 'app/models/fields/names';
import { colors, createStyles, Typography, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';
import { LiabilityStatus } from '@lenderful/domain';

const styles = (theme) =>
  createStyles({
    accountWrapper: {
      marginBottom: 0,
      paddingTop: '16px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '26px',
        borderBottom: `1px solid ${colors.grey[500]}`,
        paddingTop: 0,
      },
    },
    fieldWrapper: {
      marginTop: '15px',
    },
  });

const renderRealEstateAccounts = ({ ...props }) => {
  const { change, classes, fields, name, values, options, t } = props;

  const renderFields = () =>
    fields.map((field, index) => {
      const account = values[fields.name][index];
      const excludeAccount = account.liabilityStatus === LiabilityStatus.EXCLUDE;
      return (
        <div key={`${field}-${index}`} className={classes.accountWrapper}>
          <Typography variant="body1" classes={{ body1: 'arrayTitle' }}>
            {t('realEstateAccounts.liabilityName', {
              defaultValue : '{{ liabilityName }}',
              liabilityName: account.liabilityName,
            })}
          </Typography>
          <Typography variant="body2" classes={{ body2: 'arrayItem' }}>
            {localizeLabel(`${name}.${FieldNames.liabilityBalance}`, t, `Current Balance:`)}&nbsp;
            {t('realEstateAccounts.liabilityBalance', {
              defaultValue    : '{{ liabilityBalance, currency }}',
              liabilityBalance: account.liabilityBalance,
            })}
          </Typography>
          <Typography variant="body2" classes={{ body2: 'arrayItem' }}>
            {localizeLabel(`${name}.${FieldNames.liabilityAmount}`, t, `Monthly Payment:`)}&nbsp;
            {t('realEstateAccounts.liabilityAmount', {
              defaultValue   : '{{ liabilityAmount, currency }}',
              liabilityAmount: account.liabilityAmount,
            })}
          </Typography>
          <div className={classes.fieldWrapper}>
            <RadioField
              name     = {`${field}.${FieldNames.liabilityStatus}`}
              options  = {localizeOptions(name, t, options)}
              onChange = {() => change(`${field}.${FieldNames.liabilityExclusionReason}`, '')}
            />
            {excludeAccount ? (
              <TextInput
                label = {localizeLabel(`${name}.${FieldNames.liabilityExclusionReason}`, t, `Reason for Exclusion`)}
                name  = {`${field}.${FieldNames.liabilityExclusionReason}`}
              />
            ) : null}
          </div>
        </div>
      );
    });
  return renderFields();
};

export const RealEstateAccountsStatus = withStyles(styles)(({ ...props }: any) => {
  const { classes, change, name, values, options } = props;
  const { t }                             = useTranslation();
  return (
    <FieldArray
      component = {renderRealEstateAccounts}
      name      = {name}
      props     = {{
        change,
        classes,
        name,
        values,
        options,
        t,
      }}
    />
  );
});
