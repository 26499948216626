import React from 'react';
import { Grid, Typography, withStyles, createStyles, WithStyles } from '@material-ui/core';
import { TileBox } from 'app/components/TileBox';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getFormName } from 'app/routes/helpers';
import { getFormValues } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { ButtonDelay } from 'app/components/ButtonDelay';

const styles = (theme) =>
  createStyles({
    displayLabel: {
      fontSize    : 18,
      fontWeight  : 500,
      marginBottom: '10px',
    },
    displayDesc: {
      marginBottom: '1.75rem',
    },
    displayContainer: {
      margin    : '0 auto',
      maxWidth  : '635px',
      textAlign : 'center',
      padding: '25px 15px',
      [theme.breakpoints.down('xs')]: {
        padding: '15px',
      },
    },
  });

type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = MappedProps & WithStyles<typeof styles> | any;

let AddressVerifiedComponent = (props: Props) => {
  const {
    values,
    classes,
    submitting,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid container classes={{ container: 'summaryContainer' }}>
      <Grid item xs={12}>
        <TileBox
          color        = 'primary'
          title        = {t('collateralAddressVerified.title', { defaultValue: 'Collateral Property' })}
          tileBoxProps = {{
            className: classes.displayContainer,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.displayLabel}>
                {t('collateralAddressVerified.subtitle', { defaultValue: 'The following address has been verified:' })}
              </Typography>
              <Typography>
                { values[FieldNames.propertyStreet] } { values[FieldNames.propertyStreet2]}<br/>
                { values[FieldNames.propertyCity] }<br/>
                { values[FieldNames.propertyState] }<br/>
                { values[FieldNames.propertyZip] }<br/>
              </Typography>
              <Typography className={classes.displayDesc} style={{ marginTop: 30, marginBottom: 30 }}>
                {t('collateralAddressVerified.bodyText', {
                  defaultValue:
                    'Click the button below to proceed.',
                })}
              </Typography>
              <ButtonDelay
                loading  = { submitting }
                buttonProps = {{
                  type     : 'submit',
                  color    : 'primary',
                  disabled : submitting,
                  size     : 'large',
                  variant  : 'contained',
                  classes  : { root: 'mobileContained' },
                }}
              >
                {t('button.continue', { defaultValue: 'Continue' })}
              </ButtonDelay>
            </Grid>
          </Grid>
        </TileBox>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  const formName = getFormName(state.router.location.pathname);
  return {
    values : getFormValues(formName)(state),
  };
};

export const AddressVerified = withStyles(styles)(connect(mapStateToProps)(AddressVerifiedComponent));
