import { Type } from 'app/actions/form/reverse-mortgage/types';
import { RootState } from 'app/store/types';
import { createSelector } from 'reselect';

type ReverseMortgageState = {
  isPending: boolean;
};

const initialState: ReverseMortgageState = { isPending: false };

export const reverseMortgageReducer = (state = initialState, action): ReverseMortgageState => {
  switch (action.type) {
    case Type.AJAX_REVERSE_MORTGAGE_CALCS:
      return { isPending: true };

    case Type.AJAX_REVERSE_MORTGAGE_CALCS_SUCCESS:
      return { isPending: false };

    case Type.AJAX_REVERSE_MORTGAGE_CALCS_FAIL:
      return { isPending: false };

    default:
      return state;
  }
};

export const reverseMortgageSelector = (state: RootState): ReverseMortgageState => state.reverseMortgage;
export const isReverseMortgageCalcsPending = createSelector([reverseMortgageSelector], (reverseMortgage) => reverseMortgage.isPending);
