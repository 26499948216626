import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { getSlug } from 'app/util/headers';
import {
  AddCustomersResponse,
  AddCustomersRequest,
  GetCustomerAccountsRequest,
  GetCustomerAccountsResponse,
  GetCustomerReportsRequest,
  GetCustomerReportsResponse,
  CreateAdditionalReportsRequest,
} from 'app/api/verification/types';

/**
 * This calls the API endpoint to start the process to connect to
 * obtain financial information on the customer.
 *
 * @param {RefreshCustomerAccountsRequest} request
 * @returns {AddCustomersResponse}
 */
export const addCustomers = (request: AddCustomersRequest): Promise<AddCustomersResponse> => {
  const slug = getSlug();
  return client
    .post<AddCustomersResponse>(Endpoints.FINANCIAL_VERIFICATION, { ...request, slug })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response?.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};

/**
 * This calls the API endpoint to delete all the testing
 * customers in Finicity.  It will not remove active (production)
 * customers.
 *
 * @returns {Promise<void>}
 */
export const deleteAllCustomers = async (): Promise<void> => {
  const slug = getSlug();
  const url = `${Endpoints.FINANCIAL_VERIFICATION}?slug=${slug}`;
  await client
    .delete(url)
    .catch((error) => {
      if (error.response?.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};

/**
 * This calls the API endpoint to see if the customer accounts are
 * completely refreshsed and available.
 *
 * @param {GetCustomerAccountsRequest} request
 * @returns {Promise<GetCustomerAccountsResponse>}
 */
export const getCustomerAccounts = (request: GetCustomerAccountsRequest): Promise<GetCustomerAccountsResponse> => {
  const slug = getSlug();
  const url = `${Endpoints.FINANCIAL_VERIFICATION}/${request.customerId}/accounts?slug=${slug}`;
  return client
    .get<GetCustomerAccountsResponse>(url)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response?.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};

/**
 * This calls the API endpoint to start generating the Verification of Assets
 * report in Finicity.
 *
 * @param {CreateAdditionalReportsRequest} request
 * @returns {Promise<void>}
 */
export const createAdditionalReports = async (request: CreateAdditionalReportsRequest): Promise<void> => {
  const slug = getSlug();
  const url = `${Endpoints.FINANCIAL_VERIFICATION}/${request.customerId}/reports`;
  await client
    .post(url, { slug })
    .catch((error) => {
      if (error.response?.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};

/**
 * This calls the API endpoint to check and see if all the reports
 * we have previous requested are done and successfully generated.
 *
 * @param {GetCustomerReportsRequest} request
 * @returns {Promise<GetCustomerReportsResponse>}
 */
export const getCustomerReports = (request: GetCustomerReportsRequest): Promise<GetCustomerReportsResponse> => {
  const slug = getSlug();
  const url = `${Endpoints.FINANCIAL_VERIFICATION}/${request.customerId}/reports?slug=${slug}`;
  return client
    .get<GetCustomerReportsResponse>(url)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response?.data) {
        throw error.response.data.error;
      }
      throw error;
    });
};
