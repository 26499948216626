import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formValueSelector, getFormValues } from 'redux-form';
import { routes } from 'app/routes/route-list';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { RootState } from 'app/store/types';
import { compose } from 'redux';
import download from 'downloadjs';
import { getFormName } from 'app/routes/helpers';
import { mapUniversalLoanSource } from '../api/loan-application/universal/mappings';
import { ajaxPostChecklist } from '../actions/form/actions';

type ConnectProps = ReturnType<typeof mapStateToProps>;
type HocProps     = WithTranslation;
type Props        = ConnectProps & HocProps & { dispatchAjaxPostChecklist: (formData: FormData) => Promise<string> };

const mapStateToProps = (state: RootState) => {
  const formName = getFormName(state.router.location.pathname);
  return {
    canShowChecklist:
      state.router.location.pathname === routes.applicationPurchase ||
      state.router.location.pathname === routes.applicationRefinance ||
      state.router.location.pathname === routes.applicationConstruction ||
      state.router.location.pathname === routes.applicationLand ||
      state.router.location.pathname === routes.sbLineOfCredit ||
      state.router.location.pathname === routes.sbTerm,
    checklist: state.checklistPdf.checklist,
    formData: getFormValues(formName)(state),
    formValues: formValueSelector(formName)(state, FieldNames.firstName, FieldNames.lastName, FieldNames.commercialBusinessName),
    source: mapUniversalLoanSource(formName),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAjaxPostChecklist: (formData) => dispatch(ajaxPostChecklist(formData)),
  };
};

const DownloadChecklistComponent = (props: Props) => {
  const { checklist, canShowChecklist, formValues = {}, t } = props;

  const [currentChecklist, setCurrentChecklist] = React.useState(checklist);
  const [loading, setLoading] = React.useState(false);

  const fileName            = t('checklist.pdfFileName', {
    firstName    : formValues[FieldNames.firstName],
    lastName     : formValues[FieldNames.lastName],
    businessName : formValues[FieldNames.commercialBusinessName],
    defaultValue : '{{ lastName }}-{{ firstName }}-Checklist.pdf',
  });

  const handleClick = async () => {
    setLoading(true);
    if(!currentChecklist) {
      await props.dispatchAjaxPostChecklist(props.formData as FormData).then((response) => {
        setCurrentChecklist(response);
        download(response, fileName, 'application/pdf');
      });
    } else {
      download(currentChecklist, fileName, 'application/pdf');
    }
    setLoading(false);
  };

  return (
    canShowChecklist && (
      <React.Fragment>
        <Typography variant = "body2" paragraph = {true}>
          {t('checklist.paragraph1', {
            defaultValue:
              'Download all of the required documents your loan officer will need to review prior to closing your loan by clicking the button below.',
          })}
        </Typography>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              classes  = {{ root: 'longButton' }}
              color    = "primary"
              disabled = { loading }
              variant  = "contained"
              onClick  = {handleClick}
            >
              { loading ? 'Downloading...' : t('checklist.button.download', { defaultValue: 'Download Closing Checklist' }) }
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  );
};

export const DownloadChecklist = compose<React.ComponentType>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withTranslation()(DownloadChecklistComponent));
