import { CreateUniversalLoanRequest } from 'app/api/loan-application/types';
import { mapToUniversalLoanApplication, mapUniversalLoanSource } from 'app/api/loan-application/universal/mappings';
import { Endpoints } from 'app/api/helpers';
import { client } from 'app/api/_client';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { getSlug } from 'app/util/headers';
import { FormName } from 'app/models/options/enums';
import { logger } from 'app/util/logger';
import { FieldNames } from 'app/models/fields/names';

export const mapUniversalLoanRequest = (formData, loanOfficer: LoanOfficerDetail, formName: FormName): CreateUniversalLoanRequest => {
  const loanApplication  = mapToUniversalLoanApplication(formData, formName);
  const source           = mapUniversalLoanSource(formName);
  return {
    creditReportId : formData[FieldNames.creditReportId],
    source,
    loanApplication : loanApplication,
    loanOfficerId   : loanOfficer.id,
    auditMetaData   : formData.auditMetaData,
  };
};

export const postUniversalLoanApplication = (request: CreateUniversalLoanRequest) => {
  const slug = getSlug();
  return client
    .post(Endpoints.LOANS, { ...request, slug })
    .then((response) => {
      if (response.data?.errorMessage) {
        logger.error('Error in Universal Loan API call: ', response);
        throw response;
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      throw error;
    });
};
