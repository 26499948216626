import { Endpoints } from 'app/api/helpers';
import { client } from 'app/api/_client';
import { logger } from 'app/util/logger';

export interface TPVehicleBasic {
  make          : string;
  model         : string;
  trim          : string;
  year          : number;
}

export interface TPVehicleDetailed extends TPVehicleBasic {
  body          : string;
  doors         : string;
  drivetrain    : string;
  engine        : string;
  fuel_type     : string;
  id            : string;
}

export interface TPSearchRequest {
  query         : string;
}

export interface TPSearchResponse {
  vehicles?    : TPVehicleBasic[];
}

export interface TPDetailsRequest extends TPVehicleBasic {}

export interface TPDetailsResponse {
  listDetailed? : TPVehicleDetailed[];
}

export interface TPReportRequest {
  mileage?     : number;
  vehicleId    : string;
  zipCode      : number;
}

export interface TPReportResponse {
  retail: {
    low    : number;
    target : number;
    high   : number;
  },
  tradein: {
    low    : number;
    target : number;
    high   : number;
  },
  error? : TPErrorCode;
}

export enum TPErrorCode {
  NOT_ENOUGH_DATA = 'not_enough_data',
}

/**
 * This method makes a request to the backend to retrieve all the possible
 * year, make, model, and styles that match the given query.  The desire is narrow
 * down the search until the user picks a single vehicle (year/make/model/style).
 *
 * @param {TPSearchRequest} request query to find vehicles
 * @returns {Promise<TPSearchResponse>} matching vehicles
 */
export const getVehiclesTypeahead = (request: TPSearchRequest): Promise<TPSearchResponse> => {
  const params = { query: request.query };
  return client
    .get<TPSearchResponse>(Endpoints.AUTO_VALUATION_SEARCH, { params })
    .then((response) => response.data)
    .catch((error) => {
      logger.error('Error when trying to get auto valuation typeahead results', { error });
      throw error;
    });
};

/**
 * This method makes a request to the backend to retrieve all the possible
 * variants for a specific year, make, model, and style.  Variants are different combinations
 * of body, drivetrain, number of doors, etc.  Some vehicle have many combinations while
 * others have only one.  We need to narrow down to an exact one to get the vehicleId.
 *
 * This `vehicleId` determines the vehicles report / valuation.
 *
 * @param {TPDetailsRequest} request
 * @returns {Promise<TPDetailsResponse>} variants of specific vehicle
 */
export const getVehicleVariants = (request: TPDetailsRequest): Promise<TPDetailsResponse> => {
  return client
    .get<TPDetailsResponse>(Endpoints.AUTO_VALUATION_DETAILS, { params: request })
    .then((response) => response.data)
    .catch((error) => {
      logger.error('Error when trying to get auto valuation variants', { error });
      throw error;
    });
};

/**
 * This method makes a request to the backend to retrieve the vehicle report
 * containing the valuation.  This valuation is based on the specific vehicle_id
 * and zip_code.
 *
 * @param {TPReportRequest} request
 * @returns {Promise<TPReportResponse>} variants of specific vehicle
 */
export const getVehicleValuation = (request: TPReportRequest): Promise<TPReportResponse> => {
  return client
    .get<TPReportResponse>(Endpoints.AUTO_VALUATION_REPORT, { params: request })
    .then((response) => response.data)
    .catch((error) => {
      logger.error('Error when trying to get auto valuation report', { error });
      throw error;
    });
};
