import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';

/**
 * @see Do not add translations for verbiage.  Should not change by client.
 */
const AcrisureAgreementComponent = () => {
  return (
    <Grid container classes={{ container: 'formTermsWrapper' }}>
      <Grid item classes={{ item: 'termItem' }}>
        <Typography
          variant   = 'body2'
          component = 'span'
          classes   = {{ body2: 'small formTerms' }}
        >
          By clicking "Next", I have read and agree to the
          <Link
            underline='always'
            href='https://www.acrisure.com/terms-of-use'
            rel='noopener'
            target='_blank'
            style={{ marginLeft: 5, marginRight: 5, fontSize: '1em', whiteSpace: 'nowrap' }}
          >
            Terms of Use
          </Link>
          and
          <Link
            underline='always'
            href='https://www.acrisure.com/privacy'
            rel='noopener'
            target='_blank'
            style={{ marginLeft: 5, fontSize: '1em', whiteSpace: 'nowrap' }}
          >
            Privacy Policy
          </Link>.
        </Typography>
      </Grid>
    </Grid>
  );
};

export const AcrisureAgreement = AcrisureAgreementComponent;
