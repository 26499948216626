import { Field } from 'redux-form';
import {
  borrowerSsnRequired,
  borrowerSsnValid, coBorrowerSsnRequired,
  coBorrowerSsnValid,
  ssnCannotMatch,
} from 'app/components/FormFields/Ssn/validators';
import { CollectSsnBase } from 'app/components/FormFields/Ssn/CollectSsnBase';

/**
 * This component is used when we are required to collect the SSN for either
 * form submission (LOS integration) or financial verification (VOI, VOIE)
 * 
 * @link https://maddogtechnology.atlassian.net/wiki/spaces/MMP/pages/2909503491/Collection+of+SSN+on+Forms
 */
export const CollectRequiredSsn = ({ ...props }) => {
  return (
    <Field
      component = { CollectSsnBase }
      name      = { props.name }
      validate  = {[
        ssnCannotMatch,
        borrowerSsnValid,
        coBorrowerSsnValid,
        borrowerSsnRequired,
        coBorrowerSsnRequired,
      ]}
    />
  );
};