import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { creditCardsSelector } from 'app/reducers/app-config';
import { RootState } from 'app/store/types';
import { Trans } from 'react-i18next';
import { GetApp } from '@material-ui/icons';
import {
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
  InputLabel,
  Theme,
  Divider,
} from '@material-ui/core';
import { Field } from 'redux-form';
import { required } from 'app/util/validators';

const styles = (theme: Theme) => createStyles({
  selected: {
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.secondary.main,
      '& span': {
        color: '#ffffff',
      },
    },
  },
  radioLabel: {
    marginTop: 5,
    marginRight: 0,
    fontWeight: 400,
  },
  radioDescription: {
    marginLeft: '5%',
    fontStyle: 'italic',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginLeft: 10,
    },
  },
  disclosureItem: {
    marginLeft: '5%',
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      paddingTop: 2,
      paddingBottom: 2,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  disclosureButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  radioWrapper: {
    marginTop: 15,
    '& label': {
      marginRight: 0,
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginLeft: 0,
        '& span:last-child': {
          marginLeft: 10,
        },
      },
    },
    '& label.selected span': {
      [theme.breakpoints.down('sm')]: {
        '& span': {
          color: '#ffffff',
        },
      },
    },
  },
  divider: {
    margin: '5px 0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

const renderRadioGroup = (props) => {
  const { classes, options } = props;
  const isSelected = (option) => props.input.value === option.value;

  return (
    <FormControl fullWidth>
      <RadioGroup {...props.input}>
        {options.map((option, index) => {
          return (
            <Fragment key={index}>
              { index > 0 ? <Divider className={classes.divider} /> : null }
              <FormControlLabel
                control   = {<Radio color='primary' />}
                label     = {option.title}
                value     = {option.value}
                className = {isSelected(option) ? `${classes.radioLabel} ${classes.selected}` : classes.radioLabel}
              />
              <Grid container>
                <Grid xs={12} item className={classes.radioDescription}>
                  {option.description}
                </Grid>
                <Grid xs={12} item className={classes.disclosureItem}>
                  <Button
                    color     = 'primary'
                    variant   = 'contained'
                    className = {classes.disclosureButton}
                    rel       = 'noopener'
                    target    = '_blank'
                    href      = {option.disclosureUrl}
                    disabled  = {!isSelected(option)}
                  >
                    <Trans
                      i18nKey  = 'formAgreement.creditCardDisclosure.button'
                      values   = {{ title: option.title }}
                      defaults = '{{ title }} Disclosure'
                    />
                    <GetApp style={{ marginLeft: 5 }} />
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const CreditCardRadioComponent = withStyles(styles)(({ ...props }: any) => {
  const { classes, creditCards } = props;
  return (
    <div className={classes.radioWrapper}>
      { props.label ? <InputLabel shrink>{ props.label }</InputLabel> : null }
      <Field
        classes   = {props.classes}
        component = {renderRadioGroup}
        name      = {props.name}
        onChange  = {props.onChange}
        options   = {creditCards}
        validate  = {[required]}
      />
    </div>
  );
});

const mapStateToProps = (state: RootState) => ({
  creditCards: creditCardsSelector(state),
});

export const CreditCardRadio = connect(mapStateToProps)(CreditCardRadioComponent);
