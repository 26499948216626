import React from 'react';
import { FieldArray } from 'redux-form';
import { Select } from 'app/components/FormFields/Select';
import { Currency } from 'app/components/FormFields/Currency';
import { RETIREMENT_INCOME_SOURCE_OPTIONS } from 'app/models/options/options';
import { Button } from 'app/components/Button';
import { FieldNames } from 'app/models/fields/names';
import { requiredArray } from 'app/util/validators';
import { incomeInitialValue } from 'app/util/initial-values';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';

const RenderRetirementIncomeSourcesComponent = ({ ...props }) => {
  const { fields, name, t }   = props;
  const incomeSourceField  = `${name}.${FieldNames.incomeSource}`;
  const incomeMonthlyField = `${name}.${FieldNames.incomeMonthly}`;

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={`${field}-${index}`} style={{ paddingBottom: 20 }}>
          <Select
            label   = {localizeLabel(incomeSourceField, t, 'Retirement Income Source')}
            name    = {`${field}.${FieldNames.incomeSource}`}
            options = {localizeOptions(incomeSourceField, t, RETIREMENT_INCOME_SOURCE_OPTIONS)}
          />
          <Currency
            label = {localizeLabel(incomeMonthlyField, t, 'Monthly Retirement Income')}
            name  = {`${field}.${FieldNames.incomeMonthly}`}
          />
          {fields.length > 1 && (
            <Button
              disableRipple
              color   = "primary"
              classes = {{
                root: 'link',
                text: 'remove',
              }}
              onClick = {() => fields.remove(index)}
            >
              {t(`question.${name}.button.remove`, { defaultValue: 'Remove' })}
            </Button>
          )}
        </div>
      );
    });

  React.useEffect(() => {
    if (fields.length === 0) {
      fields.push(incomeInitialValue);
    }
  }, [fields]);

  return (
    <div>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(incomeInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Source' })}
      </Button>
    </div>
  );
};

export const RetirementIncomeSources = ({ ...props }) => {
  const { name } = props;
  const { t }    = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {RenderRetirementIncomeSourcesComponent}
        name      = {name}
        props     = {{ name, t }}
        validate  = {[
          requiredArray([FieldNames.incomeSource, FieldNames.incomeMonthly]),
        ]}
      />
    </FormControl>
  );
};
