import React from 'react';
import { connect } from 'react-redux';
import {
  colors,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import tinycolor from 'tinycolor2';

export class ThemeWrapperComponent extends React.Component<any, any> {
  render() {
    const {
      primaryColor   = '#E0E0E0',
      secondaryColor = '#E0E0E0',
      tertiaryColor  = '#E0E0E0',
    } = this.props;

    const contrastPrimaryColor = tinycolor(primaryColor).isLight() ? colors.common.black : colors.common.white;

    let theme = createTheme({
      palette: {
        primary: {
          main: `${primaryColor}`,
          contrastText: contrastPrimaryColor,
        },
        secondary: {
          main: `${secondaryColor}`,
          contrastText: colors.common.white,
        },
        tertiary: {
          main: `${tertiaryColor}`,
          dark: darken(`${tertiaryColor}`, 0.1),
          light: lighten(`${tertiaryColor}`, 0.15),
          contrastText: colors.common.white,
        },
      },
    });

    const unit = theme.spacing();

    theme = {
      ...theme,
      overrides: {
        MuiTypography: {
          root: {
            color: colors.grey[800],
            letterSpacing: 0.2,
            fontFamily: '"Roboto",sans-serif',
            fontSize: 16,
            '&.light': {
              fontWeight: 300,
              '&$body2, &$body1': {
                [theme.breakpoints.down('xs')]: {
                  fontSize: 15,
                },
              },
            },
            '&.loginTitle': {
              fontSize     : 25,
              paddingBottom: '30px',
            },
            '&.specialFont': {
              fontFamily: '"Roboto Slab",serif',
            },
            '&.semiBold': {
              fontWeight: 500,
            },
            '&.bold': {
              fontWeight: 700,
            },
            '&.white': {
              color: colors.common.white,
            },
            '&.mobileWhite': {
              [theme.breakpoints.down('sm')]: {
                color: colors.common.white,
              },
            },
            '&.mobileCenter': {
              [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
              },
            },
            '&.mobileTextShadow': {
              [theme.breakpoints.down('sm')]: {
                textShadow: 'rgba(0,0,0,.5) 0 2px 9px',
              },
            },
            '&.heroTitle': {
              color: colors.common.white,
              fontWeight: 700,
              fontSize: 60,
              textShadow: '2px 2px 4px rgba(97,97,97,0.5)',
              letterSpacing: '.2px',
            },
            '&.branchTitle': {
              color: colors.common.white,
              fontSize: 50,
              textShadow: '0 2px 5px rgba(0,0,0,0.5)',
              textAlign: 'center',
              fontFamily: '"Roboto",sans-serif',
              display: 'block',
              [theme.breakpoints.down('md')]: {
                fontSize: 40,
              },
              [theme.breakpoints.down('xs')]: {
                fontSize: 25,
              },
            },
            '&.tileBoxTitle': {
              fontSize: 18,
              lineHeight: '24px',
              fontWeight: 500,
              color: theme.palette.primary.contrastText,
            },
            '&.smallTitle': {
              fontSize  : 18,
              fontWeight: 300,
              margin    : '0 0 2px',
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: '5px',
              },
            },
            '&.loanDisplay': {
              lineHeight: '40px',
              fontSize: 18,
              [theme.breakpoints.down('xs')]: {
                fontSize: 17,
              },
            },
            '&.infoMessage': {
              overflow: 'hidden',
              marginBottom: '32px',
              lineHeight: 1.4,
            },
            '&.mobileAsset': {
              [theme.breakpoints.down('xs')]: {
                fontSize: 15,
              },
              '&.largeLabel': {
                fontSize: 18,
                fontWeight: 400,
                marginLeft: '30px',
                [theme.breakpoints.down('xs')]: {
                  fontSize: 15,
                  marginLeft: '16px',
                },
              },
              '&.largeAsset': {
                fontSize: 25,
                fontWeight: 700,
                [theme.breakpoints.down('xs')]: {
                  fontSize: 15,
                },
                '&.warning': {
                  whiteSpace: 'nowrap',
                },
              },
            },
            '&.modal': {
              '& h1, h2': {
                fontFamily: '"Roboto Slab",serif',
                marginTop: 0,
              },
            },
            '&.infoTitle': {
              fontWeight: 500,
              marginBottom: '8px',
            },
            '&.sectionData': {
              [theme.breakpoints.down('xs')]: {
                fontSize: 14,
              },
              '&.sectionDataTitle': {
                fontWeight: 500,
                color: colors.grey[500],
              },
            },
            '&.subtitle': {
              paddingBottom: '20px',
              lineHeight: 1.4,
            },
            '&.clearWrapper': {
              fontSize: 30,
              [theme.breakpoints.up('md')]: {
                marginTop: 0,
              },
              [theme.breakpoints.down('xs')]: {
                fontSize: 18,
              },
              '&.scheduleTitle': {
                marginTop: 0,
                [theme.breakpoints.down('xs')]: {
                  fontSize: 20,
                  marginBottom: '25px',
                },
              },
            },
            '&.login': {
              fontSize: 30,
              textAlign: 'center',
              display: 'block',
              marginTop: '40px',
              [theme.breakpoints.up('md')]: {
                marginTop: '60px',
                fontSize: 35,
              },
              [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.contrastText,
                marginBottom: '40px',
              },
            },
            '&.loginInstructions': {
              [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.contrastText,
              },
            },
            '&.titleMargin': {
              marginTop: '20px',
              [theme.breakpoints.up('md')]: {
                marginTop: '90px',
              },
              '&.section': {
                [theme.breakpoints.down('sm')]: {
                  marginTop: 0,
                },
              },
            },
            '&.disclaimer': {
              fontSize: '12px',
              lineHeight: 1.4,
              textAlign: 'left',
              marginBottom: '15px',
              '&.rateTable': {
                lineHeight: 1.5,
                textAlign: 'center',
                marginTop: '6px',
                marginBottom: '1em',
                fontSize: '12px',
                padding: '0 26px',
                [theme.breakpoints.down('sm')]: {
                  margin: '12px 0',
                },
                [theme.breakpoints.down('xs')]: {
                  padding: '0 16px',
                },
              },
              '&.center': {
                textAlign: 'center',
              },
            },
            '&.paddingBottom': {
              [theme.breakpoints.up('sm')]: {
                paddingBottom: '20px',
              },
            },
          },
          h6: {
            fontFamily: '"Roboto Slab",serif',
            color: colors.grey[800],
            fontSize: 18,
            fontWeight: 700,
            letterSpacing: 0,
            margin: '6px 0 16px',
            lineHeight: 1.3,
            display: 'inline-block',
            [theme.breakpoints.up('sm')]: {
              fontSize: 22,
              margin: '16px 0',
            },
            [theme.breakpoints.up('md')]: {
              marginTop: '22px',
              fontSize: 25,
            },
            '&.sectionFooterTitle': {
              display: 'block',
              [theme.breakpoints.up('md')]: {
                textAlign: 'center',
              },
              [theme.breakpoints.down('sm')]: {
                margin: 0,
              },
            },
            '&.withTopSection': {
              marginTop: '20px',
            },
            '&.landingTitle': {
              marginBottom: '5px',
            },
            '&.contentTitle': {
              [theme.breakpoints.up('md')]: {
                marginTop: '50px',
              },
            },
            '&.smallSummaryTitle': {
              marginBottom: '33px',
              textAlign: 'left',
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
                margin: '0 0 10px',
              },
            },
            '&.splashTitle': {
              [theme.breakpoints.down('sm')]: {
                fontSize: 25,
              },
            },
            '&.centerTitle': {
              display: 'block',
              textAlign: 'center',
            },
            '&.noMarginTop': {
              marginTop: 0,
            },
            '&.noMarginBottom': {
              marginBottom: 0,
            },
          },
          h5: {
            color   : colors.grey[800],
            fontSize: 25,
          },
          subtitle1: {
            color: colors.grey[800],
            fontWeight: 700,
            '&.large': {
              fontSize: 20,
              [theme.breakpoints.down('sm')]: {
                fontSize: 18,
              },
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
              },
            },
            '&.branchSubheading': {
              fontSize: 30,
              fontWeight: 500,
              color: colors.grey[900],
              marginTop: '30px',
              lineHeight: 1.25,
              [theme.breakpoints.down('sm')]: {
                fontSize: 25,
                padding: '0 30px 10px',
              },
              [theme.breakpoints.down('xs')]: {
                fontSize: 20,
                padding: 0,
              },
            },
          },
          h1: {
            fontFamily: '"Roboto Slab",serif',
            color: colors.grey[800],
            fontWeight: 700,
            fontSize: 40,
            letterSpacing: 0.5,
            marginLeft: 0,
            padding: '40px 0 25px',
            [theme.breakpoints.down('sm')]: {
              fontSize: 25,
            },
            '&.summaryHeroTitle': {
              color: colors.common.white,
              padding: 0,
              textShadow: '1px 1px 4px rgba(0,0,0,0.5)',
              [theme.breakpoints.down('sm')]: {
                fontSize: 30,
              },
            },
          },
          h2: {
            color: colors.grey[800],
            fontSize: 40,
            '&.maxLoanTitle': {
              fontSize: 20,
              marginBottom: '50px',
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: '20px',
              },
            },
            '&.submitMessage': {
              fontSize: 20,
              fontWeight: 700,
              marginBottom: '1em',
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
              },
            },
            '&.largeRateDisplay': {
              fontWeight: 700,
              fontSize: 50,
              margin: '8px 0 16px',
              [theme.breakpoints.down('xs')]: {
                fontSize: 40,
              },
              '&.formDisplay': {
                marginTop: 0,
              },
            },
          },
          h3: {
            fontFamily: '"Roboto Slab",serif',
            color: colors.grey[800],
            fontSize: 30,
            marginBottom: '24px',
            letterSpacing: '.2px',
            fontWeight: 700,
            [theme.breakpoints.down('md')]: {
              fontSize: 22,
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: 18,
              fontWeight: 700,
              marginBottom: '10px',
            },
            '&.mobileLarge': {
              [theme.breakpoints.down('md')]: {
                fontSize: 25,
              },
            },
          },
          h4: {
            color: colors.grey[800],
            fontSize: 35,
            fontWeight: 500,
            marginLeft: 0,
            '&.loader': {
              paddingBottom: '25px',
              margin: '16px 0',
              [theme.breakpoints.down('xs')]: {
                fontSize: 24,
                paddingBottom: '10px',
                marginTop: 0,
              },
            },
            '&.headerTitle': {
              fontSize    : 20,
              marginBottom: '.25rem',
            },
            '&.landingTitle': {
              fontWeight: 700,
              marginBottom: '20px',
              [theme.breakpoints.down('sm')]: {
                marginTop: '20px',
              },
              [theme.breakpoints.down('xs')]: {
                fontSize: 25,
              },
            },
          },
          body2: {
            color: colors.grey[800],
            fontSize: 16,
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
            },
            '&.inline': {
              display: 'inline',
            },
            '&.small': {
              fontSize: 14,
              lineHeight: 1.3,
              '&.formTerms': {
                color: colors.grey[800],
                [theme.breakpoints.down('xs')]: {
                  fontSize: 13,
                },
              },
            },
            '&.splashBody': {
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                lineHeight: '21px',
              },
            },
            '&.arrayItem': {
              marginBottom: '3px',
            },
            '&.readOnlyValue': {
              padding: '4px 0 7px',
              lineHeight: '1.1875em',
              [theme.breakpoints.down('xs')]: {
                paddingBottom: 0,
              },
            },
            '&.questionDisclaimer': {
              fontSize: 14,
              marginBottom: '1.25rem',
              color: colors.grey[500],
              display: 'inline-flex',
              alignItems: 'flex-start',
            },
          },
          body1: {
            color: colors.grey[800],
            fontSize: 16,
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
            },
            '&.condensedAlign': {
              [theme.breakpoints.down('sm')]: {
                lineHeight: '40px',
              },
            },
            '&.readOnlyLabel': {
              color: colors.grey[500],
              fontWeight: 500,
            },
            '&.assetTitle': {
              fontFamily: '"Roboto Slab",serif',
              fontWeight: 700,
              fontSize: 18,
              marginBottom: '16px',
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
              },
            },
            '&.submitError': {
              marginTop: '20px',
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginTop: '-5px',
                textAlign: 'center',
              },
            },
            '&.arrayTitle': {
              fontSize: 19,
              fontWeight: 500,
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                lineHeight: '1.46429em',
                marginBottom: '4px',
              },
            },
          },
          button: {
            textTransform: 'none',
            cursor: 'pointer',
            fontSize: 16,
            fontWeight: 400,
            '&:hover, &:focus': {
              textDecoration: 'underline',
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
            },
          },
          gutterBottom: {
            marginBottom: '28px',
            [theme.breakpoints.down('xs')]: {
              marginBottom: '20px',
            },
          },
          paragraph: {
            marginBottom: '22px',
            lineHeight: '23px',
            [theme.breakpoints.down('xs')]: {
              marginBottom: '16px',
            },
            '&.sectionParagraph': {
              marginBottom: '8px',
            },
          },
        },
        MuiButton: {
          root: {
            textTransform: 'none',
            fontSize: 16,
            borderRadius: '3px',
            fontWeight: 400,
            '&.light': {
              fontWeight: 300,
            },
            '&.longButton': {
              minWidth: '280px',
              minHeight: '50px',
              lineHeight: '50px',
              padding: '0 16px',
              margin: '5px 0 30px',
              [theme.breakpoints.down('xs')]: {
                margin: '10px 0 40px',
              },
            },
            '&.roundedButton': {
              borderRadius: '20px',
              fontWeight  : 500,
              '&$sizeLarge': {
                borderRadius: '25px',
                minWidth    : '220px',
                [theme.breakpoints.down('xs')]: {
                  width: '100%',
                },
              },
            },
            '&.smallTextButton': {
              fontSize  : 14,
              lineHeight: '1.46429em',
            },
            '&.leftHeaderButton': {
              minWidth: 0,
              paddingLeft: 0,
              [theme.breakpoints.down('xs')]: {
                fontSize: 14,
              },
            },
            '&.rightHeaderButton': {
              minWidth: 0,
              paddingRight: 0,
              [theme.breakpoints.down('xs')]: {
                fontSize: 14,
              },
            },
            '&.tabletButton': {
              fontSize: 18,
              minWidth: '250px',
              minHeight: '60px',
              height: '100%',
              padding: '12px 16px',
              '&$containedPrimary': {
                '&$disabled': {
                  background: theme.palette.primary.main,
                  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
                },
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
              [theme.breakpoints.down('xs')]: {
                fontWeight: 400,
                fontSize: 16,
                minWidth: '250px',
                minHeight: '50px',
                marginTop: 0,
              },
              '&.appButton': {
                '& > $label': {
                  display: 'block',
                },
              },
            },
            '&.editButton': {
              minWidth: '165px',
              minHeight: '40px',
              padding: '8px',
              [theme.breakpoints.down('xs')]: {
                width: '100%',
                minHeight: '50px',
              },
            },
            '&.cancelEditButton': {
              minHeight: '40px',
              margin: '0 0 0 25px',
              textDecoration: 'underline',
              [theme.breakpoints.down('xs')]: {
                minHeight: '50px',
                width: '100%',
                margin: '5px 0 0',
                textDecoration: 'none',
              },
            },
            '&.link': {
              padding: 0,
              minWidth: '0',
              minHeight: '0',
              textDecoration: 'underline',
              justifyContent: 'unset',
              verticalAlign: 'unset',
              fontSize: 14,
              '&.termsLink': {
                fontSize: 14,
                display: 'inline',
                verticalAlign: 'auto',
                [theme.breakpoints.down('xs')]: {
                  fontSize: 13,
                },
              },
              '&.semiBold': {
                fontWeight: 500,
              },
              '&.navLink': {
                [theme.breakpoints.down('xs')]: {
                  fontSize: 16,
                },
              },
              '&.viewAll': {
                marginTop: '16px',
              },
              '&.continue': {
                fontWeight: 700,
                fontSize: 17,
                [theme.breakpoints.down('xs')]: {
                  fontSize: 16,
                },
              },
              '&.skip': {
                fontSize: 14,
                fontWeight: 500,
                marginBottom: '30px',
              },
              '&.noUnderline': {
                textDecoration: 'none',
              },
              '&:hover': {
                textDecoration: 'underline',
                color: colors.common.black,
              },
              '&.inlineLargeButton': {
                minHeight: '40px',
                [theme.breakpoints.up('sm')]: {
                  minHeight: '50px',
                },
              },
            },
            '&.saveButton': {
              [theme.breakpoints.down('xs')]: {
                width: '100%',
                minHeight: '50px',
                marginTop: '10px',
              },
            },
            '&.mobileFixed': {
              minHeight: '50px',
              lineHeight: '50px',
              paddingTop: 0,
              paddingBottom: 0,
              fontWeight: 500,
              [theme.breakpoints.down('sm')]: {
                minHeight: '75px',
                lineHeight: '75px',
                borderRadius: 0,
                fontSize: 18,
                position: 'fixed',
                bottom: 0,
                left: 0,
                zIndex: 999,
                width: '100%',
              },
            },
            '&.remove': {
              marginTop: '-16px',
              marginBottom: '32px',
              fontSize: 14,
              letterSpacing: '.16px',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
            '&.add': {
              [theme.breakpoints.up('sm')]: {
                marginTop: '5px',
              },
            },
            '&.change': {
              margin: '30px 0',
              [theme.breakpoints.up('sm')]: {
                margin: '40px 0',
              },
              '&.condensed': {
                [theme.breakpoints.down('xs')]: {
                  marginBottom: '10px',
                },
              },
            },
            '&.stackedButton': {
              marginBottom: '25px',
            },
          },
          containedPrimary: {
            fontWeight: 500,
          },
          text: {
            color: colors.grey[800],
            '&.accentColor': {
              color: colors.blue[700],
              '&:hover': {
                color: darken(colors.blue[700], 0.1),
              },
            },
            '&.rightTableButton': {
              justifyContent: 'flex-end',
              minWidth      : '80px',
            },
            '&:hover': {
              textDecoration: 'underline',
              background: 'none',
            },
          },
          fullWidth: {
            '&.tabletSizeLarge': {
              [theme.breakpoints.between('sm', 'sm')]: {
                maxWidth: '300px',
                display: 'flex',
                margin: '0 auto 25px',
              },
              [theme.breakpoints.down('xs')]: {
                minHeight: '50px',
              },
            },
            '&.mobileOnly': {
              [theme.breakpoints.up('md')]: {
                width: 'auto',
              },
            },
          },
          sizeSmall: {
            minWidth: '100px',
            minHeight: '40px',
            lineHeight: '40px',
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: 16,
            '&.prefooterButton': {
              minWidth: '120px',
            },
            '&.mobileSmall': {
              [theme.breakpoints.down('xs')]: {
                fontSize: 14,
                minWidth: '90px',
              },
            },
            '&.brickButton': {
              borderRadius : '30px',
              minWidth     : '75px',
              minHeight    : '30px',
              lineHeight   : '30px',
              fontWeight   : 500,
              [theme.breakpoints.down('sm')]: {
                minHeight : '50px',
                lineHeight: '50px',
                maxWidth  : '220px',
                width     : '100%',
                margin: '5px 0',
              },
              [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
              },
            },
            '&.roundedSmall': {
              [theme.breakpoints.up('md')]: {
                minWidth  : '90px',
                minHeight : '35px',
                lineHeight: '35px',
              },
              '&.mobileFullWidth': {
                [theme.breakpoints.down('xs')]: {
                  width       : '100%',
                  minHeight   : '50px',
                  lineHeight  : '50px',
                  borderRadius: '25px',
                  margin      : '10px 0',
                },
              },
            },
            '&.buttonSpacing': {
              margin: '0 12px',
            },
          },
          sizeLarge: {
            minWidth: '100%',
            minHeight: '50px',
            lineHeight: '50px',
            fontSize: 16,
            padding: '0px 16px',
            [theme.breakpoints.up('sm')]: {
              minWidth: '200px',
            },
            '&.mobileContained': {
              minWidth: '190px',
            },
            '&.xLarge': {
              [theme.breakpoints.up('sm')]: {
                minWidth: '250px',
                minHeight: '60px',
                lineHeight: '60px',
              },
            },
          },
          contained: {
            '&$disabled': {
              color: colors.common.white,
              backgroundColor: colors.grey[400],
            },
            '&.tertiary': {
              background: theme.palette.tertiary.main,
              color: theme.palette.tertiary.contrastText,
              '&:hover': {
                background: theme.palette.tertiary.dark,
              },
              '&$disabled': {
                background: colors.grey[400],
              },
            },
            '&.fromOutlined': {
              border: '1px solid transparent',
            },
            '&.accentColor': {
              background: colors.blue[700],
              color: colors.common.white,
              '&:hover': {
                background: darken(colors.blue[700], 0.1),
              },
              '&$disabled': {
                background: colors.grey[400],
              },
            },
            '&.white': {
              background: colors.common.white,
              '&.accentColorText': {
                color: colors.blue[700],
              },
              '&:hover': {
                background: darken(colors.common.white, 0.1),
              },
            },
            '&.mobileGhost': {
              [theme.breakpoints.down('sm')]: {
                background: 'none',
                border: `1px solid ${colors.common.white}`,
                '&:hover': {
                  background: colors.common.white,
                  color: theme.palette.secondary.main,
                },
              },
            },
          },
          outlined: {
            color: colors.grey[800],
            borderColor: colors.grey[800],
            '&:focus, &:hover': {
              textDecoration: 'none',
            },
            '&$disabled': {
              color: colors.grey[400],
              borderColor: colors.grey[400],
            },
            '&.tertiary': {
              borderColor: theme.palette.tertiary.main,
              color: theme.palette.tertiary.main,
              '&:hover': {
                color: theme.palette.tertiary.dark,
                borderColor: theme.palette.tertiary.dark,
              },
            },
            '&.white': {
              borderColor: colors.common.white,
              color: colors.common.white,
              '&:hover': {
                background: darken(colors.common.white, 0.1),
              },
              '&.accentColor': {
                '&:hover': {
                  color: colors.blue[700],
                },
              },
            },
          },
          outlinedPrimary: {
            borderColor: theme.palette.primary.main,
            color: colors.grey[800],
            '&:focus, &:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          },
          disabled: {
            '&.keepTextColor': {
              color: colors.grey[800],
            },
          },
        },
        MuiLink: {
          root: {
            color: colors.grey[900],
            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
        MuiInput: {
          root: {
            '&.outlined': {
              border      : `1px solid ${colors.grey[300]}`,
              borderRadius: '2px',
              alignItems  : 'center',
              '&.searchTable': {
                fontSize: 14,
                [theme.breakpoints.up('md')]: {
                  width: '300px',
                },
              },
            },
            '&.light': {
              fontWeight: 300,
            },
          },
          input: {
            '&.percentField': {
              width: '100%',
              marginTop: 16,
            },
            '&.editableInput': {
              marginTop: '10px',
              fontWeight: 500,
            },
            '&.indent': {
              padding: '9px',
            },
          },
          underline: {
            '&:after': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
            '&.contrast': {
              [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.contrastText,
                '&:after': {
                  borderColor: theme.palette.secondary.contrastText,
                },
                '&:before': {
                  borderColor: theme.palette.secondary.contrastText,
                },
              },
            },
          },
        },
        MuiInputAdornment: {
          positionEnd: {
            '&.percent': {
              marginLeft: 2,
              marginTop: 10,
            },
          },
        },
        MuiIconButton: {
          root: {
            '&.buttonClose': {
              width: '40px',
              height: '40px',
              position: 'absolute',
              right: '-22px',
              top: '-8px',
              [theme.breakpoints.down('xs')]: {
                right: '-12px',
              },
            },
            '&.searchInput': {
              width: '35px',
              height: '35px',
              color: colors.grey[800],
            },
            '&.portalPaginationNav': {
              color: colors.grey[800],
              '&$disabled': {
                color: colors.grey[400],
              },
              '& svg': {
                fontSize: 32,
              },
            },
            '&.showHide': {
              width    : '37px',
              height   : '37px',
              '& svg': {
                fontSize: 20,
              },
            },
          },
        },
        MuiFormLabel: {
          root: {
            color: colors.grey[800],
            '&$focused': {
              color: theme.palette.primary.main,
            },
            '&.legend': {
              color: colors.grey[800],
              fontWeight: 700,
              fontSize: 18,
              letterSpacing: '.2px',
              paddingTop: '20px',
              [theme.breakpoints.down('sm')]: {
                fontSize: 16,
              },
            },
            '&.contrast': {
              [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.contrastText,
              },
              [theme.breakpoints.down('xs')]: {
                fontWeight: 400,
              },
              '&$focused': {
                [theme.breakpoints.down('sm')]: {
                  color: theme.palette.secondary.contrastText,
                },
              },
            },
          },
        },
        MuiInputLabel: {
          shrink: {
            transform: 'translate(0, 1.5px) scale(0.85)',
            fontWeight: 500,
            color: colors.grey[800],
            letterSpacing: '.2px',
          },
        },
        MuiStepper: {
          root: {
            background: 'none',
            padding: '10px 0 25px',
            [theme.breakpoints.down('xs')]: {
              paddingBottom: '16px',
            },
          },
        },
        MuiStepConnector: {
          root: {
            display: 'none',
          },
        },
        MuiStep: {
          horizontal: {
            width: '100%',
            paddingLeft: '5px',
            paddingRight: '5px',
          },
        },
        MuiStepButton: {
          root: {
            display: 'block',
            margin: '-24px 0',
            padding: '24px 0',
            [theme.breakpoints.down('xs')]: {
              margin: '-12px 0',
              padding: '12px 0',
            },
          },
        },
        MuiStepLabel: {
          root: {
            border: `2.5px solid ${theme.palette.primary.main}`,
          },
          disabled: {
            borderColor: colors.grey[300],
          },
        },
        MuiNativeSelect: {
          icon: {
            color: colors.grey[800],
            marginRight: '-3px',
          },
        },
        MuiFormHelperText: {
          root: {
            color: theme.palette.primary.main,
          },
        },
        MuiFormControl: {
          root: {
            marginBottom: '26px',
            '&.editableField': {
              marginBottom: '20px',
            },
            '&.noMargin': {
              marginBottom: 0,
            },
          },
        },
        MuiFormControlLabel: {
          root: {
            '&.isolatedToggle': {
              border: 0,
              padding: 0,
            },
          },
          label: {
            '&.largeToggle': {
              [theme.breakpoints.up('sm')]: {
                fontSize: 20,
              },
            },
          },
        },
        MuiSvgIcon: {
          root: {
            '&.alignDefaultSize': {
              float: 'left',
              marginRight: '5px',
            },
            '&.alignChevronLeft': {
              marginLeft: '-6px',
            },
            '&.phone': {
              transform: 'rotate(270deg)',
              fontSize: 26,
              marginLeft: '5px',
            },
            '&.search': {
              color: colors.grey[800],
            },
            '&.warning': {
              fontSize: 18,
              marginLeft: '8px',
              verticalAlign: 'text-top',
              [theme.breakpoints.down('xs')]: {
                marginLeft: '5px',
                verticalAlign: 'sub',
              },
              '&.left': {
                marginLeft: 0,
                marginRight: '8px',
                [theme.breakpoints.down('xs')]: {
                  marginLeft: '5px',
                },
              },
            },
            '&.info': {
              fontSize: 18,
              marginRight: '5px',
              verticalAlign: 'top',
            },
            '&.inputEmail': {
              fontSize: 18,
              color: colors.grey[800],
              '&.mobileContrast': {
                [theme.breakpoints.down('sm')]: {
                  color: theme.palette.secondary.contrastText,
                },
              },
            },
            '&.buttonCheck': {
              marginLeft: '2px',
              marginTop: '-.125em',
            },
            '&.closeModal': {
              [theme.breakpoints.down('xs')]: {
                fontSize: 22,
              },
            },
            '&.start': {
              marginRight: '4px',
            },
            '&.mobileFullWidthArrow': {
              [theme.breakpoints.down('sm')]: {
                fontSize: 38,
                color: colors.grey[800],
                position: 'absolute',
                right: 0,
              },
            },
            '&.tableStatus': {
              fontSize: 9,
              marginRight: '8px',
            },
            '&.account': {
              fontSize: 36,
              color: colors.grey[800],
            },
            '&.marginLeft': {
              marginLeft: '10px',
            },
          },
        },
        MuiSwitch: {
          colorPrimary: {
            height: 'auto',
            '&$checked': {
              color: theme.palette.primary.dark,
            },
          },
        },
        MuiGrid: {
          container: {
            '&.smallInputContainer': {
              [theme.breakpoints.up('md')]: {
                maxWidth: '250px',
              },
            },
            '&.headerWrapper': {
              paddingTop: '70px',
              [theme.breakpoints.down('xs')]: {
                paddingTop: '46px',
              },
            },
            '&.headerContainer': {
              maxWidth: '900px',
              width: '100%',
              margin: '0 auto',
              padding: '15px 0',
              [theme.breakpoints.down('xs')]: {
                padding: 0,
              },
            },
            '&.footerItemContainer': {
              padding: '10px 0px',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems   : 'center',
              },
            },
            '&.footerTextContainer': {
              padding: `${unit * 2}px ${0}px`,
            },
            '&.logo': {
              height: '40px',
              margin: '0 auto',
              textAlign: 'center',
              display: 'block',
              maxWidth: '100%',
              [theme.breakpoints.down('sm')]: {
                height: '30px',
              },
            },
            '&.tabletSpacing': {
              [theme.breakpoints.between('sm', 'sm')]: {
                padding: '0 26px',
              },
            },
            '&.verifyContainer': {
              borderTop: `1px solid ${colors.grey[300]}`,
              paddingTop: '10px',
            },
            '&.shadeCover': {
              '&.mobileOnly': {
                [theme.breakpoints.down('sm')]: {
                  background: 'rgba(0,0,0,.6)',
                  height    : '100%',
                  width     : '100%',
                  paddingTop: '100px',
                },
              },
            },
            '&.canvas': {
              backgroundColor: colors.grey[100],
              minHeight      : '500px',
              paddingTop     : '22px',
              paddingBottom  : '50px',
              [theme.breakpoints.up('xl')]: {
                minHeight: '700px',
              },
              [theme.breakpoints.down('sm')]: {
                minHeight      : '100%',
                height         : '100%',
                paddingTop     : 0,
                paddingBottom  : 0,
                backgroundColor: colors.common.white,
              },
              '&.withoutForm': {
                paddingTop: '62px',
                [theme.breakpoints.down('sm')]: {
                  paddingTop: '20px',
                },
                '&.mobileSplash': {
                  [theme.breakpoints.down('sm')]: {
                    paddingTop: '0',
                    background: 'none',
                  },
                },
              },
            },
            '&.detailContainer': {
              [theme.breakpoints.up('md')]: {
                padding: '15px 0',
              },
            },
            '&.banner': {
              padding: '35px 16px',
              textAlign: 'center',
            },
            '&.lightBackground': {
              background: colors.grey[100],
            },
            '&.mediumBackground': {
              background: colors.grey[200],
              '&.banner': {
                [theme.breakpoints.down('sm')]: {
                  padding: '25px 16px',
                },
              },
            },
            '&.whiteMobileBackground': {
              [theme.breakpoints.down('sm')]: {
                background: colors.common.white,
              },
            },
            '&.titleContainer': {
              marginBottom: '40px',
              [theme.breakpoints.down('xs')]: {
                marginBottom: '15px',
              },
            },
            '&.modalContainer': {
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
              display: 'block',
              position: 'absolute',
              maxWidth: '800px',
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[5],
              padding: '12px 26px 26px',
              maxHeight: '80%',
              '&:focus': {
                outline: 0,
              },
              [theme.breakpoints.down('sm')]: {
                maxWidth : '500px',
                overflowY: 'scroll',
              },
              [theme.breakpoints.down('xs')]: {
                maxWidth: '300px',
                padding: '12px 16px 16px',
              },
            },
            '&.closeWrapper': {
              paddingBottom: '24px',
              display: 'block',
            },
            '&.formTermsWrapper': {
              padding: '10px 0',
              justifyContent: 'flex-end',
              [theme.breakpoints.up('md')]: {
                height: '40px',
              },
              [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
                paddingBottom: 0,
              },
            },
            '&.mobileCenter': {
              [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
              },
            },
            '&.assetTotal': {
              [theme.breakpoints.up('sm')]: {
                padding: '10px 0',
              },
            },
            '&.centerLargeItem': {
              maxWidth: '250px',
              width: '100%',
              margin: '0 auto',
              [theme.breakpoints.up('md')]: {
                marginBottom: '20px',
              },
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
              },
              '&.largeTabletItem': {
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                  marginBottom: 0,
                },
              },
              '&.field': {
                display: 'block',
              },
            },
            '&.mediumContainer': {
              maxWidth: '975px',
              width: '100%',
              margin: '0 auto',
            },
            '&.prefooterWrapper': {
              backgroundColor: colors.grey[100],
              padding: '2.5rem 1rem',
              marginTop: '4rem',
              borderBottom: `6px solid ${theme.palette.secondary.main}`,
              [theme.breakpoints.down('sm')]: {
                padding: '1.75rem 1rem',
                marginTop: 0,
              },
            },
            '&.heroImage': {
              height: '250px',
              marginBottom: '16px',
            },
            '&.homeHero': {
              height: '200px',
              [theme.breakpoints.up('sm')]: {
                height: '230px',
              },
              [theme.breakpoints.up('lg')]: {
                height: '250px',
              },
            },
            '&.branchHero': {
              height: '300px',
              padding: '0 16px',
              [theme.breakpoints.down('xs')]: {
                height: '200px',
              },
            },
            '&.fullTextArea': {
              width: '100%',
              '& > .wrapper': {
                width: '100%',
                maxWidth: '100%',
              },
            },
            '&.formNav': {
              marginTop: '40px',
              [theme.breakpoints.down('xs')]: {
                marginTop: '30px',
              },
            },
            '&.gradient': {
              display: 'block',
              flexWrap: 'unset',
              [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: theme.palette.secondary.dark,
                background: `linear-gradient(129deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.light} 100%)`,
              },
            },
            '&.indent': {
              borderLeft: `3px solid ${theme.palette.primary.main}`,
              paddingLeft: '12px',
              [theme.breakpoints.down('sm')]: {
                margin: '10px 0',
                borderLeft: 'none',
                paddingLeft: 0,
              },
            },
            '&.section': {
              borderBottom: `1px solid ${colors.grey[300]}`,
              marginBottom: '12px',
              paddingBottom: '10px',
            },
            '&.branchContainer': {
              maxWidth: '900px',
              width: '100%',
              margin: '0 auto',
              padding: '16px 0',
              textAlign: 'center',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                padding: '0 24px',
              },
              [theme.breakpoints.down('xs')]: {
                padding: '0 16px',
              },
              '&.mobileFullWidth': {
                padding: 0,
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
              },
              '&.branchContent': {
                [theme.breakpoints.up('md')]: {
                  minHeight: '400px',
                },
              },
            },
            '&.branchButtonContainer': {
              maxWidth: '580px',
              width: '100%',
              margin: '50px auto 0',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginTop: '20px',
              },
              [theme.breakpoints.down('xs')]: {
                marginTop: '30px',
              },
              '&.numberOfButtons-3': {
                maxWidth: '880px',
              },
              '&.numberOfButtons-4': {
                [theme.breakpoints.between('sm', 'sm')]: {
                  flexDirection: 'row',
                },
              },
            },
            '&.formWrapper': {
              display: 'block',
              maxWidth: '700px',
              width: '100%',
              margin: '0 auto',
              padding: '16px 0',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                padding: '0 24px',
              },
              [theme.breakpoints.down('xs')]: {
                padding: '0 16px',
              },
              '&.topSectionPadding': {
                padding: '22px 16px',
                [theme.breakpoints.up('md')]: {
                  marginTop: '10px',
                },
              },
              '&.schedule': {
                paddingTop: '40px',
                paddingBottom: '40px',
                [theme.breakpoints.down('sm')]: {
                  maxWidth: '700px',
                },
                [theme.breakpoints.down('xs')]: {
                  paddingTop: '25px',
                  paddingBottom: '25px',
                },
              },
              '&.stepper': {
                [theme.breakpoints.up('md')]: {
                  padding: 0,
                },
                [theme.breakpoints.down('sm')]: {
                  paddingTop: '22px',
                },
                [theme.breakpoints.down('xs')]: {
                  paddingTop: '12px',
                },
                '&.summary': {
                  paddingBottom: 0,
                },
              },
              '&.gradientForm': {
                [theme.breakpoints.up('md')]: {
                  padding: 0,
                },
              },
            },
            '&.summaryContainer': {
              [theme.breakpoints.down('sm')]: {
                padding: '0 24px 22px',
              },
              [theme.breakpoints.down('xs')]: {
                padding: '0 16px 22px',
              },
            },
            '&.recalculateContainer': {
              marginBottom: '28px',
              [theme.breakpoints.up('md')]: {
                marginTop: '30px',
              },
            },
            '&.sectionHeader': {
              marginBottom: '16px',
              padding: '22px 26px 30px',
              [theme.breakpoints.up('md')]: {
                borderTop: `1px solid ${theme.palette.primary.main}`,
              },
              [theme.breakpoints.down('sm')]: {
                background: colors.grey[100],
              },
              [theme.breakpoints.down('xs')]: {
                padding: '22px 16px',
              },
            },
            '&.recalculateTitleOnly': {
              padding: '30px 26px 0',
              [theme.breakpoints.down('xs')]: {
                padding: '22px 16px 16px',
              },
            },
            '&.wideGrid': {
              padding: unit * 2,
              maxWidth: '1212px',
              width: '100%',
              margin: '0 auto',
              [theme.breakpoints.between('sm', 'sm')]: {
                paddingLeft: '24px',
                paddingRight: '24px',
              },
              '&.sectionSpacing': {
                paddingBottom: '60px',
                paddingTop: 0,
                [theme.breakpoints.down('sm')]: {
                  paddingBottom: '40px',
                },
              },
              '&.removeMobilePadding': {
                [theme.breakpoints.down('sm')]: {
                  padding: 0,
                },
              },
              '&.disclaimerContainer': {
                paddingTop: '26px',
                paddingBottom: '10px',
                [theme.breakpoints.down('sm')]: {
                  padding: '16px 26px',
                },
                [theme.breakpoints.down('xs')]: {
                  padding: '8px 16px 16px',
                },
              },
            },
          },
          item: {
            '&.profileLabel': {
              [theme.breakpoints.up('md')]: {
                maxWidth: '200px',
                width   : '100%',
              },
            },
            '&.profileQuestion': {
              [theme.breakpoints.up('md')]: {
                flexGrow: 1,
              },
            },
            '&.lightBackground': {
              background: colors.grey[100],
            },
            '&.gridButton': {
              marginBottom: '30px',
              [theme.breakpoints.down('xs')]: {
                marginBottom: '20px',
              },
            },
            '&.centerToggle': {
              maxWidth: '400px',
              margin: '0 auto',
            },
            '&.termItem': {
              [theme.breakpoints.up('md')]: {
                position: 'absolute',
                bottom: '72px',
                right: 0,
              },
            },
            '&.sectionFooterItem': {
              [theme.breakpoints.up('md')]: {
                textAlign: 'center',
              },
              [theme.breakpoints.down('sm')]: {
                borderTop: `1px solid ${colors.grey[300]}`,
              },
            },
            '&.closeContainer': {
              position: 'relative',
              textAlign: 'right',
            },
            '&.wrapper': {
              position: 'relative',
              width: '100%',
              [theme.breakpoints.up('md')]: {
                maxWidth: '350px',
              },
            },
            '&.textRight': {
              textAlign: 'right',
            },
            '&.textCenter': {
              textAlign: 'center',
            },
            '&.textLeft': {
              textAlign: 'left',
            },
            '&.schedule': {
              margin: '10px 0 20px',
              [theme.breakpoints.down('sm')]: {
                margin: '10px 0 30px',
              },
            },
            '&.splitWrapper': {
              [theme.breakpoints.up('md')]: {
                padding: '4px 26px 16px',
              },
            },
            '&.splitImage': {
              minHeight: '500px',
              display: 'block',
            },
            '&.noStepper': {
              [theme.breakpoints.up('md')]: {
                marginTop: '20px',
              },
            },
            '&.noStepQuestion': {
              paddingTop: '20px',
            },
            '&.heroContent': {
              width: '100%',
            },
            '&.formNavItem': {
              [theme.breakpoints.up('sm')]: {
                marginRight: '42px',
              },
              [theme.breakpoints.down('xs')]: {
                width: '100%',
                textAlign: 'center',
                marginBottom: '16px',
              },
            },
            '&.assetIndent': {
              paddingBottom: '20px',
              [theme.breakpoints.up('md')]: {
                marginLeft: '16px',
                paddingBottom: '30px',
              },
            },
            '&.borderBox': {
              [theme.breakpoints.down('sm')]: {
                border: `1px solid ${colors.grey[300]}`,
              },
            },
          },
        },
        MuiFormGroup: {
          root: {
            '&.topLevel': {
              [theme.breakpoints.down('sm')]: {
                padding: '5px 0 10px',
                borderTop: `1px solid ${colors.grey[300]}`,
              },
              '&.first': {
                borderTop: 'none',
              },
            },
          },
        },
        MuiPaper: {
          root: {
            '&.formWrapper': {
              display: 'block',
              maxWidth: '700px',
              width: '100%',
              margin: '0 auto',
              padding: '16px 26px',
              position: 'relative',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                padding: '0 24px',
                minHeight: 0,
                boxShadow: 'none',
              },
              [theme.breakpoints.down('xs')]: {
                padding: '0 16px',
              },
              '&.paperWithoutForm': {
                [theme.breakpoints.up('md')]: {
                  marginTop: '10px',
                },
              },
              '&.topSectionPaper': {
                marginBottom: '30px',
                [theme.breakpoints.down('sm')]: {
                  background: colors.grey[50],
                  padding: '16px',
                  boxShadow: '0 2px 4px rgba(181,181,181,0.5)',
                  marginBottom: '4px',
                },
              },
              '&.sectionFooter': {
                marginTop: '30px',
                [theme.breakpoints.down('sm')]: {
                  padding: 0,
                  borderTop: `5px solid ${colors.grey[300]}`,
                  borderBottom: `5px solid ${theme.palette.secondary.main}`,
                },
                '&.removeSpacing': {
                  [theme.breakpoints.down('sm')]: {
                    marginTop: 0,
                  },
                },
              },
              '&.fieldWrapper': {
                [theme.breakpoints.up('md')]: {
                  padding: 0,
                  boxShadow: 'none',
                },
                [theme.breakpoints.down('sm')]: {
                  marginTop: '50px',
                  paddingTop: '16px',
                  paddingBottom: '30px',
                },
              },
              '&.topPaper': {
                paddingTop: '60px',
              },
            },
            '&.mobileTransparent': {
              [theme.breakpoints.down('sm')]: {
                background: 'none',
              },
            },
            '&.portalPaperPadding': {
              padding: unit * 3.75,
            },
          },
          elevation2: {
            '&.stopGate': {
              [theme.breakpoints.up('md')]: {
                padding: 0,
              },
            },
            '&.noPadding': {
              padding: 0,
            },
            '&.clearWrapper': {
              boxShadow: 'none',
              [theme.breakpoints.up('md')]: {
                background: colors.grey[100],
                maxWidth: '752px',
                paddingTop: 0,
              },
            },
            '&.mobileHideShadow': {
              [theme.breakpoints.down('sm')]: {
                boxShadow: 'none',
              },
            },
          },
        },
        MuiCircularProgress: {
          colorPrimary: {
            '&.mobileContrast': {
              [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary.contrastText,
              },
            },
          },
        },
        MuiExpansionPanel: {
          root: {
            '&.tileBoxExpansion': {
              margin: 0,
              padding: '10px 0',
              '&:before': {
                display: 'none',
              },
              '&$expanded': {
                paddingBottom: 0,
              },
            },
          },
        },
        MuiExpansionPanelSummary: {
          root: {
            minHeight: '48px',
            '&.removePadding': {
              padding: 0,
            },
            '&.condensed': {
              minHeight: '40px',
              margin: '0 10px',
              padding: '0 5px',
            },
          },
          expandIcon: {
            '&.condensedIcon': {
              height: '40px',
              width: '40px',
            },
          },
          content: {
            '&.rateDisplayContent': {
              fontWeight: 500,
              fontSize: 14,
              color: colors.grey[800],
              textDecoration: 'underline',
              textAlign: 'center',
              display: 'block',
              marginTop: '25px',
              '&$expanded': {
                margin: '25px 0 10px',
                fontSize: 16,
                textDecoration: 'none',
                textAlign: 'left',
                paddingTop: '20px',
                borderTop: `1px solid ${colors.grey[300]}`,
              },
            },
            '&.removeMargin': {
              margin: 0,
            },
            '&.alignCenter': {
              justifyContent: 'center',
            },
          },
        },
        MuiExpansionPanelDetails: {
          root: {
            '&.removePadding': {
              padding: 0,
            },
            '&.condensedDetails': {
              padding: '10px 0',
            },
          },
        },
        MuiAppBar: {
          root: {
            '&.header': {
              boxShadow: '0 2px 6px 0 rgba(229,229,229,0.5)',
              [theme.breakpoints.down('sm')]: {
                boxShadow   : 'none',
                borderBottom: `1px solid ${colors.grey[300]}`,
              },
            },
          },
        },
        MuiToolbar: {
          root: {
            '&.tableToolbar': {
              borderBottom: `2px solid ${colors.blueGrey[50]}`,
            },
          },
          regular: {
            '&.headerHeight': {
              height: '70px',
              minHeight: 0,
              [theme.breakpoints.down('xs')]: {
                height: '46px',
              },
            },
          },
        },
        MuiTableCell: {
          root: {
            '&.portalCell': {
              fontSize  : 14,
              border    : 'none',
              '&$body': {
                color: colors.grey[800],
                fontWeight: 300,
              },
              '&$head': {
                color     : colors.grey[400],
                fontWeight: 700,
              },
            },
          },
        },
        MuiTableRow: {
          root: {
            '&.portalRow': {
              height: '70px',
              cursor: 'pointer',
              '&:nth-child(4n+1)': {
                background: colors.blueGrey[50],
              },
              '&:hover': {
                background: darken(colors.blueGrey[50], 0.1),
              },
              '&$head': {
                background: 'none',
              },
              '&$selected': {
                backgroundColor: 'inherit',
                '&:nth-child(4n+1)': {
                  background: colors.blueGrey[50],
                },
                '& .portalCell': {
                  fontWeight: 500,
                  color     : colors.blue[700],
                },
              },
            },
            '&.collapsedRow': {
              height: 'auto',
              '&:nth-child(4n+2)': {
                background: colors.blueGrey[50],
              },
              '& > td': {
                padding: 0,
              },
            },
          },
        },
        MuiTableSortLabel: {
          root: {
            '&.portalLabel': {
              color: colors.grey[400],
              width: '100%',
              height: '62px',
              '&:hover': {
                color: colors.indigo.A700,
              },
              '&$active': {
                color: colors.indigo.A700,
              },
            },
          },
        },
        MuiTablePagination: {
          root: {
            '&.portalPagination': {
              height      : '70px',
              borderTop   : `2px solid ${colors.blueGrey[50]}`,
              marginBottom: '70px',
              '& $caption': {
                color: colors.grey[400],
                fontSize: 16,
              },
              '& $actions': {
                marginLeft: '10px',
              },
              '& $toolbar': {
                height: '70px',
              },
            },
          },
        },
        MuiCollapse: {
          wrapperInner: {
            '&.tableRowContainer': {
              padding: '10px 24px 30px',
            },
          },
        },
        MuiSelect: {
          root: {
            color: colors.grey[800],
            '&.portalPaginationSelect': {
              background: colors.blueGrey[50],
              fontSize  : 14,
              padding   : '5px',
              margin    : '0 30px 0 10px',
              '& > $selectMenu': {
                minHeight: 0,
                paddingRight: '10px',
                '&:focus': {
                  background: colors.blueGrey[50],
                },
              },
              '& > $icon': {
                color   : colors.grey[800],
                fontSize: 16,
                top     : 'calc(50% - 8px)',
              },
            },
          },
        },
        MuiMenuItem: {
          root: {
            color: colors.grey[800],
          },
        },
        MuiCssBaseline: {
          '@global': {
            html: {
              fontFamily: theme.typography.fontFamily,
            },
            a: {
              textDecoration: 'none',
              '&:focus': {
                outline: 0,
              },
            },
          },
        },
      },
    };

    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return ({
    primaryColor  : state.config.primaryColor,
    secondaryColor: state.config.secondaryColor,
    tertiaryColor : state.config.tertiaryColor,
  });
};

export const ThemeWrapper = connect(mapStateToProps)(ThemeWrapperComponent);
