import React from 'react';
import { Grid, Typography, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

const styles = (theme: Theme) => {
  return createStyles({
    row: {
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
  });
};

interface Props extends WithStyles<typeof styles>, WithTranslation {
  loanAmount : number;
}

class GenericLoanAmountComponent extends React.Component<Props, any> {
  render() {
    const { classes, loanAmount } = this.props;
    return (
      <Grid item xs={12} className={classes.row}>
        <Grid container alignItems='center'>
          <Grid item xs={12}>
            <Typography align='center' component='h3' classes={{ root: 'loanDisplay' }}>
              <Trans i18nKey='GenericLoan.summary.loanAmount' values={{ loanAmount }}>
                {'Loan: <strong>{{ loanAmount, currency.0 }}</strong>'}
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export const GenericLoanAmount = withStyles(styles)(withTranslation()(GenericLoanAmountComponent));
