import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

const PurchaseAppDisclosureComponent = () => {
  return (
    <>
      <Grid container style = {{ marginTop: 10, marginBottom: 10 }}>
        <Grid item>
          <Typography
            variant   = 'body2'
            component = 'span'
            classes   = {{ body2: 'small' }}
          >
            <Trans i18nKey={'purchaseApp.disclosure'}>

            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const PurchaseAppDisclosure = withTranslation()(PurchaseAppDisclosureComponent);
