import React from 'react';
import { FieldArray } from 'redux-form';
import { Select } from 'app/components/FormFields/Select';
import { Currency } from 'app/components/FormFields/Currency';
import { INCOME_SOURCE_OPTIONS } from 'app/models/options/options';
import { Button } from 'app/components/Button';
import { FieldNames } from 'app/models/fields/names';
import { requiredArray, noopValidate } from 'app/util/validators';
import { incomeInitialValue } from 'app/util/initial-values';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';

const renderIncomeSources = ({ ...props }) => {
  const { fields, name, popover, fieldPopover, isOptional, t } = props;
  const incomeSourceField  = `${name}.${FieldNames.incomeSource}`;
  const incomeMonthlyField = `${name}.${FieldNames.incomeMonthly}`;
  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={`${field}-${index}`} style={{ paddingBottom: 20 }}>
          <Select
            label        = {localizeLabel(incomeSourceField, t, 'Source of Additional Income')}
            name         = {`${field}.${FieldNames.incomeSource}`}
            options      = {localizeOptions(incomeSourceField, t, INCOME_SOURCE_OPTIONS)}
            fieldPopover = {fieldPopover}
            popover      = {popover}
            isOptional   = {isOptional}
          />
          <Currency
            label      = {localizeLabel(incomeMonthlyField, t, 'Gross Additional Monthly Income')}
            name       = {`${field}.${FieldNames.incomeMonthly}`}
            isOptional = {isOptional}
          />
          {/* Additional Income Sources can be optional, allow user to remove all */}
          <Button
            disableRipple
            color   = "primary"
            classes = {{
              root: 'link',
              text: 'remove',
            }}
            onClick = {() => fields.remove(index)}
          >
            {t(`question.${name}.button.remove`, { defaultValue: 'Remove' })}
          </Button>
        </div>
      );
    });
  return (
    <div>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(incomeInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Source' })}
      </Button>
    </div>
  );
};

export const IncomeSources = ({ ...props }) => {
  const { name, isOptional, popover, fieldPopover } = props;
  const { t }              = useTranslation();
  /* If the question isOptional, replace the required validator */
  const isRequired = isOptional ? noopValidate : requiredArray([FieldNames.incomeSource, FieldNames.incomeMonthly]);
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderIncomeSources}
        name      = {name}
        validate  = {[isRequired]}
        props     = {{
          name,
          isOptional,
          popover,
          fieldPopover,
          t,
        }}
      />
    </FormControl>
  );
};
