import { FieldNames } from 'app/models/fields/names';
import { Select } from 'app/components/FormFields/Select';
import { Currency } from 'app/components/FormFields/Currency';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { SHARED } from 'app/models/questions/shared';
import {
  HOME_EQUITY_LOAN_PURPOSE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  PROPERTY_USAGE_OPTIONS,
  YES_OR_NO_OPTIONS,
} from 'app/models/options/options';
import { Question } from 'app/models/types';
import { RadioField } from 'app/components/FormFields/RadioField';
import { CreditScoresRadio } from 'app/components/FormFields/CreditScoresRadio';
import { TextInput } from '../../components/FormFields/TextInput';
import { isAffinityProgram, isHomeEquityLoanPurposeOther } from '../fields/conditionals';
import { State } from '../../components/FormFields/State';
import { ZipCode } from '../../components/FormFields/ZipCode';

/**
 * Takes in a field name and returns the corresponding home equity
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const homeEquityQuestionByName = (name: string): Question => {
  return HOME_EQUITY_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding home equity
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const homeEquityQuestionById = (id: number): Question => {
  return HOME_EQUITY_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const homeEquityQuestionsByName = (names: string[] = []): Question[] => {
  const questions: any[] = [];
  for (const name of names) {
    questions.push(homeEquityQuestionByName(name));
  }
  return questions;
};

export const HOME_EQUITY_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: Currency,
    label    : 'Estimated Home Value',
    name     : FieldNames.homeValue,
  },
  {
    id       : 1002,
    component: CurrencyAllowZero,
    label    : 'Current Mortgage Balance',
    name     : FieldNames.mortgageBalance,
  },
  {
    id: 1003,
    ...SHARED[FieldNames.propertyType],
    component   : Select,
    options     : PROPERTY_TYPE_OPTIONS,
    fieldPopover: true,
  },
  {
    id: 1004,
    ...SHARED[FieldNames.propertyUsage],
    component   : Select,
    options     : PROPERTY_USAGE_OPTIONS,
    fieldPopover: true,
  },
  {
    id: 1005,
    ...SHARED[FieldNames.propertyZip],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.creditScore],
    component   : CreditScoresRadio,
    title       : 'What is your estimated credit score?',
    fieldPopover: false,
    popover     : null,
  },
  {
    id       : 1007,
    component: Currency,
    label    : 'Loan Amount',
    title    : 'How much would you like to borrow?',
    name     : FieldNames.loanAmount,
    popover  : 'You can\'t borrow more than the maximum loan amount shown on the previous page.',
    validator: {
      type : 'loanAmountIsValid',
      param: FieldNames.maxLoanAmount,
    },
  },
  {
    id: 1008,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1009,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1010,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1011,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1012,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1013,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1014,
    component: RadioField,
    name     : FieldNames.hasUniqueHomeEquityProgram,
    options  : YES_OR_NO_OPTIONS,
    title    : 'This is a unique question related to home equity programs',
  },
  {
    id           : 1015,
    component    : TextInput,
    label        : 'Branch Number',
    name         : FieldNames.branchNumber,
  },
  {
    id           : 1016,
    component    : TextInput,
    label        : 'Branch Employee',
    name         : FieldNames.branchEmployee,
  },
  {
    id       : 1017,
    component: RadioField,
    name     : FieldNames.homeEquityLoanPurpose,
    options  : HOME_EQUITY_LOAN_PURPOSE_OPTIONS,
    title    : 'What is the purpose of your home equity loan?',
  },
  {
    id       : 1018,
    component: TextInput,
    name     : FieldNames.homeEquityLoanPurposeOther,
    label    : 'Reason',
    showIf   : [isHomeEquityLoanPurposeOther],
  },
  {
    id       : 1019,
    component: TextInput,
    name     : FieldNames.propertyStreet,
    label    : 'Street Address',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id        : 1020,
    component : TextInput,
    name      : FieldNames.propertyStreet2,
    label     : 'Street Address 2',
    isOptional: true,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id       : 1021,
    component: TextInput,
    name     : FieldNames.propertyCity,
    label    : 'City',
  },
  {
    id       : 1022,
    component: State,
    name     : FieldNames.propertyState,
    label    : 'State',
  },
  {
    id       : 1023,
    component: ZipCode,
    name     : FieldNames.propertyZip,
    label    : 'Zip',
  },
  {
    id: 1024,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id        : 1025,
    component : TextInput,
    name      : FieldNames.employCompanyName,
    showIf    : [isAffinityProgram],
    title     : 'What company do you work for?',
  },
];

/**
 * List of home equity questions used to recalulate rates
 * on the first summary in the order they should be displayed
 */
export const homeEquityRecalculateQuestions = [
  // Estimated Home Value
  homeEquityQuestionById(1001),
  // Current Mortgage Balance
  homeEquityQuestionById(1002),
  // Property Type
  homeEquityQuestionById(1003),
  // Property Use
  homeEquityQuestionById(1004),
];
