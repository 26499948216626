/**
 * Set of utils for working with numbers. These helpers should not be used
 * in any redux form parsers/normalizers/etc.
 */

/**
 * Rounds a number down to the nearest one thousand
 *
 * @param {number} [num=0]
 * @returns {number}
 */
export const roundDownOneThousand = (num: number = 0): number => {
  return Math.floor(num/1000)*1000;
};
