import React from 'react';
import { Field } from 'redux-form';
import { required, noopValidate } from 'app/util/validators';
import { renderTextFieldBase } from 'app/components/FormFields/_renderTextFieldBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';

export const TextArea = ({ ...props }) => {
  const { name, isOptional } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired = isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete     = {mapAutoCompleteName(name)}
      component        = {renderTextFieldBase}
      fieldPopover     = {props.fieldPopover}
      inputGridProps   = {props.inputGridProps}
      label            = {props.label}
      maxLength        = {500}
      name             = {name}
      rows             = {5}
      multiline        = {true}
      validate         = {[isRequired]}
    />
  );
};
