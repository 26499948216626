import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { FieldNames } from 'app/models/fields/names';
import { BANKRUPT_TYPE_OPTIONS, BANKRUPT_AGE_OPTIONS } from 'app/models/options/options';
import { BankruptType } from 'app/models/options/enums';
import { Select } from 'app/components/FormFields/Select';
import { IndentedWrapper } from 'app/components/IndentedWrapper';
import { nestedValueByString } from 'app/util/misc';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';
import { isHomeEquityLoan } from 'app/models/fields/conditionals';

export const DeclarationBankruptDetails = ({ values, ...props }) => {
  const borrower = props.name;
  // ie: decBorrower.bankruptType
  const bankruptTypePath = `${borrower}${FieldNames.bankruptType}`;
  const bankruptAgePath  = `${borrower}${FieldNames.bankruptAge}`;
  const years            = nestedValueByString(values, bankruptAgePath);
  const chapterSeven     = nestedValueByString(values, bankruptTypePath) === BankruptType.SEVEN;
  const chapterThirteen  = nestedValueByString(values, bankruptTypePath) === BankruptType.THIRTEEN;
  const conditionsMet    = (years && chapterSeven) || (years && chapterThirteen);
  const { t }            = useTranslation();

  const renderEligiblity = (): JSX.Element => {
    if (isHomeEquityLoan(values) || !conditionsMet) {
      return <React.Fragment />;
    }

    return (
      <div>
        <Typography
          variant = 'body2'
          color   = 'primary'
          classes = {{ root: 'infoTitle' }}
        >
          <InfoOutlined color='primary' classes={{ root: 'info' }} />
          {t('bankruptDetails.title', {
            defaultValue: 'Based on the selected conditions above:',
          })}
        </Typography>
      </div>
    );
  };

  const renderEligiblityTerms = (): JSX.Element => {
    if (isHomeEquityLoan(values)) {
      return <React.Fragment />;
    }

    if (chapterSeven) {
      if (years >= 4) {
        return (
          <Typography variant='body2'>
            {t('bankruptDetails.chapterSeven.fourToSevenYears', {
              defaultValue:
                'Based on the time since your bankruptcy was discharged or dismissed, you may be eligible for a conventional, FHA or rural development loan.',
            })}
          </Typography>
        );
      }
      if (years >= 3) {
        return (
          <Typography variant='body2'>
            {t('bankruptDetails.chapterSeven.threeYears', {
              defaultValue:
                'Based on the time since your bankruptcy was discharged or dismissed, you may be eligible for a FHA or rural development loan.',
            })}
          </Typography>
        );
      }
      if (years >= 2) {
        return (
          <Typography variant='body2'>
            {t('bankruptDetails.chapterSeven.twoYears', {
              defaultValue:
                'Based on the time since your bankruptcy was discharged or dismissed, you may only be eligible for a FHA loan.',
            })}
          </Typography>
        );
      }
      if (years >= 1) {
        return (
          <Typography variant='body2'>
            {t('bankruptDetails.chapterSeven.oneYear', {
              defaultValue:
                'Based on the time since your bankruptcy was discharged or dismissed, there may be no loan programs available. Two years after discharge, you may be eligible for a FHA loan.',
            })}
          </Typography>
        );
      }
    }
    if (chapterThirteen) {
      if (years >= 2) {
        return (
          <Typography variant='body2'>
            {t('bankruptDetails.chapterThirteen.twoToSevenYears', {
              defaultValue:
                'There may be several loan programs available depending on the exact date your bankruptcy was discharged or dismissed.',
            })}
          </Typography>
        );
      }
      if (years >= 1) {
        return (
          <Typography variant='body2'>
            {t('bankruptDetails.chapterThirteen.oneYear', {
              defaultValue:
                'Based on the time since your bankruptcy was discharged or dismissed, you may be eligible for a FHA or rural development loan but several conditions will apply.',
            })}
          </Typography>
        );
      }
    }
    return <React.Fragment/>;
  };

  return (
    <IndentedWrapper>
      <Select
        fieldPopover = {true}
        label        = {localizeLabel(bankruptTypePath, t, 'What type of bankruptcy?')}
        name         = {bankruptTypePath}
        options      = {localizeOptions(bankruptTypePath, t, BANKRUPT_TYPE_OPTIONS)}
        popover      = {t(`question.${bankruptTypePath}.popover`, {
          defaultValue:
            `Chapter 7 bankruptcy erases all debt other than federal loans and child support; Chapter 11 bankruptcy involves a reorganization of a debtor's business affairs, debts, and assets; Chapter 12 bankruptcy applies specifically to farms and fisheries; Chapter 13 bankruptcy is a re-organization of debt, to be repaid in full.`,
        })}
      />
      <Select
        label   = {localizeLabel(bankruptAgePath, t, 'How long since discharge?')}
        name    = {bankruptAgePath}
        options = {localizeOptions(bankruptAgePath, t, BANKRUPT_AGE_OPTIONS)}
      />
      <Grid>
        {renderEligiblity()}
        {renderEligiblityTerms()}
      </Grid>
    </IndentedWrapper>
  );
};
