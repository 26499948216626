import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { Button } from 'app/components/Button';
import { FieldNames } from 'app/models/fields/names';
import { AccountType } from 'app/models/options/enums';
import { APPLICATION_PURCHASE_FUNDS_SECTION } from 'app/models/sections/constants';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_TYPE_PURCHASE_OPTIONS } from 'app/models/options/options';

/**
 * Takes in the value associated with the account type and returns the user
 * friendly title. (ie: accountType of SALEPROCEEDS returns the title of
 * "Proceeds from Sale of Home")
 *
 * @param {AccountType} accountType
 * @returns {string | null}
 */
const getAccountTypeLabel = (accountType: AccountType) => {
  const option = ACCOUNT_TYPE_PURCHASE_OPTIONS.find((account) => {
    return account.value === accountType;
  });
  return option ? option.title : null;
};

const mapAssets = ({ assets, homeSaleProceeds }) => {
  if (homeSaleProceeds) {
    const otherAssets = [
      {
        accountType: 'Proceeds From Home Sale',
        accountBalance: homeSaleProceeds,
      },
    ];
    return otherAssets.concat(assets);
  }
  return assets;
};

export const AssetSummary = ({ values, goToSection }) => {
  const assets = mapAssets(values) || [];
  const assetsTotal = assets.reduce((a, b) => {
    return a + b[FieldNames.accountBalance];
  }, 0);
  const difference            = assetsTotal - values[FieldNames.downDollar];
  const availableFundsSection = APPLICATION_PURCHASE_FUNDS_SECTION;
  const { t }                 = useTranslation();

  return (
    <div>
      <Grid container={false}>
        <Grid item classes={{ item: 'assetIndent' }}>
          <Grid
            container
            spacing    = {4}
            classes    = {{ container: 'assetTotal' }}
            alignItems = "baseline"
          >
            <Grid item xs={8} sm={6}>
              <Typography variant="body1" classes={{ root: 'mobileAsset', body1: 'assetTitle' }}>
                {t(`assetSummary.${FieldNames.downDollar}.label`, {
                  defaultValue: 'Amount of Down Payment',
                })}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6}>
              <Typography
                variant = "body2"
                color   = "primary"
                classes = {{ root: 'mobileAsset largeAsset warning' }}
              >
                {t('assetSummary.downPayment', {
                  defaultValue: '{{ downPayment, currency.0 }}',
                  downPayment : values[FieldNames.downDollar],
                })}
                {difference > -1 ? null : (
                  <ErrorOutline color="error" classes={{ root: 'warning' }} />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" classes={{ body1: 'assetTitle' }}>
            {t('assetSummary.subtitle.funds', { defaultValue: 'Funds From Assets' })}
          </Typography>
          {assets.map((asset, index) => {
            return (
              <div key={index}>
                <Grid container spacing={4} alignItems="baseline">
                  <Grid item xs={8} sm={6}>
                    <Typography
                      variant = "body1"
                      classes = {{ root: 'mobileAsset largeLabel' }}
                    >
                      {t(`assetSummary.${FieldNames.accountType}.${asset[FieldNames.accountType]}.label`, {
                        defaultValue: getAccountTypeLabel(asset[FieldNames.accountType]),
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6}>
                    <Typography
                      variant = "body2"
                      color   = "primary"
                      classes = {{ root: 'mobileAsset largeAsset' }}
                    >
                      {t('assetSummary.accountBalance', {
                        accountBalance: asset[FieldNames.accountBalance],
                        defaultValue: '{{ accountBalance, currency.0 }}',
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Grid>
      </Grid>
      {difference > -1 ? null : (
        <div>
          <Typography variant="body2" component="span" classes={{ body2: 'inline' }}>
            <ErrorOutline color="error" classes={{ root: 'warning left' }} />
            {t('assetSummary.warning.additionalDownPayment', {
              difference: Math.abs(difference),
              defaultValue     :
                'You require an additional {{ difference, currency.0 }} in assets to support your down payment.',
            })}
          </Typography>
        </div>
      )}
      <Button
        color   = "primary"
        variant = "outlined"
        classes = {{ root: 'change condensed' }}
        onClick = {() => goToSection(availableFundsSection)}
      >
        {t('assetSummary.button.edit', { defaultValue: 'Make Changes' })}
      </Button>
    </div>
  );
};
