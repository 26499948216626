import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Event from '@material-ui/icons/Event';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

export const DateInput = (props) => {
  const { meta, input } = props;
  const { touched, error } = meta;
  const { onBlur, value, ...inputProps } = input;
  const shouldShowError = touched && !!error;

  const { t } = useTranslation();

  const handleDateChange = (value) => {
    moment(value).isValid() ? inputProps.onChange(value.toISOString()) : inputProps.onChange(null);
  };

  return (
    <Fragment>
      <KeyboardDatePicker
        animateYearScrolling
        cancelLabel        = {t('button.datePicker.cancel', { defaultValue: 'Cancel' })}
        error              = {shouldShowError}
        format             = "MM-DD-YYYY"
        fullWidth
        label              = {props.label}
        leftArrowIcon      = {<KeyboardArrowLeft />}
        helperText         = {touched && error}
        invalidDateMessage = {null}
        keyboardIcon       = {<Event />}
        maxDateMessage     = {null}
        minDateMessage     = {null}
        onChange           = {(date) => handleDateChange(date) }
        onFocus            = {props.input.onFocus}
        onBlur             = {() => onBlur(value ? moment(value).toISOString() : null)}
        okLabel            = {t('button.datePicker.submit', { defaultValue: 'OK' })}
        placeholder        = "MM-DD-YYYY"
        rightArrowIcon     = {<KeyboardArrowRight />}
        value              = {moment(value).isValid() ? moment(value) : null }
        {...props.datePickerProps}
      />
    </Fragment>
  );
};
