import React from 'react';
import { Confirmation } from 'app/components/Confirmation';
import { LastStep } from 'app/components/LastStep';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { FormTerms } from 'app/components/FormAgreements/FormTerms';
import { FormParagraphs } from 'app/models/paragraphs';
import { FieldNames } from 'app/models/fields/names';
import {
  hasProceedsFromSellingHome,
  isHomeowner,
  isRenting,
  isRentFree,
  isEmployee,
  isSelfEmployed,
  isRetired,
  isEmploymentOther,
  hasCoBorrower,
  schedulerEnabled,
  isCoEmployee,
  isCoSelfEmployed,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
  isExcludingSsnQuestions,
} from 'app/models/fields/conditionals';
import {
  getConstructionDeclarationQuestions,
} from 'app/models/questions/declarations';
import { BorrowerType } from 'app/models/options/enums';
import { constructionLFQuestionsByName } from '../questions/application-construction';
import {
  CONSTRUCTION_GET_AVAILABLE_FUNDS,
  CONSTRUCTION_GET_CITIZEN_AND_MILITARY,
  CONSTRUCTION_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
  CONSTRUCTION_GET_COBORROWER_HOUSING_INFORMATION,
  CONSTRUCTION_GET_COBORROWER_PERSONAL_INFORMATION,
  CONSTRUCTION_GET_CONSTRUCTION_INFORMATION,
  CONSTRUCTION_GET_CONSTRUCTION_LOCATION_INFORMATION,
  CONSTRUCTION_GET_FICO_MARRIAGE_AND_DEPENDENTS,
  CONSTRUCTION_GET_HOUSING_OWNER_INFORMATION,
  CONSTRUCTION_GET_PERSONAL_INFORMATION,
  CONSTRUCTION_GET_PURCHASE_PLAN_CURRENT_HOUSING_SITUATION,
  CONSTRUCTION_GET_SELL_CURRENT_HOUSE,
  CONSTRUCTION_GET_HOUSING_RENTER_INFORMATION,
  CONSTRUCTION_GET_HOUSING_FREE_INFORMATION,
  CONSTRUCTION_GET_ADDITIONAL_PROPERTIES,
  CONSTRUCTION_GET_BORROWER_INCOME_SOURCE,
  CONSTRUCTION_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
  CONSTRUCTION_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
  CONSTRUCTION_GET_COBORROWER_INCOME_SOURCE,
  CONSTRUCTION_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
  CONSTRUCTION_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
  CONSTRUCTION_GET_BORROWER_QUESTIONS,
  CONSTRUCTION_GET_COBORROWER_QUESTIONS,
  CONSTRUCTION_GET_BORROWER_SSN,
  CONSTRUCTION_CONFIRMATION, CONSTRUCTION_LAST_STEP,
} from './constants';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';

export const getApplicationConstructionSectionById = (id: number): Section => {
  return APPLICATION_CONSTRUCTION_SECTIONS.find((section) => section.id === id) as Section;
};

const APPLICATION_CONSTRUCTION_SECTIONS: Section[] = [
  {
    id: CONSTRUCTION_GET_PERSONAL_INFORMATION,
    title: 'Enter your personal information',
    questions: constructionLFQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
      FieldNames.constructionLoanType,
    ]),
    agreement: <FormTerms />,
    hideBackButton: true,
  },
  {
    id: CONSTRUCTION_GET_CONSTRUCTION_INFORMATION,
    title: 'Tell us about your new construction',
    questions: constructionLFQuestionsByName([
      FieldNames.constructionCost,
      FieldNames.downDollar,
      FieldNames.propertyUsage,
      FieldNames.constructionLandOwner,
      FieldNames.constructionLandAcquired,
      FieldNames.constructionLandCost,
    ]),
  },
  {
    id: CONSTRUCTION_GET_CONSTRUCTION_LOCATION_INFORMATION,
    title: 'translation here and essential',
    questions: constructionLFQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
  },
  {
    id: CONSTRUCTION_GET_AVAILABLE_FUNDS,
    title: 'Available Funds',
    paragraph: FormParagraphs.availableFunds,
    questions: constructionLFQuestionsByName([FieldNames.assets]),
  },
  {
    id: CONSTRUCTION_GET_FICO_MARRIAGE_AND_DEPENDENTS,
    questions: constructionLFQuestionsByName([
      FieldNames.creditScore,
      FieldNames.maritalStatus,
      FieldNames.numOfDependent,
      FieldNames.dependentsAges,
    ]),
  },
  {
    id: CONSTRUCTION_GET_CITIZEN_AND_MILITARY,
    questions: constructionLFQuestionsByName([
      FieldNames.citizenshipType,
      FieldNames.militaryExperience,
    ]),
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_PERSONAL_INFORMATION,
    questions: constructionLFQuestionsByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerOnTitle,
    ]),
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_HOUSING_INFORMATION,
    showIf: [hasCoBorrower],
    questions: constructionLFQuestionsByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerLivingRentOrOwn,
      FieldNames.coBorrowerLivingMonthlyRentAmount,
      FieldNames.coBorrowerMaritalStatus,
    ]),
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_CITIZEN_AND_MILITARY_INFORMATION,
    showIf: [hasCoBorrower],
    questions: constructionLFQuestionsByName([
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerMilitaryExperience,
    ]),
  },
  {
    id: CONSTRUCTION_GET_PURCHASE_PLAN_CURRENT_HOUSING_SITUATION,
    title: 'What is your current housing situation?',
    questions: constructionLFQuestionsByName([FieldNames.livingRentOrOwn]),
  },
  {
    id: CONSTRUCTION_GET_SELL_CURRENT_HOUSE,
    questions: constructionLFQuestionsByName([FieldNames.homeSoldYN]),
    showIf: [(values) => !hasProceedsFromSellingHome(values) && isHomeowner(values)],
  },
  {
    id: CONSTRUCTION_GET_HOUSING_OWNER_INFORMATION,
    showIf: [isHomeowner],
    title: 'Provide your homeowner information',
    questions: constructionLFQuestionsByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingEscrowUsage,
    ]),
  },
  {
    id: CONSTRUCTION_GET_HOUSING_RENTER_INFORMATION,
    showIf: [isRenting],
    title: 'Provide your renter information',
    questions: constructionLFQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
    ]),
  },
  {
    id: CONSTRUCTION_GET_HOUSING_FREE_INFORMATION,
    showIf: [isRentFree],
    title: 'Provide your housing information',
    questions: constructionLFQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
    ]),
  },
  {
    id: CONSTRUCTION_GET_ADDITIONAL_PROPERTIES,
    title: 'Do you own any additional properties?',
    questions: constructionLFQuestionsByName([
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
    ]),
    showIf: [isHomeowner],
  },
  {
    id: CONSTRUCTION_GET_BORROWER_INCOME_SOURCE,
    title: 'What is your income source?',
    questions: constructionLFQuestionsByName([FieldNames.employmentType]),
  },
  {
    id: CONSTRUCTION_GET_BORROWER_INCOME_EMPLOYED_OR_SELF,
    title: 'Tell us about your income and employment',
    questions: constructionLFQuestionsByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOwnershipShare,
      FieldNames.employPartyTransaction,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf: [isEmployee, isSelfEmployed],
  },
  {
    id: CONSTRUCTION_GET_BORROWER_INCOME_RETIRED_OR_OTHER,
    questions: constructionLFQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [isRetired, isEmploymentOther],
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_INCOME_SOURCE,
    showIf: [hasCoBorrower],
    title: `What is your co-borrower's income source?`,
    questions: constructionLFQuestionsByName([FieldNames.coBorrowerEmploymentType]),
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_INCOME_EMPLOYED_OR_SELF,
    title: `Tell us about your co-borrower's income and employment`,
    questions: constructionLFQuestionsByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployPhone,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployOwnershipShare,
      FieldNames.coBorrowerEmployPartyTransaction,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
    ]),
    showIf: [isCoEmployee, isCoSelfEmployed],
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_INCOME_RETIRED_OR_OTHER,
    questions: constructionLFQuestionsByName([
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerOtherIncome,
    ]),
    showIf: [isCoBorrowerRetired, isCoBorrowerEmploymentOther],
  },
  {
    id: CONSTRUCTION_GET_BORROWER_QUESTIONS,
    title: 'Borrower Questions',
    questions: getConstructionDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf: [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction: FormActions.handleLongFormConstructionSubmit,
    submitText: 'Submit',
  },
  {
    id: CONSTRUCTION_GET_COBORROWER_QUESTIONS,
    title: 'Co-Borrower Questions',
    questions: getConstructionDeclarationQuestions(BorrowerType.COBORROWER),
    showIf: [hasCoBorrower],
    submitIf: [isExcludingSsnQuestions],
    submitAction: FormActions.handleLongFormConstructionSubmit,
    submitText: 'Submit',
  },
  {
    id: CONSTRUCTION_GET_BORROWER_SSN,
    questions: constructionLFQuestionsByName([FieldNames.ssnQuestionRequirement]),
    showIf: [(values) => !isExcludingSsnQuestions(values)],
    submitAction: FormActions.handleLongFormConstructionSubmit,
    agreement: <AuthorizeSsnFullPull />,
  },
  {
    id: CONSTRUCTION_LAST_STEP,
    ifNoQuestions: LastStep,
    clearWrapper: true,
    hideProgressBar: true,
    // Conditional is redundant since the component is skipping the section itself,
    // but it is being used here for clarity.
    showIf: [schedulerEnabled],
  },
  {
    id: CONSTRUCTION_CONFIRMATION,
    ifNoQuestions: Confirmation,
    hideProgressBar: true,
  },
];
