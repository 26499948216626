import { IntegerWithSeparators } from 'app/components/FormFields/IntegerWithSeparators';
import { RadioField } from 'app/components/FormFields/RadioField';
import { Select } from 'app/components/FormFields/Select';
import { isMultiFamilyHome } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import { YesOrNo } from 'app/models/options/enums';
import { BUILDING_UNITS_OPTIONS, CONSTRUCTION_HOME_OPTIONS, CONSTRUCTION_ROOFING_OPTIONS, HOME_STORIES_OPTIONS } from 'app/models/options/options';
import { Questions } from 'app/models/questions/Questions';

export const INSURANCE_QUOTE_QUESTIONS: Questions = new Questions([
  {
    id           : 1001,
    component    : IntegerWithSeparators,
    title        : 'What is the living area of your home?',
    endAdornment : 'Square feet',
    name         : FieldNames.livingArea,
    validator    : {
      type  : 'isGreaterThanZero',
      param : FieldNames.landSize,
    },
  },
  {
    id         : 1002,
    component  : Select,
    options    : HOME_STORIES_OPTIONS,
    title      : 'How many stories does your home have?',
    name       : FieldNames.homeStories,
    isOptional : true,
  },
  {
    id         : 1003,
    component  : Select,
    options    : CONSTRUCTION_HOME_OPTIONS,
    title      : 'What is the home construction type?',
    name       : FieldNames.constructionHomeType,
    isOptional : true,
  },
  {
    id         : 1004,
    component  : Select,
    options    : CONSTRUCTION_ROOFING_OPTIONS,
    title      : 'What is the roofing type?',
    name       : FieldNames.constructionRoofingType,
    isOptional : true,
  },
  {
    id         : 1005,
    component  : Select,
    options    : BUILDING_UNITS_OPTIONS,
    title      : 'How many units in your building?',
    name       : FieldNames.buildingUnits,
    isOptional : true,
    showIf     : [isMultiFamilyHome],
  },
  {
    id: 1006,
    name: FieldNames.canReceiveInsuranceQuote,
    component: RadioField,
    options: [
      { title: 'Yes, I would like to receive a quote', value: YesOrNo.YES },
      { title: 'No, not at this time', value: YesOrNo.NO },
    ],
  },
]);
