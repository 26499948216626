import React from 'react';
import { Field } from 'redux-form';
import { renderSelectBase } from 'app/components/FormFields/_renderSelectBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import { STATE_OPTIONS } from 'app/models/options/options';
import { noopValidate, required } from 'app/util/validators';

export const State = ({ ...props }) => {
  const { name, label, isOptional } = props;
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      component    = {renderSelectBase}
      label        = {label}
      name         = {name}
      options      = {STATE_OPTIONS}
      validate     = {[isRequired]}
    />
  );
};

