import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { documentUploaderSelector } from 'app/reducers/app-config';

type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = WithTranslation & MappedProps;

const OverdraftProtectionConfirmationComponent = (props: Props) => {
  const { confirmationImage, documentUploader } = props;
  const hasDocumentUploader = !!documentUploader;

  const documentUploaderMessage = <React.Fragment>
    <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
      <Trans i18nKey='overdraftProtectionConfirmation.documentUpload.title'>
        Please upload the following documents:
      </Trans>
    </Typography>
    <Typography variant='body2' paragraph={true}>
      <Trans i18nKey='overdraftProtectionConfirmation.documentUpload.paragraph1'>
        <ul>
          <li>Copy of Driver's License</li>
          <li>Proof of Income: W2s and Paystubs (2)</li>
        </ul>
      </Trans>
    </Typography>
  </React.Fragment>;

  const thankYouMessage = <React.Fragment>
    <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
      <Trans i18nKey='overdraftProtectionConfirmation.thankYou.title'>
        Thank you!
      </Trans>
    </Typography>
    <Typography variant='body2' paragraph={true}>
      <Trans i18nKey='overdraftProtectionConfirmation.thankYou.paragraph1'>
        A bank officer will be in touch with you in the next business day or two.
      </Trans>
    </Typography>
  </React.Fragment>;

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          { hasDocumentUploader ? documentUploaderMessage : thankYouMessage }
          <Grid container justifyContent='center'>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  documentUploader  : documentUploaderSelector(state),
  confirmationImage : state.config.confirmationImage,
});

export const OverdraftProtectionConfirmation = connect(mapStateToProps, null)(withTranslation()(OverdraftProtectionConfirmationComponent));
