import React from 'react';
import { Select } from 'app/components/FormFields/Select';
import { FieldNames } from 'app/models/fields/names';
import { PROPERTY_USAGE_OPTIONS, TITLE_HELD_OPTIONS } from 'app/models/options/options';
import { IndentedWrapper } from 'app/components/IndentedWrapper';
import { useTranslation } from 'react-i18next';
import { localizeOptions, localizeLabel } from 'app/i18n/helpers';

export const DeclarationOwnershipInterest = ({ ...props }) => {
  const borrower          = props.name;
  const propertyUsagePath = `${borrower}${FieldNames.ownerPropertyUsage}`;
  const titleHeldPath     = `${borrower}${FieldNames.ownerTitleHeld}`;
  const { t }             = useTranslation();
  return (
    <IndentedWrapper>
      <Select
        label   = {localizeLabel(propertyUsagePath, t, 'Property Usage')}
        name    = {propertyUsagePath}
        options = {localizeOptions(propertyUsagePath, t, PROPERTY_USAGE_OPTIONS)}
      />
      <Select
        label   = {localizeLabel(titleHeldPath, t, 'How did you hold title to the loan?')}
        name    = {titleHeldPath}
        options = {localizeOptions(titleHeldPath, t, TITLE_HELD_OPTIONS)}
      />
    </IndentedWrapper>
  );
};
