import { Type, ZipCodeValidationState } from '../actions/form/zip-code-validation/types';

const initialState: ZipCodeValidationState = {
  isZipModalOpen      : false,
  zipCode             : undefined,
};

export const zipCodeValidationReducer = (state = initialState, action): ZipCodeValidationState => {
  switch (action.type) {
    case Type.AJAX_VALIDATE_ZIP_CODE:
      return { ...state };
    case Type.AJAX_VALIDATE_ZIP_CODE_SUCCESS:
      return { ...state, ...action.payload, isZipModalOpen: true };
    case Type.AJAX_VALIDATE_ZIP_CODE_FAIL:
      return { ...state, ...initialState };
    case Type.MODAL_ZIP_CODE_VALIDATION_CLOSE:
      return { ...state, isZipModalOpen: false };

    default:
      return state;
  }
};
