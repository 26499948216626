import { LoanPurpose } from '@lenderful/domain';
import { CreateInsuranceQuoteRequest } from 'app/api/insurance-quote/types';
import { mapBorrowers, mapSubjectProperty } from 'app/api/loan-application/mappings';
import { FieldNames } from 'app/models/fields/names';

/**
 * This maps the values in the backing redux-form to a request object that is
 * used to create insurance quotes.
 *
 * @param {any} form
 * @param {number} loanOfficerId loan officer associated to quote
 * @param {number} loanAppId loan application to associated to quote
 * @returns {CreateInsuranceQuoteRequest}
 */
export const mapInsuranceQuote = (form, loanOfficerId?: number, loanAppId?: number): CreateInsuranceQuoteRequest => ({
  application: {
    borrowers           : mapBorrowers(form),
    subjectProperty     : mapSubjectProperty(form),
    loanAppId,
    loanPurpose         : mapLoanPurpose(form),
    loanType            : form[FieldNames.loanType],
    loanAmount          : form[FieldNames.loanAmount],
    expectedClosingDate : form[FieldNames.expectedClosingDate],
    apr                 : form[FieldNames.rate],
    loanTerm            : form[FieldNames.loanTerm],
    monthlyPayment      : form[FieldNames.monthlyPayment],
  },
  loanOfficerId,
});

/**
 * This maps the correct form field to the loan purpose. It whitelists
 * the possible options for what we pass to create the quote.
 *
 * @param form
 * @returns {LoanPurpose | undefined}
 */
const mapLoanPurpose = (form): LoanPurpose | undefined => {
  const loanPurposeWhitelist = [
    LoanPurpose.PURCHASE,
    LoanPurpose.HELOC,
    LoanPurpose.CONSTRUCTION,
    LoanPurpose.MONTHLY,
    LoanPurpose.CONSOLIDATE,
    LoanPurpose.CASHOUT,
    LoanPurpose.TERMS,
  ];
  return loanPurposeWhitelist.find(item => item === form[FieldNames.loanPurpose]);
};
