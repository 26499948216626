import moment from 'moment';

/**
 * Checks to see if a date is before today's date.
 *
 * @param {Date} [date=new Date()]
 * @returns {boolean} True if input date is before today's date
 */
export const isBeforeToday = (date: Date = new Date()): boolean => {
  const today = moment();
  const inputDate = moment(date).format();
  return moment(inputDate).isBefore(today);
};

export const splitDateAndTime = (date: string):string => {
  if(date.includes('T')) {
    return date.split('T')[0];
  }
  return date;
};
